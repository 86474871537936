import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/commonservices';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { GrowlerService } from '../../services/growler.service';
import { Projectcreate } from '../../model/projectcreate';
import * as $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';

@Component({
  selector: "app-project-create",
  templateUrl: "./project-create.component.html",
  styleUrls: ["./project-create.component.scss"],
})
export class ProjectCreateComponent implements OnInit {
  checkValidPriorityTitle: boolean = false;
  StrategicPriorityInitativeId: number;
  StrategicPriorityTitle: any;
  selectedUser: any;
  loading: boolean = false;
  createOwner: any;
  defaultOwner: any = "-1";
  colsapnValue: any = 1;
  mergeTdRemove: number;
  pType: any;
  gType: any;
  showPeriods: boolean = true;
  renameStrategic: boolean = false;
  showInitiative: boolean = true;
  showHidePriorityGroup: boolean = true;
  PriorityArraykeys: number = 0;
  message: string;
  indexvalue: number = 0;
  mergeStoreTaskID: any;
  mergeStoreTaskCurrentID: any;
  searchUsers: any = [];
  initiativeValueArray: any = [];
  isOwner: any = false;
  strategicPriorityTitleList: any = [];
  strategicPriorityTitleListData: any = [];
  StrategicPriorityInitative: any;
  StrategicPriorityInitativeDesc: any;
  StrategicPriorityPeriod: any;
  strategicGroup: any = {};
  renameStrategicDropdown: boolean = true;
  ownerDropdown: boolean = true;
  initiativeArray: any = [];
  strategicPeriodsArray: any = [];
  addMorestrategicInitiativeArray: any = [];
  addMorestrategicPriorityArray: any = [];
  periodslistArray: any = [];
  extraColumnlistArray: any = [];
  initiativelistArray: any = [];
  prioritylistArray: any = [];
  projectDetail: any = [];
  activeProjectId: any;
  deletedMergeTaskId: any = [];

  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    public growlerService: GrowlerService
  ) {}
  public Projectcreate: Projectcreate = {} as Projectcreate;
  gallery = $(
    "table.time_management_personal-table tr td:not(.disableResizeDrag)"
  );

  ngOnInit(): void {
    this.activeProjectId = localStorage.getItem("editProjectId");
    this.pType = localStorage.getItem("pType");
    this.gType = localStorage.getItem("gType");
    this.setStrategicGroup();
    this.getProjectData();
    this.getOwnerbyOrganizationList();
  }
  editPage(is_group, project_type) {
    // localStorage.setItem("editProjectId", this.activeProjectId);
    // localStorage.setItem("pType", project_type);
    // localStorage.setItem("gType", is_group);
    this.router.navigateByUrl("/project-view");
  }
  /******* Jquery Initialization ****************/
  initialisation() {
    $(function () {
      $(".sortable").sortable({
        placeholder: "ui-state-highlight",
        handle: ".moveIcon",
        stop: function (event, ui) {
          let strgIdForBox = ui.item.find(".hiddendata").val();
          let boxIndex = ui.item.index();
          self.changeOrder(strgIdForBox, boxIndex);
        },
      });
      $(".sortable").disableSelection();

      let deleId: any;
      let deleTitle: any;
      var taskTrId: any;
      let pos: any;
      $(".sortabletask").sortable({
        items: ".ui-state-enabled",
        start: function (event, ui) {
          var start_pos = ui.item.index();
          ui.item.data("start_pos", start_pos);
          taskTrId = ui.item.closest("tr").attr("id");
        },
        change: function (event, ui) {
          var start_pos = ui.item.data("start_pos");
          var index = ui.placeholder.index();

          if (start_pos < index) {
            pos = "Inc";
            deleId = $(
              ".sortabletask#" + taskTrId + " td:nth-child(" + index + ")"
            ).attr("id");
          } else {
            pos = "Dec";
            deleId = $(
              ".sortabletask#" + taskTrId + " td:eq(" + (index + 1) + ")"
            ).attr("id");
          }
        },
        update: function (event, ui) {
          var startIndex = ui.item.attr("id");
          var startTaskId = Number(startIndex.split("-")[0]);
          var startPeriodId = Number(startIndex.split("-")[1]);
          var startTitle = startIndex.split("-")[2];
          var deltaskId = Number(deleId.split("-")[0]);
          var delperiodId = Number(deleId.split("-")[1]);
          var deleTitle = deleId.split("-")[2];
          var taskIdToText = deleTitle;
          this.strategicPriorityTitleListData = [];
          self.labelTaskDrag(
            startPeriodId,
            delperiodId,
            deleTitle,
            startTaskId,
            deltaskId,
            pos
          );
        },
      });
      $(".sortabletask").disableSelection();
    });

    var self = this;
    self.BindResizing(
      $("table.time_management_personal-table tr").find(
        "td:not(.disableResizeDrag):not(:last)"
      )
    );

    self.BindDragDrop(".dragDivOuter");
    $(document).on("click", ".dragDivOuter input", function () {
      $(this).focus();
    });

    /**
     * selector will only a string
     */

    var $gallery = $(
      "table.time_management_personal-table tr td:not(.disableResizeDrag)"
    );
  }

  /****Go to Project setting page from edit project setting option ****/
  goToProjectSetting(editProjId, projectName, project_type) {
    localStorage.setItem("editProjectId", editProjId);
    localStorage.setItem("projectName", projectName);
    localStorage.setItem("pType", project_type);
    this.router.navigateByUrl("/project-settings");
  }

  /******* Column Resizing function ****************/
  BindResizing(selectedElements) {
    var self = this;
  //  selectedElements = "";  // This is to disable resizable functionality as client does not require it now, In future if required again then remove/comment this line  - 04-07-2022
    $(selectedElements).resizable({
      handles: "e",
      helper: "ui-resizable-helper",
      stop: function (event, ui) {
        
        let taskIdFrom = $(ui.element)
          .closest("td")
          .find("div")
          .children(".getPeriodId")
          .val();
        let taskIdTo = $(ui.element)
          .closest("td")
          .next("td")
          .find("div")
          .children(".getPeriodId")
          .val();
        let taskIdToText = $(ui.element)
          .closest("td")
          .next("td")
          .find("div")
          .children("label")
          .html();
        let taskIdFromText = $(ui.element)
          .closest("td")
          .find("div")
          .children("label")
          .text();

        let initativeRowId = $(ui.element)
          .closest("td")
          .find("div")
          .children(".getTaskId")
          .val();
        let taskTo = $(ui.element)
          .closest("td")
          .next("td")
          .find("div")
          .children(".getTaskId")
          .val();
        this.strategicPriorityTitleList = [];
        $("#updateperiodid" + initativeRowId + "-" + taskIdFrom).val(
          taskIdFrom + "," + taskIdTo
        );

        if (ui.originalSize.width < ui.size.width) {
          self.updateTaskPeriod(
            taskIdFrom,
            taskIdTo,
            taskIdToText,
            initativeRowId,
            taskTo,
            "Dec",
            taskIdFromText
          );
          if ($(ui.element).next().hasClass("disableResizeDrag")) {
            return false;
          }
          var maxAllowedWidth = ui.size.width;
          var initWidth = ui.originalSize.width;
          //start with 0
          var initIndex = $(ui.element).index() + 1;
          var initColSpan = parseInt($(ui.element).prop("colspan")) || 1;

          var colLength =
            $(ui.element).closest("tr").find("td").length -
            $(ui.element)
              .closest("tr")
              .find("td")
              .filter(function () {
                return (
                  $(this).index() > initIndex &&
                  $(this).hasClass("disableResizeDrag")
                );
              }).length;

          while (initWidth <= maxAllowedWidth && initIndex < colLength) {
            //Remove last 2 params due to firefox compatitbility
            var selectEleWidth =
              $($(ui.element).closest("tr").find("td")[initIndex]).width() +
              parseFloat(
                $($(ui.element).closest("tr").find("td")[initIndex]).css(
                  "padding-left"
                )
              ) +
              parseFloat(
                $($(ui.element).closest("tr").find("td")[initIndex]).css(
                  "padding-right"
                )
              ); // + parseFloat($(ui.element).css("border-left")) + parseFloat($(ui.element).css("border-right"))

            //Add some constant as border
            selectEleWidth = (selectEleWidth || 0) + 2;

            if (selectEleWidth) {
              initColSpan +=
                $($(ui.element).closest("tr").find("td")[initIndex]).prop(
                  "colspan"
                ) || 1;
              initWidth += selectEleWidth || 0;
            }

            initIndex += 1;
          }
          $(ui.element).prop("colspan", initColSpan);

          $(ui.element)
            .closest("tr")
            .find("td")
            .filter(function () {
              return (
                $(this).index() < initIndex &&
                $(this).index() > $(ui.element).index()
              );
            })
            .remove();

          ui.size.width = ui.originalSize.width;

          $(ui.element)
            .closest("table.time_management_personal-table")
            .find("td")
            .removeAttr("style");

          var columnSize = $(ui.element).closest("tr").find("td").length;

          if (
            $(ui.element)
              .closest("table.time_management_personal-table")
              .find("tr")
              .filter(function () {
                return $(this).find("td").length != columnSize;
              }).length == 0
          ) {
            $(ui.element)
              .closest("table.time_management_personal-table")
              .find("td")
              .removeAttr("colspan");
          }
        } else if (ui.originalSize.width > ui.size.width) {
          self.updateTaskPeriod(
            taskIdFrom,
            taskIdTo,
            taskIdToText,
            initativeRowId,
            taskTo,
            "Inc",
            taskIdFromText
          );
          var initColSpan = parseInt($(ui.element).prop("colspan") || 1);

          if (initColSpan > 1) {
            $(ui.element).prop("colspan", initColSpan - 1);

            var newColumn = $(ui.element)
              .clone()
              .removeAttr("colspan")
              .removeAttr("style");
            newColumn.find("div:not(.dragDivOuter)").remove();

            $(newColumn)
              .find(".dragDivOuter")
              .html(
                $("<label>", {
                  placeholder: "Enter task here",
                  class: "text-center",
                  style: "border:none",
                  type: "text",
                })
              );

            $(ui.element).after(newColumn);

            this.gallery = $(
              "table.time_management_personal-table tr td:not(.disableResizeDrag)"
            );
            self.BindDragDrop(".dragDivOuter");
            self.BindResizing(newColumn);
          }

          $("table.time_management_personal-table tr")
            .find("td:not(.disableResizeDrag):last")
            .filter(function () {
              return (parseInt($(this).attr("colspan")) || 0) <= 1;
            })
            .addClass("ui-resizable-disabled");
        }
      },
    });
  }

  /******* Column Drag-Drop function ****************/
  BindDragDrop(selector) {
    var self = this;
    if (typeof this.gallery == "undefined" || this.gallery == undefined) {
      this.gallery = $(
        "table.time_management_personal-table tr td:not(.disableResizeDrag)"
      );
    }

    // Let the gallery items be draggable
    $(selector, this.gallery).draggable({
      cancel: "a.ui-icon", // clicking an icon won't initiate dragging
      revert: "invalid", // when not dropped, the item will revert back to its initial position
      containment: "document",
      helper: "clone",
      cursor: "move",
    });

    // Let the gallery be droppable as well, accepting items from the trash
    this.gallery.droppable({
      accept: selector, //"table.time_management_personal-table tr td:not(.disableResizeDrag) .dragDivOuter",
      classes: {
        "ui-droppable-active": "custom-state-active",
      },
      drop: function (event, ui) {
        //debugger;
        self.DragEvent(event, ui);
      },
    });
  }
  /******* Jquery Drag Event ****************/
  DragEvent(event, ui) {
    if (
      $(ui.draggable).closest("tr").find(event.target).length == 0 ||
      $(event.target).hasClass("disableResizeDrag")
    ) {
      return false;
    }

    var clone = $(ui.draggable).clone();
    this.AddDragDropEvent(clone);
    $(event.target).html(clone[0]);
    $(ui.draggable).html(
      $("<label>", {
        placeholder: "Enter task here",
        class: "text-center",
        style: "border:none; width:100%",
        type: "text",
      })
    );

    if (
      $(event.target)
        .closest("tr")
        .find("td:not(.disableResizeDrag):last")
        .not(event.target).length == 1 ||
      $(event.target)
        .closest("tr")
        .find("td:not(.disableResizeDrag):last")
        .filter(function () {
          return (parseInt($(this).attr("colspan")) || 0) > 1;
        }).length == 1
    ) {
      this.BindResizing($(event.target));
    }
  }
  /******* add drag event function ****************/
  AddDragDropEvent(selector) {
    $(selector, this.gallery).draggable({
      cancel: "a.ui-icon", // clicking an icon won't initiate dragging
      revert: "invalid", // when not dropped, the item will revert back to its initial position
      containment: "document",
      helper: "clone",
      cursor: "move",
    });
  }
  /******* Set initialize array function ****************/
  setStrategicGroup() {
    this.strategicGroup = {
      editstrategicPriorityText: false,
      // editstrategicPriorityLabel: false,
      editstrategicInitiativelabel: true,
      editstrategicPriorityPeriod: true,
    };
  }
  /******* add create owner coloumn function ****************/
  addCreateOwner(i, StrategicID: any) {
    this.createOwner = StrategicID;
    this.selectedUser = true;
    this.isOwner = true;

    let data = {
      id: 0,
      projectId: this.activeProjectId,
      title: "text",
      isOwner: 1,
      isDeleted: 0,
    };

    this.commonService
      .addUpdateExtraColumn(data)
      .subscribe((addUpdateExtraColumnResult) => {
        this.loading = false;
        if (!addUpdateExtraColumnResult["success"]) {
          this.message = addUpdateExtraColumnResult["error"];
        }
        if (
          addUpdateExtraColumnResult["success"] &&
          addUpdateExtraColumnResult["data"].ResponseCode == 200
        ) {
          this.showInitiative = true;
          this.ownerDropdown = false;
          this.setProjectData(addUpdateExtraColumnResult);
        } else {
          this.message = addUpdateExtraColumnResult["data"].ResponseMessage;
        }
      });
  }
  /******* Default set owner function ****************/
  defultSetOwner(ownerId: any) {
    if (ownerId) {
      this.defaultOwner = ownerId;
      //return ownerId
    } else {
      this.defaultOwner = "-1";
      //return -1
    }
  }
  /******* colspan function ****************/
  setColspanValue(initativeRowId, init: any, periodId: number, i) {
    let currentId = init[periodId].periodId;
    let nextperiodId: number;
    if (
      $("#" + periodId)
        .parent("th")
        .next()
        .children("label")
        .attr("id") != undefined
    ) {
      nextperiodId = $("#" + periodId)
        .parent("th")
        .next()
        .children("label")
        .attr("id");
    }
    this.colsapnValue = 1;
    this.mergeTdRemove = 0;
    this.mergeStoreTaskID = i;
    this.mergeStoreTaskCurrentID = i;
    let taskIds: any = currentId;

    if (init[periodId]) {
      while (init[nextperiodId] != undefined) {
        if (init[nextperiodId] != undefined) {
          if (init[currentId].Task.id == init[nextperiodId].Task.id) {
            this.colsapnValue++;
            if (
              $("#" + nextperiodId)
                .parent("th")
                .next()
                .children("label")
                .attr("id") != undefined
            ) {
              taskIds = taskIds + "," + nextperiodId;
              nextperiodId = $("#" + nextperiodId)
                .parent("th")
                .next()
                .children("label")
                .attr("id");
            } else {
              break;
            }
          } else {
            break;
          }
        } else {
          break;
        }
      }

      $("#updateperiodid" + init[currentId].Task.id + "-" + periodId).val(
        taskIds
      );
      this.mergeStoreTaskID =
        parseInt(this.colsapnValue) + parseInt(this.mergeStoreTaskID);
      //return this.colsapnValue;
    }
  }

  /******* set drag drop event function ****************/
  initiativeFunc(inIt, pId) {
    let PrePerIodId: number;
    if (
      $("#" + pId)
        .parent("th")
        .prev()
        .children("label")
        .attr("id") != undefined
    ) {
      PrePerIodId = $("#" + pId)
        .parent("th")
        .prev()
        .children("label")
        .attr("id");
    }
    //return
    let aTaskId: any;
    let bTaskId: any;
    if (inIt[PrePerIodId] != undefined) {
      aTaskId = inIt[pId].Task.id;
      bTaskId = inIt[PrePerIodId].Task.id;
      if (aTaskId == bTaskId) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }
  /******* check merge column function ****************/
  checkMergeColumn(taskId: any) {
    var isPresent = 0;
    isPresent = this.deletedMergeTaskId.some(function (el) {
      if (el == taskId) {
        return 1;
      }
    });
    return isPresent;
  }
  /******* owner list API function ****************/
  getOwnerbyOrganizationList() {
    let lastOrgId = localStorage.getItem("orgId");
    let getOwner = {
      projectId: this.activeProjectId,
    };
    this.commonService
      .GetUserListingProjectwise(getOwner)
      .subscribe((getOwnerbyOrganizationResult) => {
        this.loading = false;
        //this.searchUsers.push({"id":"-1","user_name":"all"})
        if (!getOwnerbyOrganizationResult["success"]) {
          this.message = getOwnerbyOrganizationResult["error"];
        }
        if (
          getOwnerbyOrganizationResult["success"] &&
          getOwnerbyOrganizationResult["data"].ResponseCode == 200
        ) {
          this.loading = false;
          getOwnerbyOrganizationResult["data"].ResponseData[0].push({
            id: "-1",
            roleId: "0",
            orgination: "all",
            user_name: "all",
            title: "all",
            is_active: 1,
          });
          this.searchUsers = this.changeObjToArray(
            getOwnerbyOrganizationResult["data"].ResponseData[0]
          );
        } else {
          this.message = getOwnerbyOrganizationResult["data"].ResponseMessage;
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
      });
  }
  /******* get project info. function ****************/
  getProjectData() {
    this.loading = true;
    let data = {
      projectId: this.activeProjectId,
    };
    this.commonService
      .getProjectData(data)
      .subscribe((getProjectDataResult) => {
        if (!getProjectDataResult["success"]) {
          this.message = getProjectDataResult["error"];
        }
        if (
          getProjectDataResult["success"] &&
          getProjectDataResult["data"].ResponseCode == 200
        ) {
          this.loading = false;
          console.log(getProjectDataResult["data"].ResponseData.Projectsetting);
          this.projectDetail =
            getProjectDataResult["data"].ResponseData.Projectsetting;
          this.strategicPriorityTitleList = this.changeObjToArraySort(
            getProjectDataResult["data"].ResponseData.ProjectData
          );
          this.strategicPriorityTitleList["ProjectPeriod"] =
            getProjectDataResult["data"].ResponseData.ProjectPeriod;
          this.strategicPriorityTitleList["ExtraColumn"] =
            getProjectDataResult["data"].ResponseData.ExtraColumn;
          let projectSetting =
            getProjectDataResult["data"].ResponseData.Projectsetting;

          this.strategicPriorityTitleList.forEach((myObject, index) => {
            if (myObject.InitativeData == undefined) {
              myObject.InitativeData = {
                0: {
                  id: 0,
                  title: "",
                  description: "",
                  priority: 0,
                  userId: null,
                  period: {
                    0: {
                      periodId: 0,
                      allRatings: {},
                      Task: { id: 0, title: "" },
                    },
                  },
                },
              };
            } else {
              if (Object.keys(myObject.InitativeData).length) {
                myObject.InitativeData[-1] = {
                  id: 0,
                  title: "",
                  description: "",
                  priority: 0,
                  userId: null,
                  period: {
                    0: {
                      periodId: 0,
                      allRatings: {},
                      Task: { id: 0, title: "" },
                    },
                  },
                };
              }
            }
          });

          if (!this.strategicPriorityTitleList.length) {
            //console.log('balckk');
            this.strategicPriorityTitleList.push({
              id: 0,
              title: "",
              priority: 1,
              InitativeData: {
                0: {
                  id: 0,
                  title: "",
                  description: "",
                  priority: 0,
                  userId: null,
                  period: {
                    0: {
                      periodId: 0,
                      allRatings: {},
                      Task: { id: 0, title: "" },
                    },
                  },
                },
              },
            });
          }

          if (!this.strategicPriorityTitleList["ProjectPeriod"].length) {
            this.strategicPriorityTitleList["ProjectPeriod"].push({
              id: 0,
              title: "",
            });
          }

          if (projectSetting.ownerColumn) {
            this.isOwner = true;
            this.ownerDropdown = false;
          }
          setTimeout(() => {
            this.initialisation();
          }, 1000);
        } else {
          this.message = getProjectDataResult["data"].ResponseMessage;
        }
      });
  }
  /*******Update project title function ****************/
  updateProjectTitle(projectTitle) {
    this.loading = true;
    let data = {
      projectId: this.activeProjectId, //this.activeProjectId,
      projectOwner: this.projectDetail.owner_id,
      organization: localStorage.getItem("orgId"),
      projectType: this.pType,
      projectName: projectTitle.value,
      currentPeriod: "",
      teamLeader: this.projectDetail.teamLeader,
      group: this.gType,
      projectActive: true,
    };

    this.commonService.createProject(data).subscribe((result) => {
      this.loading = false;
      if (!result["success"]) {
        this.message = result["error"];
      }
      if (result["success"] && result["data"].ResponseCode == 200) {
        let msg = "Project title updated successfully.";
        this.growlerService.success(msg);
        this.growlerService.clear();
      } else {
        this.message = result["data"].ResponseMessage;
      }
    });
  }
  /******* label drag function ****************/
  labelTaskDrag(
    startPeriodId,
    delperiodId,
    taskIdToText,
    startTaskId,
    delTaskId,
    pos
  ) {
    let Ids: any;
    if (taskIdToText) {
      if (confirm("Are you sure to drag this task")) {
        this.dragDropTask(
          startPeriodId,
          delperiodId,
          taskIdToText,
          startTaskId,
          delTaskId
        );
      } else {
        //console.log('cancel');
        window.location.reload();
      }
    }
  }
  /******* drag drop task function ****************/
  dragDropTask(
    startPeriodId,
    delperiodId,
    taskIdToText,
    startTaskId,
    delTaskId
  ) {
    let data = {
      period: delperiodId,
      id: startTaskId,
      projectId: this.activeProjectId,
      deleteTask: delTaskId,
    };
    this.commonService
      .updateTaskPeriod(data)
      .subscribe((updateTaskPeriodResult) => {
        this.loading = false;

        if (!updateTaskPeriodResult["success"]) {
          this.message = updateTaskPeriodResult["error"];
        }
        if (
          updateTaskPeriodResult["success"] &&
          updateTaskPeriodResult["data"].ResponseCode == 200
        ) {
          window.location.reload();
          this.growlerService.success("Sucessfully drag and drop task.");
          this.growlerService.clear();
        } else {
          this.message = updateTaskPeriodResult["data"].ResponseMessage;
        }
      });
  }
  /******* change order priority boxes function ****************/
  changeOrder(id, index) {
    this.loading = true;
    let data = {
      id: id,
      order: parseInt(index) + 1,
      object: "strategy",
      projectId: this.activeProjectId,
    };
    this.commonService.changeOrder(data).subscribe((changeOrderResult) => {
      if (!changeOrderResult["success"]) {
        this.message = changeOrderResult["error"];
      }
      if (
        changeOrderResult["success"] &&
        changeOrderResult["data"].ResponseCode == 200
      ) {
        this.strategicPriorityTitleList =
          changeOrderResult["data"].ResponseData.ProjectData;
        this.strategicPriorityTitleList["ProjectPeriod"] =
          changeOrderResult["data"].ResponseData.ProjectPeriod;
        this.strategicPriorityTitleList["ExtraColumn"] =
          changeOrderResult["data"].ResponseData.ExtraColumn;

        // setTimeout(() => {
        this.growlerService.success("Successfully move priority.");
        this.growlerService.clear();
        this.loading = false;
        //window.location.reload();
        //}, 2000)
      } else {
        this.message = changeOrderResult["data"].ResponseMessage;
      }
    });
  }
  /******* Update Task period function ****************/
  updateTaskPeriod(fromid, toid, taskText, InId, taskTo, pos, taskIdFromText) {
    let Ids: any;
    let taskTextlabel: any;
    if (pos == "Dec") {
      Ids = fromid + "," + toid;
      //console.log(taskText+'--'+taskIdFromText);
      taskTextlabel = taskText;
      //console.log('OOO');
    } else {
      Ids = fromid;
      taskTextlabel = taskText;
    }
    //return;
    if (taskTextlabel != undefined) {
      if (confirm("Are you sure to merge this task")) {
        this.mergeTask(
          fromid,
          toid,
          taskText,
          InId,
          taskTo,
          pos,
          taskIdFromText
        );
      } else {
        //console.log('cancel');
        this.loading = true;
        window.location.reload();
      }
    } else {
      this.mergeTask(fromid, toid, taskText, InId, taskTo, pos, taskIdFromText);
    }
  }
  /******* Merge Task function ****************/
  mergeTask(fromid, toid, taskText, InId, taskTo, pos, taskIdFromText) {
    let Ids: any;
    //return
    if (pos == "Dec") {
      Ids = fromid + "," + toid;
    } else {
      Ids = fromid.replace(/,[^,]+$/, "");
    }

    let data = {
      period: Ids,
      id: InId,
      projectId: this.activeProjectId,
      deleteTask: taskTo,
    };
    this.commonService
      .updateTaskPeriod(data)
      .subscribe((updateTaskPeriodResult) => {
        this.loading = false;

        if (!updateTaskPeriodResult["success"]) {
          this.message = updateTaskPeriodResult["error"];
        }
        if (
          updateTaskPeriodResult["success"] &&
          updateTaskPeriodResult["data"].ResponseCode == 200
        ) {
          this.setProjectData(updateTaskPeriodResult);
          this.growlerService.success("Sucessfully merge task.");
          this.growlerService.clear();
          window.location.reload();
        } else {
          this.message = updateTaskPeriodResult["data"].ResponseMessage;
        }
      });
  }
  /******* Update period function ****************/
  addUpdateProjectPeriod(i: number, editPeriodId, editPeriodText, strateID) {
    if (strateID && strateID != 0) {
      this.periodslistArray = this.strategicPriorityTitleList["ProjectPeriod"];

      this.loading = true;
      let data = {};
      if (this.periodslistArray[i].title) {
        data = {
          id: 0,
          projectId: this.activeProjectId,
          title: this.periodslistArray[i].title,
        };
      } else {
        this.loading = false;
        //console.log('mjk22');
        this.growlerService.error("Strategic Period is required.");
        this.growlerService.clear();
      }

      if (editPeriodId != 0) {
        data = {
          id: editPeriodId,
          projectId: this.activeProjectId,
          title: editPeriodText,
        };
      }

      this.commonService
        .addUpdateProjectPeriod(data)
        .subscribe((addUpdateProjectPeriodResult) => {
          this.loading = false;
          if (!addUpdateProjectPeriodResult["success"]) {
            this.message = addUpdateProjectPeriodResult["error"];
          }
          if (
            addUpdateProjectPeriodResult["success"] &&
            addUpdateProjectPeriodResult["data"].ResponseCode == 200
          ) {
            //this.setProjectData(addUpdateProjectPeriodResult);
            this.strategicPriorityTitleList = this.changeObjToArray(
              addUpdateProjectPeriodResult["data"].ResponseData.ProjectData
            );
            this.strategicPriorityTitleList["ProjectPeriod"] =
              addUpdateProjectPeriodResult["data"].ResponseData.ProjectPeriod;
            this.strategicPriorityTitleList["ExtraColumn"] =
              addUpdateProjectPeriodResult["data"].ResponseData.ExtraColumn;
            if (!this.strategicPriorityTitleList["ProjectPeriod"].length) {
              this.strategicPriorityTitleList["ProjectPeriod"].push({
                id: 0,
                title: "",
              });
            }
            this.strategicPriorityTitleList.forEach((myObject, index) => {
              if (myObject.InitativeData == undefined) {
                myObject.InitativeData = {
                  0: {
                    id: 0,
                    title: "",
                    description: "",
                    priority: 0,
                    userId: null,
                    period: {
                      0: {
                        periodId: 0,
                        allRatings: {},
                        Task: { id: 0, title: "" },
                      },
                    },
                  },
                };
              } else {
                //console.log('AA');
                if (Object.keys(myObject.InitativeData).length) {
                  myObject.InitativeData[-1] = {
                    id: 0,
                    title: "",
                    description: "",
                    priority: 0,
                    userId: null,
                    period: {
                      0: {
                        periodId: 0,
                        allRatings: {},
                        Task: { id: 0, title: "" },
                      },
                    },
                  };
                }
              }
            });
            this.growlerService.success("Strategic Period successfully added.");
            this.growlerService.clear();
          } else {
            this.message = addUpdateProjectPeriodResult["data"].ResponseMessage;
          }
        });
    } else {
      $("#periodtext" + editPeriodId).val("");
      $("#editstrategicPriorityPeriodtext" + i).val("");
      this.loading = false;
      this.growlerService.error(
        "First add Strateric Priority title than add period."
      );
      this.growlerService.clear();

      //window.location.reload();
    }
  }
  /******* add extra column function ****************/
  addUpdateExtraColumn(i: number, editExtraId, editExtraText, strateID) {
    // debugger
    this.extraColumnlistArray = this.strategicPriorityTitleList["ExtraColumn"];
    //console.log(this.extraColumnlistArray[i].title);
    this.loading = true;
    if (this.extraColumnlistArray[i].title) {
      let data = {
        id: 0,
        projectId: this.activeProjectId,
        title: this.extraColumnlistArray[i].title,
        isOwner: 0,
        isDeleted: 0,
      };

      if (editExtraId != 0) {
        data = {
          id: editExtraId,
          projectId: this.activeProjectId,
          title: editExtraText,
          isOwner: 0,
          isDeleted: 0,
        };
      }
      // console.log(data);

      this.commonService
        .addUpdateExtraColumn(data)
        .subscribe((addUpdateExtraColumnResult) => {
          this.loading = false;
          if (!addUpdateExtraColumnResult["success"]) {
            this.message = addUpdateExtraColumnResult["error"];
          }
          if (
            addUpdateExtraColumnResult["success"] &&
            addUpdateExtraColumnResult["data"].ResponseCode == 200
          ) {
            this.setProjectData(addUpdateExtraColumnResult);

            this.growlerService.success("Successfully added extra column.");
            this.growlerService.clear();
          } else {
            this.message = addUpdateExtraColumnResult["data"].ResponseMessage;
          }
        });
    } else {
      this.loading = false;
    }
  }
  /******* Rename prority title  function ****************/
  renameStrategicPriority(i, Id: number, title: any) {
    //console.log('kkkkk');
    this.renameStrategic = true;
    setTimeout(() => {
      document.getElementById(
        "editstrategicPriorityTextEdit" + i
      ).hidden = false;
    }, 1000);
  }

  /******* Add Strategic Priority Title API ****************/
  addStrategicPriority(i, Id: number, e) {
    this.loading = true;
    this.checkValidPriorityTitle = false;
    let data = {};
    if (e.value == "" && this.strategicPriorityTitleList.length == 0) {
      this.growlerService.error("Strategic priority title is required.");
      this.growlerService.clear();
      this.loading = false;
      return;
    } else {
    }

    if (this.strategicPriorityTitleList.length != 0) {
      if (!this.strategicPriorityTitleList[i].title) {
        this.growlerService.error("Strategic priority title is required.");
        this.growlerService.clear();
        this.loading = false;
        return;
      }
    }

    //return

    if (e.value) {
      data = {
        id: 0,
        projectId: this.activeProjectId,
        priorityTitle: e.value,
      };
    } else {
      if (Id) {
        data = {
          id: Id,
          projectId: this.activeProjectId,
          priorityTitle: this.strategicPriorityTitleList[i].title,
        };
      } else {
        data = {
          id: 0,
          projectId: this.activeProjectId,
          priorityTitle: this.strategicPriorityTitleList[i].title,
        };
      }
    }
    this.commonService
      .addStrategicPriority(data)
      .subscribe((addStrategicPriorityResult) => {
        this.loading = false;
        if (!addStrategicPriorityResult["success"]) {
          this.message = addStrategicPriorityResult["error"];
        }
        if (
          addStrategicPriorityResult["success"] &&
          addStrategicPriorityResult["data"].ResponseCode == 200
        ) {
          this.renameStrategic = false;
          //this.setProjectData(addStrategicPriorityResult);
          this.strategicPriorityTitleList = this.changeObjToArray(
            addStrategicPriorityResult["data"].ResponseData.ProjectData
          );

          this.strategicPriorityTitleList.forEach((myObject, index) => {
            if (myObject.InitativeData == undefined) {
              myObject.InitativeData = {
                0: {
                  id: 0,
                  title: "",
                  description: "",
                  priority: 0,
                  userId: null,
                  period: {
                    0: {
                      periodId: 0,
                      allRatings: {},
                      Task: { id: 0, title: "" },
                    },
                  },
                },
              };
            } else {
              if (Object.keys(myObject.InitativeData).length) {
                myObject.InitativeData[-1] = {
                  id: 0,
                  title: "",
                  description: "",
                  priority: 0,
                  userId: null,
                  period: {
                    0: {
                      periodId: 0,
                      allRatings: {},
                      Task: { id: 0, title: "" },
                    },
                  },
                };
              }
            }
          });

          this.strategicPriorityTitleList["ProjectPeriod"] =
            addStrategicPriorityResult["data"].ResponseData.ProjectPeriod;
          this.strategicPriorityTitleList["ExtraColumn"] =
            addStrategicPriorityResult["data"].ResponseData.ExtraColumn;
          if (!this.strategicPriorityTitleList["ProjectPeriod"].length) {
            this.strategicPriorityTitleList["ProjectPeriod"].push({
              id: 0,
              title: "",
            });
          }
          let keys = Object.keys(this.strategicPriorityTitleList);
          this.PriorityArraykeys = keys.length;

          this.growlerService.success("Strategic priority successfully added.");
          this.growlerService.clear();
        } else {
          this.message = addStrategicPriorityResult["data"].ResponseMessage;
        }
      });
  }
  /******* Change Object to array function ****************/
  changeObjToArray(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });
    return project;
  }

  /******* Change Object to array function ****************/
  changeInitiativeObjToArray(projectData) {
    let project: any = [];
    if (projectData != undefined) {
      project = Object.keys(projectData).map(function (personNamedIndex) {
        let project = projectData[personNamedIndex];
        return project;
      });
    }

    project.push({
      id: 0,
      title: "",
      description: "",
      priority: 0,
      userId: null,
      period: {
        0: {
          periodId: 0,
          allRatings: {},
          Task: { id: 0, title: "" },
        },
      },
    });
    return project;
  }

  /******* remove index function ****************/
  changeObjToArrayRemoveIndex(projectData) {
    //console.log(projectData)
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });
    return project;
  }
  /******* Array sort function ****************/
  changeObjToArraySort(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });

    project.sort(function (a, b) {
      var keyA = a.priority,
        keyB = b.priority;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return project;
  }
  /******* add initiative function ****************/
  addUpdatePriorityInitative(sgIndex, i, strategicPriorityId: any, inputtype) {
    if (strategicPriorityId) {
      this.strategicPriorityTitleList[sgIndex][
        "InitativeData"
      ] = this.changeObjToArray(
        this.strategicPriorityTitleList[sgIndex]["InitativeData"]
      );
      this.loading = true;
      let data = {};
      if (
        inputtype == "desc" &&
        this.strategicPriorityTitleList[sgIndex]["InitativeData"][i]
          .description == ""
      ) {
        this.growlerService.error(
          "Priority Initiative description is required field."
        );
        this.growlerService.clear();
      }
      if (
        inputtype == "selectedUser" &&
        this.strategicPriorityTitleList[sgIndex]["InitativeData"][i].userId ==
          ""
      ) {
        this.growlerService.error(
          "Priority Initiative Owner field is required."
        );
        this.growlerService.clear();
      }

      if (this.strategicPriorityTitleList[sgIndex]["InitativeData"][i].title) {
        if (this.strategicPriorityTitleList[sgIndex]["InitativeData"][i].id) {
          data = {
            priorityId: strategicPriorityId,
            title: this.strategicPriorityTitleList[sgIndex]["InitativeData"][i]
              .title,
            id: this.strategicPriorityTitleList[sgIndex]["InitativeData"][i].id,
            description: this.strategicPriorityTitleList[sgIndex][
              "InitativeData"
            ][i].description,
            userId: this.strategicPriorityTitleList[sgIndex]["InitativeData"][i]
              .userId,
            projectId: this.activeProjectId,
          };
        } else {
          data = {
            priorityId: strategicPriorityId,
            title: this.strategicPriorityTitleList[sgIndex]["InitativeData"][i]
              .title,
            id: 0,
            description: "",
            userId: this.strategicPriorityTitleList[sgIndex]["InitativeData"][i]
              .userId,
            projectId: this.activeProjectId,
          };
        }

        this.commonService
          .addUpdatePriorityInitative(data)
          .subscribe((addUpdatePriorityInitativeResult) => {
            this.loading = false;

            if (!addUpdatePriorityInitativeResult["success"]) {
              this.message = addUpdatePriorityInitativeResult["error"];
            }
            if (
              addUpdatePriorityInitativeResult["success"] &&
              addUpdatePriorityInitativeResult["data"].ResponseCode == 200
            ) {
              this.strategicGroup.editstrategicInitiativelabel = false;
              this.ownerDropdown = true;

              this.setProjectData(addUpdatePriorityInitativeResult);

              if (inputtype == "desc") {
                this.growlerService.success(
                  "Initiative Description successfully added."
                );
                this.growlerService.clear();
              } else if (inputtype == "selectedUser") {
                this.growlerService.success(
                  "Successfully added owner on this initiative."
                );
                this.growlerService.clear();
              } else {
                this.growlerService.success(
                  "Priority Initiative successfully added."
                );
                this.growlerService.clear();
              }
            } else {
              this.message =
                addUpdatePriorityInitativeResult["data"].ResponseMessage;
            }
          });
      } else {
        this.loading = false;
        this.strategicPriorityTitleList[sgIndex]["InitativeData"][
          i
        ].description = "";
        this.strategicPriorityTitleList[sgIndex]["InitativeData"][
          i
        ].userId = null;
        this.growlerService.error("Priority Initiative is required field.");
        this.growlerService.clear();
      }
    } else {
      this.loading = false;
      this.strategicPriorityTitleList[sgIndex]["InitativeData"][i].description =
        "";
      this.strategicPriorityTitleList[sgIndex]["InitativeData"][i].title = null;
      this.growlerService.error(
        "First add strateric priority than add initiative."
      );
      this.growlerService.clear();
    }
  }
  /******* set project response function ****************/
  setProjectData(apiname: any) {
    this.strategicPriorityTitleList = this.changeObjToArraySort(
      apiname["data"].ResponseData.ProjectData
    );
    this.strategicPriorityTitleList["ProjectPeriod"] =
      apiname["data"].ResponseData.ProjectPeriod;
    this.strategicPriorityTitleList["ExtraColumn"] =
      apiname["data"].ResponseData.ExtraColumn;

    this.strategicPriorityTitleList.forEach((myObject, index) => {
      if (myObject.InitativeData == undefined) {
        myObject.InitativeData = {
          0: {
            id: 0,
            title: "",
            description: "",
            priority: 0,
            userId: null,
            period: {
              0: {
                periodId: 0,
                allRatings: {},
                Task: { id: 0, title: "" },
              },
            },
          },
        };
      } else {
        //console.log('AA');
        if (Object.keys(myObject.InitativeData).length) {
          myObject.InitativeData[-1] = {
            id: 0,
            title: "",
            description: "",
            priority: 0,
            userId: null,
            period: {
              0: {
                periodId: 0,
                allRatings: {},
                Task: { id: 0, title: "" },
              },
            },
          };
        }
      }
    });

    setTimeout(() => {
      this.initialisation();
    }, 1000);
  }
  /******* add extra column value function ****************/
  addExtraColumnValue(i, extraCID, InitiativeID, StrategicID, e) {
    if (InitiativeID && extraCID) {
      if (!e.value) {
        e.value = "";
        this.growlerService.error("Please enter description");
        this.growlerService.clear();
        return false;
      }
      let data = {
        id: 0,
        extraFieldId: extraCID,
        title: e.value,
        initativeId: InitiativeID,
        projectId: this.activeProjectId,
      };

      this.commonService
        .addExtraColumnValue(data)
        .subscribe((addExtraColumnValueResult) => {
          this.loading = false;
          if (!addExtraColumnValueResult["success"]) {
            this.message = addExtraColumnValueResult["error"];
          }
          if (
            addExtraColumnValueResult["success"] &&
            addExtraColumnValueResult["data"].ResponseCode == 200
          ) {
            this.showInitiative = true;
            this.strategicGroup.editstrategicInitiativelabel = false;
            this.setProjectData(addExtraColumnValueResult);

            this.growlerService.success("Description successfully added.");
            this.growlerService.clear();
          } else {
            this.message = addExtraColumnValueResult["data"].ResponseMessage;
          }
        });
    } else {
      e.value = "";
      this.growlerService.error("First add initiative or add extra column.");
      this.growlerService.clear();
    }
  }
  /******* add project task function ****************/
  addUpdateProjectTask(i, periodID, InitiativeID, StrategicID, e) {
    this.loading = true;
    if (InitiativeID) {
      if (e.value) {
        let data = {
          period: periodID,
          title: e.value,
          initativeId: InitiativeID,
          projectId: this.activeProjectId,
        };
        this.commonService
          .addUpdateProjectTask(data)
          .subscribe((addUpdateProjectTaskResult) => {
            //console.log(addUpdateProjectTaskResult);
            if (!addUpdateProjectTaskResult["success"]) {
              this.message = addUpdateProjectTaskResult["error"];
            }
            if (
              addUpdateProjectTaskResult["success"] &&
              addUpdateProjectTaskResult["data"].ResponseCode == 200
            ) {
              this.strategicGroup.editstrategicInitiativelabel = false;
              this.loading = false;
              this.setProjectData(addUpdateProjectTaskResult);
              this.growlerService.success(
                "Priority Initiative task successfully added."
              );
              this.growlerService.clear();
            } else {
              this.message = addUpdateProjectTaskResult["data"].ResponseMessage;
            }
          });
      }
    } else {
      e.value = "";
      this.loading = false;
      this.growlerService.error("First add Initiative title than add task.");
      this.growlerService.clear();

      //window.location.reload();
    }
  }
  /******* add more initiative row function ****************/
  addMorestrategicInitiative(i, strID) {
    if (this.strategicPriorityTitleList[i]["InitativeData"]) {
      this.strategicPriorityTitleList[i][
        "InitativeData"
      ] = this.changeObjToArray(
        this.strategicPriorityTitleList[i]["InitativeData"]
      );
    } else {
      this.strategicPriorityTitleList[i]["InitativeData"] = [];
    }

    if (this.strategicPriorityTitleList[i]["InitativeData"] === undefined) {
      this.strategicPriorityTitleList[i]["InitativeData"] = [];
    }

    this.strategicPriorityTitleList[i]["InitativeData"].push({
      id: "",
      title: "",
      description: "",
      priority: "",
      period: "",
    });
  }
  /******* add extra column function ****************/
  addExtraTextColumn(i) {
    if (
      this.strategicPriorityTitleList["ExtraColumn"] === undefined ||
      this.strategicPriorityTitleList["ExtraColumn"] === ""
    ) {
      this.strategicPriorityTitleList["ExtraColumn"] = [];
    }

    this.strategicPriorityTitleList["ExtraColumn"].push({
      id: 0,
      title: "",
    });
    //console.log(this.strategicPriorityTitleList['ProjectPeriod'].length);
    let j = parseInt(this.strategicPriorityTitleList["ExtraColumn"].length) - 1;
    this.strategicPriorityTitleList[
      "ExtraColumn"
    ] = this.strategicPriorityTitleList["ExtraColumn"];
    this.showPeriods = true;
    this.showInitiative = false;
    this.initialisation();
  }
  /*******add period coloumn function ****************/
  strategicPeriods(i) {
    if (
      this.strategicPriorityTitleList["ProjectPeriod"] === undefined ||
      this.strategicPriorityTitleList["ProjectPeriod"] === ""
    ) {
      this.strategicPriorityTitleList["ProjectPeriod"] = [];
    }
    //console.log(this.strategicPriorityTitleList['ProjectPeriod']);
    //return;
    this.strategicPriorityTitleList["ProjectPeriod"].push({
      id: 0,
      title: "",
    });
    // console.log(this.strategicPriorityTitleList['ProjectPeriod']);
    // return;
    let j =
      parseInt(this.strategicPriorityTitleList["ProjectPeriod"].length) - 1;
    this.strategicPriorityTitleList[
      "ProjectPeriod"
    ] = this.strategicPriorityTitleList["ProjectPeriod"];
    this.showPeriods = true;
    this.initialisation();
  }
  /******* add first prority function ****************/
  addFirststrategicPriority(i) {
    this.strategicGroup.editstrategicPriorityText = true;
  }
  /******* add priorty function ****************/
  addMorestrategicPriority(i) {
    this.strategicPriorityTitleList.push({
      id: "",
      title: "",
      priority: 0,
      InitativeData: {
        0: {
          id: 0,
          title: "",
          description: "",
          priority: 0,
          userId: null,
          period: {
            0: {
              periodId: 0,
              allRatings: {},
              Task: { id: 0, title: "" },
            },
          },
        },
      },
    });
    // this.showHidePriorityGroup = false
    this.renameStrategicDropdown = false;
    this.showPeriods = false;
    let keys = Object.keys(this.strategicPriorityTitleList);
    this.PriorityArraykeys = keys.length;
    //this.ownerDropdown = false;
    setTimeout(() => {
      this.initialisation();
    }, 2000);
  }
  /******* Delete Strategic Priority Title API ****************/
  deleteStrategicPriority(titleId: number) {
    if (confirm("Are you sure to delete this strategic priority")) {
      if (titleId) {
        let data = {
          id: titleId,
          projectId: this.activeProjectId,
        };
        this.commonService
          .deleteStrategicPriority(data)
          .subscribe((deleteStrategicPriorityResult) => {
            // document.getElementById("buttonClosePop").click();
            this.loading = false;
            //console.log(deleteStrategicPriorityResult);
            if (!deleteStrategicPriorityResult["success"]) {
              this.message = deleteStrategicPriorityResult["error"];
            }
            if (
              deleteStrategicPriorityResult["success"] &&
              deleteStrategicPriorityResult["data"].ResponseCode == 200
            ) {
              this.setProjectData(deleteStrategicPriorityResult);
              this.growlerService.success(
                "Strategic priority successfully deleted."
              );
              this.growlerService.clear();
            } else {
              this.message =
                deleteStrategicPriorityResult["data"].ResponseMessage;
            }
          });
      }
    }
  }

  /******* delete task period function ****************/
  deleteTaskPeriod(pID) {
    if (confirm("Are you sure to delete this period")) {
      let data = {
        id: pID,
        projectId: this.activeProjectId,
      };
      this.loading = true;
      this.commonService
        .deleteTaskPeriod(data)
        .subscribe((deleteTaskPeriodResult) => {
          this.loading = false;

          if (!deleteTaskPeriodResult["success"]) {
            this.message = deleteTaskPeriodResult["error"];
          }
          if (
            deleteTaskPeriodResult["success"] &&
            deleteTaskPeriodResult["data"].ResponseCode == 200
          ) {
            //window.location.reload();
            this.setProjectData(deleteTaskPeriodResult);
            this.growlerService.success("Successfully deleted period.");
            this.growlerService.clear();
          } else {
            this.message = deleteTaskPeriodResult["data"].ResponseMessage;
          }
        });
    } else {
      return false;
    }
  }

  /******* delete extra column function ****************/
  deleteExtraTextColumn(columnID) {
    if (confirm("Are you sure to delete this text column")) {
      let data = {
        id: columnID,
        projectId: this.activeProjectId,
        title: "text",
        isOwner: 0,
        isDeleted: 1,
      };
      this.loading = true;
      // return
      this.commonService
        .addUpdateExtraColumn(data)
        .subscribe((addUpdateExtraColumnResult) => {
          this.loading = false;
          if (!addUpdateExtraColumnResult["success"]) {
            this.message = addUpdateExtraColumnResult["error"];
          }
          if (
            addUpdateExtraColumnResult["success"] &&
            addUpdateExtraColumnResult["data"].ResponseCode == 200
          ) {
            this.setProjectData(addUpdateExtraColumnResult);
            this.growlerService.success("Successfully deleted text column.");
            this.growlerService.clear();
          } else {
            this.message = addUpdateExtraColumnResult["data"].ResponseMessage;
          }
        });
    } else {
      return false;
    }
  }
  /******* delete extra column function ****************/
  deleteOwnerColumn(columnID) {
    if (confirm("Are you sure to delete this text column")) {
      let data = {
        id: "-1",
        projectId: this.activeProjectId,
        title: "text",
        isOwner: 1,
        isDeleted: 1,
      };
      this.loading = true;
      // return
      this.commonService
        .addUpdateExtraColumn(data)
        .subscribe((addUpdateExtraColumnResult) => {
          this.loading = false;
          if (!addUpdateExtraColumnResult["success"]) {
            this.message = addUpdateExtraColumnResult["error"];
          }
          if (
            addUpdateExtraColumnResult["success"] &&
            addUpdateExtraColumnResult["data"].ResponseCode == 200
          ) {
            this.isOwner = false;
            this.setProjectData(addUpdateExtraColumnResult);
            this.growlerService.success("Successfully deleted text column.");
            this.growlerService.clear();
          } else {
            this.message = addUpdateExtraColumnResult["data"].ResponseMessage;
          }
        });
    } else {
      return false;
    }
  }


  showEditFunction(id){
    $("#txtIni" + id).show();
    $("#lblIni" + id).hide();
    $("#txtIni" + id).focus();
  }
}
