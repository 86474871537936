import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  isLoggedIn: any;
  username: string;
  profile_image: any;
  useremail: any;
  constructor(private authService: AuthService, public router: Router) {}

  ngOnInit(): void {
    this.isLoggedIn = localStorage.getItem("isLoggedIn");
    this.profile_image = localStorage.getItem("profile_image");
    if (this.isLoggedIn == "true") {
      this.isLoggedIn = "true";
      this.username = localStorage.getItem("username");
      this.useremail = localStorage.getItem("email");
    } else {
      this.isLoggedIn = "false";
    }

    if(this.profile_image=='null'){
       this.profile_image = false
    }
  }

  onLogout() {
    // setTimeout(() => {
    //   this.setSideBar();
    // }, 100);
    document.body.className =document.body.className.replace("fullScreenOn","");
    
    this.authService.logout();
    this.router.navigate([""]);
  }

  setSideBar(){
   
      var element = document.getElementById("bodyMain");
      var element2 = document.getElementById("fullScreenBtn");
      // element.classList.toggle("fullScreenOn");
      // element2.classList.toggle("active");
      document.getElementById('fullScreenBtn').hidden = false;
      document.getElementById('fullScreenBtnExit').hidden = true;
    
  }
  profilePage() {
    this.fullScreenExit();
    this.router.navigateByUrl("/my-profile");
  }
  fullScreenExit() {
    var element = document.getElementById("bodyMain");
    var element2 = document.getElementById("fullScreenBtn");
    element.classList.remove("fullScreenOn");
    //element2.classList.remove("active");
   
  }
}
