import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-sfp-project',
  templateUrl: './sfp-project.component.html',
  styleUrls: ['./sfp-project.component.scss']
})
export class SfpProjectComponent implements OnInit {


   constructor() {
  
   }

  ngOnInit(): void {
  }

  

}
