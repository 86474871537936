import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../services/commonservices';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from "../../auth/auth.service";
import { Adduserorganization } from "../../model/adduserorganization";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GrowlerService } from '../../services/growler.service';
import { Project } from "../../model/project";
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  @ViewChild("forguser") forguser; // Added this
  public sectionToggle1: boolean = true;
  public sectionToggle2: boolean = true;
  public showLoder: boolean = true;
  notValid: boolean = false;
  validTrue: boolean = false;
  notSelected: boolean = false;
  public user: any = {};
  htmlOptions: any = {};
  projectData: any = {};
  activeProjectList: any = [];
  globalRoles: any = [];
  templatesList: object;
  pType: number;
  gType: number;
  roleList: object;
  createdprojectId: number;
  loading: boolean = false;
  showAddUserDiv: boolean = false;
  message: any;
  searchUsers: any = [];
  userlist: any = [];
  selectedUser: any;
  public Project: Project = {} as Project;
  isLoggedIn: any;
  public Adduserorganization: Adduserorganization = {} as Adduserorganization;
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public growlerService: GrowlerService,
    public router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    localStorage.removeItem("editProjectId");
    localStorage.removeItem("EditProjOwner");
    let roles = localStorage.getItem("roles");
    this.getPermissionByRole(roles);

    let isPassChange = localStorage.getItem("is_initial_pass_change");
    this.showLoder = true;
    let self = this;
    this.showLoder = false;
    let token = localStorage.getItem("token");
    this.isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = localStorage.getItem("userId");
    if (this.isLoggedIn == "true" && token) {
      this.getActiveProjectDashboard(userId);
    }
    localStorage.removeItem("pType");
    localStorage.removeItem("gType");
    localStorage.removeItem("editProjectId");
    localStorage.removeItem("projectName");
    //this.isLoggedIn = 'true';
  }

  /****Go to Project setting page from tempalte box ****/
  projectType(templateId, gType, pType) {
    var projectKey = pType == 1 ? "sop_create_project" : "sfr_create_project";
    if (this.globalRoles[projectKey] === undefined) {
      alert("You do not have permissions to create project.");
      return false;
    }

    this.onProjectSubmit(templateId, gType, pType);
    //this.router.navigateByUrl("/project-settings");
  }
  createFromScratch() {
    if (
      this.globalRoles["sop_create_project"] === undefined &&
      this.globalRoles["sfr_create_project"] === undefined
    ) {
      alert("You do not have permissions to create project.");
      return false;
    }
    this.router.navigateByUrl("/project-settings");
  }
  onProjectSubmit(templateId, gType, pType) {
    this.showLoder = true;
    let self = this;
    var tempName = "";
    // if (gType == 1) {
    //   tempName += "Individual";
    // }
    // if (gType == 2) {
    //   tempName += "Group";
    // }
    if (pType == 1) {
      tempName += " SOP";
    }
    if (pType == 2) {
      this.Project.teamLeader = "";
      tempName += " SFR";
    }
    if (gType == 1) {
      tempName += "- " + localStorage.getItem("username");
    }
    if (gType == 2) {
      tempName += localStorage.getItem("orgnization_name")
        ? "- " + localStorage.getItem("orgnization_name")
        : "";
    }

    this.Project.templateId = templateId;
    this.Project.projectId = 0;
    this.Project.projectName = tempName;
    this.Project.organization = parseInt(localStorage.getItem("orgId"));
    this.Project.projectOwner = parseInt(localStorage.getItem("userId"));
    this.Project.projectType = pType;
    this.Project.group = gType;
    this.Project.currentPeriod = new Date();
    this.Project.projectActive = true;
    if (!self.Project.projectId) {
      var msg = "Project added successfully.";
    } else {
      msg = "Project updated successfully.";
    }
    this.commonService.createProject(self.Project).subscribe((result) => {
      this.showLoder = false;
      if (!result["success"]) {
        this.message = result["error"];
      }
      if (result["success"] && result["data"].ResponseCode == 200) {
        this.growlerService.success(msg);
        this.growlerService.clear();
        let projectID = result["data"]["ResponseData"][0]["InprojectId"];
        this.activeProjectOnClickWithToast(projectID, gType, pType, this.Project);
      } else {
        this.message = result["data"].ResponseMessage;
      }
    });
  }

  /****Go to Project setting page from edit project setting option ****/
  goToProjectSetting(editProjId, projectName, project_type, project) {
    var projectKey =
      project_type == 1 ? "sop_change_settings" : "sfr_change_settings";
    var permissions = (project)?project.permissions:null;
    if (this.checkPermission(projectKey, permissions)) {
      alert("You do not have permissions to change project setting.");
      return false;
    }

    localStorage.setItem("editProjectId", editProjId);
    localStorage.setItem("projectName", projectName);
    localStorage.setItem("pType", project_type);
    this.router.navigateByUrl("/project-settings");
  }

  /****Get active project and load predefined tempaltes ****/
  getActiveProjectDashboard(userId) {
    let data = {
      userId: userId,
    };
    this.showLoder = true;
    this.commonService
      .GetActiveProjectDashboard(data)
      .subscribe((activeProjectResult) => {
        this.showLoder = false;
        if (!activeProjectResult["success"]) {
          this.message = activeProjectResult["error"];
        }
        if (
          activeProjectResult["success"] &&
          activeProjectResult["data"].ResponseCode == 200
        ) {
          this.activeProjectList =
            activeProjectResult["data"].ResponseData.projects;

          this.templatesList =
            activeProjectResult["data"].ResponseData.template;
        } else {
          this.message = activeProjectResult["data"].ResponseMessage;
        }
      });
  }

  onSubmitChangePass() {
    if (this.user.password != this.htmlOptions.confirm_password) {
      return;
    }
    let data = {
      id: localStorage.getItem("userId"),
      password: this.htmlOptions.old_password,
      new_password: this.user.password,
    };
    this.loading = true;
    //call login api here
    this.commonService.ChangePassword(data).subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          document.getElementById("buttonClosePop").click();
          //this.message = result;
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;

          setTimeout(() => {
            this.onLogout();
          }, 4000);
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    draggable: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  slickInit(e) {
    console.log("slick initialized");
  }

  breakpoint(e) {
    console.log("breakpoint");
  }

  onLogout() {
    this.authService.logout();
    this.router.navigate([""]);
  }

  activeProjectOnClickWithToast(editProjId, is_group, project_type, project) {
    var projType = project_type == 1 ? "sop_view_project" : "sfr_view_project";
    //var permissions = (project)?project.permissions:null;
    var permissions = project ? project.permissions : null;
    if (this.checkPermission(projType, permissions)) {
      alert("You do not have permissions to view project.");
      return false;
    }
    localStorage.setItem("editProjectId", editProjId);
    localStorage.setItem("pType", project_type);
    localStorage.setItem("gType", is_group);
    if (project_type == 1) {
      this.router.navigateByUrl("/sop-view?first=true");
    } else {
      this.router.navigateByUrl("/feedback-sfr?first=true");
    }
  }

  activeProjectOnClick(editProjId, is_group, project_type, project) {
    var projType = project_type == 1 ? "sop_view_project" : "sfr_view_project";
    //var permissions = (project)?project.permissions:null;
    var permissions = project ? project.permissions : null;
    if (this.checkPermission(projType, permissions)) {
      alert("You do not have permissions to view project.");
      return false;
    }
    localStorage.setItem("editProjectId", editProjId);
    localStorage.setItem("pType", project_type);
    localStorage.setItem("gType", is_group);
    if (project_type == 1) {
      this.router.navigateByUrl("/sop-view");
    } else {
      this.router.navigateByUrl("/feedback-sfr");
    }
  }
  copyProject(projectId, projectType, project) {
    var projType =
      projectType == 1 ? "sop_create_project" : "sfr_create_project";
    var permissions = (project)?project.permissions:null;
    if (this.checkPermission(projType, permissions)) {
      alert("You do not have permissions to copy project.");
      return false;
    }
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    const modalRef = this.modalService.open(ConfirmationModalComponent, ngbModalOptions);
    modalRef.result.then((result: any) => {
      console.log(result);
      if (result) {
        this.showLoder = true;
        let data = {
          projectId: projectId,
          projectType: projectType,
          withRating: result
        };
        this.commonService.apiCall(data, "copyProject").subscribe((result) => {
          if (!result["success"]) {
            this.showLoder = false;
            this.message = result["error"];
          }
          if (result["success"] && result["data"].ResponseCode == 200) {
            var msg = "Project copied successfully.";
            this.growlerService.success(msg);
            this.growlerService.clear();
            this.ngOnInit();
          } else {
            this.showLoder = false;
            this.message = result["data"].ResponseMessage;
          }
        });
      }
    }).catch((result) => {
      console.log('cancelling');
    });

  }
  checkPermission(permissionKey, permissions) {

    if (
      (permissions != null &&
        (permissions[permissionKey] === undefined ||
          !permissions[permissionKey])) ||
      (permissions === null && this.globalRoles[permissionKey] === undefined)
    ) {
      return true;
    }
  }
  deleteProject(projectId, project_type, project) {
    var projType = project_type == 1 ? "sop_delete" : "sfr_delete";
    var permissions = (project)?project.permissions:null;
    if (this.checkPermission(projType, permissions)) {
      alert("You do not have permissions to archive projects");
      return false;
    }
    this.showLoder = true;
    let data = {
      projectId: projectId,
    };
    this.commonService.apiCall(data, "deleteProject").subscribe((result) => {
      //this.showLoder = false;
      if (!result["success"]) {
        this.showLoder = false;
        this.message = result["error"];
      }
      if (result["success"] && result["data"].ResponseCode == 200) {
        this.ngOnInit();
        var msg = "Project archived successfully.";
        this.growlerService.success(msg);
        this.growlerService.clear();
      } else {
        this.showLoder = false;
        this.message = result["data"].ResponseMessage;
      }
    });
  }
  addUser(ProjId, OrgId, type, title, project) {
    var projType = type == 1 ? "sop_add_participants" : "sfr_add_participants";
    var permissions = (project)?project.permissions:null;
    if (this.checkPermission(projType, permissions)) {
      alert("You do not have permissions to add participants.");
      return false;
    }
    this.getUserRoleList();
    this.projectData = {
      organizationId: OrgId,
      projectId: ProjId,
      selectedUserId: this.selectedUser,
      roleId: 2,
      projectName: title,
      projectType: type,
    };
    this.searchPrincipleForProject(OrgId, ProjId);
    document.getElementById("btnAddUser").click();
  }
  searchPrincipleForProject(OrgId, ProjId) {
    this.loading = true;
    let PostDetail = {
      orgId: OrgId,
      projectId: ProjId,
    };

    this.commonService
      .searchPrincipleForProject(PostDetail)
      .subscribe((projectUsersResultData) => {
        this.loading = false;

        if (!projectUsersResultData["success"]) {
          this.message = projectUsersResultData["error"];
        }

        if (
          projectUsersResultData["success"] &&
          projectUsersResultData["data"].ResponseCode == 200
        ) {
          let currentOrgUser = projectUsersResultData["data"].ResponseData[0];
          let otherPrincipals = projectUsersResultData["data"].ResponseData[1];
          this.searchUsers = currentOrgUser.concat(otherPrincipals);
          this.searchUsers.map((i) => {
            if (i.orgnization) {
              i.fullDetails = i.user_name + " (" + i.orgnization+")";
            }else{
              i.fullDetails = i.user_name;
            }
            return i;
          });
        } else {
          this.message = projectUsersResultData["data"].ResponseMessage;
        }
      });
  }
  selectedUserAdd() {
    this.loading = true;

    if (!this.selectedUser) {
      //this.notSelected = true;
      setTimeout(() => {
        this.notSelected = true;
      }, 1000);
    } else {
      this.notSelected = false;
    }

    if (this.selectedUser) {
      let data = {
        organizationId: this.projectData.organizationId,
        projectId: this.projectData.projectId,
        selectedUserId: this.selectedUser,
        roleId: 2,
        projectName: this.projectData.projectId,
        projectType: this.projectData.projectType,
      };
      document.getElementById("addNewUserHide").click();
      this.commonService
        .addUserInProject(data)
        .subscribe((addUserInProjectResult) => {
          this.loading = false;
          if (!addUserInProjectResult["success"]) {
            this.message = addUserInProjectResult["error"];
          }
          if (
            addUserInProjectResult["success"] &&
            addUserInProjectResult["data"].ResponseCode == 200
          ) {
            this.selectedUser ="";
          } else {
            this.message = addUserInProjectResult["data"].ResponseMessage;
          }
        });
    } else {
      this.loading = false;
    }
  }
  addMoreUser() {
    this.showAddUserDiv = true;
  }
  onAddUser(formUser: any) {
    this.loading = true;

    this.userlist = formUser.value;
    if (this.userlist.useremail) {
      let dataEmail = {
        email: this.userlist.useremail,
      };
      this.commonService
        .checkEmailExist(dataEmail)
        .subscribe((emailCheckResult) => {
          this.loading = false;
          if (!emailCheckResult["success"]) {
            this.message = emailCheckResult["error"];
          }

          if (emailCheckResult["data"].ResponseCode == 200) {
            const listArray = Object.assign({}, this.userlist);

            this.forguser.resetForm();

            let selfuser = this;
            let data = {
              organizationId: this.projectData.organizationId,
              user: listArray,
              projectId: this.projectData.projectId,
              selectedUserId: this.selectedUser,
              roleId: 2,
              projectName: this.projectData.projectId,
              projectType: this.projectData.projectType,
            };

            document.getElementById("addNewUserHide").click();
            this.commonService
              .addUserInProject(data)
              .subscribe((addUserInProjectResult) => {
                this.loading = false;
                if (!addUserInProjectResult["success"]) {
                  this.message = addUserInProjectResult["error"];
                }
                if (
                  addUserInProjectResult["success"] &&
                  addUserInProjectResult["data"].ResponseCode == 200
                ) {
                  var msg = "User added successfully.";
                  this.growlerService.success(msg);
                  this.growlerService.clear();
                  this.loading = false;
                } else {
                  this.message = addUserInProjectResult["data"].ResponseMessage;
                  this.growlerService.error(this.message);
                  this.growlerService.clear();
                  this.loading = false;
                }
              });
          } else {
            this.growlerService.error(
              "This email is already registered with the system."
            );
            this.growlerService.clear();
            this.loading = false;
          }
        });
    } else {
      this.loading = false;
    }
  }
  getUserRoleList() {
    let data = {};
    this.commonService.GetUserRoleList(data).subscribe((roleResult) => {
      if (!roleResult["success"]) {
        this.message = roleResult["error"];
      }
      if (roleResult["success"] && roleResult["data"].ResponseCode == 200) {
        this.roleList = roleResult["data"].ResponseData;
      } else {
        this.message = roleResult["data"]
          ? roleResult["data"].ResponseMessage
          : "";
      }
    });
  }
  getPermissionByRole(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = result["data"].ResponseData;
          console.log(this.globalRoles);
          //localStorage.setItem("globalRoles", this.globalRoles);
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
}
