<app-growler *ngIf="htmlOptions.download">
</app-growler>
<app-header *ngIf="htmlOptions.download"></app-header>
<div class="wrapper">
    <div class="loaderOuter" *ngIf="loading">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
    <app-nav-bar></app-nav-bar>
    <div class="inner-container headerTopPaddipad" (click)="ClickedOut($event)" id="contentToConvert">
        <div class="header_left-part" *ngIf="htmlOptions.download">
            <div class="row">
                <div class="col-lg-7 col-xl-8">
                    <span class="ptypespan">
                        <img *ngIf="gType == 1" src="./assets/images/individual-sop-icon2.png" alt="icon" height="19">
                        <img *ngIf="gType == 2" src="./assets/images/group-sop-icon2.png" alt="icon" height="19">
                    </span>
                    <h3 class="sfp_project-title"> {{projectDetail.title}}</h3>
                    <a routerLink="/project-settings" title="Click here to go to project setting page"><i class="fa fa-cog mx-2" aria-hidden="true"></i></a>
                    <a href="javascript:void(0)" title="Click here to edit project" (click)="editPage(gType, projectDetail.projectType)"><i class="fa fa-pencil mx-2"
                        aria-hidden="true"></i></a>
                    <a href="javascript:void(0)" data-toggle="modal" data-target="#printModal"><i class="fa fa-download mx-2" aria-hidden="true" title="Click here to download report"></i></a>
                    <a href="javaScript:void(0)" title="Click here to view full screen mode" id="fullScreenBtn" onclick="fullScreen()">
                        <i class="fullScreenIcon mx-2"> <img width="23" src="./assets/images/fulllScreen.png"> </i></a>

                    <a href="javaScript:void(0)" hidden title="Click here to exit full screen mode" id="fullScreenBtnExit" onclick="fullScreenExit()">
                        <i class="fullScreenIcon"> <img width="23" class=" mx-2" src="./assets/images/expan_remove.png"> </i></a>

                </div>
                <div class="col-lg-5 col-xl-4">
                    <div class="form-group row mb-0">
                        <label class="col-md-3 col-xl-6 col-5 mt-2 pl-0 text-right">METRIC TYPE<span class="req">*</span></label>
                        <select class="form-control col-6 col-md-6 col-xl-6" name="projectType" (change)="changeProjectType($event)">
                            <option [value]="null">--Select--</option>
                            <option [value]="1">Average</option>
                            <option [value]="2">Median</option>
                            <option [value]="3">Adjusted Average</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="strategic_priority-outer strategic_priority-viewMode" id="contentToConvert" style="background: #EAEBF0 !important;" >
            <h3 class="inner-title mb-3">Your Strategic Priority</h3>
            <div class="strategic_priority-content" *ngIf="projectData">
                <!-- table-responsive -->
                <ng-container *ngFor="let project of projectData; let k= index">
                    <ng-container *ngIf="priorityIncludes(project.id)">
                        <!-- [ngStyle]="{'min-width.px': containerMinusWi.offsetWidth + containerMinusWi2.offsetWidth + 380}" -->
                        <div class="mainOutertableDiv" #container>
                            <table>
                                <tr>
                                    <td #containerMinusWi style="vertical-align: top;width: 250px;overflow: auto;">
                                        <table class="strategic_priority-Table" style="border-spacing: 1px; border-collapse: separate;" >
                                            <thead>
                                                <tr>
                                                    <th colspan="2" style="vertical-align: middle; color: #fff !important; background: #0B3760; height: 50px !important; line-height: 16px;font-size: 16px;padding: 5px 10px; " class="titleThIcon headingBlueBgNew">{{project.title}}</th>
                                                    <ng-container *ngIf="ExtraColumn">
                                                        <th style="color: #0B3760; font-size: 16px; padding: 5px 10px; height: 50px; vertical-align: top;" class="text-center" *ngFor="let column of ExtraColumn">
                                                            <p id="{{column.id}}" *ngIf="k==0">{{column.title}}</p>
                                                        </th>
                                                    </ng-container>
                                                    <th style="color: #0B3760; font-size: 16px; padding: 5px 10px; height: 50px; vertical-align: top;" class="titleThIcon pl-0 text-center" *ngIf="projectDetail.ownerColumn">
                                                        <p *ngIf="k==0"> Owner</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.individualMode">

                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                        <td style="background: none; width: 35px;" ></td>
                                                        <td style="vertical-align: middle; color: #fff !important; background: #d6e8ff !important; height: 60px !important; line-height: 16px;font-size: 16px;padding: 5px 10px; border-left: 6px solid #0B3760; width: 230px;"  class="text-left widthSefix strategicIntTitle">
                                                            <p style="font-size: 13px; line-height: 13px; color: #555555; text-align: left; font-weight: 600;" >{{Initative.title}}</p>
                                                        </td>
                                                        <ng-container *ngIf="ExtraColumn">
                                                            <td style="color: #0B3760; font-size: 16px; padding: 5px 10px; height: 50px; vertical-align: top;" class="periodsBoxOuter" *ngFor="let Column of ExtraColumn">
                                                                <span class="text-center" *ngFor="let extra of changeObjToArrayForTasks(Initative.extraFields) ">
                                                            <p *ngIf="extra.id==Column.id">{{extra.title}} </p>
                                                        </span>
                                                            </td>
                                                        </ng-container>
                                                        <td style="color: #0B3760; font-size: 16px; padding: 5px 10px; height: 50px; vertical-align: top; max-width: 100px !important;width: 100px !important;min-width: 100px !important;" class="periodsBoxOuter" *ngIf="projectDetail.ownerColumn">
                                                            <p *ngIf="Initative.userId">{{Initative.userId}}</p>
                                                        </td>
                                                    </tr>
                                                </ng-container>

                                            </tbody>
                                            
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.averageMode">
                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                        <td style="background: none; width: 35px;" ></td>
                                                        <td style="vertical-align: middle; color: #fff !important; background: #d6e8ff !important; height: 60px !important; line-height: 16px;font-size: 16px;padding: 5px 10px; border-left: 6px solid #0B3760;width: 230px;" class="text-left widthSefix strategicIntTitle">
                                                            <p style="font-size: 13px; line-height: 13px; color: #555555; text-align: left; font-weight: 600;" >{{Initative.title}}</p>
                                                        </td>
                                                        <ng-container *ngIf="ExtraColumn">
                                                            <td style="color: #0B3760; font-size: 16px; padding: 5px 10px; height: 50px; vertical-align: top;" class="periodsBoxOuter" *ngFor="let Column of ExtraColumn">
                                                                <span class="text-center" *ngFor="let extra of changeObjToArrayForTasks(Initative.extraFields) ">
                                                            <p *ngIf="extra.id==Column.id">{{extra.title}} </p>
                                                        </span>
                                                            </td>
                                                        </ng-container>
                                                        <td style="color: #0B3760; font-size: 16px; padding: 5px 10px; height: 50px; vertical-align: top; max-width: 100px !important;width: 100px !important;min-width: 100px !important;" class="periodsBoxOuter" *ngIf="projectDetail.ownerColumn">
                                                            <p>{{Initative.userId}}</p>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.medianMode">
                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                        <td style="background: none; width: 35px;" ></td>
                                                        <td style="vertical-align: middle; color: #fff !important; background: #d6e8ff !important; height: 60px !important; line-height: 16px;font-size: 16px;padding: 5px 10px; border-left: 6px solid #0B3760;width: 230px;" class="text-left widthSefix strategicIntTitle">
                                                            <p style="font-size: 13px; line-height: 13px; color: #555555; text-align: left; font-weight: 600;" >{{Initative.title}}</p>
                                                        </td>
                                                        <ng-container *ngIf="ExtraColumn">
                                                            <td class="periodsBoxOuter" *ngFor="let Column of ExtraColumn">
                                                                <span class="text-center" *ngFor="let extra of changeObjToArrayForTasks(Initative.extraFields) ">
                                                            <p *ngIf="extra.id==Column.id">{{extra.title}} </p>
                                                        </span>
                                                            </td>
                                                        </ng-container>
                                                        <td style="color: #0B3760; font-size: 16px; padding: 5px 10px; height: 50px; vertical-align: top; max-width: 100px !important;width: 100px !important;min-width: 100px !important;" class="periodsBoxOuter" *ngIf="projectDetail.ownerColumn">
                                                            <p>{{Initative.userId}}</p>
                                                        </td>


                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.adjustedMode">
                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                        <td style="background: none; width: 35px;" ></td>
                                                        <td style="vertical-align: middle; color: #fff !important; background: #d6e8ff !important;height: 60px !important; line-height: 16px;font-size: 16px;padding: 5px 10px; border-left: 6px solid #0B3760;width: 230px;" class="text-left widthSefix strategicIntTitle">
                                                            <p style="font-size: 13px; line-height: 13px; color: #555555; text-align: left; font-weight: 600;" >{{Initative.title}}</p>
                                                        </td>
                                                        <ng-container *ngIf="ExtraColumn">
                                                            <td style="color: #0B3760; font-size: 16px; padding: 5px 10px; height: 50px; vertical-align: top;" class="periodsBoxOuter" *ngFor="let Column of ExtraColumn">
                                                                <span class="text-center" *ngFor="let extra of changeObjToArrayForTasks(Initative.extraFields) ">
                                                            <p *ngIf="extra.id==Column.id">{{extra.title}} </p>
                                                        </span>
                                                            </td>
                                                        </ng-container>
                                                        <td style="color: #0B3760; font-size: 16px; padding: 5px 10px; height: 50px; vertical-align: top; max-width: 100px !important;width: 100px !important;min-width: 100px !important;" class="periodsBoxOuter" *ngIf="projectDetail.ownerColumn">
                                                            <p>{{Initative.userId}}</p>
                                                        </td>



                                                    </tr>
                                                </ng-container>

                                            </tbody>

                                        </table>
                                    </td>
                                    <!-- 'width.px': container.offsetWidth - containerMinusWi.offsetWidth - containerMinusWi2.offsetWidth,  -->
                                    <td class="pcontainer" [ngStyle]="{'max-width.px': container.offsetWidth - containerMinusWi.offsetWidth - containerMinusWi2.offsetWidth}" style="vertical-align: top; position: relative; width: auto !important; min-width: 382px !important;">
                                        <a *ngIf="k==0 && htmlOptions.download && showScrollRight && checkAvailavility()" class="rightArrowChanges" href="javaScript:void(0)"><i (click)="scrollRight()" class="fa fa-angle-right" ></i></a>
                                        <a *ngIf="k==0 && htmlOptions.download && scrollLeftSize > 0" class="leftArrowChanges" href="javaScript:void(0)"><i (click)="scrollLeft()" class="fa fa-angle-left" ></i></a>

                                        <div class="widgetsContent" id="widgetsContent" style="min-width: 190px; max-width: 100%; width: auto; overflow-x: hidden;">
                                            <table class="strategic_priority-Table midTableScrollable" style="border-spacing: 1px; border-collapse: separate;" >

                                                <thead>
                                                    <th style="color: #0B3760; font-size: 16px; padding: 5px 10px; height: 50px; vertical-align: top;" class="text-center" *ngFor="let Period of ProjectPeriod">
                                                        <p id="{{Period.id}}" *ngIf="k==0">{{Period.title}}</p>
                                                    </th>
                                                </thead>
                                                <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.individualMode">
                                                    <ng-container *ngIf="project.InitativeData">
                                                        <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                            <ng-container *ngFor="let Periods of ProjectPeriod; let idx= index">
                                                                <ng-container *ngIf="hideDivNextTime(Initative.period,Periods.id)">
                                                                    <td class="periodsBoxOuter" id="{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}-{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.title:''}}" [attr.colspan]="setColspanValue(Initative.period,Periods.id)" style="background: #fff !important; min-width: 190px; width: 190px; vertical-align: top; padding:  8px 0px; position: relative; padding-bottom: 0px !important; height: 60px;" >
                                                                      <div class="tdInnerratingOuter" >
                                                                        <p style="font-size: 13px; margin:0px; text-align: center !important; line-height: 13px; color: #555555; height: 27px;" class="text-center">{{Initative.period[Periods.id]['Task'].title}}</p>
                                                                      <div class="multiInARow" style="display: -webkit-box; display: flex;" > <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)>1"> 
                                                                            <ng-container *ngFor="let in of counter(setColspanValue(Initative.period,Periods.id)) ; let pdx= index">
                                                                                

                                                                                    <p class="dropdownmultipal" *ngIf="Initative.period[mergeTdRemoveArr[pdx]].rating" style="width: 190px; float: left; padding: 0 1px; position: relative; bottom: 1px; margin-top: 0 !important;" >
                                                                                        <input id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" [readonly]="true" class="ratingBoxBlank" type="text" value="{{Initative.period[mergeTdRemoveArr[pdx]].rating}}" (click)="getRatingAndCommentforloop($event)" class="rating{{getRound(Initative.period[mergeTdRemoveArr[pdx]].rating)}}bg" style="border: none;  margin: 7px 0 -2px 0;
                                                                                        height: 20px; border: none;border-radius: 0px; background: #ffd966; color: #000;">
                                                                                    </p>
                                                                                    <div class="dropdown dropdownmultipal" *ngIf="!Initative.period[mergeTdRemoveArr[pdx]].rating" style="width: 190px; float: left; padding: 0 1px; position: relative; bottom: 1px; margin-top: 0 !important;" >
                                                                                        <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" role="button" (click)="blankBox()"
                                                                                            id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  style="border:1px solid #343a40; display: block; width: 100%; height:20px; margin-top: 7px !important;" >
                                                                                        </a>
                                
                                                                                        <div class="dropdown-menu p-1 px-3" aria-labelledby="dropdownMenuLink">
                                                                                            <div class="divSlider slider">
                                                                                                <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                                    <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                                                id="range{{Initative.period[mergeTdRemoveArr[pdx]]['Task'].id}}{{Initative.id}}" class="" value="">
    
                                                                                                <input type="hidden" id="rangeperiod{{Initative.period[mergeTdRemoveArr[pdx]]['Task'].id}}{{Initative.id}}" value="{{mergeTdRemoveArr[pdx]}}">
                                                                                            <textarea rows="2" class="form-control"                                                 [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                                                           
                                                                                                <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                                    (click)="addRating(Initative.period[Periods.id]['Task'].id,Initative.id, feedback.text)">Submit</a>
                                                                                        </div>
                                                                                    </div>
                                                                                
                                                                                
                                                                            </ng-container>
                                                                        </ng-container></div>
                                                                        <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)==1"> 
                                                                            <p class="dropdownFullSize" style="font-size: 13px; line-height: 13px; color: #555555;height: auto; position: absolute; left: 1px; width: calc(100% - 2px); bottom: 3px; margin: 0 !important;">
                                                                                <input [readonly]="true" *ngIf="Initative.period[Periods.id].rating" class="ratingBoxBlank" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)" type="text"
                                                                                    value="{{Initative['period'][Periods.id].rating}}" class="rating{{getRound(Initative.period[Periods.id].rating)}}bg" style="border: none; margin: 7px 0 -2px 0;
                                                                                    height: 20px; border: none;border-radius: 0px; background: #ffd966; color: #000;">
                                                                            </p>
                                                                            <div class="dropdown dropdownFullSize" *ngIf="!Initative.period[Periods.id].rating" style=" position: absolute; left: 1px; width: calc(100% - 2px); bottom: 3px; margin: 0 !important;">
                                                                                <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" (click)="blankBox()"
                                                                                    role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                                                    aria-expanded="false" style="border:1px solid #343a40; display: block; width: 100%; height:20px;margin-bottom: -2px;">
                                                                                </a>
                                                                                <div class="dropdown-menu p-1 px-3" aria-labelledby="dropdownMenuLink">
                                                                                    <div class="divSlider slider">
                                                                                        <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                            <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                                        id="range{{Initative.period[Periods.id]['Task'].id}}{{Initative.id}}" class="" value="">
                                                                                        <input type="hidden" id="rangeperiod{{Initative.period[Periods.id]['Task'].id}}{{Initative.id}}" value="{{Periods.id}}">   
                                                                                    <textarea rows="2" class="form-control"
            
                                                                                        [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                                                        <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                            (click)="addRating(Initative.period[Periods.id]['Task'].id,Initative.id, feedback.text)">Submit</a>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                        
                                                                        </div>
                                                                    </td>
                                                                </ng-container>
                                                            </ng-container>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                                <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.averageMode">
                                                    <ng-container *ngIf="project.InitativeData">
                                                        <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                            <ng-container *ngFor="let Periods of ProjectPeriod; let idx= index">
                                                                <ng-container *ngIf="hideDivNextTime(Initative.period,Periods.id)">
                                                                    <td class="periodsBoxOuter" id="{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}-{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.title:''}}" [attr.colspan]="setColspanValue(Initative.period,Periods.id)" style="background: #fff !important; min-width: 190px; width: 190px; vertical-align: top; padding:  8px 0px; position: relative; padding-bottom: 0px !important; height: 60px;" >
                                                                      <div class="tdInnerratingOuter" >
                                                                        <p class="text-center" style="font-size: 13px; margin:0px; text-align: center !important; line-height: 13px; color: #555555; height: 27px;" >{{Initative.period[Periods.id]['Task'].title}}</p>
                                                                        <div class="multiInARow"  style="display: -webkit-box; display: flex;">
                                                                            
                                                                            <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)>1"> 
                                                                                 <ng-container *ngFor="let in of counter(setColspanValue(Initative.period,Periods.id)) ; let pdx= index">
                                                                                    <p class="dropdownmultipal" *ngIf="Initative.period[mergeTdRemoveArr[pdx]].rating" style="width: 190px; float: left; padding: 0 1px; position: relative; bottom: 1px; margin-top: 0 !important;" >
                                                                                        <input id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" [readonly]="true" class="ratingBoxBlank" type="text" value="{{averageRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}" (click)="getRatingAndCommentforloop($event)" class="rating{{averageRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}bg" style="border: none; margin: 7px 0 -2px 0;
                                                                                        height: 20px; border: none;border-radius: 0px; background: #ffd966; color: #000;">
                                                                                    </p>
                                                                                    <div class="dropdown dropdownmultipal" *ngIf="!Initative.period[mergeTdRemoveArr[pdx]].rating" style="width: 190px; float: left; padding: 0 1px; position: relative; bottom: 1px; margin-top: 0 !important;" >
                                                                                        <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" role="button" (click)="blankBox()"
                                                                                            id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  style="border:1px solid #343a40; display: block; width: 100%; height:20px;margin-top: 7px !important;">
                                                                                        </a>
                                
                                                                                        <div class="dropdown-menu p-1 px-3" aria-labelledby="dropdownMenuLink">
                                                                                            <div class="divSlider slider">
                                                                                                <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                                    <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                                                id="range{{Initative.period[mergeTdRemoveArr[pdx]]['Task'].id}}{{Initative.id}}" class="" value="">
    
                                                                                                <input type="hidden" id="rangeperiod{{Initative.period[mergeTdRemoveArr[pdx]]['Task'].id}}{{Initative.id}}" value="{{mergeTdRemoveArr[pdx]}}">
                                                                                            <textarea rows="2" class="form-control"                                                 [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                                                           
                                                                                                <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                                    (click)="addRating(Initative.period[Periods.id]['Task'].id,Initative.id, feedback.text)">Submit</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                                </div>
                                                                                
                                                                        <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)==1"> 
                                                                            <p class="dropdownFullSize" style="font-size: 13px; line-height: 13px; color: #555555;height: auto; position: absolute; left: 1px; width: calc(100% - 2px); bottom: 3px; margin: 0 !important;" >
                                                                                <input [readonly]="true" *ngIf="Initative.period[Periods.id].rating" class="ratingBoxBlank" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)" type="text"
                                                                                    value="{{averageRating(Initative['period'][Periods.id].allRatings)}}" class="rating{{averageRating(Initative['period'][Periods.id].allRatings)}}bg" style="border: none; margin: 7px 0 -2px 0;
                                                                                    height: 20px; border: none;border-radius: 0px; background: #ffd966; color: #000;">
                                                                            </p>
                                                                            <div class="dropdown dropdownFullSize" *ngIf="!Initative.period[Periods.id].rating"style=" position: absolute; left: 1px; width: calc(100% - 2px); bottom: 3px; margin: 0 !important;">
                                                                                <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" (click)="blankBox()"
                                                                                    role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                                                    aria-expanded="false"  style="border:1px solid #343a40; display: block; width: 100%; height:20px;margin-bottom: -2px;">
                                                                                </a>
                                                                                <div class="dropdown-menu p-1 px-3" aria-labelledby="dropdownMenuLink">
                                                                                    <div class="divSlider slider">
                                                                                        <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                            <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                                        id="range{{Initative.period[Periods.id]['Task'].id}}{{Initative.id}}" class="" value="">
                                                                                        <input type="hidden" id="rangeperiod{{Initative.period[Periods.id]['Task'].id}}{{Initative.id}}" value="{{Periods.id}}">   
                                                                                    <textarea rows="2" class="form-control"
            
                                                                                        [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                                                        <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                            (click)="addRating(Initative.period[Periods.id]['Task'].id,Initative.id, feedback.text)">Submit</a>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                        
                                                                        </div>
                                                                    </td>
                                                                </ng-container>
                                                            </ng-container>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                                <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.medianMode">
                                                    <ng-container *ngIf="project.InitativeData">
                                                        <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                            <ng-container *ngFor="let Periods of ProjectPeriod; let idx= index">
                                                                <ng-container *ngIf="hideDivNextTime(Initative.period,Periods.id)">
                                                                    <td class="periodsBoxOuter" id="{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}-{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.title:''}}" [attr.colspan]="setColspanValue(Initative.period,Periods.id)" style="background: #fff !important; min-width: 190px; width: 190px; vertical-align: top; padding:  8px 0px; position: relative; padding-bottom: 0px !important; height: 60px;" >
                                                                      <div class="tdInnerratingOuter" >
                                                                        <p class="text-center" style="font-size: 13px; margin:0px; text-align: center !important; line-height: 13px; color: #555555; height: 27px;" >{{Initative.period[Periods.id]['Task'].title}}</p>
                                                                        <div class="multiInARow" style="display: -webkit-box; display: flex;" >
                                                                            <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)>1"> 
                                                                                 <ng-container *ngFor="let in of counter(setColspanValue(Initative.period,Periods.id)) ; let pdx= index">
                                                                            
                                                                                    <p class="dropdownmultipal" *ngIf="Initative.period[mergeTdRemoveArr[pdx]].rating" style="width: 190px; float: left; padding: 0 1px; position: relative; bottom: 1px; margin-top: 0 !important;" >
                                                                                        <input id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" [readonly]="true" class="ratingBoxBlank" type="text" value="{{medianRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}" (click)="getRatingAndCommentforloop($event)" class="rating{{medianRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}bg" style="border: none; margin: 7px 0 -2px 0;
                                                                                        height: 20px; border: none;border-radius: 0px; background: #ffd966; color: #000;" >
                                                                                    </p>
                                                                                    <div class="dropdown dropdownmultipal" *ngIf="!Initative.period[mergeTdRemoveArr[pdx]].rating" style="width: 190px; float: left; padding: 0 1px; position: relative; bottom: 1px; margin-top: 0 !important;" >
                                                                                        <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" role="button" (click)="blankBox()"
                                                                                            id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  style="border:1px solid #343a40; display: block; width: 100%; height:20px;margin-top: 7px !important;">
                                                                                        </a>
                                
                                                                                        <div class="dropdown-menu p-1 px-3" aria-labelledby="dropdownMenuLink">
                                                                                            <div class="divSlider slider">
                                                                                                <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                                    <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                                                id="range{{Initative.period[mergeTdRemoveArr[pdx]]['Task'].id}}{{Initative.id}}" class="" value="">
    
                                                                                                <input type="hidden" id="rangeperiod{{Initative.period[mergeTdRemoveArr[pdx]]['Task'].id}}{{Initative.id}}" value="{{mergeTdRemoveArr[pdx]}}">
                                                                                            <textarea rows="2" class="form-control"                                                 [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                                                           
                                                                                                <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                                    (click)="addRating(Initative.period[Periods.id]['Task'].id,Initative.id, feedback.text)">Submit</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                                </div>
                                                                                
                                                                        <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)==1"> 
                                                                            <p class="dropdownFullSize" style="font-size: 13px; line-height: 13px; color: #555555;height: auto; position: absolute; left: 1px; width: calc(100% - 2px); bottom: 3px; margin: 0 !important; ">
                                                                                <input [readonly]="true" *ngIf="Initative.period[Periods.id].rating" class="ratingBoxBlank" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)" type="text"
                                                                                    value="{{medianRating(Initative['period'][Periods.id].allRatings)}}" class="rating{{medianRating(Initative['period'][Periods.id].allRatings)}}bg" style="border: none; margin: 7px 0 -2px 0;
                                                                                    height: 20px; border: none;border-radius: 0px; background: #ffd966; color: #000;">
                                                                            </p>
                                                                            <div class="dropdown dropdownFullSize" *ngIf="!Initative.period[Periods.id].rating" style=" position: absolute; left: 1px; width: calc(100% - 2px); bottom: 3px; margin: 0 !important;" >
                                                                                <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" (click)="blankBox()"
                                                                                    role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                                                    aria-expanded="false"  style="border:1px solid #343a40; display: block; width: 100%; height:20px; margin-bottom: -2px;">
                                                                                </a>
                                                                                <div class="dropdown-menu p-1 px-3" aria-labelledby="dropdownMenuLink">
                                                                                    <div class="divSlider slider">
                                                                                        <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                            <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                                        id="range{{Initative.period[Periods.id]['Task'].id}}{{Initative.id}}" class="" value="">
                                                                                        <input type="hidden" id="rangeperiod{{Initative.period[Periods.id]['Task'].id}}{{Initative.id}}" value="{{Periods.id}}">   
                                                                                    <textarea rows="2" class="form-control"
            
                                                                                        [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                                                        <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                            (click)="addRating(Initative.period[Periods.id]['Task'].id,Initative.id, feedback.text)">Submit</a>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                        
                                                                        </div>
                                                                    </td>
                                                                </ng-container>
                                                            </ng-container>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                                <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.adjustedMode">
                                                    <ng-container *ngIf="project.InitativeData">
                                                        <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                            <ng-container *ngFor="let Periods of ProjectPeriod; let idx= index">
                                                                <ng-container *ngIf="hideDivNextTime(Initative.period,Periods.id)">
                                                                    <td class="periodsBoxOuter" id="{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}-{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.title:''}}" [attr.colspan]="setColspanValue(Initative.period,Periods.id)" style="background: #fff !important; min-width: 190px; width: 190px; vertical-align: top; padding: 8px 0px; position: relative; padding-bottom: 0px !important; height: 60px;" >
                                                                      <div class="tdInnerratingOuter" >
                                                                        <p class="text-center" style="font-size: 13px; margin:0px; text-align: center !important; line-height: 13px; color: #555555; height: 27px;" >{{Initative.period[Periods.id]['Task'].title}}</p>
                                                                        <div class="multiInARow" style="display: -webkit-box; display: flex;" >
                                                                           <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)>1"> 
                                                                                <ng-container *ngFor="let in of counter(setColspanValue(Initative.period,Periods.id)) ; let pdx= index">

                                                                                    <p class="dropdownmultipal" *ngIf="Initative.period[mergeTdRemoveArr[pdx]].rating" style="width: 190px; float: left; padding: 0 1px; position: relative; bottom: 1px; margin-top: 0 !important;" >
                                                                                        <input id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" [readonly]="true" class="ratingBoxBlank" type="text" value="{{adjustedMode(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}" (click)="getRatingAndCommentforloop($event)" class="rating{{adjustedMode(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}bg"style="border: none; margin: 7px 0 -2px 0;
                                                                                        height: 20px; border: none;border-radius: 0px; background: #ffd966; color: #000;">
                                                                                    </p>
                                                                                    <div class="dropdown dropdownmultipal" *ngIf="!Initative.period[mergeTdRemoveArr[pdx]].rating" style="width: 190px; float: left; padding: 0 1px; position: relative; bottom: 1px; margin-top: 0 !important;" >
                                                                                        <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" role="button" (click)="blankBox()"
                                                                                            id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  style="border:1px solid #343a40; display: block; width: 100%; height:20px;margin-top: 7px !important;">
                                                                                        </a>
                                
                                                                                        <div class="dropdown-menu p-1 px-3" aria-labelledby="dropdownMenuLink">
                                                                                            <div class="divSlider slider">
                                                                                                <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                                    <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                                                id="range{{Initative.period[mergeTdRemoveArr[pdx]]['Task'].id}}{{Initative.id}}" class="" value="">
    
                                                                                                <input type="hidden" id="rangeperiod{{Initative.period[mergeTdRemoveArr[pdx]]['Task'].id}}{{Initative.id}}" value="{{mergeTdRemoveArr[pdx]}}">
                                                                                            <textarea rows="2" class="form-control"                                                 [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                                                           
                                                                                                <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                                    (click)="addRating(Initative.period[Periods.id]['Task'].id,Initative.id, feedback.text)">Submit</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                                </div>
                                                                                
                                                                        <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)==1"> 
                                                                            <p class="dropdownFullSize" style="font-size: 13px; line-height: 13px; color: #555555;height: auto; position: absolute; left: 1px; width: calc(100% - 2px); bottom: 3px; margin: 0 !important;" >
                                                                                <input [readonly]="true" *ngIf="Initative.period[Periods.id].rating" class="ratingBoxBlank" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)" type="text"
                                                                                    value="{{adjustedMode(Initative['period'][Periods.id].allRatings)}}" class="rating{{adjustedMode(Initative['period'][Periods.id].allRatings)}}bg" style="border: none; margin: 7px 0 -2px 0;
                                                                                    height: 20px; border: none;border-radius: 0px; background: #ffd966; color: #000;">
                                                                            </p>
                                                                            <div class="dropdown dropdownFullSize" *ngIf="!Initative.period[Periods.id].rating" style=" position: absolute; left: 1px; width: calc(100% - 2px); bottom: 3px; margin: 0 !important;">
                                                                                <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" (click)="blankBox()" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="padding: 9px !important; border-radius: 0px !important; margin-bottom: -2px;font-size: 14px; " >
                                                                                </a>
                                                                                <div class="dropdown-menu p-1 px-3" aria-labelledby="dropdownMenuLink">
                                                                                    <div class="divSlider slider">
                                                                                        <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                            <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                                        id="range{{Initative.period[Periods.id]['Task'].id}}{{Initative.id}}" class="" value="">
                                                                                        <input type="hidden" id="rangeperiod{{Initative.period[Periods.id]['Task'].id}}{{Initative.id}}" value="{{Periods.id}}">   
                                                                                    <textarea rows="2" class="form-control"
            
                                                                                        [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                                                        <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                            (click)="addRating(Initative.period[Periods.id]['Task'].id,Initative.id, feedback.text)">Submit</a>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                        
                                                                        </div>
                                                                    </td>
                                                                </ng-container>
                                                            </ng-container>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                                
                                            </table>
                                        </div>
                                    </td>
                                    <td #containerMinusWi2 style="vertical-align: top;width: 300px;max-width: 300px;overflow: auto;">
                                        <table class="strategic_priority-Table midTableScrollable" style="border-spacing: 1px; border-collapse: separate;" >
                                            <thead>
                                                <tr>
                                                    <th style="width: 35px;"></th>
                                                    <th style="color: #0B3760; font-size: 16px; padding: 5px 10px; height: 50px; vertical-align: top; min-width: 220px;">
                                                        <p *ngIf="k==0">What success looks like...</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.individualMode">

                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">


                                                        <td style="background: #fff;" ></td>
                                                        <td class="strategicSuccessNote" style="font-weight: 600; background: #D4F7D5 !important; border-top: none; padding: 8px 8px; height: 60px; min-width: 150px; width: 150px;" >
                                                            <p style="font-size: 13px; line-height: 13px; color: #555555; height: 27px;" >{{Initative.description}}</p>
                                                        </td>
                                                    </tr>
                                                </ng-container>

                                            </tbody>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.averageMode">
                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">

                                                        <td style="background: #fff;" ></td>
                                                        <td class="strategicSuccessNote" style="font-weight: 600; background: #D4F7D5 !important; border-top: none; padding: 8px 8px; height: 60px; min-width: 150px; width: 150px;" >
                                                            <p style="font-size: 13px; line-height: 13px; color: #555555; height: 27px;" >{{Initative.description}}</p>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.medianMode">
                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">


                                                        <td style="background: #fff;" ></td>
                                                        <td class="strategicSuccessNote" style="font-weight: 600; background: #D4F7D5 !important; border-top: none; padding: 8px 8px; height: 60px; min-width: 150px; width: 150px;" >
                                                            <p style="font-size: 13px; line-height: 13px; color: #555555; height: 27px;" >{{Initative.description}}</p>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.adjustedMode">
                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">

                                                        <td style="background: #fff;" ></td>
                                                        <td class="strategicSuccessNote" style="font-weight: 600; background: #D4F7D5 !important; border-top: none; padding: 8px 8px; height: 60px; min-width: 150px; width: 150px;" >
                                                            <p style="font-size: 13px; line-height: 13px; color: #555555; height: 27px;" >{{Initative.description}}</p>
                                                        </td>

                                                    </tr>
                                                </ng-container>

                                            </tbody>

                                        </table>
                                    </td>
                                </tr>

                            </table>



                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div *ngIf="htmlOptions.download" class="rightSidebar_Table-body" [ngClass]="{'active' : sidebarToggle}">
                <div class="rightSidebar_Table-header">
                    <h4>Rating & Comments
                        <a class="pull-right userViewLink" (click)="userColHideShow()" href="javascript:void(0);"> <i style="font-size: 20px;" class="fa fa-user"></i> </a>
                        <a class="rightSidebarCloseBtn" href="javascript:void(0);" (click)="closeCommentPopup()"> <i class="fa fa-times"></i> </a>
                    </h4>
                </div>
                <div class="table-responsive normal_table-outer" *ngIf="ratingAndComment">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="d-none" [ngClass]="{'d-none' : userColHide}">User </th>
                                <th class="text-center">Rating</th>
                                <th class="text-center">Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr *ngFor="let rating of ratingAndComment">
                                <td class="d-none" [ngClass]="{'d-none' : userColHide}">{{rating.user_name}} </td>
                                <td class="text-center"> <button class="btn btn-sm rating{{getRound(rating.rating)}}bg"> {{rating.rating}} </button> </td>
                                <td class="text-left">{{rating.comment}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="table-responsive normal_table-outer" *ngIf="ratingAndComment">
                    <div *ngIf="notValid" class="alert alert-danger mt-2">
                        <strong>{{message}}.</strong>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div data-backdrop="true" class="modal fade" id="printModal" tabindex="-1" role="dialog" aria-labelledby="printModal" aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">

            <div class="modal-content">
                <div class="modal-header">

                    <button type="button" id="printHide" class="close" (click)="closeDiv();" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body py-4">
                    <form class="" name="form" #f1="ngForm" (ngSubmit)="f1.form.valid && captureScreen()" novalidate>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="py-6 buttonPOsSetBt">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Strategic Priority<span class="req">*</span></label>
                                                <ng-select [clearable]="false" class="max-width400" [multiple]="true" [(ngModel)]="priorities.data" (add)="addPriorities($event)" name="data" (remove)="removePriorities($event)" required>
                                                    <ng-option *ngFor="let project of projectData" [value]="project.id">{{project.title}}</ng-option>
                                                </ng-select>
                                                <div *ngIf="priority.length==0" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Strategic Priority field is required
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Page Size<span class="req">*</span></label>
                                                <select class="form-control" name="pageSize" [(ngModel)]="priorities.pageSize" #pageSize="ngModel" id="pageSize" (change)="changeSize($event.target.value)" required>
                                                <option value=''>Please select</option>
                                                <option>A2</option>
                                                <option>A3</option>
                                                <option>A4</option>
                                            </select>
                                                <div *ngIf="f1.submitted && !priorities.pageSize" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Page Size field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-right">
                                            <button style="display: none;" id='closeDiv' type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                            <button type="submit" id="closeModal" class="btn btn-primary themeBtnBlue">Download</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>