<app-growler>
</app-growler>
<app-header></app-header>
<div class="wrapper">
    <app-nav-bar></app-nav-bar>
    <div class="inner-container headerTopPaddipad">
        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-10 col-xl-8">
                    <h3>Settings</h3>
                </div>
            </div>
        </div>


        <ngb-tabset class="settingTabsOuter" [destroyOnHide]="false">
            <ngb-tab>
                <ng-template ngbTabTitle>Users</ng-template>
                <ng-template ngbTabContent>
                    <div class="row justify-content-center mt-3">
                        <div class="col-md-4">
                            <ng-select class="form-control" [items]="cities" bindLabel="name" placeholder="Search for an organisation" [(ngModel)]="selectedCity">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-12">
                            <div class="table-responsive project_settings-table">
                                <table class="table ">
                                    <thead>
                                        <th>User Name</th>
                                        <th class="text-center">Active?</th>
                                        <th class="text-center max-width400" width="500">Global Roles</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p class="mb-0">Matt Tench</p>
                                                <span>Director of Technology | Chifley Corporation</span>
                                            </td>
                                            <td class="text-center">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck1" checked>
                                                    <label class="custom-control-label" for="customCheck1"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <ng-select class="max-width400" [multiple]="true" [(ngModel)]="selectedCars">
                                                    <ng-option *ngFor="let car of cars" [value]="car.id">{{car.name}}</ng-option>
                                                </ng-select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p class="mb-0">Matt Tench</p>
                                                <span>Director of Technology | Chifley Corporation</span>
                                            </td>
                                            <td class="text-center">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck1" checked>
                                                    <label class="custom-control-label" for="customCheck1"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <ng-select class="max-width400" [multiple]="true" [(ngModel)]="selectedCars">
                                                    <ng-option *ngFor="let car of cars" [value]="car.id">{{car.name}}</ng-option>
                                                </ng-select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p class="mb-0">Matt Tench</p>
                                                <span>Director of Technology | Chifley Corporation</span>
                                            </td>
                                            <td class="text-center">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck1" checked>
                                                    <label class="custom-control-label" for="customCheck1"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <ng-select class="max-width400" [multiple]="true" [(ngModel)]="selectedCars">
                                                    <ng-option *ngFor="let car of cars" [value]="car.id">{{car.name}}</ng-option>
                                                </ng-select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p class="mb-0">Matt Tench</p>
                                                <span>Director of Technology | Chifley Corporation</span>
                                            </td>
                                            <td class="text-center">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck1" checked>
                                                    <label class="custom-control-label" for="customCheck1"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <ng-select class="max-width400" [multiple]="true" [(ngModel)]="selectedCars">
                                                    <ng-option *ngFor="let car of cars" [value]="car.id">{{car.name}}</ng-option>
                                                </ng-select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p class="mb-0">Matt Tench</p>
                                                <span>Director of Technology | Chifley Corporation</span>
                                            </td>
                                            <td class="text-center">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck1" checked>
                                                    <label class="custom-control-label" for="customCheck1"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <ng-select class="max-width400" [multiple]="true" [(ngModel)]="selectedCars">
                                                    <ng-option *ngFor="let car of cars" [value]="car.id">{{car.name}}</ng-option>
                                                </ng-select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p class="mb-0">Matt Tench</p>
                                                <span>Director of Technology | Chifley Corporation</span>
                                            </td>
                                            <td class="text-center">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck1" checked>
                                                    <label class="custom-control-label" for="customCheck1"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <ng-select class="max-width400" [multiple]="true" [(ngModel)]="selectedCars">
                                                    <ng-option *ngFor="let car of cars" [value]="car.id">{{car.name}}</ng-option>
                                                </ng-select>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <input type="text" data-toggle="modal" data-target="#addUserModal" id="btnGroupAddon" placeholder="+ Add New User" class="addNewUserTextBox">
                                            </td>
                                            <td class="text-center">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck1">
                                                    <label class="custom-control-label" for="customCheck1"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <ng-select class="max-width400" [multiple]="true" [(ngModel)]="selectedCars">
                                                    <ng-option *ngFor="let car of cars" [value]="car.id">{{car.name}}</ng-option>
                                                </ng-select>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </ng-template>
            </ngb-tab>
            <ngb-tab>
                <ng-template ngbTabTitle>Roles</ng-template>
                <ng-template ngbTabContent>Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid.
                    <p>Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl cillum
                        PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar helvetica VHS salvia yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit, sustainable jean shorts beard
                        ut DIY ethical culpa terry richardson biodiesel. Art party scenester stumptown, tumblr butcher vero sint qui sapiente accusamus tattooed echo park.</p>
                </ng-template>
            </ngb-tab>
        </ngb-tabset>
    </div>
</div>