<app-growler>
</app-growler>
<app-header></app-header>
<div class="wrapper">
    <div class="loaderOuter" *ngIf="loading">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
    <app-nav-bar></app-nav-bar>
    <div class="inner-container headerTopPaddipad">
        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-10 col-xl-8">
                    <h3 class="breadCrumb"> <a class="profileIcn" herf="javascript:void(0)" (click)="cancleEdit()">My Projects</a> <span *ngIf="htmlOptions.editMode"><a
                                herf="javascript:void(0)">> Edit</a></span></h3>
                    <button *ngIf="htmlOptions.editMode" class="btn btn-primary themeBtnBlue" (click)="cancleEdit()">Back</button>
                </div>
            </div>
        </div>

        <div class="white_box-body minheight100vh">
            <div class="client-outer pt-3">

                <div class="row">
                    <div class="col-md-12">
                        <div class="normal_table-outer client_table-outer">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <!-- <th><input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"></th> -->
                                            <th>Project</th>
                                            <th>Type</th>
                                            <th>Individual / Group</th>
                                            <th>My role</th>
                                            <th>Owner</th>
                                            <th>Status</th>
                                            <!-- <th># Participants</th> -->
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="myProjects">
                                        <tr *ngFor="let project of myProjects  | paginate: { itemsPerPage: 10, currentPage: p }; let i =index">
                                            <!-- <td><input type="checkbox" value="{{project.id}}" [(ngModel)]="myProjects[i].checked"></td> -->
                                            <td>
                                                <a *ngIf="!project.is_deleted" href="javascript:void(0)" (click)="activeProjectOnClick(project.id,project.is_group,project.project_type, project)">{{project.title}}</a>
                                                <span *ngIf="project.is_deleted">{{project.title}}</span>
                                            </td>
                                            <td>
                                                <span *ngIf="project.project_type == 1"> Strategic
                                                    Operating Plan</span>
                                                <span *ngIf="project.project_type == 2"> Stakeholder
                                                    Feedback Report</span>
                                            </td>
                                            <td>
                                                <img *ngIf="project.is_group == 1" src="./assets/images/individual-sop-icon.png" alt="icon" width="20px">
                                                <img *ngIf="project.is_group == 2" src="./assets/images/group-sop-icon.png" alt="icon" width="20px">
                                            </td>
                                            <td *ngIf="project.userRole">{{project.userRole}}</td>
                                            <td *ngIf="!project.userRole">Owner</td>
                                            <td>{{project.ownerName}}</td>
                                            <td>
                                                <span *ngIf="project.is_deleted">Archived</span>
                                                <span *ngIf="project.is_active">Active</span>
                                                <span *ngIf="!project.is_active">Inactive</span>
                                            </td>
                                            <!--    <td>{{project.totalPrarticipant}}</td> -->
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="myProjects && !myProjects.length">
                                        <tr>
                                            <td colspan="7">
                                                No Records found
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <div class="text-left">
                                    <button class="btn btn-primary" [disabled]="isAllCheckBoxNotChecked()" (click)="deleteProjects()">Delete Selected Projects</button>
                                </div> -->
                                <div class="text-right" *ngIf="myProjects">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>



    </div>