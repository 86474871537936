<app-growler>
</app-growler>
<div class="loaderOuter" *ngIf="loading">
    <div class="lds-ripple">
        <div></div>
        <div></div>
    </div>
</div>
<div class="loginOuter">
    <div class="row no-gutters">
        <div class="col-md-6 themeBg">
            <div class="loginLogoSection text-center min-height100vh">
                <img src="./assets/images/Adelante-Logo.png" alt="logo">
            </div>
        </div>
        <div class="col-md-6">
            <div class="loginForm min-height100vh">
                <div class="row justify-content-center">
                    <div class="col-md-12 text-center">
                        <img src="./assets/images/Adelante-Logo.png" alt="logo" class="formTopLogoImg">
                        <p>Change Password</p>
                    </div>
                    <div class="col-10 col-lg-8 col-xl-5 mt-5">
                        <form class="form-horizontal form-material" name="form" #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()" novalidate>

                            <div class="form-group">
                                <label for="exampleInputPassword1">Old Password</label>

                                <input type="password" maxlength="80" class="form-control" name="old_password" [(ngModel)]="htmlOptions.old_password" #old_password="ngModel" trim="blur" [ngClass]="{ 'is-invalid': (f.submitted && !old_password.valid) }" required>

                                <div *ngIf="f.submitted && !old_password.valid" class="invalid-feedback">
                                    <div>Old Password is required</div>
                                </div>

                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Password</label>

                                <input type="password" maxlength="80" class="form-control" name="password" [(ngModel)]="user.password" #password="ngModel" trim="blur" pattern="^[0-9,a-z,A-Z,@,#,$,^,!,%,&,*]{6,30}$" [ngClass]="{ 'is-invalid': (f.submitted && !password.valid) || (password.errors && password.errors.pattern) }"
                                    required>

                                <div *ngIf="f.submitted && !password.valid && !password.errors.pattern" class="invalid-feedback">
                                    <div>Password is required</div>
                                </div>
                                <div *ngIf="password.errors && password.errors.pattern" class="invalid-feedback">
                                    <div>Password must contain at least 6 characters</div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="exampleInputPassword1">Confirm Password</label>

                                <input type="password" maxlength="80" class="form-control" name="confirm_password" [(ngModel)]="htmlOptions.confirm_password" #confirm_password="ngModel" trim="blur" [ngClass]="{ 'is-invalid': (f.submitted && !confirm_password.valid) || (htmlOptions.confirm_password != user.password) }"
                                    required>

                                <div *ngIf="f.submitted && !confirm_password.valid" class="invalid-feedback">
                                    <div>Confirm Password is required</div>
                                </div>
                                <div *ngIf="confirm_password.valid && htmlOptions.confirm_password != user.password" class="invalid-feedback">
                                    <div>Confirm password doesn't match with password</div>
                                </div>
                            </div>

                            <div class="form-group text-right">
                                <a routerLink="/"><u>Back to login</u></a>
                            </div>
                            <button type="submit" class="btn btn-primary btn-block themeBtnBlue mt-5">Submit</button>
                        </form>
                        <div *ngIf="validTrue" class="alert alert-success mt-2">
                            <strong>{{message}}</strong>
                        </div>
                        <div *ngIf="notValid" class="alert alert-danger mt-2">
                            <strong>{{message}}.</strong>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>