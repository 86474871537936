import { Component } from '@angular/core';
import { CommonService } from '../app/services/commonservices';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Adelante";
  isLoggedIn: any;
  constructor(
    private commonService: CommonService,

  ) {}
  ngOnInit() {
    this.getSetting();
    this.isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = localStorage.getItem("userId");
    if (this.isLoggedIn == "true") {
      this.isLoggedIn = "true";
    } else {
      this.isLoggedIn = "false";
    }
  }
  getSetting() {
    //call login api here
    this.commonService.getSetting().subscribe(
      (result) => {

        if (result["success"] && result["data"].ResponseCode == 200) {
          localStorage.setItem("logo", result["data"]["ResponseData"].logo);
        } else {

        }
      },
      (error) => {}
    );
  }
}
