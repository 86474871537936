<app-growler>
</app-growler>
<app-header></app-header>
<div class="wrapper">
    <div class="loaderOuter" *ngIf="loading">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>

    <app-nav-bar></app-nav-bar>
    <div class="inner-container headerTopPaddipad">
        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-10 col-xl-9">
                    <!-- <input type="text" class="projectTitleInputBox" [value]="projectSettingData.projectName"> -->
                    <span class="ptypespan">
                        <img *ngIf="projectSettingData.group==1" src="./assets/images/individual-sop-icon2.png" alt="icon" height="19">
                        <img *ngIf="projectSettingData.group==2" src="./assets/images/group-sop-icon2.png" alt="icon" height="19">
                    </span>
                    <h3 class="sfp_project-title"> {{projectSettingData.projectName}}</h3>



                    <a routerLink="/project-settings" title="Click here to go to project setting page"><i class="fa fa-cog mx-2" aria-hidden="true"></i></a>
                    <a href="javascript:void(0)" title="Click here to edit project" (click)="editPage(projectSettingData.group, projectSettingData.projectType)"><i
                        class="fa fa-pencil mx-2" aria-hidden="true"></i></a>
                    <a href="javascript:void(0)" data-toggle="modal" data-target="#printModal"><i class="fa fa-download mx-2" aria-hidden="true" title="Click here to download report"></i></a>
                </div>
            </div>
        </div>
        <div>
            <div class="mb-3">
                <h3 class="sfp_project-title"> Stakeholder Feedback Report</h3>

            </div>
            <div class="white_box-body" id="contentToConvert">
                <div style="background-color: #fff; box-shadow: 0px 2px 5px 3px #cccccc; padding: 15px;">
                    <div style="float: left; color: #0b3760; font-size: 20px; font-weight: 700; text-transform: uppercase; margin-top: 15px; width: 33%;"><i style="padding: 5px; top: 5px;"><img src="./assets/images/individual-sop-icon.png" alt="user" style="vertical-align: middle; width: 35px;"></i>Company Name</div>

                    <div style="width: 33%; text-align: center; float: left;">
                        <h4 style="margin: 0px; color: #0b3762; font-size: 24px;">Stakeholder Feedback Report</h4>
                        <div style="display: block; float: left; text-align: center; margin-top: 15px; width: 50%;">
                            <span style="color: #103760; font-size: 18px;">{{currentMonth}}</span>
                            <span style="margin: auto; border-radius: 3px; display: block; background: rgb(4,59,110); background: linear-gradient(0deg, rgba(4,59,110,1) 0%, rgba(37,105,167,1) 100%); height: 10px; width: 50px; margin-top: 10px;"></span>
                        </div>
                        <div *ngIf="showCompareMode && ProjectPeriodData.currentPeriod" style="display: block; float: left; text-align: center; margin: 15px 0px 0px 15px; width: 45%;">
                            <span style="color: #103760; font-size: 18px;">{{compareMonth}}</span>
                            <span style="margin: auto; border-radius: 3px; display: block; background: rgb(131,185,235);
                    background: linear-gradient(0deg, rgba(131,185,235,1) 0%, rgba(159,209,255,1) 100%);  height: 10px; width: 50px; margin-top: 10px;"></span>
                        </div>
                    </div>
                    <div style="float: right; ">
                        <div style="float: left; line-height: 30px;">
                            <ul style="padding-left:0px; list-style: none; text-align: right; margin-top: 0px;">
                                <li>Project Owner: <span>Project Owner</span></li>
                                <li>Organisation Name</li>
                                <li>Date (dd-mm-yyyy)</li>
                            </ul>
                        </div>
                        <div style="float: right; margin-left: 15px;"><img src="logo.png"></div>
                    </div>

                    <div style="clear: both; float: none;"></div>
                </div>
                <div class="sfp_project_header-outer">
                    <div class="row">
                        <div class="col-md-6">
                            <h3 class="sfp_project-title" style="color: #0b3760; font-size: 20px; font-weight: 700;">
                                <span>Displaying period:
                            <div class="d-inline-block text-center" [ngClass]="{'currentMonth_Box' : showCompareMode}">
                                <p>{{currentMonth}} <span *ngIf="showCompareMode">(Current)</span></p>
                                <p class="progressPipe_currentbg current_Result_Bar-Bg" style="position: absolute; display: inline-block; border-radius: 3px; height: 10px;  min-width: 80%; background-color: #286dad; background: #286dad; background: -webkit-gradient(linear, left top, left bottom, from(#286dad), to(#003667)); background: linear-gradient(to bottom, #286dad 0%, #003667 100%);"
                                    *ngIf="showCompareMode"></p>
                        </div>
                        <div class="d-inline-block text-center" *ngIf="showCompareMode && ProjectPeriodData.currentPeriod" [ngClass]="{'currentMonth_Box' : showCompareMode}">
                            <p>{{compareMonth}}</p>
                            <p class="progressPipe_currentbg compareMonth_Result_Bar-Bg" style="position: absolute; display: inline-block; border-radius: 3px; height: 10px;  min-width: 80%; background-color: #286dad; background: #9fd1ff;  background: -webkit-gradient(linear, left top, left bottom, from(#9fd1ff), to(#5281aa)); background: linear-gradient(to bottom, #9fd1ff 0%, #5281aa 100%);"></p>
                        </div>
                        </span>
                        </h3>
                    </div>
                    <div class="col-md-6" *ngIf="htmlOptions.download">
                        <div class="row">
                            <div class="col-md-5 text-right">

                                <div class="switcherOuter-body mt-2">
                                    <span> Compare mode <!-- {{showCompareMode }}{{ disableFeedbackButton}} -->
                                    <span *ngIf="!showCompareMode">OFF</span>
                                    <span *ngIf="showCompareMode">ON</span>
                                    </span>
                                    <div class="switchToggle d-inline-block ml-2">
                                        <input type="checkbox" id="switch" (click)='handleCompare($event);'>
                                        <label for="switch"></label>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7" *ngIf="!showCompareMode && disableFeedbackButton">
                                <div class="switcherOuter-body mt-2">
                                    <span> Feedback Providers
                                <span *ngIf="!showFeedbackMode">OFF</span>
                                    <span *ngIf="showFeedbackMode">ON</span>
                                    </span>
                                    <div class="switchToggle d-inline-block ml-2">
                                        <input type="checkbox" id="switch1" (click)='handleFeedback($event);'>
                                        <label for="switch1"></label>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7" *ngIf="showCompareMode">
                                <div class="form-group row">
                                    <label for="inputState" class="col-sm-5 col-form-label text-right">Compare
                                        period</label>
                                    <div class="col-sm-7">
                                        <div class="input-group">
                                            <p-calendar [readonlyInput]="true" [minDate]="" [maxDate]="" placeholder="Select Period" [(ngModel)]="ProjectPeriodData.currentPeriod" name="currentPeriod" view="month" [showIcon]="false" dateFormat="MM/yy" class="monthCalendra" [yearNavigator]="true"
                                                yearRange="2020:2030" #currentPeriod="ngModel" (onSelect)="changePeriod($event, isDummyUser)"><span class="form-control"></span>
                                            </p-calendar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-responsive pb-4 pr-4" *ngIf='showCompareMode'>
                <div class="sfpProject_table-outer">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="3" rowspan="2" style="text-align: left;" class="text-left v-top percHeadWidth"><span style="color: #0B3760; font-weight: 700; font-size: 19px;">Perceptions of Key Leadership
                                        Capabilities &
                                        Behaviours</span></th>
                                <th colspan="4" style="padding: 2px 5px; text-align: center; min-width: 35px; color: #0B3760; font-weight: 700; font-size: 19px;"> Summary </th>
                                <th colspan="10" style="padding: 2px 5px; text-align: center; min-width: 35px; color: #0B3760; font-weight: 700; font-size: 19px;">Score Results</th>
                            </tr>
                            <tr>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">Current Period Mean</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">Compared Period Mean</small> </th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">Delta</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">Trend</small></th>

                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">1</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">2</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">3</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">4</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">5</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">6</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">7</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">8</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">9</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">10</small></th>

                            </tr>

                        </thead>

                        <tbody>

                            <tr *ngFor="let project of projectData; let idx= index">
                                <td style="width: 40px;"><span style="font-size: 15px; color: #0B3760; font-weight: 700; text-align: left; display: inline-block; width: 100%;" class="text-center">{{idx+1}}</span></td>
                                <td class="queOuter" style="width: 30%; max-width: 220px; text-align: left; vertical-align: middle;"><span style="font-size: 15px; color: #0B3760; font-weight: 700; text-align: left; display: inline-block; width: 100%;">{{project.question}}</span></td>
                                <td>
                                    <a *ngIf="htmlOptions.download" href="javascript:void(0)" data-toggle="tooltip" [title]="project.description"><i class="fa fa-question-circle" aria-hidden="true"></i></a>


                                </td>
                                <td style="color: #666; font-size: 12px; font-weight: 300; text-align: center;">{{project.mean | number : '1.1-1'}}</td>
                                <td style="color: #666; font-size: 12px; font-weight: 300; text-align: center;">{{project.secondaryMean| number : '1.1-1'}}</td>
                                <td style="color: #666; font-size: 12px; font-weight: 300; text-align: center;">{{delta(project.mean, project.secondaryMean)| number : '1.1-1'}}</td>
                                <td>
                                    <i *ngIf="trend(project.mean, project.secondaryMean) == 1" class="fa fa-arrow-up" aria-hidden="true"></i>
                                    <i *ngIf="trend(project.mean, project.secondaryMean) == 2" class="fa fa-arrow-up upRight_angle" aria-hidden="true"></i>
                                    <i *ngIf="trend(project.mean, project.secondaryMean) == 3" class="fa fa-arrow-down downRight_angle" aria-hidden="true"></i>
                                    <i *ngIf="trend(project.mean, project.secondaryMean) == 4" class="fa fa-arrow-down" aria-hidden="true"></i>
                                    <i *ngIf="trend(project.mean, project.secondaryMean) == 5" class="fa fa-arrow-up upRight_angle" aria-hidden="true"></i>
                                </td>
                                <td colspan="10">
                                    <div class="scoreResult_bar-body" style="position: relative; width: 100%; min-height: 25px;">
                                        <div class="scoreBarpipe mb-2" [ngClass]="[htmlOptions.download ? 'current_Result_Bar-Bg' : 'current_Result_Bar-Bg-pdf']" style="position: absolute; display: inline-block; border-radius: 3px;  height: 10px; background-color: #286dad; background: #286dad; background: -webkit-gradient(linear, left top, left bottom, from(#286dad), to(#003667)); background: linear-gradient(to bottom, #286dad 0%, #003667 100%);"
                                            [ngStyle]="{'left.%': (project.minval*10)-5, 'width.%': calculateWidth(project.minval, project.maxval)}" *ngIf='project.minval'>
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint" style="position: absolute; width: auto; font-size: 10px; top: -5px; color: #919191; left:-20px">{{project.minval| number : '1.1-1'}}</span>
                                            <span class="scoreBarpipe_Circle" style="background: #F7A34F; height: 10px; width: 10px; border-radius: 20px; top: 0px; position: absolute; box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2); border: 1px solid #FFDEBE; left: 50%;"></span>
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint" style="position: absolute;width: auto;font-size: 10px;top: -5px;color: #919191;right: -20px;">{{project.maxval| number : '1.1-1'}}</span>
                                        </div>
                                        <div class="scoreBarpipe compareMonth_Result_Bar-Bg" style="position: absolute; display: inline-block; border-radius: 3px; height: 10px; background-color: #286dad; background: #9fd1ff;  background: -webkit-gradient(linear, left top, left bottom, from(#9fd1ff), to(#5281aa)); background: linear-gradient(to bottom, #9fd1ff 0%, #5281aa 100%);"
                                            [ngStyle]="{'left.%': (project.secondaryMinval*10)-5, 'width.%': calculateWidth(project.secondaryMinval, project.secondaryMaxval)}" *ngIf='project.secondaryMinval'>
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint" style="position: absolute; width: auto; font-size: 10px; top: -5px; color: #919191; left:-20px">{{project.secondaryMinval| number : '1.1-1'}}</span>
                                            <span class="scoreBarpipe_Circle" style="background: #F7A34F; height: 10px; width: 10px; border-radius: 20px; top: 0px; position: absolute; box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2); border: 1px solid #FFDEBE; left: 50%;"></span>
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint" style="position: absolute;width: auto;font-size: 10px;top: -5px;color: #919191;right: -20px;">{{project.secondaryMaxval| number : '1.1-1'}}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
            <div class="table-responsive pb-4 pr-4" *ngIf='!showCompareMode'>
                <div class="sfpProject_table-outer">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="3" rowspan="2" style="text-align: left;" class="text-left v-top percHeadWidth"><span style="color: #0B3760; font-weight: 700; font-size: 19px;">Perceptions of Key Leadership
                                    Capabilities &
                                    Behaviours</span> </th>
                                <th colspan="4" style="color: #0B3760; font-weight: 700; font-size: 19px;"> Summary </th>
                                <th colspan="10" style="color: #0B3760; font-weight: 700; font-size: 19px;">Score Results</th>
                                <th colspan="4" *ngIf="showFeedbackMode" style="color: #0B3760; font-weight: 700; font-size: 19px;">Feedback Providers <label *ngIf="isDummyUser" class="addFeddProPlus"><i (click)="addDummyUsers() " class="fa fa-plus-circle " aria-hidden="true "></i></label></th>
                            </tr>
                            <tr>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">Mean</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">Median</small> </th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">Min</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">Max</small></th>

                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">1</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">2</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">3</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">4</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">5</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">6</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">7</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">8</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">9</small></th>
                                <th><small style="font-size: 9px; border-bottom: 1px solid #ddd; display: inline-block; width: 100%;">10</small></th>
                                <ng-container *ngIf="showFeedbackMode && !isDummyUser">
                                    <th *ngFor='let user of userData' class="feedbackProInnWit">
                                        <small>{{user.user_name}}</small></th>
                                </ng-container>
                                <ng-container *ngIf="showFeedbackMode && isDummyUser">
                                    <th *ngFor='let user of dummyUser' class="feedbackProInnWit">
                                        <small *ngIf="user.id">{{user.user_name}}</small>
                                        <input class="feedBacProviders" *ngIf="!user.id" type="text" value="" placeholder="Enter user name" (keyup.enter)="addDummyUser($event.target.value)" (blur)="addDummyUser($event.target.value)">
                                    </th>
                                    <th>
                                    </th>
                                </ng-container>

                            </tr>

                        </thead>

                        <tbody>
                            <ng-container *ngFor="let project of projectData; let idx= index">
                                <tr style="background: #F8F8F8; border-bottom: 1px solid #fff;" class="{{project.id}}">
                                    <td style="width: 40px;"><span style="font-size: 15px; color: #0B3760; font-weight: 700; text-align: left; display: inline-block; width: 100%;" class="text-center">{{idx+1}}</span></td>
                                    <td class="queOuter" style="width: 30%; max-width: 220px; text-align: left; vertical-align: middle;"> <span style="font-size: 15px; color: #0B3760; font-weight: 700; text-align: left; display: inline-block; width: 100%;">{{project.question}}</span></td>
                                    <td>
                                        <a *ngIf="htmlOptions.download" href="javascript:void(0)" data-toggle="tooltip" [title]="project.description"><i
                                        class="fa fa-question-circle" aria-hidden="true"></i></a>

                                    </td>

                                    <td style="color: #666; font-size: 12px; font-weight: 300; text-align: center;">{{project.mean| number : '1.1-1'}}</td>
                                    <td style="color: #666; font-size: 12px; font-weight: 300; text-align: center;">{{median(project.userRating)| number : '1.1-1'}}</td>
                                    <td style="color: #666; font-size: 12px; font-weight: 300; text-align: center;">{{project.minval| number : '1.1-1'}}</td>
                                    <td style="color: #666; font-size: 12px; font-weight: 300; text-align: center;">{{project.maxval| number : '1.1-1'}}</td>

                                    <td colspan="10">
                                        <div class="scoreResult_bar-body" style="position: relative; width: 100%; min-height: 25px;" *ngIf='project.minval'>
                                            <div class="scoreBarpipe centerBar" [ngClass]="[htmlOptions.download ? 'current_Result_Bar-Bg' : 'current_Result_Bar-Bg-pdf']" style="position: absolute; display: inline-block; border-radius: 3px; height: 10px; background-color: #286dad; background: #286dad; background: -webkit-gradient(linear, left top, left bottom, from(#286dad), to(#003667)); background: linear-gradient(to bottom, #286dad 0%, #003667 100%);"
                                                [ngStyle]="{'left.%': (project.minval*10)-5, 'width.%': calculateWidth(project.minval, project.maxval)}">
                                                <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint" style="position: absolute; width: auto; font-size: 10px; top: -5px; color: #919191; left:-20px">{{project.minval| number : '1.1-1'}}</span>
                                                <span class="scoreBarpipe_Circle" style="background: #F7A34F; height: 10px; width: 10px; border-radius: 20px; top: 0px; position: absolute; box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2); border: 1px solid #FFDEBE; left: 50%;"></span>
                                                <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint" style="position: absolute;width: auto;font-size: 10px;top: -5px;color: #919191;right: -20px;">{{project.maxval| number : '1.1-1'}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <ng-container *ngIf="showFeedbackMode  && !isDummyUser">
                                        <ng-container *ngFor='let user of userData'>
                                            <!-- user{{user.id}} -->
                                            <td (mouseover)="addClass(user.id,project.id)" id="{{user.id}}{{project.id}}" (mouseleave)="removeClass(user.id,project.id)" class="bgWhiteBrBt tdonhover user{{user.id}}">

                                                <div class="dropdown" *ngIf="!getUserRating(user, project.userRating)">
                                                    <a class="p-3" href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBox()" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    </a>

                                                    <div class="dropdown-menu p-4" aria-labelledby="dropdownMenuLink">
                                                        <div class="divSlider slider">
                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                            </div>
                                                        </div>
                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange" id='range{{project.id}}{{user.id}}' class="" value="">
                                                        <textarea class="form-control" [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                        <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1" (click)="addRating(project.id,user.id, feedback.text, project.isDummyUser)">Submit</a>
                                                    </div>
                                                </div>

                                                <div class="dropdown" *ngIf="getUserRating(user, project.userRating)">
                                                    <a href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBox()" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span class="m-0 text-center" *ngIf="getUserRating(user, project.userRating)">{{getUserRating(user, project.userRating) | number : '1.1-1'}}</span>
                                                    </a>

                                                    <div class="dropdown-menu p-2" aria-labelledby="dropdownMenuLink">

                                                        <div class="table-responsive normal_table-outer">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-center">Comments</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="text-left">{{getUserComments(user, project.userRating)}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <label class="m-0 text-center" *ngIf="getUserRating(user, project.userRating)">{{getUserRating(user, project.userRating) | number : '1.1-1'}}</label> -->
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="showFeedbackMode  && isDummyUser">
                                        <ng-container *ngFor='let user of dummyUser'>
                                            <!-- (mouseover)='over(user.id)' (mouseout)='out(user.id)' -->
                                            <td (mouseover)="addClass(user.id,project.id)" (mouseleave)="removeClass(user.id,project.id)" class="bgWhiteBrBt tt user{{user.id}}">

                                                <div class="dropdown" *ngIf="!getUserRating(user, project.userRating)">
                                                    <a class="p-3" href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBox()" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    </a>

                                                    <div class="dropdown-menu p-4" aria-labelledby="dropdownMenuLink">
                                                        <div class="divSlider slider">
                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                            </div>
                                                        </div>
                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange" id='range{{project.id}}{{user.id}}' class="" value="">
                                                        <textarea class="form-control" [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                        <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1" (click)="addRating(project.id,user.id, feedback.text, project.isDummyUser)">Submit</a>
                                                    </div>
                                                </div>

                                                <div class="dropdown" *ngIf="getUserRating(user, project.userRating)">
                                                    <a href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBox()" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span class="m-0 text-center" *ngIf="getUserRating(user, project.userRating)">{{getUserRating(user, project.userRating) | number : '1.1-1'}}</span>
                                                    </a>

                                                    <div class="dropdown-menu p-2" aria-labelledby="dropdownMenuLink">

                                                        <div class="table-responsive normal_table-outer">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-center">Comments</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="text-left">{{getUserComments(user, project.userRating)}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <td></td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>


</div>
</div>
<div data-backdrop="true" class="modal fade" id="printModal" tabindex="-1" role="dialog" aria-labelledby="printModal" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">

        <div class="modal-content">
            <div class="modal-header">

                <button type="button" id="printHide" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body py-4">
                <form class="" name="form" #forprint="ngForm" (ngSubmit)="forprint.form.valid &&captureScreen()" novalidate>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="py-6 buttonPOsSetBt">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Page Size<span class="req">*</span></label>
                                            <select class="form-control" name="pageSize" [(ngModel)]="priorities.pageSize" #pageSize="ngModel" id="pageSize" (change)="changeSize($event.target.value)" required>
                                                <option value=''>Please select</option>
                                                <option>A3</option>
                                                <option>A4</option>
                                            </select>
                                            <div *ngIf="forprint.submitted && !priorities.pageSize" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Page Size field is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-right">
                                        <button type="submit" id="closeModal" class="btn btn-primary themeBtnBlue">Download</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>