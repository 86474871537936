import { NgModule } from '@angular/core';
import { AuthGuard } from "./services/auth.guard";
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { ProjectComponent } from './components/project/project.component';
import { SfpProjectComponent } from './components/sfp-project/sfp-project.component';
import { ProjectSettingsComponent } from './components/project-settings/project-settings.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { ClientsComponent } from './components/clients/clients.component';
import { ApplicationSettingsComponent } from './components/application-settings/application-settings.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ProjectCreateComponent } from './components/project-create/project-create.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ProjectViewComponent } from './components/project-view/project-view.component';
import { ProjectSfrComponent } from './components/project-sfr/project-sfr.component';
import { FeedbackSfrComponent } from './components/feedback-sfr/feedback-sfr.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { MyProjectsComponent } from './components/my-projects/my-projects.component';
import { SopViewComponent } from './components/sop-view/sop-view.component';
import { SopViewPdfComponent } from './components/sop-view-pdf/sop-view-pdf.component';
import { SopCreateComponent } from './components/sop-create/sop-create.component';
import { FeedbackSfrPdfComponent } from './components/feedback-sfr-pdf/feedback-sfr-pdf.component';
import {ThanksComponent} from './components/thanks/thanks.component'


const routes: Routes = [
  { path: "dashboard", component: HomeComponent, canActivate: [AuthGuard] },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "thanks", component: ThanksComponent },
  { path: "project", component: ProjectComponent, canActivate: [AuthGuard] },
  {
    path: "sfp-project",
    component: SfpProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "resetpassword",
    component: ResetpasswordComponent,
  },
  {
    path: "generatepassword",
    component: ResetpasswordComponent,
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "my-projects",
    component: MyProjectsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sfp-project",
    component: SfpProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "project-settings",
    component: ProjectSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "project-create",
    component: ProjectCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "project-view",
    component: ProjectViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "project-sfr",
    component: ProjectSfrComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "feedback-sfr",
    component: FeedbackSfrComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "feedback-sfr-pdf",
    component: FeedbackSfrPdfComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "feedback",
    component: FeedbackComponent,
  },
  {
    path: "project-settings/:id",
    component: ProjectSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "project-create",
    component: ProjectCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "my-profile",
    component: MyProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "settings",
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "clients",
    component: ClientsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "application-settings",
    component: ApplicationSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sop-view",
    component: SopViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sop-view-pdf",
    component: SopViewPdfComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sop-create",
    component: SopCreateComponent,
    canActivate: [AuthGuard],
  },
  { path: "", component: LoginComponent },
  { path: "**", redirectTo: "dashboard", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
