import { Component, OnInit, Input } from '@angular/core';
import { GrowlerService} from 'src/app/services';
import { AuthService } from "../../auth/auth.service";
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/commonservices';
@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit {
  @Input() newLogo: string;
  globalRoles: any = [];
  logoUrl: string;
  userId: any;
  constructor(
    public growlerService: GrowlerService,
    private commonService: CommonService,
    private authService: AuthService,
    public router: Router
  ) {}
  isLoggedIn: any;
  ngOnInit(): void {

    let roles = localStorage.getItem("roles");
    this.userId = localStorage.getItem("userId");
    this.getPermissionByRole(roles);
    this.logoUrl = localStorage.getItem("logo");
    this.isLoggedIn = localStorage.getItem("isLoggedIn");
    this.refreshToken();
    if (this.isLoggedIn == "true") {
      this.isLoggedIn = "true";
    } else {
      this.isLoggedIn = "false";
    }
  }
  refreshToken() {
    if (localStorage.getItem("LoggedInTime")) {
      var t0 = Number(localStorage.getItem("LoggedInTime"));
      if (isNaN(t0)) t0 = 0;
      var t1 = new Date().getTime();
      var duration = t1 - t0;
      if (duration > 15 * 60 * 1000) {
        this.getUserDetails();
        var loginTime = new Date().getTime();
        localStorage.setItem("LoggedInTime", loginTime.toString());
      }
    }
  }
  refreshAuthToken() {
    var token = this.commonService.getCurrentUser().token;
    this.commonService.apiCall(token, "refreshAuthToken").subscribe(
      (resultProfileData) => {
        if (resultProfileData["statusCode"] == 200) {
          var token = resultProfileData["responsePacket"].token;
        }
      },
      (error) => {}
    );
  }
  getPermissionByRole(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        if (!result["success"]) {
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = result["data"].ResponseData;
        } else {
          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  getUserDetails() {
    var data = {
    };
    this.commonService.apiCall(data, "getUserDetails").subscribe(
      (resultUserData) => {
        if (resultUserData["data"].ResponseCode == 200) {
          this.commonService.setCurrentUser(
            resultUserData["data"].ResponseData
          );
        }
      },
      (error) => {}
    );
  }
  onLogout() {
    this.authService.logout();
    this.router.navigate([""]);
  }
}
