import { Component, OnInit,Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { GrowlerService } from '../../services';

@Component({
  selector: 'app-growler',
  templateUrl: './growler.component.html',
  styleUrls: ['./growler.component.scss']
})
export class GrowlerComponent implements OnInit {
  private subscription: Subscription;
  message: any;
  @Input() position: string = 'top-right';
  @Input() timeout: number = 3000;

  constructor(private toast: GrowlerService) { }

  ngOnInit(): void {

    this.subscription = this.toast.getAlert()
            .subscribe(message => {
                switch (message && message.type) {
                    case 'success':
                   
                        message.cssClass = 'alert alert-success';
                       // setTimeout(() => this.toast.clear(), 100000);
                        break;
                    case 'error':
                        message.cssClass = 'alert alert-danger';
                       // setTimeout(() => this.toast.clear(), 5000);
                        break;
                }

                this.message = message;
            
              });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

@Component({
  selector: 'app-loader',
  template:
    `<div class="loaderOuter">
    <div class="lds-ripple">
        <div></div>
        <div></div>
    </div>
</div>`
})
export class OverlayComponent {
  constructor() { }
}
