<div class="loaderOuter" *ngIf="loading">
    <div class="lds-ripple">
        <div></div>
        <div></div>
    </div>
</div>
<div class="loginOuter">
    <div class="row no-gutters">
        <div class="col-md-6 themeBg">
            <div class="loginLogoSection text-center min-height100vh">
                <app-logo></app-logo>
            </div>
        </div>
        <div class="col-md-6">
            <div class="loginForm min-height100vh">
                <div class="row justify-content-center">
                    <div class="col-md-12 text-center">
                        <img [src]="logoUrl" alt="logo" class="formTopLogoImg">
                        <p>Forgot Password</p>
                    </div>
                    <div class="col-10 col-lg-8 col-xl-5 mt-5">
                        <form name="form" #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()" novalidate>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Enter Email</label>
                                <input type="text" trim="blur" class="form-control" maxlength="80" placeholder="Enter Email" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" name="email" [(ngModel)]="user.email" #email="ngModel" [ngClass]="{'is-invalid': f.submitted && !email.valid }"
                                    required>

                                <div *ngIf="f.submitted && !email.valid" class="invalid-feedback">
                                    <div>Email field is required</div>
                                </div>

                            </div>
                            <div class="form-group text-right">
                                <a routerLink="/"><u>Back to login</u></a>
                            </div>
                            <button type="submit" class="btn btn-primary btn-block themeBtnBlue mt-5">Submit</button>
                        </form>
                        <div *ngIf="validTrue" class="alert alert-success mt-2">
                            <strong>{{message}}.</strong>
                        </div>
                        <div *ngIf="notValid" class="alert alert-danger mt-2">
                            <strong>{{message}}.</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>