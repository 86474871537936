import { Component, OnInit, Pipe, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../../services/commonservices';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { GrowlerService } from '../../services/growler.service';
import { Projectcreate } from '../../model/projectcreate';
import { AuthService } from "../../auth/auth.service";
/* import * as $ from 'jquery';
import 'jquery-ui-dist/jquery-ui'; */
declare var $: any;

@Component({
  selector: "app-sop-create",
  templateUrl: "./sop-create.component.html",
  styleUrls: ["./sop-create.component.scss"],
})
export class SopCreateComponent implements OnInit {
  public showLoder: boolean = true;
  public sidebarToggle: boolean = false;
  public userColHide: boolean = false;
  permission: any = [];
  globalRoles: any = [];
  notValid: boolean = false;
  validTrue: boolean = false;
  projectData: any = [];
  ratingAndComment: any = [];
  ProjectPeriod: any = [];
  ExtraColumn: any = [];
  projectDetail: any = [];
  colsapnValue: any = 1;
  mergeTdRemove: number;
  feedback: any = {};
  htmlOptions: any = {};
  loading: boolean = false;
  message: any;
  pType: any;
  gType: any;
  activeProjectId: any;
  scrollLeftSize: number = 0;
  showScrollRight: boolean = true;
  allowAddPeriod: boolean = true;
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public growlerService: GrowlerService,
    public router: Router
  ) {}
  @ViewChild("widgetsContent") widgetsContent: ElementRef;
  gallery = $(
    "table.time_management_personal-table tr td:not(.disableResizeDrag)"
  );
  ngOnInit(): void {
    this.activeProjectId = localStorage.getItem("editProjectId");
    this.pType = localStorage.getItem("pType");
    this.gType = localStorage.getItem("gType");
    this.allowAddPeriod = true;
    this.getProjectData(this.activeProjectId);
    let roles = localStorage.getItem("roles");
    this.getPermissionByRole(roles);
    this.getUserPermissionOnProject(this.activeProjectId);
    // this.customeSlider();
  }

  getUserPermissionOnProject(projectId) {
    var data = {
      projectId: projectId,
    };
    this.commonService.apiCall(data, "getUserPermissionOnProject").subscribe(
      (result) => {
        // this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.permission = result["data"].ResponseData;
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  customeSlider() {
    $(function () {
      var handle = $(".custom-handle");
      $(".slider").slider({
        orientation: "horizantal",
        range: "min",
        value: 1,
        step: 0.5,
        min: 1.0,
        max: 10.0,
        animate: "fast",
        create: function () {
          handle.text($(this).slider("value"));
          handle.addClass(
            "rating" + Math.round($(this).slider("value")) + "bg"
          );
        },
        slide: function (event, ui) {
          handle.text(ui.value);
          handle.removeClass("rating" + (Math.round(ui.value) - 1) + "bg");
          handle.addClass("rating" + Math.round(ui.value) + "bg");
          var attrId = $(this)
            .closest(".dropdown-menu.p-3")
            .find('input[name="feedbackRange"]')
            .attr("id");
          $("#" + attrId).val(ui.value);
        },
      });
    });
  }
  getPermissionByRole(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        // this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = result["data"].ResponseData;
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  addUpdatePriority(id, title) {
    if (!title) {
      this.growlerService.error("Strategic priority title is required.");
      this.growlerService.clear();
      return false;
    }
    let data = {
      id: id,
      projectId: this.activeProjectId,
      priorityTitle: title,
    };

    this.loading = true;
    //call login api here
    this.commonService.apiCall(data, "addStrategicPriority").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.growlerService.success("Strategic priority successfully added.");
          this.growlerService.clear();
          this.ngOnInit();
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }

  getProjectData(activeProjectId) {
    let data = {
      projectId: activeProjectId, //58
    };
    this.loading = true;
    //call login api here
    this.commonService.getProjectData(data).subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          setTimeout(() => {
            this.initialisation();
          }, 1000);
          let projectDataObj = result["data"]["ResponseData"]["ProjectData"];

          if (!this.changeObjToArraySort(projectDataObj).length) {
            projectDataObj[0] = {
              id: 0,
              title: "",
              priority: 1,
            };
          }
          let projectData = this.changeObjToArraySort(projectDataObj);
          //Sort
          this.ProjectPeriod = this.changeObjToArray(
            result["data"]["ResponseData"]["ProjectPeriod"]
          );

          if (!this.ProjectPeriod.length) {
            this.ProjectPeriod.push({
              id: 0,
              title: "",
            });
          }

          this.ExtraColumn = this.changeObjToArray(
            result["data"]["ResponseData"]["ExtraColumn"]
          );
          this.projectData = Object.keys(projectData).map(function (
            personNamedIndex
          ) {
            let project = projectData[personNamedIndex];
            return project;
          });
          this.projectDetail = result["data"].ResponseData.Projectsetting;

          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
  }

  deleteInitativeRow(initativeId){
    this.loading = true;
    //call deleteInitativeRow api here
    this.commonService.removeInitiativeRow({projectInitiativeId:initativeId}).subscribe(
      (result) => {
        console.log('result...', result);
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.growlerService.success(result["data"].ResponseMessage);
          this.growlerService.clear();
          setTimeout(() => {
            this.ngOnInit();
          }, 1000);
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  changeObjToArrayForTasks(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });

    return project;
  }
  changeObjToArray(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });
    return project;
  }
  changeObjToArraySort(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });

    project.sort(function (a, b) {
      var keyA = a.priority,
        keyB = b.priority;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return project;
  }

  checkMergeColumn(init: any, periodId: number) {
    let currentId = init[periodId].periodId;
    let nextperiodId = currentId + 1;
    let manageColumn = 0;
    if (init[periodId]) {
      if (init[nextperiodId] != undefined) {
        if (init[currentId].Task.id == init[nextperiodId].Task.id) {
          this.colsapnValue = 2;
          manageColumn = 1;
        }
      }
    }
    return manageColumn;
  }
  setColspanValue(init: any, periodId: number) {
    let currentId = init[periodId].periodId;
    let nextperiodId: number;
    var colsapnValue = 1;
    if (
      $("#" + periodId)
        .parent("th")
        .next()
        .children("span")
        .attr("id") != undefined
    ) {
      nextperiodId = $("#" + periodId)
        .parent("th")
        .next()
        .children("span")
        .attr("id");
    }
    this.colsapnValue = 1;
    this.mergeTdRemove = 0;
    let taskIds: any = currentId;
    if (init[periodId]) {
      while (init[nextperiodId] != undefined) {
        if (init[nextperiodId] != undefined) {
          if (init[currentId].Task.id == init[nextperiodId].Task.id) {
            colsapnValue++;
            taskIds = taskIds + "," + nextperiodId;
            if (
              $("#" + nextperiodId)
                .parent("th")
                .next()
                .children("span")
                .attr("id") != undefined
            ) {
              nextperiodId = $("#" + nextperiodId)
                .parent("th")
                .next()
                .children("span")
                .attr("id");
            } else {
              break;
            }
          } else {
            break;
          }
        } else {
          break;
        }
      }
    }
    //console.log(taskIds);
    $("#updateperiodid" + init[currentId].Task.id + "-" + periodId).val(
      taskIds
    );
    return colsapnValue;
  }
  hideDivNextTime(inIt, pId) {
    let PrePerIodId: number;
    if (
      $("#" + pId)
        .parent("th")
        .prev()
        .children("span")
        .attr("id") != undefined
    ) {
      PrePerIodId = $("#" + pId)
        .parent("th")
        .prev()
        .children("span")
        .attr("id");
    }
    //return
    let aTaskId: any;
    let bTaskId: any;
    if (inIt[PrePerIodId] != undefined) {
      aTaskId = inIt[pId].Task.id;
      bTaskId = inIt[PrePerIodId].Task.id;
      if (aTaskId == bTaskId) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }
  counter(i: number) {
    if (i && i > 1) {
      return new Array(i);
    }
  }
  getNextId(pId) {
    let PrePerIodId: number;
    if (
      $("#" + pId)
        .parent("th")
        .next()
        .children("p")
        .attr("id") != undefined
    ) {
      PrePerIodId = $("#" + pId)
        .parent("th")
        .next()
        .children("p")
        .attr("id");
    }
    return PrePerIodId;
  }
  scrollLeft() {
    $(".widgetsContent").animate(
      {
        scrollLeft: "-=190px",
      },
      "slow"
    );
    const container = document.querySelector(".widgetsContent");
    this.scrollLeftSize = container.scrollLeft - 190;
    var elmnt = document.getElementById("widgetsContent");
    var x = elmnt.scrollWidth;
    var ow = $(".widgetsContent").width();
    if (ow + container.scrollLeft >= x) {
      this.showScrollRight = false;
    } else {
      this.showScrollRight = true;
    }
  }

  scrollRight() {
    $(".widgetsContent").animate(
      {
        scrollLeft: "+=190px",
      },
      "slow"
    );
    var elmnt = document.getElementById("widgetsContent");
    const container = document.querySelector(".widgetsContent");
    var x = elmnt.scrollWidth;
    var ow = $(".widgetsContent").width();
    if (ow + container.scrollLeft + 190 >= x) {
      this.showScrollRight = false;
    } else {
      this.showScrollRight = true;
    }
    this.scrollLeftSize = container.scrollLeft + 190;
  }
  showEditFunction(lbl, txt, id) {
    $("#" + txt + id).show();
    $("#" + lbl + id).hide();
    $("#" + txt + id).focus();
  }

  /******* add initiative function ****************/
  addUpdatePriorityInitative(
    strategicPriorityId,
    fieldType,
    fieldValue,
    initativeData: any
  ) {
    if (fieldValue == "") {
      this.growlerService.error(fieldType + " is required");
      this.growlerService.clear();
      return false;
    }
    if (strategicPriorityId) {
      if (fieldValue == "") {
        this.growlerService.error("Priority Initiative is required field.");
        this.growlerService.clear();
        return false;
      }
      this.loading = true;

      if (fieldValue == "") {
        this.growlerService.error(fieldType + " is required");
        this.growlerService.clear();
      }
      if (!initativeData) {
        initativeData = {
          priorityId: "",
          title: "",
          id: "",
          description: "",
          userId: "",
          projectId: this.activeProjectId,
        };
      }
      let data = initativeData;
      data.priorityId = strategicPriorityId;
      data.projectId = this.activeProjectId;
      if (fieldType == "Initative Title") {
        data.title = fieldValue;
      }
      if (fieldType == "Initative Description") {
        data.description = fieldValue;
      }
      if (fieldType == "Owner") {
        data.userId = fieldValue;
      }
      this.commonService
        .apiCall(data, "addUpdatePriorityInitative")
        .subscribe((resultUpdateInitative) => {
          //this.loading = false;

          if (!resultUpdateInitative["success"]) {
            this.message = resultUpdateInitative["error"];
            this.growlerService.error(this.message);
            this.growlerService.clear();
          }
          if (
            resultUpdateInitative["success"] &&
            resultUpdateInitative["data"].ResponseCode == 200
          ) {
            this.growlerService.success(fieldType + " updated successfully");
            this.growlerService.clear();
            location.reload();
          } else {
            this.message = resultUpdateInitative["data"].ResponseMessage;
            this.growlerService.success(this.message);
            this.growlerService.clear();
          }
        });
    } else {
      this.growlerService.error(
        "First add strateric priority than add initiative."
      );
      this.growlerService.clear();
    }
  }
  /******* add create extra and owner column function ****************/
  addUpdateExtra(id, title) {
    if (!title) {
      this.growlerService.error("Please enter the text to update.");
      this.growlerService.clear();
      return false;
    }
    let owner = title == "Owner" ? 1 : 0;
    let data = {
      id: id,
      projectId: this.activeProjectId,
      title: title,
      isOwner: owner,
      isDeleted: 0,
    };

    this.commonService
      .addUpdateExtraColumn(data)
      .subscribe((addUpdateExtraColumnResult) => {
        //this.loading = false;
        if (!addUpdateExtraColumnResult["success"]) {
          this.message = addUpdateExtraColumnResult["error"];
        }
        if (
          addUpdateExtraColumnResult["success"] &&
          addUpdateExtraColumnResult["data"].ResponseCode == 200
        ) {
          let createStatus = "added";
          if (id) {
            createStatus = "updated";
          }
          this.growlerService.success(
            title + " " + createStatus + " Successfully"
          );
          this.growlerService.clear();
          location.reload();
        } else {
          this.message = addUpdateExtraColumnResult["data"].ResponseMessage;
        }
      });
  }
  deleteExtraColumn(id) {
    if (confirm("Are you sure to delete this column")) {
      let data = {
        id: id,
        projectId: this.activeProjectId,
        title: "text",
        isOwner: 1,
        isDeleted: 1,
      };
      this.loading = true;
      // return
      this.commonService
        .apiCall(data, "addUpdateExtraColumn")
        .subscribe((addUpdateExtraColumnResult) => {
          this.loading = false;
          if (!addUpdateExtraColumnResult["success"]) {
            this.message = addUpdateExtraColumnResult["error"];
          }
          if (
            addUpdateExtraColumnResult["success"] &&
            addUpdateExtraColumnResult["data"].ResponseCode == 200
          ) {
            this.ngOnInit();
            this.growlerService.success("Deleted Successfully.");
            this.growlerService.clear();
          } else {
            this.message = addUpdateExtraColumnResult["data"].ResponseMessage;
          }
        });
    } else {
      return false;
    }
  }
  addUpdateProjectPeriod(periodId, title) {
    let data = {
      id: periodId,
      projectId: this.activeProjectId,
      title: title,
    };
    this.loading = true;
    if (title == "") {
      this.loading = false;
      this.growlerService.error("Strategic Period is required.");
      this.growlerService.clear();
      return false;
    }

    this.commonService
      .apiCall(data, "addUpdateProjectPeriod")
      .subscribe((addUpdateProjectPeriodResult) => {
        //this.loading = false;
        if (!addUpdateProjectPeriodResult["success"]) {
          this.message = addUpdateProjectPeriodResult["error"];
        }
        if (
          addUpdateProjectPeriodResult["success"] &&
          addUpdateProjectPeriodResult["data"].ResponseCode == 200
        ) {
          // this.ngOnInit();
          location.reload();
          this.growlerService.success("Strategic Period updated successfully.");
          this.growlerService.clear();
        } else {
          this.message = addUpdateProjectPeriodResult["data"].ResponseMessage;
        }
      });
  }
  deleteTaskPeriod(pID) {
    if (confirm("Are you sure to delete this period")) {
      let data = {
        id: pID,
        projectId: this.activeProjectId,
      };
      this.loading = true;
      this.commonService
        .apiCall(data, "deleteTaskPeriod")
        .subscribe((deleteTaskPeriodResult) => {
          //this.loading = false;

          if (!deleteTaskPeriodResult["success"]) {
            this.message = deleteTaskPeriodResult["error"];
            this.growlerService.error(this.message);
            this.growlerService.clear();
          }
          if (
            deleteTaskPeriodResult["success"] &&
            deleteTaskPeriodResult["data"].ResponseCode == 200
          ) {
            location.reload();
            this.growlerService.success("Successfully deleted period.");
            this.growlerService.clear();
          } else {
            this.message = deleteTaskPeriodResult["data"].ResponseMessage;
            this.growlerService.error(this.message);
            this.growlerService.clear();
          }
        });
    } else {
      return false;
    }
  }
  addExtraTextColumn() {
    this.ExtraColumn.push({
      id: 0,
      title: "",
    });
    //this.ngOnInit();
  }
  addExtraColumnValue(columnId, initiativeId, title) {
    if (initiativeId && columnId) {
      if (!title) {
        this.growlerService.error("Please enter description");
        this.growlerService.clear();
        return false;
      }
      let data = {
        id: 0,
        extraFieldId: columnId,
        title: title,
        initativeId: initiativeId,
        projectId: this.activeProjectId,
      };

      this.commonService
        .addExtraColumnValue(data)
        .subscribe((addExtraColumnValueResult) => {
          this.loading = false;
          if (!addExtraColumnValueResult["success"]) {
            this.message = addExtraColumnValueResult["error"];
            this.growlerService.error(this.message);
            this.growlerService.clear();
          }
          if (
            addExtraColumnValueResult["success"] &&
            addExtraColumnValueResult["data"].ResponseCode == 200
          ) {
            this.ngOnInit();
            this.growlerService.success("Description successfully added.");
            this.growlerService.clear();
          } else {
            this.message = addExtraColumnValueResult["data"].ResponseMessage;
            this.growlerService.error(this.message);
            this.growlerService.clear();
          }
        });
    } else {
      this.growlerService.error("First add initiative or add extra column.");
      this.growlerService.clear();
    }
  }
  addUpdateProjectTask(periodId, InitiativeId, title, taskId) {

    if (InitiativeId) {
      //if (title) {
      this.loading = true;
      let data = {
        period: periodId,
        title: title,
        initativeId: InitiativeId,
        projectId: this.activeProjectId,
        taskId: taskId,
      };

      this.commonService
        .apiCall(data, "addUpdateProjectTask")
        .subscribe((addUpdateProjectTaskResult) => {
          console.log(addUpdateProjectTaskResult);
          if (!addUpdateProjectTaskResult["success"]) {
            this.message = addUpdateProjectTaskResult["error"];
            this.growlerService.error(this.message);
            this.growlerService.clear();
          }
          if (
            addUpdateProjectTaskResult["success"] &&
            addUpdateProjectTaskResult["data"].ResponseCode == 200
          ) {
            //location.reload();
            this.growlerService.success(
              "Priority Initiative task successfully added."
            );
            this.growlerService.clear();
            this.loading = false;
          } else {
            this.message = addUpdateProjectTaskResult["data"].ResponseMessage;
            this.growlerService.error(this.message);
            this.growlerService.clear();
          }
        });
      //}
    } else {
      this.loading = false;
      this.growlerService.error("Add Initiative title first to add task.");
      this.growlerService.clear();
    }
  }
  initialisation() {
    $(function () {
      $(".sortable").sortable({
        placeholder: "ui-state-highlight",
        handle: ".moveIcon",
        stop: function (event, ui) {
          let strgIdForBox = ui.item.find(".hiddendata").val();
          let boxIndex = ui.item.index();

          self.changeOrder(strgIdForBox, boxIndex);
        },
      });
      $(".sortable").disableSelection();

      let deleId: any;
      let deleTitle: any;
      var taskTrId: any;
      let pos: any;
      $(".sortabletask").sortable({
        items: ".ui-state-enabled",
        start: function (event, ui) {
          var start_pos = ui.item.index();
          ui.item.data("start_pos", start_pos);
          taskTrId = ui.item.closest("tr").attr("id");
        },
        change: function (event, ui) {
          var start_pos = ui.item.data("start_pos");
          var index = ui.placeholder.index();

          if (start_pos < index) {
            pos = "Inc";
            deleId = $(
              ".sortabletask#" + taskTrId + " td:nth-child(" + index + ")"
            ).attr("id");
          } else {
            pos = "Dec";
            deleId = $(
              ".sortabletask#" + taskTrId + " td:eq(" + (index + 1) + ")"
            ).attr("id");
          }
        },
        update: function (event, ui) {
          let initativeIdElementData = ui.item.closest("tr").attr("id");
          let initativeIdElementDataARR = initativeIdElementData.split("-");
          let initativeIdElement = initativeIdElementDataARR[1];
          var startIndex = ui.item.attr("id");
          var startTaskId = Number(startIndex.split("-")[0]);
          let taskIdFrom = ui.item.closest("td").find("div").children(".getPeriodId").val();
          var startPeriodId = Number(startIndex.split("-")[1]);
          var startTitle = startIndex.split("-")[2];
          var deltaskId = Number(deleId.split("-")[0]);
          var delperiodId = Number(deleId.split("-")[1]);
          var deleTitle = deleId.split("-")[2];
          var taskIdToText = deleTitle;
          this.strategicPriorityTitleListData = [];
          console.log(startPeriodId +" "+ delperiodId);

          self.labelTaskDrag(
            startPeriodId,
            delperiodId,
            deleTitle,
            startTaskId,
            taskIdFrom,
            pos,
            initativeIdElement
          );
        },
      });
      $(".sortabletask").disableSelection();
    });

    var self = this;
    self.BindResizing(
      $("table.time_management_personal-table tr").find(
        "td:not(.disableResizeDrag)"
      )
    );

    self.BindDragDrop(".dragDivOuter");
    $(document).on("click", ".dragDivOuter input", function () {
      $(this).focus();
    });

    /**
     * selector will only a string
     */

    var $gallery = $(
      "table.time_management_personal-table tr td:not(.disableResizeDrag)"
    );
  }
  /******* Column Resizing function ****************/
  BindResizing(selectedElements) {
    var self = this;
    selectedElements = "";  // This is to disable resizable functionality as client does not require it now, In future if required again then remove/comment this line  - 04-07-2022
    $(selectedElements).resizable({
      handles: "e",
      helper: "ui-resizable-helper",
      stop: function (event, ui) {
        let initativeIdElementData = $(ui.element).closest("tr").attr("id");
        let initativeIdElementDataARR = initativeIdElementData.split("-");
        let initativeIdElement = initativeIdElementDataARR[1];
        let taskIdFrom = $(ui.element)
          .closest("td")
          .find("div")
          .children(".getPeriodId")
          .val();
        var arr = taskIdFrom.split(",");
        var startId = arr[0];
        var totalMergeColumn = Math.ceil(ui.size.width / 190);

        var mergePeriod = startId;
        if (totalMergeColumn > 1) {
          var i;
          for (i = 1; i < totalMergeColumn; i++) {
            let nextPeriodLabelId = $("#lblperiod" + startId)
              .closest("th")
              .next("th")
              .find("label")
              .attr("id");
            let nextPeriodId = nextPeriodLabelId.replace("lblperiod", "");
            mergePeriod += "," + nextPeriodId;
            startId = nextPeriodId;
          }
        }
        let taskIdTo = $(ui.element)
          .closest("td")
          .next("td")
          .find("div")
          .children(".getPeriodId")
          .val();

        let taskIdToText = $(ui.element)
          .closest("td")
          .next("td")
          .find("div")
          .children("span")
          .children("label")
          .text();
        let taskIdFromText = $(ui.element)
          .closest("td")
          .find("div")
          .children("span")
          .children("label")
          .text();

        let initativeRowId = $(ui.element)
          .closest("td")
          .find("div")
          .children(".getTaskId")
          .val();
        let taskTo = $(ui.element)
          .closest("td")
          .next("td")
          .find("div")
          .children(".getTaskId")
          .val();
        this.strategicPriorityTitleList = [];
        $("#updateperiodid" + initativeRowId + "-" + taskIdFrom).val(
          taskIdFrom + "," + taskIdTo
        );
        //console.log(mergePeriod);
        if (ui.originalSize.width < ui.size.width) {
          //console.log("here"); return false;
          self.updateTaskPeriod(
            taskIdFrom,
            taskIdTo,
            taskIdToText,
            initativeRowId,
            taskTo,
            "Dec",
            taskIdFromText,
            mergePeriod,
            initativeIdElement
          );
          if ($(ui.element).next().hasClass("disableResizeDrag")) {
            return false;
          }
          var maxAllowedWidth = ui.size.width;
          var initWidth = ui.originalSize.width;
          //start with 0
          var initIndex = $(ui.element).index() + 1;
          var initColSpan = parseInt($(ui.element).prop("colspan")) || 1;

          var colLength =
            $(ui.element).closest("tr").find("td").length -
            $(ui.element)
              .closest("tr")
              .find("td")
              .filter(function () {
                return (
                  $(this).index() > initIndex &&
                  $(this).hasClass("disableResizeDrag")
                );
              }).length;

          while (initWidth <= maxAllowedWidth && initIndex < colLength) {
            //Remove last 2 params due to firefox compatitbility
            var selectEleWidth =
              $($(ui.element).closest("tr").find("td")[initIndex]).width() +
              parseFloat(
                $($(ui.element).closest("tr").find("td")[initIndex]).css(
                  "padding-left"
                )
              ) +
              parseFloat(
                $($(ui.element).closest("tr").find("td")[initIndex]).css(
                  "padding-right"
                )
              ); // + parseFloat($(ui.element).css("border-left")) + parseFloat($(ui.element).css("border-right"))

            //Add some constant as border
            selectEleWidth = (selectEleWidth || 0) + 2;

            if (selectEleWidth) {
              initColSpan +=
                $($(ui.element).closest("tr").find("td")[initIndex]).prop(
                  "colspan"
                ) || 1;
              initWidth += selectEleWidth || 0;
            }

            initIndex += 1;
          }
          $(ui.element).prop("colspan", initColSpan);

          $(ui.element)
            .closest("tr")
            .find("td")
            .filter(function () {
              return (
                $(this).index() < initIndex &&
                $(this).index() > $(ui.element).index()
              );
            })
            .remove();

          ui.size.width = ui.originalSize.width;

          $(ui.element)
            .closest("table.time_management_personal-table")
            .find("td")
            .removeAttr("style");

          var columnSize = $(ui.element).closest("tr").find("td").length;

          if (
            $(ui.element)
              .closest("table.time_management_personal-table")
              .find("tr")
              .filter(function () {
                return $(this).find("td").length != columnSize;
              }).length == 0
          ) {
            $(ui.element)
              .closest("table.time_management_personal-table")
              .find("td")
              .removeAttr("colspan");
          }
        } else if (ui.originalSize.width > ui.size.width) {
          self.updateTaskPeriod(
            taskIdFrom,
            taskIdTo,
            taskIdToText,
            initativeRowId,
            "",
            "Inc",
            taskIdFromText,
            mergePeriod,
            initativeIdElement
          );
          var initColSpan = parseInt($(ui.element).prop("colspan") || 1);

          if (initColSpan > 1) {
            $(ui.element).prop("colspan", initColSpan - 1);

            var newColumn = $(ui.element)
              .clone()
              .removeAttr("colspan")
              .removeAttr("style");
            newColumn.find("div:not(.dragDivOuter)").remove();

            $(newColumn)
              .find(".dragDivOuter")
              .html(
                $("<label>", {
                  placeholder: "Enter task here",
                  class: "text-center",
                  style: "border:none",
                  type: "text",
                })
              );

            $(ui.element).after(newColumn);

            this.gallery = $(
              "table.time_management_personal-table tr td:not(.disableResizeDrag)"
            );
            self.BindDragDrop(".dragDivOuter");
            self.BindResizing(newColumn);
          }

          $("table.time_management_personal-table tr")
            .find("td:not(.disableResizeDrag):last")
            .filter(function () {
              return (parseInt($(this).attr("colspan")) || 0) <= 1;
            })
            .addClass("ui-resizable-disabled");
        }
      },
    });
  }

  /******* Column Drag-Drop function ****************/
  BindDragDrop(selector) {
    var self = this;
    if (typeof this.gallery == "undefined" || this.gallery == undefined) {
      this.gallery = $(
        "table.time_management_personal-table tr td:not(.disableResizeDrag)"
      );
    }

    // Let the gallery items be draggable
    $(selector, this.gallery).draggable({
      cancel: "a.ui-icon", // clicking an icon won't initiate dragging
      revert: "invalid", // when not dropped, the item will revert back to its initial position
      containment: "document",
      helper: "clone",
      cursor: "move",
    });

    // Let the gallery be droppable as well, accepting items from the trash
    this.gallery.droppable({
      accept: selector, //"table.time_management_personal-table tr td:not(.disableResizeDrag) .dragDivOuter",
      classes: {
        "ui-droppable-active": "custom-state-active",
      },
      drop: function (event, ui) {
        //debugger;
        self.DragEvent(event, ui);
      },
    });
  }
  /******* Jquery Drag Event ****************/
  DragEvent(event, ui) {
    if (
      $(ui.draggable).closest("tr").find(event.target).length == 0 ||
      $(event.target).hasClass("disableResizeDrag")
    ) {
      return false;
    }

    var clone = $(ui.draggable).clone();
    this.AddDragDropEvent(clone);
    $(event.target).html(clone[0]);
    $(ui.draggable).html(
      $("<label>", {
        placeholder: "Enter task here",
        class: "text-center",
        style: "border:none; width:100%",
        type: "text",
      })
    );

    if (
      $(event.target)
        .closest("tr")
        .find("td:not(.disableResizeDrag):last")
        .not(event.target).length == 1 ||
      $(event.target)
        .closest("tr")
        .find("td:not(.disableResizeDrag):last")
        .filter(function () {
          return (parseInt($(this).attr("colspan")) || 0) > 1;
        }).length == 1
    ) {
      this.BindResizing($(event.target));
    }
  }
  /******* add drag event function ****************/
  AddDragDropEvent(selector) {
    $(selector, this.gallery).draggable({
      cancel: "a.ui-icon", // clicking an icon won't initiate dragging
      revert: "invalid", // when not dropped, the item will revert back to its initial position
      containment: "document",
      helper: "clone",
      cursor: "move",
    });
  }
  /******* Update Task period function ****************/
  updateTaskPeriod(
    fromid,
    toid,
    taskText,
    InId,
    taskTo,
    pos,
    taskIdFromText,
    mergeColumn,
    initativeIdElement
  ) {
    let Ids: any;
    let taskTextlabel: any;
    if (taskTextlabel != "") {
      if (confirm("Are you sure to merge this task")) {
        this.mergeTask(
          fromid,
          toid,
          taskText,
          InId,
          taskTo,
          pos,
          taskIdFromText,
          mergeColumn,
          initativeIdElement
        );
      } else {
        this.loading = true;
        location.reload();
      }
    } else {
      this.mergeTask(
        fromid,
        toid,
        taskText,
        InId,
        taskTo,
        pos,
        taskIdFromText,
        mergeColumn,
        initativeIdElement
      );
    }
  }
  /******* Merge Task function ****************/
  mergeTask(
    fromid,
    toid,
    taskText,
    InId,
    taskTo,
    pos,
    taskIdFromText,
    mergeColumn,
    initativeIdElement
  ) {
    console.log(taskTo);
    // return false;

    this.loading = true;
    let Ids: any;
    let data = {
      period: mergeColumn,
      id: InId,
      projectId: this.activeProjectId,
      deleteTask: taskTo,
    };
    this.commonService
      .updateTaskPeriod(data)
      .subscribe((updateTaskPeriodResult) => {
        //this.loading = false;
        if (!updateTaskPeriodResult["success"]) {
          this.message = updateTaskPeriodResult["error"];
        }
        if (
          updateTaskPeriodResult["success"] &&
          updateTaskPeriodResult["data"].ResponseCode == 200
        ) {
          if (pos != "Dec") {
            let deletedTaskPeriod = fromid.replace(mergeColumn, "");
            console.log(deletedTaskPeriod);

            let data = {
              period: deletedTaskPeriod.replace(/(^,)|(,$)/g, ""),
              title: '',
              initativeId: initativeIdElement,
              projectId: initativeIdElement,
              taskId: 0,
            };
      
            this.commonService
              .apiCall(data, "addUpdateProjectTask")
              .subscribe((addUpdateProjectTaskResult) => {
                
                if (
                  addUpdateProjectTaskResult["success"] &&
                  addUpdateProjectTaskResult["data"].ResponseCode == 200
                ) {
                  this.growlerService.success("Sucessfully merge task.");
                  this.growlerService.clear();
                  this.ngOnInit();
                  location.reload();
                } 
              });

            // this.addUpdateProjectTask(
            //   deletedTaskPeriod.replace(/(^,)|(,$)/g, ""),
            //   initativeIdElement,
            //   "",
            //   0
            // );
          }else{
            this.growlerService.success("Sucessfully merge task.");
            this.growlerService.clear();
            this.ngOnInit();
            location.reload();
          }

          
        } else {
          this.message = updateTaskPeriodResult["data"].ResponseMessage;
        }
      });
  }
  changeOrder(id, index) {
    this.loading = true;
    let data = {
      id: id,
      order: parseInt(index) + 1,
      object: "strategy",
      projectId: this.activeProjectId,
    };
    this.commonService.changeOrder(data).subscribe((changeOrderResult) => {
      //this.loading = false;
      if (!changeOrderResult["success"]) {
        this.message = changeOrderResult["error"];
      }
      if (
        changeOrderResult["success"] &&
        changeOrderResult["data"].ResponseCode == 200
      ) {
        this.growlerService.success("Successfully move priority.");
        this.growlerService.clear();
        location.reload();
        //this.ngOnInit();
      } else {
        this.message = changeOrderResult["data"].ResponseMessage;
      }
    });
  }
  labelTaskDrag(
    startPeriodId,
    delperiodId,
    taskIdToText,
    startTaskId,
    delTaskId,
    pos,
    initativeIdElement
  ) {
    let Ids: any;

    if (confirm("Are you sure to drag this task")) {
      this.dragDropTask(
        startPeriodId,
        delperiodId,
        taskIdToText,
        startTaskId,
        delTaskId,
        initativeIdElement
      );
    } else {
      this.loading = true;
      location.reload();
    }
  }
  dragDropTask(
    startPeriodId,
    delperiodId,
    taskIdToText,
    startTaskId,
    delTaskId,
    initativeIdElement
  ) {
    this.loading = true;
    let data = {
      period: delperiodId,
      id: startTaskId,
      projectId: this.activeProjectId,
      deleteTask: delTaskId,
    };
    this.commonService
      .updateTaskPeriod(data)
      .subscribe((updateTaskPeriodResult) => {
        //this.loading = false;

        if (!updateTaskPeriodResult["success"]) {
          this.message = updateTaskPeriodResult["error"];
        }
        if (
          updateTaskPeriodResult["success"] &&
          updateTaskPeriodResult["data"].ResponseCode == 200
        ) {
          this.addUpdateProjectTask(delTaskId, initativeIdElement, "", 0);
          //location.reload();
          this.growlerService.success("Sucessfully drag and drop task.");
          this.growlerService.clear();
          setTimeout(() => {
            location.reload();
          }, 1000);
          
        } else {
          this.message = updateTaskPeriodResult["data"].ResponseMessage;
        }
      });
  }
  /****Go to Project setting page from edit project setting option ****/
  goToProjectSetting(editProjId, projectName, project_type, project) {
    var projectKey =
      project_type == 1 ? "sop_change_settings" : "sfr_change_settings";

    if (this.checkPermission(projectKey, this.permission)) {
      alert("You do not have permissions to change project setting.");
      return false;
    }
    localStorage.setItem("editProjectId", editProjId);
    localStorage.setItem("projectName", projectName);
    localStorage.setItem("pType", project_type);
    this.router.navigateByUrl("/project-settings");
  }
  checkPermission(permissionKey, permissions) {
    if (
      (permissions != null &&
        (permissions[permissionKey] === undefined ||
          !permissions[permissionKey])) ||
      (permissions === null && this.globalRoles[permissionKey] === undefined)
    ) {
      return true;
    }
  }
  editPage(is_group, project_type) {
    var projType = "sop_view_project";
    if (this.checkPermission(projType, this.permission)) {
      alert("You do not have permission to edit project");
      return false;
    }
    this.router.navigateByUrl("/sop-view");
  }
  showAlert(e) {
    this.growlerService.error("Please add initative title first");
    this.growlerService.clear();
    e.target.value = "";
    return false;
  }
  strategicPeriods(i) {
    if (this.allowAddPeriod) {
      this.ProjectPeriod.push({
        id: 0,
        title: "",
      });
      var elmnt = document.getElementById("widgetsContent");
      var x = elmnt.scrollWidth;
      $(".widgetsContent").animate(
        {
          scrollLeft: x,
        },
        "slow"
      );
      this.allowAddPeriod = false;
    } else {
      alert("You can add one period at a time.");
    }
  }
  addMorestrategicPriority(i) {
    this.projectData.push({
      id: "",
      title: "",
      priority: 0,
    });
  }
  deleteStrategicPriority(titleId: number) {
    if (confirm("Are you sure to delete this strategic priority")) {
      if (titleId) {
        let data = {
          id: titleId,
          projectId: this.activeProjectId,
        };
        this.commonService
          .deleteStrategicPriority(data)
          .subscribe((deleteStrategicPriorityResult) => {
            this.loading = false;
            if (!deleteStrategicPriorityResult["success"]) {
              this.message = deleteStrategicPriorityResult["error"];
            }
            if (
              deleteStrategicPriorityResult["success"] &&
              deleteStrategicPriorityResult["data"].ResponseCode == 200
            ) {
              this.ngOnInit();
              this.growlerService.success(
                "Strategic priority successfully deleted."
              );
              this.growlerService.clear();
            } else {
              this.message =
                deleteStrategicPriorityResult["data"].ResponseMessage;
            }
          });
      } else {
        this.ngOnInit();
        this.growlerService.success("Strategic priority successfully deleted.");
        this.growlerService.clear();
      }
    }
  }
  checkAvailavility() {
    var elmnt = document.getElementById("widgetsContent");
    var x = elmnt.scrollWidth;
    var ow = $(".widgetsContent").width();

    if (ow < x) {
      return true;
    } else {
      return false;
    }
  }
}




