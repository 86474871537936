<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <!-- <button type="button" aria-label="Close" (click)="dismiss()"></button> -->
</div>
<div class="modal-body">
    

    <div class="custom-control custom-checkbox radioBtn mb-2">
        <input type="radio" class="custom-control-input" name="msg" id="msg" value="1" [(ngModel)]="option">
        <label class="custom-control-label pl-2" for="msg"> Copy Project With Rating and Comments </label>
    </div>
    <div class="custom-control custom-checkbox radioBtn">
        <input type="radio" class="custom-control-input" name="msg" id="msg2" value="0" [(ngModel)]="option">
        <label class="custom-control-label pl-2" for="msg2"> Copy Project Without Rating and Comments </label>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary mw105 my-1 me-3 normal" (click)="accept()">{{ btnOkText }}</button>
    <button type="button" class="btn btn-secondary mw105 my-1 normal" (click)="decline()">{{ btnCancelText }}</button>
</div>