<app-growler>
</app-growler>
<app-header></app-header>
<div class="wrapper">
    <div class="loaderOuter" *ngIf="loading">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
    <app-nav-bar></app-nav-bar>
    <div class="inner-container headerTopPaddipad">
        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-10 col-xl-8">
                    <span class="ptypespan">
                        <img *ngIf="gType == 1" src="./assets/images/individual-sop-icon2.png" alt="icon" height="19">
                        <img *ngIf="gType == 2" src="./assets/images/group-sop-icon2.png" alt="icon" height="19">
                    </span>
                    <h3 class="sfp_project-title"> {{projectDetail.title}}</h3>
                    <!-- routerLink="/project-settings" -->

                    <a (click)="goToProjectSetting(projectDetail.id,projectDetail.title,projectDetail.project_type,projectDetail)"><i class="fa fa-cog mx-2" aria-hidden="true" title="Click here to go to project setting page"></i></a>
                    <a href="javascript:void(0)" title="Click here to view project" (click)="editPage(projectDetail.group, projectDetail.projectType)"><i class="fa fa-eye mx-2"
                            aria-hidden="true"></i></a>
                </div>

            </div>
        </div>
        <div class="strategic_priority-outer strategic_priority-viewMode">
            <div class="strategic_priority-content" *ngIf="projectData" class="sortable">
                <!-- table-responsive -->
                <div class="mainOutertableDiv" #container [ngStyle]="{'min-width.px': containerMinusWi.offsetWidth + containerMinusWi2.offsetWidth + 380}" *ngFor="let project of projectData; let k= index">
                    <input type="hidden" value="{{project.id}}" class="hiddendata">
                    <!-- <input type="hidden" value="{{index}}"> -->
                    <table>
                        <tr>
                            <td #containerMinusWi style="vertical-align: bottom;width: 250px;overflow: visible;">
                                <table class="strategic_priority-Table leftColOuter">
                                    <thead>
                                        <tr>
                                            <th class="titleThIcon">
                                                <div class="dropdown">
                                                    <a href="javaScript:void()" class="dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
                                                    </a>

                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">

                                                        <a class="dropdown-item" (click)="addMorestrategicPriority(k+1)" href="javascript:void(0)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Create New Strategic Priority
                                                        </a>
                                                        <a class="dropdown-item" *ngIf="!projectDetail.ownerColumn && project.id" (click)="addUpdateExtra(0,'Owner')" href="javascript:void(0)"><i class="fa fa-user-plus"
                                                                aria-hidden="true"></i> Create New Owner
                                                            Column</a>
                                                        <a *ngIf="project.id" class="dropdown-item " href="javascript:void(0)" (click)="addExtraTextColumn()"><i
                                                                class="fa fa-clipboard " aria-hidden="true "></i>
                                                            Create New Text Column (eg. Strategic Rationale)</a>


                                                        <a data-toggle="modal " (click)="deleteStrategicPriority(project.id) " class="dropdown-item " href="javascript:void(0) "><i class="fa fa-clipboard " aria-hidden="true "></i> Delete this
                                                            Strategic Priority Group</a>

                                                    </div>
                                                </div>
                                                <a class="moveIcon ml-2" href="javascript:void(0)" title="Move Strategic Priority Group">
                                                    <i class="fa fa-ellipsis-v " aria-hidden="true "></i>
                                                    <i class="fa fa-ellipsis-v " aria-hidden="true "></i>
                                                </a>
                                            </th>
                                            <th class="headingBlueBgNew" colspan="2">
                                                <label *ngIf="project.id" id="lblsttg{{project.id}}" class="handIcon" (click)="showEditFunction('lblsttg','txtsttg',project.id)">
                                                    <strong>{{project.title}}</strong>
                                                </label>
                                                <input id="txtsttg{{project.id}}" type="text" maxlength="100" class="form-control" placeholder="Strategic priority title" required value="{{project.title}}" class="hideBlock" (keyup.enter)="addUpdatePriority(project.id,$event.target.value)" (blur)="addUpdatePriority(project.id,$event.target.value)">
                                                <input *ngIf="!project.id" id="txtsttg{{project.id}}" type="text" maxlength="100" class="form-control" placeholder="Strategic priority title" required value="{{project.title}}" (keyup.enter)="addUpdatePriority(project.id,$event.target.value)" (blur)="addUpdatePriority(project.id,$event.target.value)">
                                            </th>
                                            <ng-container *ngIf="ExtraColumn">
                                                <th class="text-center" *ngFor="let column of ExtraColumn">

                                                    <label *ngIf="column.id && k==0" id="lblextra{{column.id}}" class="handIcon" (click)="showEditFunction('lblextra','txtextra',column.id)">
                                                    <strong>{{column.title}}</strong>
                                                </label>
                                                    <input *ngIf="!column.id" id="txtextra{{column.id}}" type="text" (blur)="addUpdateExtra(0,$event.target.value)" (keyup.enter)="addUpdateExtra(0,$event.target.value)" placeholder="Enter text here">

                                                    <input class="hideBlock" id="txtextra{{column.id}}" type="text" (blur)="addUpdateExtra(column.id,$event.target.value)" (keyup.enter)="addUpdateExtra(column.id,$event.target.value)" value="{{column.title}}">

                                                    <a *ngIf="column.id" href="javaScript:void(0)" class="periodDeleteBtn" (click)="deleteExtraColumn(column.id)">
                                                        <span class="fa fa-minus"></span>
                                                    </a>

                                                </th>
                                            </ng-container>
                                            <th class="titleThIcon pl-0 text-center" *ngIf="projectDetail.ownerColumn" style="max-width: 160px !important;width: 160px !important;min-width: 160px !important;">
                                                <p *ngIf="k==0"> Owner
                                                    <a href="javaScript:void(0)" class="periodDeleteBtn" (click)="deleteExtraColumn(-1)">
                                                        <span class="fa fa-minus"></span> </a>
                                                </p>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="strategic_priority-tbody">

                                        <ng-container *ngIf="project.InitativeData">
                                            <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                <td></td>
                                                <td (click)="deleteInitativeRow(Initative.id)" style="width: 30px;background: #eaebf0;"><a _ngcontent-pwa-c6="" class="periodDeleteBtn d-block" href="javaScript:void(0)" style="
                                                    visibility: visible;"><span _ngcontent-pwa-c6="" class="fa fa-minus"></span></a></td>
                                                <td class="text-left widthSefix strategicIntTitle">
                                                    <label id="lblIni{{Initative.id}}" class="handIcon" (click)="showEditFunction('lblIni','txtIni',Initative.id)">{{Initative.title}}</label>
                                                    <input id="txtIni{{Initative.id}}" (blur)="addUpdatePriorityInitative(project.id,'Initative Title',$event.target.value, Initative) " (keyup.enter)="addUpdatePriorityInitative(project.id,'Initative Title',$event.target.value, Initative) " maxlength="80 "
                                                        required type="text " placeholder="+ Initative Title" value="{{Initative.title}}" class="hideBlock">
                                                </td>
                                                <ng-container *ngIf="ExtraColumn">
                                                    <td class="periodsBoxOuter text-center" *ngFor="let Column of ExtraColumn">
                                                        <span class="text-center" *ngIf="Initative.extraFields[Column.id]">
                                                            <label *ngIf="Column.id" id="lblIniOwner{{Initative.id}}"
                                                                class="handIcon" (click)="showEditFunction('lblIniOwner','txtIniOwner',Initative.id)">{{Initative.extraFields[Column.id].title}}</label>

                                                            <input id="txtIniOwner{{Initative.id}}"
                                                                (blur)="addExtraColumnValue(Column.id,Initative.id,$event.target.value)" (keyup.enter)="addExtraColumnValue(Column.id,Initative.id,$event.target.value)" maxlength="80 " required
                                                                type="text " value="{{Initative.extraFields[Column.id].title}}" class="hideBlock">
                                                        </span>
                                                        <span *ngIf="Initative.extraFields && Initative.extraFields[Column.id]==undefined">
                                                        <input id="txtIniOwner{{Initative.id}}"
                                                        (blur)="addExtraColumnValue(Column.id,Initative.id,$event.target.value)" (keyup.enter)="addExtraColumnValue(Column.id,Initative.id,$event.target.value)" placeholder="Enter description here" maxlength="80 " required
                                                        type="text">
                                                        </span>
                                                    </td>
                                                </ng-container>
                                                <td style="max-width: 160px !important;width: 160px !important;min-width: 160px !important;" class="periodsBoxOuter text-center" *ngIf="projectDetail.ownerColumn">
                                                    <label *ngIf="Initative.userId" id="lblIniOwner{{Initative.id}}" class="handIcon" (click)="showEditFunction('lblIniOwner','txtIniOwner',Initative.id)">{{Initative.userId}}</label>
                                                    <input *ngIf="!Initative.userId" id="txtIniOwner{{Initative.id}}" (blur)="addUpdatePriorityInitative(project.id,'Owner',$event.target.value, Initative)" (keyup.enter)="addUpdatePriorityInitative(project.id,'Owner',$event.target.value, Initative)" maxlength="80 "
                                                        required type="text " value="{{Initative.userId}}" placeholder="Enter owner name">
                                                    <input id="txtIniOwner{{Initative.id}}" (blur)="addUpdatePriorityInitative(project.id,'Owner',$event.target.value, Initative) " (keyup.enter)="addUpdatePriorityInitative(project.id,'Owner',$event.target.value, Initative) " maxlength="80 " required type="text "
                                                        value="{{Initative.userId}}" class="hideBlock">
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <td></td>
                                            <td style="width: 30px;background: #eaebf0;"></td>
                                            <td class="text-left widthSefix strategicIntTitle">
                                                <input (blur)="addUpdatePriorityInitative(project.id,'Initative Title',$event.target.value, '') " (keyup.enter)="addUpdatePriorityInitative(project.id,'Initative Title',$event.target.value, '') " maxlength="80 " required type="text " placeholder="+ Initative Title"
                                                    value="">
                                            </td>
                                            <ng-container *ngIf="ExtraColumn">
                                                <td class="periodsBoxOuter text-center" *ngFor="let Column of ExtraColumn">
                                                    <span>
                                                        <input
                                                            (blur)="showAlert($event)" (keyup.enter)="showAlert($event)"
                                                            placeholder="Enter description here" maxlength="80 " required type="text">
                                                    </span>
                                                </td>
                                            </ng-container>
                                            <td style="max-width: 160px !important;width: 160px !important;min-width: 160px !important;" class="periodsBoxOuter" *ngIf="projectDetail.ownerColumn">
                                                <input (blur)="showAlert($event)" (keyup.enter)="showAlert($event)" placeholder="Enter owner here" maxlength="80" required type="text " value="">
                                            </td>
                                        </tr>
                                    </tbody>


                                </table>
                            </td>
                            <!-- 'width.px': container.offsetWidth - containerMinusWi.offsetWidth - containerMinusWi2.offsetWidth,  -->
                            <td class="MiddleScrollSafari" [ngStyle]="{ 'max-width.px': container.offsetWidth - containerMinusWi.offsetWidth - containerMinusWi2.offsetWidth}" style="vertical-align: bottom; width:auto; position: relative;">
                                <a *ngIf="k==0 && showScrollRight && checkAvailavility()" class="rightArrowChanges" href="javaScript:void(0)"><i (click)="scrollRight()" class="fa fa-angle-right" ></i></a>
                                <a *ngIf="k==0 && scrollLeftSize > 0" class="leftArrowChanges" href="javaScript:void(0)"><i (click)="scrollLeft()" class="fa fa-angle-left" ></i></a>

                                <div class="widgetsContent" id="widgetsContent" style="min-width: 190px; max-width: 100%; width: 100%; overflow-x: hidden;">
                                    <table class="time_management_personal-table strategic_priority-Table midTableScrollable">

                                        <thead>
                                            <th class="text-center" *ngFor="let period of ProjectPeriod">
                                                <label id="lblperiod{{period.id}}" (click)="showEditFunction('lblperiod','txtperiod',period.id)"><strong *ngIf="k==0 && period.id">{{period.title}}</strong></label>
                                                <span id="{{period.id}}"></span>
                                                <input class="hideBlock" style="width:85%" id="txtperiod{{period.id}}" type="text" value="{{period.title}}" (blur)="addUpdateProjectPeriod(period.id,$event.target.value)" (keyup.enter)="addUpdateProjectPeriod(period.id,$event.target.value)">
                                                <ng-container *ngIf="k==0">
                                                    <a *ngIf="period.id" href="javaScript:void(0)" class="periodDeleteBtn" (click)="deleteTaskPeriod(period.id)">
                                                        <span class="fa fa-minus"></span> </a>
                                                </ng-container>
                                                <input *ngIf="!period.id && k==0" type="text" value="" placeholder="Enter period here" (blur)="addUpdateProjectPeriod(period.id,$event.target.value)" (keyup.enter)="addUpdateProjectPeriod(period.id,$event.target.value)">
                                            </th>

                                        </thead>
                                        <tbody class="strategic_priority-tbody">
                                            <ng-container *ngIf="project.InitativeData">
                                                <ng-container *ngFor="let Initative of changeObjToArray(project.InitativeData); let last = last">
                                                    <tr class="sortabletask" id="init-{{Initative.id}}">
                                                        <ng-container *ngFor="let Periods of ProjectPeriod; let idx= index">
                                                            <ng-container *ngIf="Initative.period[Periods.id]">
                                                                <ng-container *ngIf="hideDivNextTime(Initative.period,Periods.id)">
                                                                    <td class="periodsBoxOuter ui-state-enabled" id="{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}-{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.title:''}}" [attr.colspan]="setColspanValue(Initative.period,Periods.id)">
                                                                        <div class="dragDivOuter">

                                                                            <input type="hidden" id="updateperiodid{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}" class="getPeriodId" value="{{Periods.id}}">
                                                                            <input type="hidden" class="getTaskId" value="{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.id:0}}">
                                                                            <span class="text-center" *ngFor="let Period of changeObjToArrayForTasks(Initative.period) ; let i= index">
                                                                        <label id="lblTask{{Period.Task.id}}" *ngIf="Period.periodId==Periods.id && Period.Task.title!=''" (click)="showEditFunction('lblTask','txtTasks',Period.Task.id)" class="handIcon">{{Period.Task.title}} </label>
                                                                        <input id="txtTasks{{Period.Task.id}}"*ngIf="Period.periodId==Periods.id && Period.Task.title!=''" #task type="text "
                                                                            (blur)="addUpdateProjectTask(Periods.id,Initative.id,$event.target.value,Period.Task.id) "
                                                                            (keyup.enter)="addUpdateProjectTask(Periods.id,Initative.id,$event.target.value,Period.Task.id) " value="{{Period.Task.title}}" maxlength="56"
                                                                            class="form-control hideBlock" placeholder="Enter task here">

                                                                        <input *ngIf="Period.periodId==Periods.id && Period.Task.title==''" #task type="text " (blur)="addUpdateProjectTask(Periods.id,Initative.id,$event.target.value,Period.Task.id) " (keyup.enter)="addUpdateProjectTask(Periods.id,Initative.id,$event.target.value,Period.Task.id) " maxlength="56" class="form-control" placeholder="Enter task here">


                                                        </span>
                                                                        </div>
                                                                    </td>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container *ngIf="!Initative.period[Periods.id]">
                                                                <td class="periodsBoxOuter">
                                                                    <div>
                                                                        <input type="hidden" class="getPeriodId" value="{{Periods.id}}"></div>
                                                                    <input #task type="text " (blur)="addUpdateProjectTask(Periods.id,Initative.id,$event.target.value,0) " (keyup.enter)="addUpdateProjectTask(Periods.id,Initative.id,$event.target.value,0) " maxlength="56" class="form-control disableResizeDrag" placeholder="Enter task here">
                                                                </td>
                                                            </ng-container>
                                                        </ng-container>

                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                            <tr>
                                                <ng-container *ngFor="let Periods of ProjectPeriod; let idx= index">
                                                    <td class="periodsBoxOuter disableResizeDrag">
                                                        <input #task type="text" (blur)="showAlert($event)" (keyup.enter)="showAlert($event)" maxlength="100" class="form-control" placeholder="Enter task here">
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                            <!-- change containerMinusWi2 width 300 to 250 -->
                            <td #containerMinusWi2 style="vertical-align: bottom;width: 250px;max-width: 300px;overflow: auto;">
                                <table class="strategic_priority-Table midTableScrollable">
                                    <thead>
                                        <tr>
                                            <th class="repeatHeadTHFixTop" style="min-width: 35px; width: 35px !important; vertical-align: top; padding: 3px 10px !important;">
                                                <label *ngIf="k==0" class="handIcon"><i (click)="strategicPeriods(k) " class="fa fa-plus-circle " aria-hidden="true "></i></label>
                                            </th>
                                            <th style="min-width: 220px;">
                                                <p *ngIf="k==0">What success looks like...</p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="strategic_priority-tbody">

                                        <ng-container *ngIf="project.InitativeData">
                                            <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                <td style="width: 40px;"></td>
                                                <td class="strategicSuccessNote">
                                                    <label *ngIf="Initative.description!=''" class="handIcon" id="lblIniDsc{{Initative.id}}" (click)="showEditFunction('lblIniDsc','txtIniDsc',Initative.id)">{{Initative.description}}</label>
                                                    <input *ngIf="Initative.description==''" id="txtIniDsc{{Initative.id}}" (blur)="addUpdatePriorityInitative(project.id,'Initative Description',$event.target.value, Initative) " (keyup.enter)="addUpdatePriorityInitative(project.id,'Initative Description',$event.target.value, Initative) "
                                                        maxlength="80 " required type="text" value="" placeholder="Enter description here">

                                                    <input id="txtIniDsc{{Initative.id}}" (blur)="addUpdatePriorityInitative(project.id,'Initative Description',$event.target.value, Initative) " (keyup.enter)="addUpdatePriorityInitative(project.id,'Initative Description',$event.target.value, Initative) "
                                                        maxlength="80 " required type="text " value="{{Initative.description}}" class="hideBlock">
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <td style="width: 40px;"></td>
                                            <td class="strategicSuccessNote">
                                                <input (blur)="showAlert($event)" (keyup.enter)="showAlert($event)" maxlength="80" type="text" value="" placeholder="Enter description here">
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </td>
                        </tr>

                    </table>



                </div>
            </div>
        </div>
    </div>