<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div class="modal-body">
<span [innerHTML]="message"></span>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="accept()">{{ btnOkText }}</button>
    <button type="button" class="btn btn-secondary ml-2" (click)="decline()">{{ btnCancelText }}</button>
</div>