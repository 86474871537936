import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {
  logoUrl:any;
  
  constructor() { }

  ngOnInit(): void {
    this.logoUrl = localStorage.getItem("logo");
  }

}
