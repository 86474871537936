import { Component, OnInit, Pipe, ViewChild, ElementRef  } from "@angular/core";
import { CommonService } from "../../services/commonservices";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../auth/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GrowlerService } from "../../services/growler.service";
import * as $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import "jquery-ui-dist/jquery-ui";
 import * as jspdf from "jspdf";
 import html2canvas from "html2canvas";

@Component({
  selector: 'app-sop-view-pdf',
  templateUrl: './sop-view-pdf.component.html',
  styleUrls: ['./sop-view-pdf.component.scss']
})
export class SopViewPdfComponent implements OnInit {

  public showLoder: boolean = true;
  public sidebarToggle: boolean = false;
  public userColHide: boolean = false;
  pageSize: any;
  globalRoles: any = [];
  permission: any = [];
  notValid: boolean = false;
  validTrue: boolean = false;
  projectData: any = [];
  ratingAndComment: any = [];
  ProjectPeriod: any = [];
  ExtraColumn: any = [];
  projectDetail: any = [];
  colsapnValue: any = 1;
  mergeTdRemove: number;
  mergeTdRemoveArr: any = [];
  feedback: any = {};
  htmlOptions: any = {};
  priority: any = [];
  priorities: any = {};
  loading: boolean = false;
  scrollLeftSize:number = 0;
  showScrollRight:boolean = true;
  message: any;
  pType: any;
  gType: any;
  activeProjectId: any;
  updetedPeriodId:number;
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public growlerService: GrowlerService,
    public router: Router,
    private elementRef:ElementRef
  ) {}

  ngOnInit(): void {
    this.priorities.pageSize='';
    this.priority = [];
    this.activeProjectId = localStorage.getItem("editProjectId");
    this.pType = localStorage.getItem("pType");
    this.gType = localStorage.getItem("gType");
    this.getProjectData(this.activeProjectId);
    this.setHtmlOptions();
    this.customeSlider();
    let roles = localStorage.getItem("roles");
    this.getPermissionByRole(roles);
    this.getUserPermissionOnProject(this.activeProjectId);
    $("body").on("click", ".dropdown-menu", function (e) {
      $(this).parent().is(".show") && e.stopPropagation();
    });
  }

  goToProjectSetting(editProjId, projectName, project_type, project) {
    var projectKey =
      project_type == 1 ? "sop_change_settings" : "sfr_change_settings";

    if (this.checkPermission(projectKey, this.permission)) {
      alert("You do not have permissions to change project setting.");
      return false;
    }
    this.router.navigateByUrl("/project-settings");
  }
  editPage(is_group, project_type) {
    this.fullScreenExit();
    var projType = "global_edit_proj";
    if (this.checkPermission(projType, this.permission)) {
      alert("You do not have permission to edit project");
      return false;
    }
    this.router.navigateByUrl("/sop-create");
  }
  getPermissionByRole(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = result["data"].ResponseData;
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  checkPermission(permissionKey, permissions) {
    if (
      (permissions != null &&
        (permissions[permissionKey] === undefined ||
          !permissions[permissionKey])) ||
      (permissions === null && this.globalRoles[permissionKey] === undefined)
    ) {
      return true;
    }
  }
  setHtmlOptions() {
    this.htmlOptions = {
      individualMode: true,
      averageMode: false,
      medianMode: false,
      adjustedMode: false,
      download: true,
    };
  }
  customeSlider() {
    $(function () {
      var handle = $(".custom-handle");
      $(".slider").slider({
        orientation: "horizantal",
        range: "min",
        value: 1,
        step: 0.5,
        min: 1.0,
        max: 10.0,
        animate: "fast",
        create: function () {
          //console.log("plus");
          handle.text($(this).slider("value"));
          handle.addClass(
            "rating" + Math.round($(this).slider("value")) + "bg"
          );
        },
        slide: function (event, ui) {
           //console.log("minus");
          // console.log(ui.value);
          handle.text(ui.value);
          handle.addClass("rating" + Math.round(ui.value) + "bg");
          handle.removeClass("rating" + (Math.round(ui.value) + 1) + "bg");

          var attrId = $(this)
            .closest(".dropdown-menu.p-1")
            .find('input[name="feedbackRange"]')
            .attr("id");
          $("#" + attrId).val(ui.value);
        },
      });
    });
  }
  addRating(taskId: any, pId: any, commntes: any) {
    var projType = "sop_provide_rating";
    //$(".pcontainer").removeClass("maxwidthbox");
    if (this.checkPermission(projType, this.permission)) {
      alert("You do not have permissions to rate the tasks");
      return false;
    }
    let projectId = this.activeProjectId;

    let rating = $("#range" + taskId + pId).val();
    pId = $("#rangeperiod" + taskId + pId).val();
    if (rating == 0) {
      rating = 1;
    }
    this.addRatingAndCommentTask(taskId, rating, pId, commntes, projectId);
  }
  addRatingAndCommentTask(taskId, rating, pId, commntes, projectId) {
    let data = {
      taskId: taskId,
      rating: rating,
      periodId: pId,
      comment: commntes,
      projectId: projectId,
    };

    this.loading = true;
    //call login api here
    this.commonService.addRatingAndCommentTask(data).subscribe(
      (result) => {
        //this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          location.reload();
          this.feedback.text = "";
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
  }
  blankBox() {

    this.feedback.text = "";
    $(".custom-handle").text(1);
    var handle = $(".custom-handle");
    handle.text();
    for (let n = 2; n < 11; ++n) {
      handle.removeClass("rating" + n + "bg");
    }
    this.customeSlider();

    // setTimeout(() => {
    //   $(".pcontainer").removeClass("maxwidthbox");
    // }, 3000);
  }

  ClickedOut(event) {
    //debugger;
    console.log('outside');
   // $(".pcontainer").removeClass("maxwidthbox");
  }
  getProjectData(activeProjectId) {
    let data = {
      projectId: activeProjectId, //58
    };
    this.loading = true;
    //call login api here
    this.commonService.getProjectData(data).subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          let projectData = this.changeObjToArraySort(
            result["data"]["ResponseData"]["ProjectData"]
          );
          var self = this;
          projectData.forEach(function (item) {
            self.priority.push(item.id);
          });

          this.priorities.data = this.priority;
          //Sort
          this.customeSlider();
          this.ProjectPeriod = this.changeObjToArray(
            result["data"]["ResponseData"]["ProjectPeriod"]
          );
          this.ExtraColumn = this.changeObjToArray(
            result["data"]["ResponseData"]["ExtraColumn"]
          );
          this.projectData = Object.keys(projectData).map(function (
            personNamedIndex
          ) {
            let project = projectData[personNamedIndex];
            return project;
          });
          this.projectDetail = result["data"].ResponseData.Projectsetting;

          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
  }
  changeObjToArrayForTasks(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });

    return project;
  }
  changeObjToArray(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });
    return project;
  }
  changeObjToArraySort(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });

    project.sort(function (a, b) {
      var keyA = a.priority,
        keyB = b.priority;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return project;
  }

  checkMergeColumn(init: any, periodId: number) {
    let currentId = init[periodId].periodId;
    let nextperiodId = currentId + 1;
    let manageColumn = 0;
    if (init[periodId]) {
      if (init[nextperiodId] != undefined) {
        if (init[currentId].Task.id == init[nextperiodId].Task.id) {
          this.colsapnValue = 2;
          manageColumn = 1;
        }
      }
    }
    return manageColumn;
  }
  setColspanValue(init: any, periodId: number) {
    let currentId = init[periodId].periodId;
    let nextperiodId: any;
    var colsapnValue = 1;
    if (
      $("#" + periodId)
        .parent("th")
        .next()
        .children("p")
        .attr("id") != undefined
    ) {
      nextperiodId = $("#" + periodId);
      nextperiodId = $("#" + periodId)
        .parent("th")
        .next()
        .children("p")
        .attr("id");
    }
    this.colsapnValue = 1;
    this.mergeTdRemove = 0;
    this.mergeTdRemoveArr = [];
    this.mergeTdRemoveArr.push(currentId);
    if (init[periodId]) {
      while (init[nextperiodId] != undefined) {
        if (init[nextperiodId] != undefined) {
          if (init[currentId].Task.id == init[nextperiodId].Task.id) {
            colsapnValue++;

            this.mergeTdRemoveArr.push(parseInt(nextperiodId));
            if (
              $("#" + nextperiodId)
                .parent("th")
                .next()
                .children("p")
                .attr("id") != undefined
            ) {
              nextperiodId = $("#" + nextperiodId)
                .parent("th")
                .next()
                .children("p")
                .attr("id");
            } else {
              break;
            }
          } else {
            break;
          }
        } else {
          break;
        }
      }
    }
    return colsapnValue;
  }
  hideDivNextTime(inIt, pId) {
    let PrePerIodId: number;
    if (
      $("#" + pId)
        .parent("th")
        .prev()
        .children("p")
        .attr("id") != undefined
    ) {
      PrePerIodId = $("#" + pId)
        .parent("th")
        .prev()
        .children("p")
        .attr("id");
    }
    //return
    let aTaskId: any;
    let bTaskId: any;
    if (inIt[PrePerIodId] != undefined) {
      aTaskId = inIt[pId].Task.id;
      bTaskId = inIt[PrePerIodId].Task.id;
      if (aTaskId == bTaskId) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }
  counter(i: number) {
    if (i && i > 1) {
      return new Array(i);
    }
  }
  getNextId(pId) {
    let PrePerIodId: number;
    if (
      $("#" + pId)
        .parent("th")
        .next()
        .children("p")
        .attr("id") != undefined
    ) {
      PrePerIodId = $("#" + pId)
        .parent("th")
        .next()
        .children("p")
        .attr("id");
    }
    return PrePerIodId;
  }
  getRatingAndComment(taskId, periodId) {
    console.log(taskId+"-"+periodId)
    let data = {
      taskId: taskId,
      periodId: periodId,
    };

    this.loading = true;
    //call login api here
    this.commonService.getRatingAndComment(data).subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
          this.ratingAndComment = result["data"].ResponseData[0];
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
    this.loading = false;
    this.sidebarToggle = true;
    this.userColHide = true;
  }
  getRatingAndCommentforloop($event) {
    console.log($event.target.id);
    var dataArr = $event.target.id.split("-");
    var taskId = dataArr[0]
    var periodId = dataArr[1]
    let data = {
      taskId: taskId,
      periodId: periodId,
    };

    this.loading = true;
    //call login api here
    this.commonService.getRatingAndComment(data).subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
          this.ratingAndComment = result["data"].ResponseData[0];
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
    this.loading = false;
    this.sidebarToggle = true;
    this.userColHide = true;
  }

  changeProjectType(metricType) {
    if (metricType.target.value == 1) {
      this.htmlOptions = {
        individualMode: false,
        averageMode: true,
        medianMode: false,
        adjustedMode: false,
      };
    } else if (metricType.target.value == 2) {
      this.htmlOptions = {
        individualMode: false,
        averageMode: false,
        medianMode: true,
        adjustedMode: false,
      };
    } else if (metricType.target.value == 3) {
      this.htmlOptions = {
        individualMode: false,
        averageMode: false,
        medianMode: false,
        adjustedMode: true,
      };
    } else {
      this.htmlOptions = {
        individualMode: true,
        averageMode: false,
        medianMode: false,
        adjustedMode: false,
      };
    }
    this.htmlOptions.download = true;
    this.customeSlider();
  }
  averageRating(allRatings) {
    const allRatingsArr = this.changeObjToArray(allRatings);
    const arrAvg =
      allRatingsArr.reduce((a, b) => a + b, 0) / allRatingsArr.length;
    return Math.round(arrAvg);
  }
  getRound(rating) {
    return Math.round(rating);
  }
  medianRating(allRatings) {
    const values = this.changeObjToArray(allRatings);
    if (values && values.length < 5) {
      return "N/A";
    } else {
      const mid = Math.floor(values.length / 2),
        nums = [...values].sort((a, b) => a - b);
      return Math.round(
        values.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2
      );
    }
  }
  adjustedMode(allRatings) {
    const values = this.changeObjToArray(allRatings);
    if (values && values.length < 5) {
      return "N/A";
    } else {
      var maxValue = Math.max(...values);
      var minValue = Math.min(...values);
      const arrAvg = values.reduce((a, b) => a + b, 0);
      const adjusted = (arrAvg - maxValue - minValue) / values.length - 2;
      return Math.round(adjusted);
    }
  }
  closeCommentPopup() {
    this.sidebarToggle = false;
    this.userColHide = false;
  }

  userColHideShow() {
    this.userColHide = !this.userColHide;
  }
  addPriorities(e: any) {
    this.priority.push(e);
    console.log(this.priority);
  }
  removePriorities(e: any) {
    this.priority.pop(e.value);
    console.log(this.priority);
  }
  checkPriorityForPrint(priorityId) {
    if (this.priority) {
      this.priority.forEach(function (item) {
        if (item == priorityId) {
          return false;
        }
      });
    }
    return true;
  }
  priorityIncludes(projectId){
    if (this.priority.includes(projectId)) {
      return true;
    }
      return false
  }
  closeDiv(){
    this.ngOnInit();
    document.getElementById("closeDiv").click();
  }
  captureScreenold() {
      $(".headerTopPaddipad").css("overflow", "visible");
      $(".widgetsContent").css("overflow", "visible");
      //$(".pcontainer").addClass("maxwidth100");
      $(".pcontainer").removeAttr("style");
      $("#closeDiv").click();

    // this.htmlOptions.download = false;
    // this.loading = true;
    //const elementToPrint =$("#contentToConvert").html();
    const elementToPrint = $("#contentToConvert").clone().find(".p-1").remove().end().html()
    //let html = htmlToPdfmake(elementToPrint.outerHTML)

    let data = {
      html: elementToPrint,
      pageSize: this.pageSize,
      orientation:'landscape'
    };
    this.commonService.apiCall(data, "downloadPdf").subscribe(
      (result) => {
        if (result["success"] && result["data"].ResponseCode == 200) {
          var fileToDownload = result["data"].ResponseData;
          var href = "https://d37b3e1yz0z6u8.cloudfront.net/" + fileToDownload;
          setTimeout(() => {
            var req = new XMLHttpRequest();
            req.open("GET", href, true);
            req.responseType = "blob";

            req.onload = function (event) {
              var blob = req.response;
              console.log(blob.size);
              var link=document.createElement('a');
              link.href=window.URL.createObjectURL(blob);
              link.download="SOP_" + new Date() + ".pdf";
              link.click();
            };

            req.send();
          }, 5000);
        }
      },
      (error) => {}
    )
    //$("#fullScreenBtn").click();
    // $("body").addClass("fullScreenOn");

    // $(".headerTopPaddipad").css("overflow", "visible");

    // $(".widgetsContent").css("overflow", "visible");
    // $(".pcontainer").addClass("maxwidth100");
    // $("#closeDiv").click();

    // this.htmlOptions.download = false;
    // this.loading = true;

    // this.loading = false;

    // let pdf = new jspdf("l", "mm", this.pageSize);
    // var position = 0;

    // const elementToPrint = document.getElementsByTagName("body"); //The html element to become a pdf
    // var self = this;
    // var options = {
    //   pagesplit: true,
    // };

    // pdf.addHTML(elementToPrint, () => {
    //   self.htmlOptions.download = true;
    //   this.priority = [];
    //   pdf.save(this.projectDetail.title + ".pdf");
    //   $("body").removeClass("fullScreenOn");
    //   this.ngOnInit();
    // });

    // // html2canvas(data).then((canvas) => {

    // });
  }
  captureScreen() {

    $(".headerTopPaddipad").css("overflow", "visible");
    $(".widgetsContent").css("overflow", "visible");
    //$(".pcontainer").addClass("maxwidth100");
    $(".pcontainer").removeAttr("style");
    $("#closeDiv").click();

  // this.htmlOptions.download = false;
  // this.loading = true;
  //const elementToPrint =$("#contentToConvert").html();
  const elementToPrint = $("#contentToConvert").clone().find(".p-1").remove().end().html()
  //let html = htmlToPdfmake(elementToPrint.outerHTML)
  const header ="";
  let data = {
    method:"printpdf",
	  action:"print",
    html: elementToPrint,
    pageSize: this.pageSize,
    orientation:'landscape',
    header: header
  };
  this.commonService.apiCallPdf(data).subscribe(
    (result) => {
      if (result["success"] && result["data"].ResponseCode == 200) {
        var fileToDownload = result["data"].responseData;
        var href = "https://d37b3e1yz0z6u8.cloudfront.net/" + fileToDownload;
        setTimeout(() => {
          var req = new XMLHttpRequest();
          req.open("GET", href, true);
          req.responseType = "blob";

          req.onload = function (event) {
            var blob = req.response;
            console.log(blob.size);
            var link=document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download="SOP_" + new Date() + ".pdf";
            link.click();
          };

          req.send();
        }, 5000);
      }
    },
    (error) => {}
  )
  }
  getUserPermissionOnProject(projectId) {
    var data = {
      projectId: projectId,
    };
    this.commonService.apiCall(data, "getUserPermissionOnProject").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.permission = result["data"].ResponseData;
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  // scrollLeft() {
  //   $(".widgetsContent").animate(
  //     {
  //       scrollLeft: "-=190px",
  //     },
  //     "slow"
  //   );
  // }

  // scrollRight() {
  //   $(".widgetsContent").animate(
  //     {
  //       scrollLeft: "+=190px",
  //     },
  //     "slow"
  //   );
  // }

  scrollLeft() {
    $(".widgetsContent").animate(
      {
        scrollLeft: "-=190px",
      },
      "slow"
    );
    const container = document.querySelector('.widgetsContent');
    this.scrollLeftSize = container.scrollLeft - 190;
    var elmnt = document.getElementById("widgetsContent");
    var x = elmnt.scrollWidth;
    var ow = $(".widgetsContent").width();
    if(ow+container.scrollLeft >= x){
      console.log("in")
      this.showScrollRight = false;
    }else{
      this.showScrollRight = true;
    }

  }

  scrollRight() {
    $(".widgetsContent").animate(
      {
        scrollLeft: "+=190px",
      },
      "slow"
    );
    var elmnt = document.getElementById("widgetsContent");
    const container = document.querySelector('.widgetsContent');
    var x = elmnt.scrollWidth;
    var ow = $(".widgetsContent").width();
    if(ow+container.scrollLeft+190 >= x){
      console.log("in")
      this.showScrollRight = false;
    }else{
      this.showScrollRight = true;
    }
    this.scrollLeftSize = container.scrollLeft + 190;
  }

  changeSize(size) {
    this.pageSize = size;
  }

  checkAvailavility(){
    var elmnt = document.getElementById("widgetsContent");
    var x = elmnt.scrollWidth;
    var ow = $(".widgetsContent").width();
   // console.log(ow +"<="+ x +"--")
    if(ow < x){
      //console.log('MAN A');
      return true
    }else{
      //console.log('MAN AB');
      return false
    }
  }
  // updatePeriodIdValue(index:any,currentId,inUpdetedPeriodId){
  //   if(index > 0){
  //     this.updetedPeriodId = this.getNextId(inUpdetedPeriodId);
  //   }else{
  //     this.updetedPeriodId = currentId;
  //   }
  // }
  // calculateWidth(n){
  //   console.log(100/n)
  //   return 100/n;
  // }
  fullScreenExit() {
    var element = document.getElementById("bodyMain");
    var element2 = document.getElementById("fullScreenBtn");
    element.classList.remove("fullScreenOn");
    element2.classList.remove("active");

}

}
