<app-growler>
</app-growler>
<app-header *ngIf="htmlOptions.download"></app-header>

<div class="wrapper">
    <div class="loaderOuter" *ngIf="loading">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
    <app-nav-bar></app-nav-bar>
    <div class="inner-container headerTopPaddipad" (click)="ClickedOut($event)">




        <div class="header_left-part " *ngIf="htmlOptions.download">
            <div class="row">
                <div class="col-lg-7 col-xl-8">
                    <span class="ptypespan">
                        <img *ngIf="gType == 1" src="./assets/images/individual-sop-icon2.png" alt="icon" height="19">
                        <img *ngIf="gType == 2" src="./assets/images/group-sop-icon2.png" alt="icon" height="19">
                    </span>

                    <h3 class="sfp_project-title"> {{projectDetail.title}}</h3>
                    <a href="javascript:void(0)" title="Click here to go to project setting page"><i class="fa fa-cog mx-2" aria-hidden="true" (click)="goToProjectSetting(projectDetail.id,projectDetail.title,projectDetail.project_type,projectDetail)"></i></a>


                    <a href="javascript:void(0)" title="Click here to edit project" (click)="editPage(gType, projectDetail.projectType)"><i class="fa fa-pencil mx-2"
                        aria-hidden="true"></i></a>
                    <a href="javascript:void(0)" data-toggle="modal" data-target="#printModal"><i class="fa fa-download mx-2" aria-hidden="true" title="Click here to download report"></i></a>
                    <a href="javaScript:void(0)" title="Click here to view full screen mode" id="fullScreenBtn" onclick="fullScreen()">
                        <i class="fullScreenIcon mx-2"> <img width="23" src="./assets/images/fulllScreen.png"> </i></a>

                    <a href="javaScript:void(0)" hidden title="Click here to exit full screen mode" id="fullScreenBtnExit" onclick="fullScreenExit()">
                        <i class="fullScreenIcon"> <img width="23" class=" mx-2" src="./assets/images/expan_remove.png"> </i></a>

                </div>
                <div class="col-lg-5 col-xl-4">
                    <div class="form-group row mb-0">
                        <label class="col-md-3 col-xl-6 col-5 mt-2 pl-0 text-right">METRIC TYPE<span class="req">*</span></label>
                        <select class="form-control col-6 col-md-6 col-xl-6" name="projectType" (change)="changeProjectType($event)">
                            <option [value]="null">My ratings</option>
                            <option [value]="1">Group Average</option>
                            <option [value]="2">Group Median</option>
                            <option [value]="3">Group Adjusted Average</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>



        <div id="headerdiv" *ngIf="showheader" style="height:180px; border: none; margin: 0 0 15px 0;">

            <table style="border: 0px; border-collapse: collapse; width: 100%; background: #ffffff; border: none; height:180px; margin-bottom:
            15px; padding: 0px;">
                <tr>
                    <td class="pdfHeaderProjectName" style="width: 33%; padding: 0 5px; box-sizing: border-box; border: none; text-align: left;">
                        <div style="color: #0b3760; font-weight: 700; text-transform: uppercase; margin-top: 15px; width: 100%;">

                            <i style="padding: 5px; top: 5px;">
                                <img *ngIf="gType == 1" src="{{imageurl}}assets/images/individual-sop-icon2.png" alt="icon" style="vertical-align: middle; width: 35px;">
                                <img *ngIf="gType == 2" src="{{imageurl}}assets/images/group-sop-icon2.png" alt="icon"  style="vertical-align: middle; width: 35px;">
                            </i>{{projectDetail.title}}
                        </div>
                    </td>
                    <td class="pdfheaderMid" style="width: 33%; padding: 0 15px; box-sizing: border-box; border: none;">
                        <div style="width: 100%; text-align: center; ">
                            <h4 style="margin: 0px; color: #0b3762;">Strategic Operating Plan</h4>
                        </div>
                    </td>
                    <td style="width: 33%; padding: 0 0px; box-sizing: border-box; border: none; text-align: right;">
                        <table style="width: 100%; border: none;">
                            <tr>
                                <td class="proOwnerDetail" style="padding:0px 10px 0 0 ; list-style: none; text-align: right; margin-top: 0px;">
                                    <p style="margin:0px">Project Owner: <span>{{projectOwnerData.ownerName}}</span></p>
                                    <p style="margin:0px">{{projectOwnerData.orgName}}</p>
                                    <p style="margin:0px">Date ({{today}})</p>
                                </td>
                                <td style="width: 100px; padding: 0px;">
                                    <div style="float: right; margin-left: 0;"><img height="80" style="height: 80px;" src="{{logoUrl}}"></div>
                                </td>
                            </tr>
                        </table>

                    </td>
                </tr>
            </table>
        </div>



        <div class="strategic_priority-outer strategic_priority-viewMode" id="contentToConvert">
            <h3 class="inner-title mb-3">Strategic Priority</h3>
            <div class="strategic_priority-content" *ngIf="projectData">
                <!-- table-responsive -->
                <ng-container *ngFor="let project of projectData; let k= index">
                    <br/>
                    <ng-container *ngIf="priorityIncludes(project.id)">
                        <!-- [ngStyle]="{'min-width.px': containerMinusWi.offsetWidth + containerMinusWi2.offsetWidth + 380}" -->
                        <div class="mainOutertableDiv" #container>
                            <table>
                                <tr>
                                    <td #containerMinusWi style="vertical-align: bottom;width: 250px;overflow: auto;">
                                        <table class="strategic_priority-Table">
                                            <thead>
                                                <tr>
                                                    <th colspan="2" class="titleThIcon headingBlueBgNew">{{project.title}}</th>
                                                    <ng-container *ngIf="ExtraColumn">
                                                        <th class="text-center" *ngFor="let column of ExtraColumn">
                                                            <p id="{{column.id}}" *ngIf="k==0">{{column.title}}</p>
                                                        </th>
                                                    </ng-container>
                                                    <th class="titleThIcon pl-0 text-center" *ngIf="projectDetail.ownerColumn">
                                                        <p *ngIf="k==0"> Owner</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.individualMode">

                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                        <td id="{{Initative.id}}"></td>
                                                        <td class="text-left widthSefix strategicIntTitle">
                                                            <p>{{Initative.title}}</p>
                                                        </td>
                                                        <ng-container *ngIf="ExtraColumn">
                                                            <td style="max-width: 100px;width: 100px;min-width: 100px;" class="periodsBoxOuter" *ngFor="let Column of ExtraColumn">
                                                                <span class="text-center" *ngFor="let extra of changeObjToArrayForTasks(Initative.extraFields) ">
                                                            <p *ngIf="extra.id==Column.id">{{extra.title}} </p>
                                                        </span>
                                                            </td>
                                                        </ng-container>
                                                        <td style="max-width: 100px;width: 100px;min-width: 100px;" class="periodsBoxOuter" *ngIf="projectDetail.ownerColumn">
                                                            <p *ngIf="Initative.userId">{{Initative.userId}}</p>
                                                        </td>
                                                    </tr>
                                                </ng-container>

                                            </tbody>

                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.averageMode">
                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                        <td></td>
                                                        <td class="text-left widthSefix strategicIntTitle">
                                                            <p>{{Initative.title}}</p>
                                                        </td>
                                                        <ng-container *ngIf="ExtraColumn">
                                                            <td class="periodsBoxOuter" *ngFor="let Column of ExtraColumn">
                                                                <span class="text-center" *ngFor="let extra of changeObjToArrayForTasks(Initative.extraFields) ">
                                                            <p *ngIf="extra.id==Column.id">{{extra.title}} </p>
                                                        </span>
                                                            </td>
                                                        </ng-container>
                                                        <td style="max-width: 100px !important;width: 100px !important;min-width: 100px !important;" class="periodsBoxOuter" *ngIf="projectDetail.ownerColumn">
                                                            <p>{{Initative.userId}}</p>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.medianMode">
                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                        <td></td>
                                                        <td class="text-left widthSefix strategicIntTitle">
                                                            <p>{{Initative.title}}</p>
                                                        </td>
                                                        <ng-container *ngIf="ExtraColumn">
                                                            <td class="periodsBoxOuter" *ngFor="let Column of ExtraColumn">
                                                                <span class="text-center" *ngFor="let extra of changeObjToArrayForTasks(Initative.extraFields) ">
                                                            <p *ngIf="extra.id==Column.id">{{extra.title}} </p>
                                                        </span>
                                                            </td>
                                                        </ng-container>
                                                        <td style="max-width: 100px !important;width: 100px !important;min-width: 100px !important;" class="periodsBoxOuter" *ngIf="projectDetail.ownerColumn">
                                                            <p>{{Initative.userId}}</p>
                                                        </td>


                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.adjustedMode">
                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                        <td></td>
                                                        <td class="text-left widthSefix strategicIntTitle">
                                                            <p>{{Initative.title}}</p>
                                                        </td>
                                                        <ng-container *ngIf="ExtraColumn">
                                                            <td class="periodsBoxOuter" *ngFor="let Column of ExtraColumn">
                                                                <span class="text-center" *ngFor="let extra of changeObjToArrayForTasks(Initative.extraFields) ">
                                                            <p *ngIf="extra.id==Column.id">{{extra.title}} </p>
                                                        </span>
                                                            </td>
                                                        </ng-container>
                                                        <td style="max-width: 100px !important;width: 100px !important;min-width: 100px !important;" class="periodsBoxOuter" *ngIf="projectDetail.ownerColumn">
                                                            <p>{{Initative.userId}}</p>
                                                        </td>



                                                    </tr>
                                                </ng-container>

                                            </tbody>

                                        </table>
                                    </td>
                                    <!-- 'width.px': container.offsetWidth - containerMinusWi.offsetWidth - containerMinusWi2.offsetWidth,  -->
                                    <td class="pcontainer MiddleScrollSafari" [ngStyle]="{'max-width.px': container.offsetWidth - containerMinusWi.offsetWidth - containerMinusWi2.offsetWidth}" 
                                    style="vertical-align: bottom; position: relative; width: auto !important; min-width: 382px !important;">
                                        <a *ngIf="k==0 && htmlOptions.download && showScrollRight && checkAvailavility()" class="rightArrowChanges" href="javaScript:void(0)"><i (click)="scrollRight()" class="fa fa-angle-right" ></i></a>
                                        <a *ngIf="k==0 && htmlOptions.download && scrollLeftSize > 0" class="leftArrowChanges" href="javaScript:void(0)"><i (click)="scrollLeft()" class="fa fa-angle-left" ></i></a>

                                        <div class="widgetsContent" id="widgetsContent" style="min-width: 190px; max-width: 100%; width: auto; overflow-x: hidden;">
                                            <table class="strategic_priority-Table midTableScrollable">

                                                <thead>
                                                    <tr>
                                                        <th class="text-center" *ngFor="let Period of ProjectPeriodCopy">
                                                            <p id="{{Period.id}}" *ngIf="k==0">{{Period.title}}</p>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.individualMode">
                                                    <ng-container *ngIf="project.InitativeData">
                                                        <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                            <ng-container *ngFor="let Periods of ProjectPeriodCopy; let idx= index">
                                                                <ng-container *ngIf="hideDivNextTime(Initative.period,Periods.id)">
                                                                    <td class="periodsBoxOuter" id="{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}-{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.title:''}}" [attr.colspan]="setColspanValue(Initative.period,Periods.id)">
                                                                        <div class="tdInnerratingOuter">
                                                                            <table style="border: none; padding: 0px; height: 27px; width: 100%;">
                                                                                <tr>
                                                                                    <td style="padding: 0px; text-align: center;width: 100%;font-size: 12px;line-height: 12px;height: 27px;">{{Initative.period[Periods.id]['Task'].title}}</td>
                                                                                </tr>
                                                                            </table>
                                                                            <!-- <p class="text-center">{{Initative.period[Periods.id]['Task'].title}} &nbsp;</p> -->
                                                                            <div class="multiInARow">
                                                                                <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)>1">
                                                                                    <table>
                                                                                        <tr>
                                                                                            <td *ngFor="let in of counter(setColspanValue(Initative.period,Periods.id)) ; let pdx= index">

                                                                                                <table *ngIf="Initative.period[mergeTdRemoveArr[pdx]].rating" style="width:100%; height:20px;bottom: -6px;position: relative;" class="text-center ratingOuterpdfBox">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" class="rating{{getRound(Initative.period[mergeTdRemoveArr[pdx]].rating)}}bg ratedBoxOuter" (click)="getRatingAndCommentforloop($event)" style="height: 20px;line-height: 18px;vertical-align: bottom;">
                                                                                                                {{Initative.period[mergeTdRemoveArr[pdx]].rating}} </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                                <!-- <p id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" *ngIf="Initative.period[mergeTdRemoveArr[pdx]].rating" class="rating{{getRound(Initative.period[mergeTdRemoveArr[pdx]].rating)}}bg ratedBoxOuter" (click)="getRatingAndCommentforloop($event)"
                                                                                    >
                                                                                    {{Initative.period[mergeTdRemoveArr[pdx]].rating}}
                                                                                    </p> -->
                                                                                                <!-- <p class="dropdownmultipal" *ngIf="Initative.period[mergeTdRemoveArr[pdx]].rating">
                                                                                            <input id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" [readonly]="true" class="ratingBoxBlank" type="text" value="{{Initative.period[mergeTdRemoveArr[pdx]].rating}}" (click)="getRatingAndCommentforloop($event)" class="rating{{getRound(Initative.period[mergeTdRemoveArr[pdx]].rating)}}bg">
                                                                                        </p> -->
                                                                                                <div class="dropdown dropdownmultipal" *ngIf="!Initative.period[mergeTdRemoveArr[pdx]].rating">

                                                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" role="button" (click)="blankBox()" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                    </a>

                                                                                                    <div class="dropdown-menu p-1 px-3 notinpdf" aria-labelledby="dropdownMenuLink">
                                                                                                        <div class="divSlider slider">
                                                                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                                                <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange" id="range{{Initative.period[mergeTdRemoveArr[pdx]]['Task'].id}}{{Initative.id}}" class="" value="">

                                                                                                        <input type="hidden" id="rangeperiod{{Initative.period[mergeTdRemoveArr[pdx]]['Task'].id}}{{Initative.id}}" value="{{mergeTdRemoveArr[pdx]}}">
                                                                                                        <textarea rows="2" class="form-control" [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>

                                                                                                        <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1" (click)="addRating(Initative.id,Initative.period[Periods.id]['Task'].id,Initative.id, feedback.text)">Submit</a>
                                                                                                    </div>
                                                                                                </div>


                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </ng-container>
                                                                            </div>
                                                                            <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)==1">
                                                                                <table *ngIf="Initative.period[Periods.id].rating" style="width:100%; height:20px;bottom: -6px;position: relative;" class="text-center ratingOuterpdfBox">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td class="rating{{getRound(Initative.period[Periods.id].rating)}}bg ratedBoxOuter" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)" style="height: 20px;line-height: 18px;vertical-align: bottom;"> {{Initative['period'][Periods.id].rating}} </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>

                                                                                <!-- <button *ngIf="Initative.period[Periods.id].rating" class=" rating{{getRound(Initative.period[Periods.id].rating)}}bg ratedBoxOuter" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)"
                                                                                     >
                                                                                     {{Initative['period'][Periods.id].rating}}
                                                                                     </button> -->

                                                                                <div class="dropdown dropdownFullSize" *ngIf="!Initative.period[Periods.id].rating">
                                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" (click)="blankBox()" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    </a>
                                                                                    <div class="dropdown-menu p-1 px-3 notinpdf" aria-labelledby="dropdownMenuLink">
                                                                                        <div class="divSlider slider">
                                                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                                <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange" id="range{{Initative.period[Periods.id]['Task'].id}}{{Initative.id}}" class="" value="">
                                                                                        <input type="hidden" id="rangeperiod{{Initative.period[Periods.id]['Task'].id}}{{Initative.id}}" value="{{Periods.id}}">
                                                                                        <textarea rows="2" class="form-control" [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                                                        <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1" (click)="addRating(Initative.id,Initative.period[Periods.id]['Task'].id,Initative.id, feedback.text)">Submit</a>
                                                                                    </div>
                                                                                </div>
                                                                            </ng-container>

                                                                        </div>
                                                                    </td>
                                                                </ng-container>
                                                            </ng-container>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                                <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.averageMode">
                                                    <ng-container *ngIf="project.InitativeData">
                                                        <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                            <ng-container *ngFor="let Periods of ProjectPeriod; let idx= index">
                                                                <ng-container *ngIf="hideDivNextTime(Initative.period,Periods.id)">
                                                                    <td class="periodsBoxOuter" id="{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}-{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.title:''}}" [attr.colspan]="setColspanValue(Initative.period,Periods.id)">
                                                                        <div class="tdInnerratingOuter">
                                                                            <table style="border: none; padding: 0px; height: 27px; width: 100%;">
                                                                                <tr>
                                                                                    <td style="padding: 0px; text-align: center;width: 100%;font-size: 12px;line-height: 12px;height: 27px;">{{Initative.period[Periods.id]['Task'].title}}</td>
                                                                                </tr>
                                                                            </table>
                                                                            <!-- <p class="text-center">{{Initative.period[Periods.id]['Task'].title}} &nbsp;</p> -->
                                                                            <div class="multiInARow">

                                                                                <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)>1">
                                                                                    <table>
                                                                                        <tr>
                                                                                            <td *ngFor="let in of counter(setColspanValue(Initative.period,Periods.id)) ; let pdx= index">


                                                                                                <table *ngIf="calcLength(Initative.period[mergeTdRemoveArr[pdx]].allRatings) > 0 && averageRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)>0" style="width:100%; height:20px;bottom: -6px;position: relative;" class="text-center ratingOuterpdfBox">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" class="rating{{averageRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}bg ratedBoxOuter" (click)="getRatingAndCommentforloop($event)" style="height: 20px;line-height: 18px;vertical-align: bottom;">
                                                                                                                {{averageRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}} </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                                <!-- <button id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}"  *ngIf="Initative.period[mergeTdRemoveArr[pdx]].rating" class="rating{{averageRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}bg ratedBoxOuter" (click)="getRatingAndCommentforloop($event)"
                                                                                    >
                                                                                    {{averageRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}
                                                                                    </button> -->
                                                                                                <!-- <p class="dropdownmultipal" *ngIf="Initative.period[mergeTdRemoveArr[pdx]].rating">
                                                                                        <input id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" [readonly]="true" class="ratingBoxBlank" type="text" value="{{averageRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}" (click)="getRatingAndCommentforloop($event)" class="rating{{averageRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}bg">
                                                                                    </p> -->

                                                                                                <div class="dropdown dropdownmultipal" *ngIf="calcLength(Initative.period[mergeTdRemoveArr[pdx]].allRatings) == 0 ||averageRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)==0">
                                                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" role="button" id="dropdownMenuLink">
                                                                                                    </a>


                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </ng-container>
                                                                            </div>

                                                                            <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)==1">

                                                                                <table *ngIf="calcLength(Initative['period'][Periods.id].allRatings) > 0" style="width:100%; height:20px;bottom: -6px;position: relative;" class="text-center ratingOuterpdfBox">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td class="rating{{averageRating(Initative['period'][Periods.id].allRatings)}}bg ratedBoxOuter" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)" style="height: 20px;line-height: 18px;vertical-align: bottom;"> {{averageRating(Initative['period'][Periods.id].allRatings)}} </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>

                                                                                <!-- <p class="dropdownFullSize" >
                                                                                <input [readonly]="true" *ngIf="Initative.period[Periods.id].rating" class="ratingBoxBlank" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)" type="text"
                                                                                    value="{{averageRating(Initative['period'][Periods.id].allRatings)}}" class="rating{{averageRating(Initative['period'][Periods.id].allRatings)}}bg">
                                                                            </p> -->

                                                                                <div class="dropdown dropdownFullSize" *ngIf="calcLength(Initative['period'][Periods.id].allRatings) == 0">
                                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" role="button" id="dropdownMenuLink">
                                                                                    </a>

                                                                                </div>
                                                                            </ng-container>

                                                                        </div>
                                                                    </td>
                                                                </ng-container>
                                                            </ng-container>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                                <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.medianMode">
                                                    <ng-container *ngIf="project.InitativeData">
                                                        <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                            <ng-container *ngFor="let Periods of ProjectPeriod; let idx= index">
                                                                <ng-container *ngIf="hideDivNextTime(Initative.period,Periods.id)">
                                                                    <td class="periodsBoxOuter" id="{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}-{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.title:''}}" [attr.colspan]="setColspanValue(Initative.period,Periods.id)">
                                                                        <div class="tdInnerratingOuter">
                                                                            <table style="border: none; padding: 0px; height: 27px; width: 100%;">
                                                                                <tr>
                                                                                    <td style="padding: 0px; text-align: center;width: 100%;font-size: 12px;line-height: 12px;height: 27px;">{{Initative.period[Periods.id]['Task'].title}}</td>
                                                                                </tr>
                                                                            </table>
                                                                            <!-- <p class="text-center">{{Initative.period[Periods.id]['Task'].title}} &nbsp;</p> -->
                                                                            <div class="multiInARow">
                                                                                <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)>1">
                                                                                    <table>
                                                                                        <tr>
                                                                                            <td *ngFor="let in of counter(setColspanValue(Initative.period,Periods.id)) ; let pdx= index">


                                                                                                <table *ngIf="calcLength(Initative.period[mergeTdRemoveArr[pdx]].allRatings) > 0  && medianRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings) !=0" style="width:100%; height:20px;bottom: -6px;position: relative;" class="text-center ratingOuterpdfBox">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" class="rating{{medianRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}bg ratedBoxOuter" (click)="getRatingAndCommentforloop($event)" style="height: 20px;line-height: 18px;vertical-align: bottom;">
                                                                                                                {{medianRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}} </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                                <!-- <button *ngIf="Initative.period[mergeTdRemoveArr[pdx]].rating" id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" class="rating{{medianRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}bg ratedBoxOuter" (click)="getRatingAndCommentforloop($event)"
                                                                                    >
                                                                                    {{medianRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}
                                                                                    </button> -->
                                                                                                <!-- <p class="dropdownmultipal" *ngIf="Initative.period[mergeTdRemoveArr[pdx]].rating">
                                                                                        <input id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" [readonly]="true" class="ratingBoxBlank" type="text" value="{{medianRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}" (click)="getRatingAndCommentforloop($event)" class="rating{{medianRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}bg">
                                                                                    </p> -->
                                                                                                <div class="dropdown dropdownmultipal" *ngIf="calcLength(Initative.period[mergeTdRemoveArr[pdx]].allRatings) == 0 || medianRating(Initative.period[mergeTdRemoveArr[pdx]].allRatings)==0">
                                                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" role="button" id="dropdownMenuLink">
                                                                                                    </a>

                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </ng-container>
                                                                            </div>

                                                                            <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)==1">

                                                                                <table *ngIf="calcLength(Initative['period'][Periods.id].allRatings) > 0" style="width:100%; height:20px;bottom: -6px;position: relative;" class="text-center ratingOuterpdfBox">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td class="rating{{medianRating(Initative['period'][Periods.id].allRatings)}}bg ratedBoxOuter" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)" style="height: 20px;line-height: 18px;vertical-align: bottom;"> {{medianRating(Initative['period'][Periods.id].allRatings)}} </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <!-- <button *ngIf="Initative.period[Periods.id].rating" class="rating{{medianRating(Initative['period'][Periods.id].allRatings)}}bg ratedBoxOuter" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)"
                                                                                    >
                                                                                    {{medianRating(Initative['period'][Periods.id].allRatings)}}
                                                                                    </button> -->
                                                                                <!-- <p class="dropdownFullSize" >
                                                                                <input [readonly]="true" *ngIf="Initative.period[Periods.id].rating" class="ratingBoxBlank" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)" type="text"
                                                                                    value="{{medianRating(Initative['period'][Periods.id].allRatings)}}" class="rating{{medianRating(Initative['period'][Periods.id].allRatings)}}bg">
                                                                            </p> -->
                                                                                <div class="dropdown dropdownFullSize" *ngIf="calcLength(Initative['period'][Periods.id].allRatings) == 0">
                                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" role="button" id="dropdownMenuLink">
                                                                                    </a>
                                                                                </div>
                                                                            </ng-container>

                                                                        </div>
                                                                    </td>
                                                                </ng-container>
                                                            </ng-container>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                                <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.adjustedMode">
                                                    <ng-container *ngIf="project.InitativeData">
                                                        <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                                            <ng-container *ngFor="let Periods of ProjectPeriod; let idx= index">
                                                                <ng-container *ngIf="hideDivNextTime(Initative.period,Periods.id)">
                                                                    <td class="periodsBoxOuter" id="{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}-{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.title:''}}" [attr.colspan]="setColspanValue(Initative.period,Periods.id)">
                                                                        <div class="tdInnerratingOuter">
                                                                            <table style="border: none; padding: 0px; height: 27px; width: 100%;">
                                                                                <tr>
                                                                                    <td style="padding: 0px; text-align: center;width: 100%;font-size: 12px;line-height: 12px;height: 27px;">{{Initative.period[Periods.id]['Task'].title}}</td>
                                                                                </tr>
                                                                            </table>
                                                                            <!-- <p class="text-center">{{Initative.period[Periods.id]['Task'].title}} &nbsp;</p> -->
                                                                            <div class="multiInARow">
                                                                                <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)>1">
                                                                                    <table>
                                                                                        <tr>
                                                                                            <td *ngFor="let in of counter(setColspanValue(Initative.period,Periods.id)) ; let pdx= index">

                                                                                                <table *ngIf="calcLength(Initative.period[mergeTdRemoveArr[pdx]].allRatings) > 0  && adjustedMode(Initative.period[mergeTdRemoveArr[pdx]].allRatings) !=0" style="width:100%; height:20px;bottom: -6px;position: relative;" class="text-center ratingOuterpdfBox">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" class="rating{{adjustedMode(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}bg ratedBoxOuter" (click)="getRatingAndCommentforloop($event)" style="height: 20px;line-height: 18px;vertical-align: bottom;">
                                                                                                                {{adjustedMode(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}} </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                                <!-- <button *ngIf="Initative.period[mergeTdRemoveArr[pdx]].rating" id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" class="rating{{adjustedMode(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}bg ratedBoxOuter" (click)="getRatingAndCommentforloop($event)"
                                                                                    >
                                                                                    {{adjustedMode(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}
                                                                                    </button> -->
                                                                                                <!-- <p class="dropdownmultipal" *ngIf="Initative.period[mergeTdRemoveArr[pdx]].rating">
                                                                                        <input id="{{Initative.period[mergeTdRemoveArr[pdx]]['Task']['id']}}-{{mergeTdRemoveArr[pdx]}}" [readonly]="true" class="ratingBoxBlank" type="text" value="{{adjustedMode(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}" (click)="getRatingAndCommentforloop($event)" class="rating{{adjustedMode(Initative.period[mergeTdRemoveArr[pdx]].allRatings)}}bg">
                                                                                    </p> -->
                                                                                                <div class="dropdown dropdownmultipal" *ngIf="calcLength(Initative.period[mergeTdRemoveArr[pdx]].allRatings) == 0  || adjustedMode(Initative.period[mergeTdRemoveArr[pdx]].allRatings) ==0">
                                                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" role="button" id="dropdownMenuLink">
                                                                                                    </a>


                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </ng-container>
                                                                            </div>

                                                                            <ng-container *ngIf="setColspanValue(Initative.period,Periods.id)==1">
                                                                                <!-- <p class="dropdownFullSize" >
                                                                                <input [readonly]="true" *ngIf="Initative.period[Periods.id].rating" class="ratingBoxBlank" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)" type="text"
                                                                                    value="{{adjustedMode(Initative['period'][Periods.id].allRatings)}}" class="rating{{adjustedMode(Initative['period'][Periods.id].allRatings)}}bg">
                                                                            </p> -->
                                                                                <table *ngIf="calcLength(Initative['period'][Periods.id].allRatings) > 0" style="width:100%; height:20px;bottom: -6px;position: relative;" class="text-center ratingOuterpdfBox">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td class="rating{{adjustedMode(Initative['period'][Periods.id].allRatings)}}bg ratedBoxOuter" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)" style="height: 20px;line-height: 18px;vertical-align: bottom;"> {{adjustedMode(Initative['period'][Periods.id].allRatings)}} </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <!-- <p *ngIf="Initative.period[Periods.id].rating" class="rating{{adjustedMode(Initative['period'][Periods.id].allRatings)}}bg ratedBoxOuter" (click)="getRatingAndComment(Initative['period'][Periods.id]['Task']['id'], Periods.id)"
                                                                                    >
                                                                                    {{adjustedMode(Initative['period'][Periods.id].allRatings)}}
                                                                                    </p> -->
                                                                                <div class="dropdown dropdownFullSize" *ngIf="calcLength(Initative['period'][Periods.id].allRatings) == 0">
                                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" role="button" id="dropdownMenuLink">
                                                                                    </a>

                                                                                </div>
                                                                            </ng-container>

                                                                        </div>
                                                                    </td>
                                                                </ng-container>
                                                            </ng-container>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>

                                            </table>
                                        </div>
                                    </td>
                                    <td #containerMinusWi2 style="vertical-align: bottom;width: 300px;max-width: 300px;overflow: auto;">
                                        <table class="strategic_priority-Table midTableScrollable">
                                            <thead>
                                                <tr>
                                                    <th class="notinpdf" style="width: 35px;"></th>
                                                    <th style="min-width: 220px;">
                                                        <p *ngIf="k==0">What success looks like...</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.individualMode">

                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">


                                                        <td class="notinpdf" style="width: 35px;"></td>
                                                        <td class="strategicSuccessNote">
                                                            <p>{{Initative.description}}</p>
                                                        </td>
                                                    </tr>
                                                </ng-container>

                                            </tbody>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.averageMode">
                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">

                                                        <td class="notinpdf" style="width: 35px;"></td>
                                                        <td class="strategicSuccessNote">
                                                            <p>{{Initative.description}}</p>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.medianMode">
                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">


                                                        <td class="notinpdf" style="width: 35px;"></td>
                                                        <td class="strategicSuccessNote">
                                                            <p>{{Initative.description}}</p>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                            <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.adjustedMode">
                                                <ng-container *ngIf="project.InitativeData">
                                                    <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">

                                                        <td class="notinpdf" style="width: 35px;"></td>
                                                        <td class="strategicSuccessNote">
                                                            <p>{{Initative.description}}</p>
                                                        </td>

                                                    </tr>
                                                </ng-container>

                                            </tbody>

                                        </table>
                                    </td>
                                </tr>

                            </table>

                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="notinpdf text-right mt-2" *ngIf="projectData.length>0">
                <button (click)="exitwindow()" class="btn btn-danger btn-sm px-4">Exit</button>
            </div>
            <div *ngIf="htmlOptions.download" class="rightSidebar_Table-body notinpdf" [ngClass]="{'active' : sidebarToggle}">
                <div class="rightSidebar_Table-header">
                    <h4>Rating & Comments
                        <a class="pull-right userViewLink" (click)="userColHideShow()" href="javascript:void(0);"> <i style="font-size: 20px;" class="fa fa-user"></i> </a>
                        <a class="rightSidebarCloseBtn" href="javascript:void(0);" (click)="closeCommentPopup()"> <i class="fa fa-times"></i> </a>
                    </h4>
                    <h6 *ngIf="ratingAndComment[0]!=undefined">
                        {{ratingAndComment[0].initiativeTitle}}- {{ratingAndComment[0].periodTitle}}
                        <span *ngIf="ratingAndComment[0].taskTitle!=''">-</span> {{ratingAndComment[0].taskTitle}}
                    </h6>
                </div>
                <div class="table-responsive normal_table-outer" *ngIf="ratingAndComment">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="d-none" [ngClass]="{'d-none' : userColHide}">User </th>
                                <th class="text-center">Rating</th>
                                <th class="text-center">Comments</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let rating of ratingAndComment">
                                <td class="d-none" [ngClass]="{'d-none' : userColHide}">{{rating.user_name}} </td>
                                <td class="text-center"> <button class="btn btn-sm rating{{getRound(rating.rating)}}bg"> {{rating.rating | number : '1.1-1'}} </button> </td>
                                <td class="text-left">{{rating.comment}}</td>
                                <td class="text-center" *ngIf="loginUser==rating.user_id">

                                    <div class="dropdown editDropdownRatingBox">
                                        <a class="btn btn-dark btn-sm" href="javascript:void(0)" role="button" (click)="blankBoxRating(rating.rating,rating.comment)" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Edit
                                        </a>

                                        <div class="dropdown-menu p-1 px-3 notinpdf" aria-labelledby="dropdownMenuLink">
                                            <div class="divSlider slider">
                                                <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                    <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                </div>
                                            </div>

                                            <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange" id="rangeupdate{{rating.id}}" class="" value="{{rating.rating}}">
                                            <textarea id="comment{{rating.id}}" rows="2" class="form-control" [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                            <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1" (click)="updateRating(rating.id,rating.rating,rating.task_id,rating.period_id)">Submit</a>
                                        </div>
                                    </div>

                                </td>
                                <td class="text-left" *ngIf="loginUser!=rating.user_id"></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="table-responsive normal_table-outer" *ngIf="ratingAndComment">
                    <div *ngIf="notValid" class="alert alert-danger mt-2">
                        <strong>{{message}}.</strong>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div data-backdrop="true" class="modal fade" id="printModal" tabindex="-1" role="dialog" aria-labelledby="printModal" aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">

            <div class="modal-content">
                <div class="modal-header">

                    <button type="button" id="printHide" class="close" (click)="closeDiv();" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body py-4">
                    <form class="" name="form" #f1="ngForm" (ngSubmit)="f1.form.valid && captureScreen()" novalidate>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="py-6 buttonPOsSetBt">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Strategic Priority<span class="req">*</span></label>
                                                <ng-select [clearable]="false" class="max-width400" [multiple]="true" [(ngModel)]="priorities.data" (add)="addPriorities($event)" name="data" (remove)="removePriorities($event)" required>
                                                    <ng-option *ngFor="let project of projectData" [value]="project.id">{{project.title}}</ng-option>
                                                </ng-select>
                                                <div *ngIf="priority.length==0" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Strategic Priority field is required
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Project Periods<span class="req">*</span></label>
                                                <ng-select [clearable]="false" class="max-width400" [multiple]="true" [(ngModel)]="priorities.periods" (add)="addPeriods($event)" name="periods" (remove)="removePeriods($event)" required>
                                                    <ng-option *ngFor="let period of ProjectPeriod" [value]="period.id">{{period.title}}</ng-option>
                                                </ng-select>
                                                <div *ngIf="periods.length==0" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Project Periods field is required
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Page Size<span class="req">*</span></label>
                                                <select class="form-control" name="pageSize" [(ngModel)]="priorities.pageSize" #pageSize="ngModel" id="pageSize" (change)="changeSize($event.target.value)" required>
                                                <option value=''>Please select</option>
                                                <option>A3</option>
                                                <option>A4</option>
                                            </select>
                                                <div *ngIf="f1.submitted && !priorities.pageSize" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Page Size field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-right">
                                            <button style="display: none;" id='closeDiv' type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                            <button type="submit" id="closeModal" class="btn btn-primary themeBtnBlue">Download</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>


    <div data-backdrop="true" class="modal fade" id="downloadModal" tabindex="-1" role="dialog" aria-labelledby="downloadModal" aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body py-4 text-center">
                    <button type="button" id="" class="close" (click)="closeDivPdf();" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="mb-3">Download Report</h4>
                    <p>You download will start automatically.<br>If not please <a href="" id="downloadView" download><strong>Click here</strong></a> to start download.</p>
                    <p><span id="time"></span></p>
                </div>
            </div>
        </div>
    </div>