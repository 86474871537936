import {
  Component,
  OnInit,
  ViewChild,
  ɵConsole,
  ComponentFactoryResolver,
} from "@angular/core";
import { CommonService } from "../../services/commonservices";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, Validators } from "@angular/forms";
import { GrowlerService } from "../../services/growler.service";
/* import * as $ from "jquery"; */
declare var $: any;

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit {
  logoUrl: string;
  currentMonthCount: any;
  currentYearCount: any;
  public showLoder: boolean = true;
  public showCompare: boolean = false;
  public showCompareMode: boolean = false;
  date12: Date;
  minDate: Date;
  maxDate: Date;
  currentMonth: any;
  compareMonth: any;
  notValid: boolean = false;
  notValid1: boolean = false;
  validTrue: boolean = false;
  projectId: any;
  userRating: any;
  projectData: any = [];
  userData: any = [];
  feedback: any = {};
  ProjectPeriodData: any = {};
  projectSettingData: any = {};
  ProjectPeriod: any = [];
  loading: boolean = false;
  notesdata: any;
  message: any;
  token: any;
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    public growlerService: GrowlerService
  ) {}

  ngOnInit(): void {
    let d = new Date();
    let m = d.getMonth();
    let y = d.getFullYear();
    this.currentMonthCount = m + 1;
    this.currentYearCount = y;
    this.route.queryParamMap.subscribe((params) => {
      this.token = params.get("token");
    });
    this.route.queryParamMap.subscribe((params) => {
      this.projectId = params.get("projectId");
    });
    this.logoUrl = localStorage.getItem("logo");
    this.currentMonth = this.getMonthNameByDate(new Date());
    this.projectSettingData.projectName = "";

    this.getProjectSetting(this.projectId);
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.customeSlider(1);
    this.getSetting();

    $("body").on("click", ".dropdown-menu", function (e) {
      $(this).parent().is(".show") && e.stopPropagation();
    });
  }
  getSetting() {
    this.loading = true;
    //call login api here
    this.commonService.getSetting().subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.logoUrl = result["data"]["ResponseData"].logo;
          localStorage.setItem("logo", this.logoUrl);
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }
  addRating(queId: any, token: any, commntes: any) {
    let projectId = this.projectId;
    let rating = $("#range" + queId + token).val();
    if (rating == 0) {
      rating = 1;
    }
    this.addRatingAndCommentTask(queId, rating, token, commntes, projectId);
  }
  addRatingAndCommentTask(queId, rating, token, commntes, projectId) {
    let data = {
      id: queId,
      userId: "",
      token: token,
      rating: rating,
      comment: commntes,
      projectId: projectId,
      requestedMonth: this.currentMonthCount,
      requestedYear: this.currentYearCount,
    };

    this.loading = true;
    //call login api here
    this.commonService.addRatingComment(data).subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.ngOnInit();
          $(".custom-handle").text(1);
          this.feedback.text = "";
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
  }
  blankBox() {
    this.feedback.text = "";
    $(".custom-handle").text(1);
    var handle = $(".custom-handle");
    handle.text();
    for (let n = 2; n < 11; ++n) {
      handle.removeClass("rating" + n + "bg");
    }
    this.customeSlider(1);
  }
  blankBoxRating(rating) {
    //console.log(rating[0].comment);
    this.feedback.text = rating[0].comment;
    this.customeSlider(rating[0].rating);
    $(".custom-handle").text(rating[0].rating);
    

    var handle = $(".custom-handle");
    handle.text();
    for (let n = 2; n < 11; ++n) {
      handle.removeClass("rating" + n + "bg");
    }
    handle.addClass("rating" + Math.round(rating[0].rating) + "bg");
    this.customeSlider(rating[0].rating);



  }
  customeSlider(value) {
    $(function () {
      var handle = $(".custom-handle");
      $(".slider").slider({
        orientation: "horizantal",
        range: "min",
        value: value,
        step: 0.5,
        min: 1.0,
        max: 10.0,
        animate: "fast",
        create: function () {
          handle.text($(this).slider("value"));
          handle.addClass(
            "rating" + Math.round($(this).slider("value")) + "bg"
          );
        },
        slide: function (event, ui) {
          handle.text(ui.value);
          
          handle.addClass("rating" + Math.round(ui.value) + "bg");
          handle.removeClass("rating" + (Math.round(ui.value) + 1) + "bg");
          var attrId = $(this)
            .closest(".dropdown-menu.p-3")
            .find('input[name="feedbackRange"]')
            .attr("id");
          $("#" + attrId).val(ui.value);
        },
      });
    });
  }
  
  getSfrProjectData() {
    let data = {
      projectId: this.projectId,
      requestedMonth: this.currentMonthCount,
      requestedYear: this.currentYearCount,
    };
    this.loading = true;
    //call login api here
    this.commonService.getSfrProjectData(data).subscribe(
      (result) => {
        this.loading = false;
        this.notesdata = "";
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.projectData = result["data"]["ResponseData"].questionData;
          this.userData = result["data"]["ResponseData"].projectUsers;
          this.customeSlider(1);
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          $(function () {
            $('[data-toggle="tooltip"]').tooltip();
          });
          this.message = result["data"].ResponseMessage;
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
  }
  calculateWidth(minVal: any, maxVal: any) {
    var max = maxVal * 10 - 5;
    var min = minVal * 10 - 5;
    let total = max - min;
    return total;
  }
  getProjectSetting(activeProjectId) {
    let projectDetail = {
      projectId: activeProjectId,
    };
    this.commonService
      .editProjectSetting(projectDetail)
      .subscribe((projectSettingResultData) => {
        this.loading = false;
        if (!projectSettingResultData["success"]) {
          this.message = projectSettingResultData["error"];
        }

        if (
          projectSettingResultData["success"] &&
          projectSettingResultData["data"].ResponseCode == 200
        ) {
          //let projectData= projectSettingResultData["data"].ResponseData.data;
          this.projectSettingData =
            projectSettingResultData["data"].ResponseData.data[0];
            let makeDate =
            projectSettingResultData["data"].ResponseData.data[0].currentPeriod;
          makeDate = new Date(makeDate);
          this.currentMonth = this.getMonthNameByDate(makeDate);
           let d = makeDate;
           let m = d.getMonth();
           let y = d.getFullYear();
           this.currentMonthCount = m + 1;

            this.getSfrProjectData();
          /*  let makeDate =
            projectSettingResultData["data"].ResponseData.data[0].currentPeriod;
          makeDate = new Date(makeDate);
          makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
          this.minDate = makeDate;

          this.maxDate = new Date();
          makeDate = new Date(
            this.maxDate.setMonth(this.maxDate.getMonth() - 1)
          ); */
        } else {
          this.message = projectSettingResultData["data"].ResponseMessage;
        }
      });
  }
  median(values) {
    if (!values) return "";
    var temp = [];
    values.forEach(function (item) {
      temp.push(item.rating);
    });

    temp.sort(function (a, b) {
      return a - b;
    });

    var half = Math.floor(temp.length / 2);
    if (temp.length % 2) return temp[half];

    return (temp[half - 1] + temp[half]) / 2.0;
  }
  getUserRating(user, userRating) {
    var temp = 0;
    if (!userRating) {
      temp = 0;
    } else if (userRating) {
      userRating.forEach(function (item) {
        if (item.userId == user.id) {
          temp = item.rating;
        }
      });
    } else {
      temp = 0;
    }
    return temp;
  }
  handleCompare(e: any) {
    this.showCompare = e.target.checked;
    this.showCompareMode = e.target.checked;
    if (!e.target.checked) {
      this.ngOnInit();
    } else {
      this.ProjectPeriodData.currentPeriod = "";
    }
  }
  changePeriod(e: any) {
    if (e) {
      this.showCompareMode = true;
      this.currentMonth = this.getMonthNameByDate(new Date());
      this.compareMonth = this.getMonthNameByDate(e);
      this.sfrCompareMonth();
    }
  }
  getMonthNameByDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date(date);
    return monthNames[d.getMonth()] + " " + d.getFullYear();
  }

  sfrCompareMonth() {
    let projectDetail = {
      projectId: this.projectId,
      primaryMonth: 5,
      primaryYear: 2020,
      secondaryMonth: 3,
      secondaryYear: 2020,
    };
    this.commonService
      .sfrCompareMonth(projectDetail)
      .subscribe((projectSettingResultData) => {
        this.loading = false;
        if (!projectSettingResultData["success"]) {
          this.message = projectSettingResultData["error"];
        }

        if (
          projectSettingResultData["success"] &&
          projectSettingResultData["data"].ResponseCode == 200
        ) {
          this.projectData = projectSettingResultData["data"].ResponseData;
          $(function () {
            $('[data-toggle="tooltip"]').tooltip();
          });
        } else {
          this.message = projectSettingResultData["data"].ResponseMessage;
        }
      });
  }
  delta(min1, min2) {
    if (min1 && min2) {
      return min1 - min2;
    } else {
      return "";
    }
  }
  getString(string1: any, string2: any) {
    if (string1 && string2) {
      return true;
    } else {
      return false;
    }
  }
  trend(min1, min2) {
    if (min1 && min2) {
      let delta = (min1 - min2) / min2;
      if (delta > 1.5) {
        return 1;
      } else if (delta >= 0.51 && delta < 1.5) {
        return 2;
      } else if (delta >= -0.5 && delta < +0.5) {
        return 3;
      } else if (delta >= -0.51 && delta < -1.51) {
        return 4;
      } else {
        return 5;
      }
    } else {
      return 0;
    }
  }
  roundRating(number) {
    return Math.round(number);
  }
  getRatingdata(userRating) {
    var temp = 0;
    var self = this;
    if (userRating === undefined) {
      temp = 0;
    } else if (userRating) {
      userRating.forEach(function (item) {
        if (item != undefined && item.token == self.token) {
          temp = item.rating;
        }
      });
    } else {
      temp = 0;
    }
    return temp;
  }
  addClass(e): void {
    $("#"+e).addClass("highlight");
    $(".feedbackRatingbgWhite").addClass("highlight");
    $("#r"+e).addClass("highlight-dark");
    // $(".user" + e).addClass("highlight");
    // $("." + event).addClass("highlight");
    // $("#"+e+event).addClass("highlight-dark");

  }

  removeClass(e, event:any=null): void {
    $("#"+e).removeClass("highlight");
    $(".feedbackRatingbgWhite").removeClass("highlight");
    $("#r"+e).removeClass("highlight-dark");
    // $(".user" + e).removeClass("highlight");
    // $("." + event).removeClass("highlight");
    // $("#"+e+event).removeClass("highlight-dark");
  }
  exitwindow(){
    //if(confirm("Do u want to continue?")){
      this.router.navigateByUrl("/thanks");
    //}
  }
}