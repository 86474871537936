<aside class="leftNav themeBg" *ngIf="isLoggedIn == 'true'">
    <nav>
        <div class="navcollapseIcon">
            <a href="javaScript:void(0)" onclick="myFunction()"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
        </div>
        <div class="navLogo">
            <a routerLink="/"> <img [src]="logoUrl" alt="logo" class="img-fluid mt-3"> </a>
        </div>
        <ul>
            <li><a routerLink="/"><i class="fa fa-home" aria-hidden="true" title="Click here to go to home page"></i> <span>Dashboard</span></a></li>
            <li><a href="javascript:void(0)" routerLink="/my-projects"><i class="fa fa-table" aria-hidden="true" title="Click here to view Projects"></i> <span>My Projects</span></a></li>
            <li *ngIf="globalRoles && globalRoles.global_client_detail"><a href="javascript:void(0)" routerLink="/clients"><i class="fa fa-users" aria-hidden="true" title="Click here to view clients"></i> <span>Clients</span></a></li>
            <li *ngIf="globalRoles && globalRoles.global_manage_setting"><a href="javascript:void(0)" routerLink="/settings"><i class="fa fa-cog" aria-hidden="true" title="Click here to manage settings"></i> <span>Settings</span></a></li>
        </ul>
    </nav>
</aside>