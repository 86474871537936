<app-growler>
</app-growler>
<app-header></app-header>
<div class="wrapper">
    <app-nav-bar></app-nav-bar>
    <div class="inner-container headerTopPaddipad">
        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-10 col-xl-8">
                    <input type="text" class="projectTitleInputBox" value="SFR Title (Edit Mode)">
                    <img src="./assets/images/individual-sop-icon.png" alt="icon" height="15"> <span class="projectType">Individual</span>
                    <a href="javascript:void(0);"><i class="fa fa-pencil mx-2" aria-hidden="true"></i></a>
                    <a href="javascript:void(0);"><i class="fa fa-cog mx-2" aria-hidden="true"></i></a>
                    <a href="javascript:void(0);"><i class="fa fa-download mx-2" aria-hidden="true"></i></a>
                    <button class="btn btn-primary float-right themeBtnBlue">Save</button>
                </div>
            </div>
        </div>

        <div class="strategic_priority-outer">
            <div class="strategic_priority-content">

                <div class="table-responsive">
                    <table class="strategic_priority-Table">
                        <thead>
                            <tr>
                                <th>
                                    <div class="dropdown">
                                        <a href="javaScript:void()" class="dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
                                        </a>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit
                      Project</a>
                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-clipboard" aria-hidden="true"></i> Copy
                      Project</a>
                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-user-plus" aria-hidden="true"></i> Add
                      participant</a>
                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-archive" aria-hidden="true"></i> Archive
                      project</a>
                                        </div>
                                    </div>
                                </th>
                                <th class="titleThIcon pl-0"><i class="fa fa-ellipsis-v" aria-hidden="true"></i><i class="fa fa-ellipsis-v" aria-hidden="true"></i> Strategic Priority Title</th>
                                <th><i class="fa fa-plus-circle" aria-hidden="true"></i></th>
                                <th>What success looks like...</th>
                            </tr>
                        </thead>

                        <tbody class="strategic_priority-tbody">
                            <tr>
                                <td></td>
                                <td class="brleftBlue"><input type="text" placeholder="+ Initiative Title"> </td>
                                <td></td>
                                <td><input type="text" placeholder="Enter Description here"> </td>
                            </tr>
                        </tbody>

                    </table>
                </div>

            </div>
        </div>




        <div class="strategic_priority-outer mt-5">
            <div class="strategic_priority-content">

                <div class="table-responsive">
                    <table class="strategic_priority-Table widthFull">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="titleThIcon pl-0">Perceptions of Key Leadership Capabilities & Behaviours</th>
                                <th class="pl-0">Question Notes</th>
                            </tr>
                        </thead>

                        <tbody class="strategic_priority-tbody">
                            <tr>
                                <td></td>
                                <td class="width45per brleftBlue">
                                    <p class="mb-0">Attract and develop talent </p>
                                </td>
                                <td>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco </p>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td class="width45per brleftBlue">
                                    <p class="mb-0">Attract and develop talent </p>
                                </td>
                                <td>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco </p>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>

            </div>
        </div>


        <div class="strategic_priority-outer mt-5">
            <div class="strategic_priority-content" id="sortable">

                <div class="table-responsive">
                    <table class="strategic_priority-Table time_management_personal-table ">
                        <thead>
                            <tr>
                                <th>
                                    <div class="dropdown">
                                        <a href="javaScript:void()" class="dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
                                        </a>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit
                      Project</a>
                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-clipboard" aria-hidden="true"></i> Copy
                      Project</a>
                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-user-plus" aria-hidden="true"></i> Add
                      participant</a>
                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-archive" aria-hidden="true"></i> Archive
                      project</a>
                                        </div>
                                    </div>
                                </th>
                                <th class="titleThIcon pl-0"><i class="fa fa-ellipsis-v" aria-hidden="true"></i><i class="fa fa-ellipsis-v" aria-hidden="true"></i> Strategic Priority Title</th>
                                <th class="text-center">Jan</th>
                                <th class="text-center">Feb</th>
                                <th class="text-center">Mar</th>
                                <th style="width: 35px;"><i class="fa fa-plus-circle" aria-hidden="true"></i></th>
                                <th style="min-width: 220px;">What success looks like...</th>
                            </tr>
                        </thead>

                        <tbody class="strategic_priority-tbody">
                            <tr>
                                <td class="disableResizeDrag"></td>
                                <td class="brleftBlue disableResizeDrag"><input type="text" placeholder="+ Initiative Title"> </td>
                                <td>
                                    <div class="dragDivOuter"><label>asdasas dasdasda dsdasdd</label></div>
                                </td>
                                <td>
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here2"></div>
                                </td>
                                <td class="">
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here3"></div>
                                </td>
                                <td class="disableResizeDrag"></td>
                                <td class="disableResizeDrag"><input type="text" placeholder="Enter Description here"> </td>
                            </tr>
                            <tr>
                                <td class="disableResizeDrag"></td>
                                <td class="brleftBlue disableResizeDrag"><input type="text" placeholder="+ Initiative Title"> </td>
                                <td>
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here1"></div>
                                </td>
                                <td>
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here2"></div>
                                </td>
                                <td class="">
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here3"></div>
                                </td>
                                <td class="disableResizeDrag"></td>
                                <td class="disableResizeDrag"><input type="text" placeholder="Enter Description here"> </td>
                            </tr>
                            <tr>
                                <td class="disableResizeDrag"></td>
                                <td class="brleftBlue disableResizeDrag"><input type="text" placeholder="+ Initiative Title"> </td>
                                <td>
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here1"></div>
                                </td>
                                <td>
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here2"></div>
                                </td>
                                <td class="">
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here3"></div>
                                </td>
                                <td class="disableResizeDrag"></td>
                                <td class="disableResizeDrag"><input type="text" placeholder="Enter Description here"> </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                <div class="table-responsive">
                    <table class="strategic_priority-Table time_management_personal-table ">
                        <thead>
                            <tr>
                                <th>
                                    <div class="dropdown">
                                        <a href="javaScript:void()" class="dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
                                        </a>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit
                      Project</a>
                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-clipboard" aria-hidden="true"></i> Copy
                      Project</a>
                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-user-plus" aria-hidden="true"></i> Add
                      participant</a>
                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-archive" aria-hidden="true"></i> Archive
                      project</a>
                                        </div>
                                    </div>
                                </th>
                                <th class="titleThIcon pl-0"><i class="fa fa-ellipsis-v" aria-hidden="true"></i><i class="fa fa-ellipsis-v" aria-hidden="true"></i> Strategic Priority Title</th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th style="width: 35px;"></th>
                                <th style="min-width: 220px;"></th>
                            </tr>
                        </thead>

                        <tbody class="strategic_priority-tbody">
                            <tr>
                                <td class="disableResizeDrag"></td>
                                <td class="brleftBlue disableResizeDrag"><input type="text" placeholder="+ Initiative Title"> </td>
                                <td>
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here1"></div>
                                </td>
                                <td>
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here2"></div>
                                </td>
                                <td class="">
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here3"></div>
                                </td>
                                <td class="disableResizeDrag"></td>
                                <td class="disableResizeDrag"><input type="text" placeholder="Enter Description here"> </td>
                            </tr>
                            <tr>
                                <td class="disableResizeDrag"></td>
                                <td class="brleftBlue disableResizeDrag"><input type="text" placeholder="+ Initiative Title"> </td>
                                <td>
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here1"></div>
                                </td>
                                <td>
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here2"></div>
                                </td>
                                <td class="">
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here3"></div>
                                </td>
                                <td class="disableResizeDrag"></td>
                                <td class="disableResizeDrag"><input type="text" placeholder="Enter Description here"> </td>
                            </tr>
                            <tr>
                                <td class="disableResizeDrag"></td>
                                <td class="brleftBlue disableResizeDrag"><input type="text" placeholder="+ Initiative Title"> </td>
                                <td>
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here1"></div>
                                </td>
                                <td>
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here2"></div>
                                </td>
                                <td class="">
                                    <div class="dragDivOuter"><input type="text" class="text-center" placeholder="Enter task here3"></div>
                                </td>
                                <td class="disableResizeDrag"></td>
                                <td class="disableResizeDrag"><input type="text" placeholder="Enter Description here"> </td>
                            </tr>
                        </tbody>

                    </table>
                </div>

            </div>
        </div>



        <div class="strategic_priority-outer mt-5 strategic_priority-viewMode">
            <div class="strategic_priority-content">
                <div class="table-responsive">

                    <table class="strategic_priority-Table ">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="titleThIcon pl-0">Core Business</th>
                                <th class="text-center">Jan</th>
                                <th class="text-center">Feb</th>
                                <th class="text-center">Mar</th>
                                <th style="width: 35px;"></th>
                                <th style="min-width: 220px;">What success looks like...</th>
                            </tr>
                        </thead>

                        <tbody class="strategic_priority-tbody">
                            <tr>
                                <td></td>
                                <td class="text-left widthSefix strategicIntTitle">
                                    <p>Service improvements</p>
                                </td>
                                <td class="">
                                    <p>Baseline measurement</p><input type="text" value="2" class="rating2bg"> </td>
                                <td class="">
                                    <p>Baseline measurement</p><input type="text" value="3" class="rating3bg"> </td>
                                <td class="">
                                    <p>Baseline measurement</p><input type="text" value="8" class="rating8bg"> </td>
                                <td></td>
                                <td class="strategicSuccessNote">
                                    <p>Employees understand our plan and are engaged in …</p>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td class="text-left widthSefix strategicIntTitle">
                                    <p>Employee Engagement</p>
                                </td>
                                <td class="">
                                    <p>Baseline measurement</p><input type="text" value="5" class="rating5bg"> </td>
                                <td class="">
                                    <p>Baseline measurement</p><input type="text" value="6" class="rating6bg"> </td>
                                <td class="">
                                    <p>Baseline measurement</p><input type="text" value="8" class="rating8bg"> </td>
                                <td></td>
                                <td class="strategicSuccessNote">
                                    <p>Employees understand our plan and are engaged in …</p>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td class="text-left widthSefix strategicIntTitle">
                                    <p>Time Management</p>
                                </td>
                                <td class="">
                                    <p> </p><input type="text" value="" class="ratingBoxBlank"> </td>
                                <td class="">
                                    <p>Baseline measurement</p><input type="text" value="3" class="rating3bg"> </td>
                                <td class="">
                                    <p>Baseline measurement</p><input type="text" value="9" class="rating9bg"> </td>
                                <td></td>
                                <td class="strategicSuccessNote">
                                    <p>Employees understand our plan and are engaged in …</p>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td class="text-left widthSefix strategicIntTitle">
                                    <p>Personal Time</p>
                                </td>
                                <td class="">
                                    <p> </p>
                                    <div class="dropdown">
                                        <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-3" href="javascript:void(0);" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                        </a>

                                        <div class="dropdown-menu p-4" aria-labelledby="dropdownMenuLink">
                                            <div class="divSlider">
                                                <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                    <div id="custom-handle" class="ui-slider-handle"></div>
                                                </div>
                                            </div>
                                            <textarea class="form-control" placeholder="Enter your comments"></textarea>
                                        </div>
                                    </div>

                                </td>
                                <td class="">
                                    <p>Baseline measurement</p><input type="text" value="3" class="rating3bg"> </td>
                                <td class="">
                                    <p>Baseline measurement</p><input type="text" value="9" class="rating9bg"> </td>
                                <td></td>
                                <td class="strategicSuccessNote">
                                    <p>Employees understand our plan and are engaged in …</p>
                                </td>
                            </tr>
                        </tbody>

                    </table>

                    <table class="strategic_priority-Table">
                        <thead>
                            <tr>
                                <th>
                                </th>
                                <th colspan="4" class="titleThIcon pl-0">Time management / personal</th>
                                <th style="width: 35px;"></th>
                                <th style="min-width: 220px;"></th>
                            </tr>
                        </thead>

                        <tbody class="strategic_priority-tbody">
                            <tr>
                                <td class="disableResizeDrag"></td>
                                <td class="text-left widthSefix strategicIntTitle disableResizeDrag">
                                    <p>Personal Time</p>
                                </td>
                                <td class="">
                                    <p>Baseline measurement</p><input type="text" value="2" class="rating2bg"> </td>
                                <td class="">
                                    <p>Baseline measurement</p><input type="text" value="3" class="rating3bg"> </td>
                                <td class="disableResizeDrag">
                                    <p>Baseline measurement</p><input type="text" value="8" class="rating8bg"> </td>
                                <td class="disableResizeDrag"></td>
                                <td class="strategicSuccessNote">
                                    <p>Employees understand our plan and are engaged in …</p>
                                </td>
                            </tr>

                            <tr>
                                <td class="disableResizeDrag"></td>
                                <td class="text-left widthSefix strategicIntTitle disableResizeDrag">
                                    <p>Time Management</p>
                                </td>
                                <td class="">
                                    <p>Baseline measurement</p><input type="text" value="3" class="rating3bg"> </td>
                                <td class="">
                                    <p> </p>
                                    <div class="dropdown">
                                        <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-3" href="javascript:void(0);" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                        </a>

                                        <div class="dropdown-menu p-4" aria-labelledby="dropdownMenuLink">
                                            <div class="divSlider">
                                                <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                    <div id="custom-handle" class="ui-slider-handle"></div>
                                                </div>
                                            </div>
                                            <textarea class="form-control" placeholder="Enter your comments"></textarea>
                                        </div>
                                    </div>

                                </td>
                                <td class="disableResizeDrag">
                                    <p>Baseline measurement</p><input type="text" value="9" class="rating9bg"> </td>
                                <td class="disableResizeDrag"></td>
                                <td class="strategicSuccessNote">
                                    <p>Employees understand our plan and are engaged in …</p>
                                </td>
                            </tr>
                        </tbody>

                    </table>

                </div>

            </div>
        </div>

    </div>