import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/commonservices';
import { GrowlerService } from '../../services/growler.service';
import { AuthService } from '../../auth/auth.service';
@Component({
  selector: "app-resetpassword",
  templateUrl: "./resetpassword.component.html",
  styleUrls: ["./resetpassword.component.scss"],
})
export class ResetpasswordComponent implements OnInit {
  message: any;
  logoUrl: string;
  token: any;
  public user: any = {};
  htmlOptions: any = {};
  notValid: boolean = false;
  validTrue: boolean = false;
  loading: boolean = false;
  constructor(
    private commonService: CommonService,
    public growlerService: GrowlerService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.logoUrl = localStorage.getItem("logo");
    this.route.queryParamMap.subscribe((params) => {
      this.token = params.get("token");
    });
    console.log(this.token + " pawam");
    if (this.token) {
      console.log(this.token + " joshi");
      let data = {
        token: this.token,
      };
      this.ValidateToken(data);
    } else {
      this.message = "No such token found";
      this.notValid = true;
      setTimeout(() => {
        this.notValid = false;
        this.router.navigateByUrl("/");
      }, 4000);
    }
  }

  ValidateToken(data) {
    this.loading = true;
    this.commonService.ValidateToken(data).subscribe((validationResult) => {
      this.loading = false;
      if (!validationResult["success"]) {
        this.message = validationResult["data"].ResponseMessage;
        this.notValid = true;
        setTimeout(() => {
          this.notValid = false;
          this.router.navigateByUrl("/");
        }, 4000);
        // setTimeout(() => {
        //   this.router.navigateByUrl("/");
        // }, 4000);
      }
      if (
        validationResult["success"] &&
        validationResult["data"].ResponseCode == 200
      ) {
      } else {
        this.message = validationResult["data"].ResponseMessage;
        this.notValid = true;
        setTimeout(() => {
          this.notValid = false;
          this.router.navigateByUrl("/");
        }, 4000);
        setTimeout(() => {
          this.router.navigateByUrl("/");
        }, 4000);
      }
    });
  }

  // convenience getter for easy access to form fields
  onSubmit() {
    if (this.user.password != this.htmlOptions.confirm_password) {
      return;
    }
    let data = {
      token: this.token,
      password: this.user.password,
    };
    this.loading = true;
    //call login api here
    this.commonService.resetPass(data).subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          //this.message = result;
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;

          setTimeout(() => {
            this.authService.logout();
            this.router.navigateByUrl("/");
          }, 4000);
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
}
