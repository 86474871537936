<app-growler>
</app-growler>
<app-header></app-header>
<div class="wrapper">
    <div class="loaderOuter" *ngIf="loading">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
    <app-nav-bar></app-nav-bar>
    <div class="inner-container headerTopPaddipad">
        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-12">
                    <!-- <input type="text" class="projectTitleInputBox" [value]="projectSettingData.projectName"> -->
                    <span class="ptypespan">
                        <img *ngIf="projectSettingData.group==1" src="./assets/images/individual-sop-icon2.png" alt="icon" height="19">
                        <img *ngIf="projectSettingData.group==2" src="./assets/images/group-sop-icon2.png" alt="icon" height="19">
                    </span>
                    <h3 class="sfp_project-title"> {{projectSettingData.projectName}}</h3>



                    <!-- <a routerLink="/project-settings" title="Click here to go to project setting page"><i class="fa fa-cog mx-2" aria-hidden="true"></i></a> -->

                    <a href="javascript:void(0)" title="Click here to go to project setting page"><i class="fa fa-cog mx-2" aria-hidden="true" (click)="goToProjectSetting()"></i></a>

                    <a href="javascript:void(0)" title="Click here to edit project" (click)="editPage(projectSettingData.group, projectSettingData.projectType)"><i
                        class="fa fa-pencil mx-2" aria-hidden="true"></i></a>
                    <a href="javascript:void(0)" data-toggle="modal" data-target="#printModal"><i class="fa fa-download mx-2" aria-hidden="true" title="Click here to download report"></i></a>
                </div>
            </div>
        </div>
        <div>


            <div id="header" *ngIf="showheader" style="background-color: #fff; box-shadow: 0px 2px 5px 3px #cccccc; padding: 15px;">
                <table style="border: 0px; border-collapse: collapse; width: 100%; background: #ffffff; border: none; height:180px; margin-bottom:
            15px;">
                    <tr>
                        <td class="pdfHeaderProjectName" style="width: 33%; padding: 0 0px; box-sizing: border-box; border: none; text-align: left;">
                            <div>
                                <i style="padding: 5px; top: 5px;">
                                <img *ngIf="projectSettingData.group == 1" src="{{imageurl}}assets/images/individual-sop-icon2.png" alt="icon" style="vertical-align: middle; width: 35px;">
                                <img *ngIf="projectSettingData.group == 2" src="{{imageurl}}assets/images/group-sop-icon2.png" alt="icon"  style="vertical-align: middle; width: 35px;">
                            </i>{{projectSettingData.projectName}}
                            </div>
                        </td>
                        <td class="pdfheaderMid" style="width: 33%; padding: 0 15px; box-sizing: border-box; border: none; text-align: center;">

                            <h4 style="margin: 0px; color: #0b3762;">Stakeholder Feedback Report</h4>
                            <div style="width: 100%; text-align: center; ">
                                <table style="width: 100%;">
                                    <tr>
                                        <td style="width: 50%; text-align: center;">
                                            <div style="display: block; float: left; text-align: center; margin-top: 15px; width: 50%;">
                                                <span style="color: #103760; font-size: 14px;">{{currentMonth}}</span>
                                                <table style="border-collapse: collapse;">
                                                    <tr>
                                                        <td style="margin: auto; padding: 0px; display: block; background: rgb(4,59,110); background: #0b3762; height: 10px; width: 50px; margin-top: 10px;"></td>
                                                    </tr>
                                                </table>
                                            </div>

                                        </td>
                                        <td style="width: 50%; text-align: center;" *ngIf="showCompareMode && ProjectPeriodData.currentPeriod">
                                            <div style="display: block; float: left; text-align: center; margin-top: 15px; width: 50%;">
                                                <span style="color: #103760; font-size: 14px;">{{compareMonth}}</span>
                                                <table style="border-collapse: collapse;">
                                                    <tr>
                                                        <td style="margin: auto; padding: 0px; display: block; background: rgb(4,59,110); background: #9fd1ff; height: 10px; width: 50px; margin-top: 10px;"></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </table>


                            </div>
                        </td>
                        <td style="width: 33%; padding: 0 0px; box-sizing: border-box; border: none; text-align: right;">
                            <table style="width: 100%; border: none;">
                                <tr>
                                    <td class="proOwnerDetail" style="padding:0px 10px 0 0;  list-style: none; text-align: right; margin-top: 0px;">
                                        <p style="margin:0px">Project Owner: <span>{{projectOwnerData.ownerName}}</span></p>
                                        <p style="margin:0px">{{projectOwnerData.orgName}}</p>
                                        <p style="margin:0px">Date ({{today}})</p>
                                    </td>
                                    <td style="width: 100px; padding: 0px;">
                                        <div style="float: right; margin-left: 15px;"><img class height="70" style="height: 70px;" src="{{logoUrl}}"></div>
                                    </td>
                                </tr>
                            </table>

                        </td>
                    </tr>
                </table>


            </div>


            <div class="mb-3">
                <h3 class="sfp_project-title"> Stakeholder Feedback Report</h3>
            </div>
            <div class="white_box-body" id="contentToConvert">
                <div class="sfp_project_header-outer notinpdf">
                    <div class="row">
                        <div class="col-md-6">
                            <h3 class="sfp_project-title">
                                <span>Displaying period:
                            <div class="d-inline-block text-center" [ngClass]="{'currentMonth_Box' : showCompareMode}">
                                <p>{{currentMonth}} <span *ngIf="showCompareMode">(Current)</span></p>
                                <span class="progressPipe_currentbg current_Result_Bar-Bg" *ngIf="showCompareMode"></span>
                        </div>
                        <div class="d-inline-block text-center" *ngIf="showCompareMode && ProjectPeriodData.currentPeriod" [ngClass]="{'currentMonth_Box' : showCompareMode}">
                            <p>{{compareMonth}}</p>
                            <span class="progressPipe_currentbg compareMonth_Result_Bar-Bg"></span>
                        </div>
                        </span>
                        </h3>
                    </div>
                    <div class="col-md-6" *ngIf="htmlOptions.download">
                        <div class="row">
                            <div class="col-md-5 text-right">

                                <div class="switcherOuter-body mt-2">
                                    <span> Compare mode <!-- {{showCompareMode }}{{ disableFeedbackButton}} -->
                                    <span *ngIf="!showCompareMode">OFF</span>
                                    <span *ngIf="showCompareMode">ON</span>
                                    </span>
                                    <div class="switchToggle d-inline-block ml-2">
                                        <input type="checkbox" id="switch" (click)='handleCompare($event);'>
                                        <label for="switch"></label>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7" *ngIf="!showCompareMode">
                                <div class="switcherOuter-body mt-2">
                                    <span> Feedback Providers
                                <span *ngIf="!showFeedbackMode">OFF</span>
                                    <span *ngIf="showFeedbackMode">ON</span>
                                    </span>
                                    <div class="switchToggle d-inline-block ml-2">
                                        <input type="checkbox" id="switch1" (click)='handleFeedback($event);'>
                                        <label for="switch1"></label>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7" *ngIf="showCompareMode">
                                <div class="form-group row">
                                    <label for="inputState" class="col-sm-5 col-form-label text-right">Compare
                                        period</label>
                                    <div class="col-sm-7">
                                        <div class="input-group">
                                            <p-calendar [readonlyInput]="true" [minDate]="" [maxDate]="" placeholder="Select Period" [(ngModel)]="ProjectPeriodData.currentPeriod" name="currentPeriod" view="month" [showIcon]="false" dateFormat="MM/yy" class="monthCalendra" [yearNavigator]="true"
                                                yearRange="2020:2030" #currentPeriod="ngModel" (onSelect)="changePeriod($event, isDummyUser)"><span class="form-control"></span>
                                            </p-calendar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive pb-4 pr-4" *ngIf='showCompareMode'>
                <div class="sfpProject_table-outer">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="3" rowspan="2" class="text-left v-top percHeadWidth"><span>Perceptions of Key Leadership
                                        Capabilities &
                                        Behaviours</span></th>
                                <th colspan="4"> Summary </th>
                                <th colspan="10">Score Results</th>
                            </tr>
                            <tr>
                                <th><small style="font-size: 9px;">Current Period Mean</small></th>
                                <th><small style="font-size: 9px;">Compared Period Mean</small> </th>
                                <th><small style="font-size: 9px;">Delta</small></th>
                                <th><small style="font-size: 9px;">Trend</small></th>

                                <th><small style="font-size: 9px;">1</small></th>
                                <th><small style="font-size: 9px;">2</small></th>
                                <th><small style="font-size: 9px;">3</small></th>
                                <th><small style="font-size: 9px;">4</small></th>
                                <th><small style="font-size: 9px;">5</small></th>
                                <th><small style="font-size: 9px;">6</small></th>
                                <th><small style="font-size: 9px;">7</small></th>
                                <th><small style="font-size: 9px;">8</small></th>
                                <th><small style="font-size: 9px;">9</small></th>
                                <th><small style="font-size: 9px;">10</small></th>

                            </tr>

                        </thead>

                        <tbody>

                            <tr *ngFor="let project of projectData; let idx= index">
                                <td class="queOuterNo"><span class="text-center">{{idx+1}}</span></td>
                                <td class="queOuter"><span>{{project.question}}</span></td>
                                <td>
                                    <a *ngIf="htmlOptions.download" href="javascript:void(0)" data-toggle="tooltip" [title]="project.description"><i class="fa fa-question-circle" aria-hidden="true"></i></a>


                                </td>
                                <td>{{project.mean | number : '1.1-1'}}</td>
                                <td>{{project.secondaryMean| number : '1.1-1'}}</td>
                                <td>{{delta(project.mean, project.secondaryMean)| number : '1.1-1'}}</td>
                                <td>
                                    <img style="width:22px" *ngIf="trend(project.mean, project.secondaryMean) == 1" src="{{imageurl}}/trend-arrow-up.jpg" />
                                    <img style="width:22px" *ngIf="trend(project.mean, project.secondaryMean) == 2" src="{{imageurl}}/trend-arrow-right-up.jpg" />
                                    <img style="width:22px" *ngIf="trend(project.mean, project.secondaryMean) == 3" src="{{imageurl}}/trend-arrow-right.png" />
                                    <img style="width:22px" *ngIf="trend(project.mean, project.secondaryMean) == 4" src="{{imageurl}}/trend-arrow-right-down.jpg" />
                                    <img style="width:22px" *ngIf="trend(project.mean, project.secondaryMean) == 5" src="{{imageurl}}/trend-arrow-down.jpg" />
                                </td>
                                <td class="pdfSetting" colspan="10">
                                    <div class="scoreResult_bar-body">
                                        <div style="width: 100%; background: none;" class="scoreBarpipe mb-2" [ngClass]="[htmlOptions.download ? 'current_Result_Bar-Bg' : 'current_Result_Bar-Bg-pdf']" *ngIf='project.minval'>
                                            <table style="padding: 0px;position: relative;">
                                                <tr>
                                                    <!-- <td style="padding: 0px 10px 0 0; font-size: 10px; text-align: right; width:30px;" [ngStyle]="{'width.%': (project.minval*10)-4, 'width.%': calculateWidth(project.minval, project.maxval)}">{{project.minval| number : '1.1-1'}}</td> -->
                                                    <td style="padding: 0px 10px 0 0; font-size: 10px; text-align: right; width:30px;" [ngStyle]="{'width.%': calculateWidth(project.minval, project.maxval)}">{{project.minval| number : '1.1-1'}}</td>
                                                    <!-- <td [ngStyle]="{'left.%': (project.minval*10)-5, 'width.%': calculateWidth(project.minval, project.maxval)+1}" style="padding: 0px;"> -->
                                                    <td [ngStyle]="{'width.%': calculateWidth(project.minval, project.maxval)+1}" style="padding: 0px; position: relative;">
                                                        <!-- up -->
                                                        <span class="scoreBarpipe_Circle"></span>
                                                        <table style="width: 100%;">
                                                            <tr>
                                                                <td class="normalScrobarOuter" style="background: #003667; height: 10px; width: 50%;padding: 0px; border-radius: 5px 0px 0px 5px;"></td>
                                                                <td class="dNoneWebview" style="width: 10px; background: #F7A34F; border: 1px solid #fff;padding: 0px 5px;border-radius: 10px;"></td>
                                                                <td class="normalScrobarOuter" style="background: #003667; height: 10px; width: 50%;padding: 0px; border-radius: 0px 5px 5px 0px;"></td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td style="padding: 0px 0px 0 10px; font-size: 10px; text-align: left;">{{project.maxval| number : '1.1-1'}}</td>
                                                </tr>
                                            </table>

                                        </div>
                                        <div style="background: none; width: 100%;" class="scoreBarpipe compareMonth_Result_Bar-Bg" *ngIf='project.secondaryMinval'>
                                            <table style="position: relative;">
                                                <tr>
                                                    <!-- <td style="padding: 0px 10px 0 0; font-size: 10px; text-align: right; width:30px;" [ngStyle]="{'width.%': (project.secondaryMinval*10)-4, 'width.%': calculateWidth(project.secondaryMinval, project.secondaryMaxval)}">{{project.secondaryMinval| number : '1.1-1'}}</td> -->
                                                    <td style="padding: 0px 10px 0 0; font-size: 10px; text-align: right; width:30px;" [ngStyle]="{'width.%': calculateWidth(project.secondaryMinval, project.secondaryMaxval)}">{{project.secondaryMinval| number : '1.1-1'}}</td>
                                                    <td [ngStyle]="{'width.%': calculateWidth(project.secondaryMinval, project.secondaryMaxval)+1}" style="padding: 0px; position: relative;">
                                                        <!-- down -->
                                                        <span class="scoreBarpipe_Circle"></span>
                                                        <table style="width: 100%;">
                                                            <tr>
                                                                <td class="compareModScrobarOuter" style="background: #5281aa; height: 10px; width: 50%; padding: 0px; border-radius: 5px 0px 0px 5px;"></td>
                                                                <td class="dNoneWebview" style="width: 10px; background: #F7A34F; border: 1px solid #fff; padding: 0px 5px; border-radius: 10px; "></td>
                                                                <td class="compareModScrobarOuter" style="background: #5281aa; height: 10px; width: 50%; padding: 0px; border-radius: 0px 5px 5px 0px;"></td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td style="padding: 0px 0px 0 10px; font-size: 10px; text-align: left;">{{project.secondaryMaxval| number : '1.1-1'}}</td>
                                                </tr>
                                            </table>

                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
            <div class="table-responsive pb-4 pr-4" *ngIf='!showCompareMode'>
                <div class="sfpProject_table-outer">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="3" rowspan="2" class="text-left v-top percHeadWidth"><span>Perceptions of Key Leadership
                                        Capabilities &
                                        Behaviours</span> </th>
                                <th colspan="4"> Summary </th>
                                <th colspan="10">Score Results</th>
                                <th class="notinpdf" colspan="4" *ngIf="showFeedbackMode">Feedback Providers <label *ngIf="isDummyUser" class="addFeddProPlus"><i (click)="addDummyUsers() " class="fa fa-plus-circle " aria-hidden="true "></i></label></th>
                            </tr>
                            <tr>
                                <th style="width: 70px;"><small style="font-size: 9px;">Mean</small></th>
                                <th style="width: 70px;"><small style="font-size: 9px;">Median</small> </th>
                                <th style="width: 70px;"><small style="font-size: 9px;">Min</small></th>
                                <th style="width: 70px;"><small style="font-size: 9px;">Max</small></th>

                                <th><small style="font-size: 9px;">1</small></th>
                                <th><small style="font-size: 9px;">2</small></th>
                                <th><small style="font-size: 9px;">3</small></th>
                                <th><small style="font-size: 9px;">4</small></th>
                                <th><small style="font-size: 9px;">5</small></th>
                                <th><small style="font-size: 9px;">6</small></th>
                                <th><small style="font-size: 9px;">7</small></th>
                                <th><small style="font-size: 9px;">8</small></th>
                                <th><small style="font-size: 9px;">9</small></th>
                                <th><small style="font-size: 9px;">10</small></th>

                                <ng-container *ngIf="showFeedbackMode && !isDummyUser">
                                    <ng-container *ngFor='let user of userData'>
                                        <th *ngIf="(permission && permission.sfr_capture_feedback && user.id!=currentLogin) || ( permission && permission.sfr_provide_rating && user.id==currentLogin)" class="feedbackProInnWit notinpdf">
                                            <small>{{user.user_name}}</small>
                                        </th>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="showFeedbackMode && isDummyUser">
                                    <th *ngFor='let user of dummyUser' class="feedbackProInnWit notinpdf">
                                        <small *ngIf="user.id">{{user.user_name}}</small>
                                        <input class="feedBacProviders" *ngIf="!user.id" type="text" value="" placeholder="Enter user name" (keyup.enter)="addDummyUser($event.target.value)" (blur)="addDummyUser($event.target.value)">
                                    </th>
                                    <th>
                                    </th>
                                </ng-container>

                            </tr>

                        </thead>

                        <tbody>
                            <ng-container *ngFor="let project of projectData; let idx= index">
                                <tr class="{{project.id}}">
                                    <td class="queOuterNo"><span class="text-center">{{idx+1}}</span></td>
                                    <td class="queOuter"> <span>{{project.question}}</span></td>
                                    <td>
                                        <a *ngIf="htmlOptions.download" href="javascript:void(0)" data-toggle="tooltip" [title]="project.description"><i
                                            class="fa fa-question-circle" aria-hidden="true"></i></a>

                                    </td>

                                    <td>{{project.mean| number : '1.1-1'}}</td>
                                    <td>{{median(project.userRating)| number : '1.1-1'}}</td>
                                    <td>{{project.minval| number : '1.1-1'}}</td>
                                    <td>{{project.maxval| number : '1.1-1'}}</td>

                                    <td class="pdfSetting" colspan="10">
                                        <div class="scoreResult_bar-body" *ngIf='project.minval'>
                                            <div style="width: 100%; background: none;" class="scoreBarpipe centerBar" [ngClass]="[htmlOptions.download ? 'current_Result_Bar-Bg' : 'current_Result_Bar-Bg-pdf']">
                                                <table style="border: none; position: relative; border-spacing: 0px; border-collapse: collapse; text-align: left">
                                                    <tr style="border: none; position: relative;">
                                                        <td style="padding: 0px; position: relative; border: none; text-align: left;" [ngStyle]="{'width.%': calculateWidth(project.minval, project.maxval)}">

                                                            <table class="pdfInnermarginminus" style="position: relative;">
                                                                <tr style="border: none;">
                                                                    <!-- <td style="padding: 0px 10px 0 0; font-size: 10px; text-align: right; width:30px;" [ngStyle]="{'width.%': (project.minval*10)-4, 'width.%': calculateWidth(project.minval, project.maxval)}">{{project.minval| number : '1.1-1'}}</td> -->
                                                                    <td style="padding: 0px 10px 0 0; font-size: 10px; text-align: right; width:30px;" [ngStyle]="{'width.%': calculateWidth(project.minval, project.maxval)}">{{project.minval| number : '1.1-1'}}</td>
                                                                    <td [ngStyle]="{'width.%': calculateWidth(project.minval, project.maxval)+1}" style="padding: 0px; position: relative;">
                                                                        <!-- single -->
                                                                        <span class="scoreBarpipe_Circle"></span>
                                                                        <table style="width: 100%;">
                                                                            <tr>
                                                                                <td class="normalScrobarOuter" style="background: #003667; height: 10px; width: 50%; padding: 0px; border-radius: 5px 0px 0px 5px;"></td>
                                                                                <td class="dNoneWebview" style="width: 10px; background: #F7A34F; border: 1px solid #fff; padding: 0px 5px;border-radius: 10px;"></td>
                                                                                <td class="normalScrobarOuter" style="background: #003667; height: 10px; width: 50%; padding: 0px; border-radius: 0px 5px 5px 0px;"></td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                    <td style="padding: 0px 0px 0 10x; font-size: 10px; text-align: left;">{{project.maxval| number : '1.1-1'}}</td>
                                                                </tr>
                                                            </table>

                                                        </td>
                                                    </tr>
                                                </table>

                                            </div>
                                        </div>
                                    </td>

                                    <ng-container *ngIf="showFeedbackMode  && !isDummyUser">
                                        <ng-container *ngFor='let user of userData'>
                                            <!-- user{{user.id}} -->
                                            <td *ngIf="(permission && permission.sfr_capture_feedback && user.id!=currentLogin) || (permission && permission.sfr_provide_rating && user.id==currentLogin)" (mouseover)="addClass(user.id,project.id)" id="{{user.id}}{{project.id}}" (mouseleave)="removeClass(user.id,project.id)"
                                                class="bgWhiteBrBt notinpdf tdonhover user{{user.id}}">

                                                <div class="dropdown" *ngIf="!getUserRating(user, project.userRating)">
                                                    <a class="p-3" href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBox()" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    </a>

                                                    <div class="dropdown-menu p-4" aria-labelledby="dropdownMenuLink">
                                                        <div class="divSlider slider">
                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                            </div>
                                                        </div>
                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange" id='range{{project.id}}{{user.id}}' class="" value="">
                                                        <textarea class="form-control" [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                        <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1" (click)="addRating(project.id,user.id, feedback.text, project.isDummyUser)">Submit</a>
                                                    </div>
                                                </div>

                                                <div class="dropdown" *ngIf="getUserRating(user, project.userRating)">
                                                    <a href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBoxrating(getUserRating(user, project.userRating),getUserComments(user, project.userRating))" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span class="m-0 text-center" *ngIf="getUserRating(user, project.userRating)">{{getUserRating(user, project.userRating) | number : '1.1-1'}}</span>
                                                    </a>
                                                    <div class="dropdown-menu p-4" aria-labelledby="dropdownMenuLink">
                                                        <div class="divSlider slider">
                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                            </div>
                                                        </div>
                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange" id='range{{project.id}}{{user.id}}' class="" value="feedback.range">
                                                        <textarea class="form-control" [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                        <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1" (click)="addRating(project.id,user.id, feedback.text, project.isDummyUser)">Submit</a>
                                                    </div>
                                                    <!-- <div class="dropdown-menu p-2" aria-labelledby="dropdownMenuLink">

                                                            <div class="table-responsive normal_table-outer">
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center">Comments</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="text-left">{{getUserComments(user, project.userRating)}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div> -->
                                                </div>
                                                <!-- <label class="m-0 text-center" *ngIf="getUserRating(user, project.userRating)">{{getUserRating(user, project.userRating) | number : '1.1-1'}}</label> -->
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="showFeedbackMode  && isDummyUser">
                                        <ng-container *ngFor='let user of dummyUser'>
                                            <!-- (mouseover)='over(user.id)' (mouseout)='out(user.id)' -->
                                            <td (mouseover)="addClass(user.id,project.id)" (mouseleave)="removeClass(user.id,project.id)" class="bgWhiteBrBt notinpdf tt user{{user.id}}">

                                                <div class="dropdown" *ngIf="!getUserRating(user, project.userRating)">
                                                    <a class="p-3" href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBox()" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    </a>

                                                    <div class="dropdown-menu p-4" aria-labelledby="dropdownMenuLink">
                                                        <div class="divSlider slider">
                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                            </div>
                                                        </div>
                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange" id='range{{project.id}}{{user.id}}' class="" value="">
                                                        <textarea class="form-control" [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                        <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1" (click)="addRating(project.id,user.id, feedback.text, project.isDummyUser)">Submit</a>
                                                    </div>
                                                </div>

                                                <div class="dropdown" *ngIf="getUserRating(user, project.userRating)">
                                                    <a href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBoxrating(getUserRating(user, project.userRating),getUserComments(user, project.userRating))" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span class="m-0 text-center" *ngIf="getUserRating(user, project.userRating)">{{getUserRating(user, project.userRating) | number : '1.1-1'}}</span>
                                                    </a>

                                                    <div class="dropdown-menu p-4" aria-labelledby="dropdownMenuLink">
                                                        <div class="divSlider slider">
                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                            </div>
                                                        </div>
                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange" id='range{{project.id}}{{user.id}}' class="" value="">
                                                        <textarea class="form-control" [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                        <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1" (click)="addRating(project.id,user.id, feedback.text, project.isDummyUser)">Submit</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <td></td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </tbody>

                    </table>
                    <div class="notinpdf text-right mt-2" *ngIf="projectData.length">
                        <button (click)="exitwindow()" class="btn btn-danger btn-sm px-4">Exit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<div data-backdrop="true" class="modal fade" id="printModal" tabindex="-1" role="dialog" aria-labelledby="printModal" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">

        <div class="modal-content">
            <div class="modal-header">

                <button type="button" id="printHide" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body py-4">
                <form class="" name="form" #forprint="ngForm" (ngSubmit)="forprint.form.valid &&captureScreen()" novalidate>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="py-6 buttonPOsSetBt">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Page Size<span class="req">*</span></label>
                                            <select class="form-control" name="pageSize" [(ngModel)]="priorities.pageSize" #pageSize="ngModel" id="pageSize" (change)="changeSize($event.target.value)" required>
                                                <option value=''>Please select</option>
                                                <option>A3</option>
                                                <option>A4</option>
                                            </select>
                                            <div *ngIf="forprint.submitted && !priorities.pageSize" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Page Size field is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-right">
                                        <button type="submit" id="closeModal" class="btn btn-primary themeBtnBlue">Download</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div data-backdrop="true" class="modal fade" id="downloadModal" tabindex="-1" role="dialog" aria-labelledby="downloadModal" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body py-4 text-center">
                <button type="button" id="" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="mb-3">Download Report</h4>
                <p>You download will start automatically.<br>If not please <a href="" id="downloadView" download><strong>Click here</strong></a> to start download.</p>
                <p><span id="time"></span></p>
            </div>
        </div>
    </div>
</div>