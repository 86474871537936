<app-growler>
</app-growler>
<app-header></app-header>
<div class="wrapper">
    <div class="loaderOuter" *ngIf="loading">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
    <app-nav-bar></app-nav-bar>
    <div class="inner-container headerTopPaddipad">
        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-10 col-xl-8">
                    <span class="ptypespan">
                        <img *ngIf="gType == 1" src="./assets/images/individual-sop-icon.png" alt="icon" height="19">
                        <img *ngIf="gType == 2" src="./assets/images/group-sop-icon.png" alt="icon" height="19">
                    </span>
                    <h3 class="sfp_project-title"> {{projectDetail.title}}</h3>
                    <!-- routerLink="/project-settings" -->

                    <a (click)="goToProjectSetting(projectDetail.id,projectDetail.title,projectDetail.project_type)"><i class="fa fa-cog mx-2" aria-hidden="true" title="Click here to go to project setting page"></i></a>
                    <a href="javascript:void(0)" title="Click here to view project" (click)="editPage(projectDetail.group, projectDetail.projectType)"><i class="fa fa-eye mx-2"
                            aria-hidden="true"></i></a>
                </div>
            </div>
        </div>

        <div class="strategic_priority-outer">
            <div class="strategic_priority-content" id="mainBox" class="sortable">
                <table *ngIf="strategicPriorityTitleList.length==0" class="strategic_priority-Table">
                    <thead>
                        <tr>
                            <th>
                                <div class="dropdown">
                                    <a href="javaScript:void()" class="dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a class="dropdown-item" (click)="addFirststrategicPriority(0)" href="javascript:void(0)"><i
                        class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      Create New Strategic Priority
                    </a>

                                    </div>
                                </div>
                            </th>

                            <th class="titleThIcon pl-0">
                                <input *ngIf="strategicGroup.editstrategicPriorityText" #priorityTitle id="editstrategicPriorityText0" type="text " (blur)="addStrategicPriority(0,0,priorityTitle)" maxlength="30 " class="form-control " placeholder="Strategic priority title " required>
                            </th>

                        </tr>
                    </thead>

                </table>

                <div *ngFor="let strategic of strategicPriorityTitleList; index as sgIndex" class="table-responsive">
                    <!-- {{sgIndex}} -->

                    <input type="hidden" value="{{strategic.id}}" class="hiddendata">
                    <table class="time_management_personal-table strategic_priority-Table mb-2 ">
                        <thead>
                            <tr>
                                <th>
                                    <div class="dropdown ">
                                        <a href="javaScript:void()" class="dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
                                        </a>

                                        <div class="dropdown-menu " aria-labelledby="dropdownMenuLink ">
                                            <a class="dropdown-item " *ngIf="renameStrategicDropdown && strategic.id" href="javascript:void(0) " (click)="renameStrategicPriority(sgIndex,strategic.id,strategic.title) "><i
                          class="fa fa-clipboard " aria-hidden="true "></i> Rename Strategic
                        Priority Group</a>

                                            <a *ngIf="showHidePriorityGroup" class="dropdown-item " (click)="addMorestrategicPriority(sgIndex+1) " href="javascript:void(0) "><i
                          class="fa fa-pencil-square-o " aria-hidden="true "></i> Create New Strategic
                        Priority
                      </a>

                                            <a class="dropdown-item " *ngIf="ownerDropdown && strategic.InitativeData" (click)="addCreateOwner(sgIndex,strategic.id) " href="javascript:void(0) "><i class="fa fa-user-plus " aria-hidden="true "></i> Create New Owner
                        Column</a>
                                            <!-- *ngIf="sgIndex==0" -->
                                            <a class="dropdown-item " href="javascript:void(0)" (click)="addExtraTextColumn(sgIndex)"><i
                          class="fa fa-clipboard " aria-hidden="true "></i>
                        Create New Text Column (eg. Strategic Rationale)</a>


                                            <a data-toggle="modal " (click)="deleteStrategicPriority(strategic.id) " class="dropdown-item " href="javascript:void(0) "><i class="fa fa-clipboard " aria-hidden="true "></i> Delete this
                        Strategic Priority Group</a>

                                        </div>
                                    </div>
                                </th>
                                <th class="titleThIcon pl-0 ">
                                    <a style="float: left;" class="moveIcon" href="javascript:void(0)" title="Move Strategic Priority Group">
                                        <i class="fa fa-ellipsis-v " aria-hidden="true "></i>
                                        <i class="fa fa-ellipsis-v " aria-hidden="true "></i>
                                    </a>

                                    <input *ngIf="!strategic.id" id="editstrategicPriorityText{{sgIndex}}" style="margin-top: -10px;" type="text " (blur)="addStrategicPriority(sgIndex,strategic.id,'')" maxlength="30 " class="form-control
                                    " placeholder="Strategic priority title " [(ngModel)]="strategicPriorityTitleList[sgIndex].title " required>

                                    <input [hidden]="true" *ngIf="strategic.id" id="editstrategicPriorityTextEdit{{sgIndex}}" type="text " (blur)="addStrategicPriority(sgIndex,strategic.id,'')" maxlength="30 " class="form-control
                                    " placeholder="Strategic priority title " [(ngModel)]="strategicPriorityTitleList[sgIndex].title " required>

                                    <label *ngIf="strategic.id && !renameStrategic" id="editstrategicPriorityLabel{{sgIndex}}"><strong>&nbsp;
                                        {{strategic.title}}</strong></label>
                                </th>

                                <ng-container *ngIf="this.strategicPriorityTitleList['ExtraColumn'] ">
                                    <!-- {{changeObjToArray(this.strategicPriorityTitleList[ 'ProjectPeriod']) |json }} {{sgIndex}} -->
                                    <th class="repeatHeadTHFixTop text-center" *ngFor="let extra of changeObjToArray(this.strategicPriorityTitleList['ExtraColumn']); index as extrai ">
                                        <input *ngIf="sgIndex==0 && !extra.id" [(ngModel)]="extra.title" type="text " id="editstrategicPriorityextratext{{extrai}}" (blur)="addUpdateExtraColumn(extrai,'0',$event.target.value,strategic.id)" maxlength="30 " class="form-control text-center " style="margin-top: -10px;"
                                            placeholder="Enter text here" required>
                                        <label id="{{extra.id}}"><strong *ngIf="sgIndex==0 && extra.id">{{extra.title}}</strong></label>

                                        <input class="hideBlock" id="extratext{{extra.id}}" type="text" (blur)="addUpdateExtraColumn(extrai,extra.id,$event.target.value,strategic.id)">

                                        <a *ngIf="extra.id" href="javaScript:void(0)" class="periodDeleteBtn" (click)="deleteExtraTextColumn(extra.id)">
                                            <span class="fa fa-minus"></span> </a>
                                        <!-- <a href="javaScript:void(0)" class="extraDeleteBtn"> <span class="fa fa-trash"></span> </a> -->
                                    </th>
                                </ng-container>
                                <th style="max-width: 100px !important;width: 100px !important;min-width: 100px !important;" *ngIf="isOwner" class="repeatHeadTHFixTop text-center"><label>Owner</label>
                                    <a href="javaScript:void(0)" class="periodDeleteBtn" (click)="deleteOwnerColumn(isOwner)">
                                        <span class="fa fa-minus"></span> </a>
                                </th>
                                <ng-container *ngIf="this.strategicPriorityTitleList['ProjectPeriod'] ">
                                    <th class="repeatHeadTHFixTop text-center" *ngFor="let period of changeObjToArray(this.strategicPriorityTitleList['ProjectPeriod']); index as periodi ">
                                        <input *ngIf="sgIndex==0 && !period.id" [(ngModel)]="period.title " type="text " id="editstrategicPriorityPeriodtext{{periodi}}" (blur)="addUpdateProjectPeriod(periodi,'0',$event.target.value,strategic.id) " maxlength="30 " class="form-control " style="margin-top: -10px;"
                                            placeholder="Enter period here" required>

                                        <label id="{{period.id}}"><strong *ngIf="sgIndex==0 && period.id">{{period.title}}</strong></label>

                                        <input class="hideBlock" id="periodtext{{period.id}}" type="text" (blur)="addUpdateProjectPeriod(periodi,period.id,$event.target.value,strategic.id)">

                                        <a *ngIf="period.id" href="javaScript:void(0)" class="periodDeleteBtn" (click)="deleteTaskPeriod(period.id)">
                                            <span class="fa fa-minus"></span> </a>
                                    </th>
                                </ng-container>

                                <th class="repeatHeadTHFixTop" style="min-width: 35px; width: 35px !important; vertical-align: top; padding: 3px 10px !important;">
                                    <label><i
                      (click)="strategicPeriods(sgIndex) " class="fa fa-plus-circle " aria-hidden="true "></i></label>
                                </th>



                                <th *ngIf="sgIndex==0 " style="max-width: 200px !important;width: 200px !important;min-width: 200px !important;" class="repeatHeadTHFixTop"><label>What success looks like...</label></th>



                            </tr>
                        </thead>

                        <tbody class="strategic_priority-tbody">

                            <ng-container *ngIf="strategic.InitativeData !=null ">

                                <tr class="sortabletask" *ngFor="let Initiative of changeObjToArray(strategic.InitativeData); index as i;" id="init-{{Initiative.id}}">
                                    <td></td>

                                    <td *ngIf="!Initiative.id" class="disableResizeDrag brleftBlue ">
                                        <input (blur)="addUpdatePriorityInitative(sgIndex,i,strategic.id,'title') " [(ngModel)]="Initiative.title " maxlength="30 " required type="text " placeholder="+ Initiative Title" value="{{Initiative.title}}">
                                    </td>

                                    <td *ngIf="Initiative.id" class="disableResizeDrag brleftBlue ">
                                        <label id="lblIni{{Initiative.id}}" (click)="showEditFunction(Initiative.id)">{{Initiative.title}}</label>
                                        <input id="txtIni{{Initiative.id}}" (blur)="addUpdatePriorityInitative(sgIndex,i,strategic.id,'title') " [(ngModel)]="Initiative.title " maxlength="30 " required type="text " placeholder="+ Initiative Title" value="{{Initiative.title}}" class="hiddenStatus">
                                    </td>


                                    <ng-container *ngIf="this.strategicPriorityTitleList['ExtraColumn']">
                                        <ng-container *ngFor="let extraData of changeObjToArray(this.strategicPriorityTitleList['ExtraColumn']); index as i ">
                                            <td *ngIf="Initiative.extraFields && Initiative.extraFields[extraData.id]!=undefined" class="disableResizeDrag">
                                                <label *ngIf="Initiative.extraFields[extraData.id]">{{Initiative.extraFields[extraData.id]?Initiative.extraFields[extraData.id].title:''}}</label>
                                            </td>

                                            <td *ngIf="Initiative.extraFields==undefined || Initiative.extraFields[extraData.id]==undefined" class="disableResizeDrag" id="0-{{extraData.id}}-''">
                                                <input #extra type="text " maxlength="200 " (blur)="addExtraColumnValue(i,extraData.id,Initiative.id,strategic.id,extra)" class="form-control " placeholder="Enter description here">
                                            </td>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="isOwner">
                                        <td style="max-width: 100px !important;width: 100px !important;min-width: 152px !important;" class="disableResizeDrag width-min-max-190" *ngIf="Initiative.userId || Initiative.userId==null">
                                            <input type="hidden" value="{{Initiative.userId}}">
                                            <input #owner type="text" [(ngModel)]="Initiative.userId" (blur)="addUpdatePriorityInitative(sgIndex,i,strategic.id,'selectedUser') " maxlength="30 " class="form-control " style="min-width: 130px !important;" placeholder="Enter owner name">
                                            <!--  <select (blur)="addUpdatePriorityInitative(sgIndex,i,strategic.id,'selectedUser')" class="form-control" name="someName" [(ngModel)]="Initiative.userId">
                                            <option
                                            [selected]="Initiative.userId == user.id || (Initiative.userId == null && user.id=='-1') || (Initiative.userId == '' && user.id=='-1') || Initiative.userId == null"
                                            *ngFor="let user of searchUsers" [value]="((Initiative.userId == null || Initiative.userId) == '' ? '-1' : user.id)">{{ user.user_name }}</option>
                                        </select> -->

                                        </td>
                                    </ng-container>

                                    <!-- {{this.strategicPriorityTitleList['ProjectPeriod'] | json}} -->
                                    <ng-container *ngFor="let period of changeObjToArray(this.strategicPriorityTitleList['ProjectPeriod']); index as i; let last = last">

                                        <ng-container *ngIf="Initiative.period[period.id] && period.id !=0">
                                            <ng-container *ngIf="initiativeFunc(Initiative.period,period.id)">

                                                {{setColspanValue(Initiative.id,Initiative.period,period.id,i)}}

                                                <td class="ui-state-enabled" id="{{Initiative.period[period.id].Task.id}}-{{period.id}}-{{Initiative.period[period.id]?Initiative.period[period.id].Task.title:''}}" [attr.colspan]="colsapnValue" [ngClass]="{'disableResizeDrag' : last}">
                                                    <!-- hello -->
                                                    <div class="dragDivOuter">
                                                        <input type="hidden" id="updateperiodid{{Initiative.period[period.id].Task.id}}-{{period.id}}" class="getPeriodId" value="{{period.id}}">
                                                        <input type="hidden" class="getTaskId" value="{{Initiative.period[period.id]?Initiative.period[period.id].Task.id:0}}">
                                                        <label>{{Initiative.period[period.id]?Initiative.period[period.id].Task.title:''}}</label>
                                                        <!-- <input #task type="text" (blur)="addUpdateProjectTask(i,period.id,Initiative.id,strategic.id,task) " maxlength="30 " class="form-control " required placeholder="Enter task hereddf2 " required> -->
                                                    </div>
                                                </td>

                                            </ng-container>
                                        </ng-container>
                                        <td class="ui-state-enabled" id="0-{{period.id}}-''" *ngIf="!Initiative.period[period.id] || period.id ==0" [ngClass]="{'disableResizeDrag' : last}">
                                            <!-- *ngIf="!Initiative.period[period.id] " -->
                                            <div class="dragDivOuter">
                                                <input type="hidden" class="getPeriodId" value="{{period.id}}">
                                                <input type="hidden" class="getTaskId" value="{{Initiative.period[period.id]?Initiative.period[period.id].Task.id:0}}">
                                                <input #task type="text " (blur)="addUpdateProjectTask(i,period.id,Initiative.id,strategic.id,task) " maxlength="30 " class="form-control " placeholder="Enter task here">
                                            </div>
                                        </td>

                                    </ng-container>
                                    <td class="dragDivOuter width-min-max-35"></td>
                                    <td class="disableResizeDrag " style="max-width: 200px !important;width: 200px !important;min-width: 200px !important;"><input type="text " maxlength="200" (blur)="addUpdatePriorityInitative(sgIndex,i,strategic.id,'desc') " [(ngModel)]="Initiative.description " required placeholder="Enter Description here ">
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <!-- </span> -->
                    <!-- {{addMorestrategicInitiative(sgIndex,strategic.id)}} -->
                </div>
            </div>
        </div>
    </div>