import { Component, OnInit, Pipe } from "@angular/core";
import { CommonService } from "../../services/commonservices";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../auth/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GrowlerService } from "../../services/growler.service";
import * as $ from "jquery";
import "jquery-ui-dist/jquery-ui";
 import * as jspdf from "jspdf";

import html2canvas from "html2canvas";

@Component({
  selector: "app-project-view",
  templateUrl: "./project-view.component.html",
  styleUrls: ["./project-view.component.scss"],
})
export class ProjectViewComponent implements OnInit {
  public showLoder: boolean = true;
  public sidebarToggle: boolean = false;
  public userColHide: boolean = false;
  globalRoles: any = [];
  permission: any = [];
  notValid: boolean = false;
  validTrue: boolean = false;
  projectData: any = [];
  ratingAndComment: any = [];
  ProjectPeriod: any = [];
  ExtraColumn: any = [];
  projectDetail: any = [];
  colsapnValue: any = 1;
  mergeTdRemove: number;
  feedback: any = {};
  htmlOptions: any = {};
  loading: boolean = false;
  message: any;
  pType: any;
  gType: any;
  activeProjectId: any;
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public growlerService: GrowlerService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.activeProjectId = localStorage.getItem("editProjectId");
    this.pType = localStorage.getItem("pType");
    this.gType = localStorage.getItem("gType");
    this.getProjectData(this.activeProjectId);
    this.setHtmlOptions();
    this.customeSlider();
    let roles = localStorage.getItem("roles");
    this.getPermissionByRole(roles);
    this.getUserPermissionOnProject(this.activeProjectId);
    $("body").on("click", ".dropdown-menu", function (e) {
      $(this).parent().is(".show") && e.stopPropagation();
    });
  }
  editPage(is_group, project_type) {
    var projType = "global_edit_proj";
    if (this.checkPermission(projType, this.permission)) {
      alert("You do not have permission to edit project");
      return false;
    }
    // localStorage.setItem("editProjectId", this.activeProjectId);
    // localStorage.setItem("pType", project_type);
    // localStorage.setItem("gType", is_group);
    this.router.navigateByUrl("/project-create");
  }
  getPermissionByRole(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = result["data"].ResponseData;
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  checkPermission(permissionKey, permissions) {
    if (
      (permissions != null &&
        (permissions[permissionKey] === undefined ||
          !permissions[permissionKey])) ||
      (permissions === null && this.globalRoles[permissionKey] === undefined)
    ) {
      return true;
    }
  }
  setHtmlOptions() {
    this.htmlOptions = {
      individualMode: true,
      averageMode: false,
      medianMode: false,
      adjustedMode: false,
      download: true,
    };
  }
  customeSlider() {
    $(function () {
      var handle = $(".custom-handle");
      $(".slider").slider({
        orientation: "horizantal",
        range: "min",
        value: 1,
        step: 0.5,
        min: 1.0,
        max: 10.0,
        animate: "fast",
        create: function () {
          handle.text($(this).slider("value"));
          handle.addClass(
            "rating" + Math.round($(this).slider("value")) + "bg"
          );
        },
        slide: function (event, ui) {
          handle.text(ui.value);
          handle.removeClass("rating" + (Math.round(ui.value) - 1) + "bg");
          handle.addClass("rating" + Math.round(ui.value) + "bg");
          var attrId = $(this)
            .closest(".dropdown-menu.p-3")
            .find('input[name="feedbackRange"]')
            .attr("id");
          $("#" + attrId).val(ui.value);
        },
      });
    });
  }
  addRating(taskId: any, pId: any, commntes: any) {
    var projType = "sop_provide_rating";
    if (this.checkPermission(projType, this.permission)) {
      alert("You do not have permissions to rate the tasks");
      return false;
    }
    let projectId = this.activeProjectId;
    let rating = $("#range" + taskId + pId).val();
    if (rating == 0) {
      rating = 1;
    }
    this.addRatingAndCommentTask(taskId, rating, pId, commntes, projectId);
  }
  addRatingAndCommentTask(taskId, rating, pId, commntes, projectId) {
    let data = {
      taskId: taskId,
      rating: rating,
      periodId: pId,
      comment: commntes,
      projectId: projectId,
    };

    this.loading = true;
    //call login api here
    this.commonService.addRatingAndCommentTask(data).subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.ngOnInit();
          this.feedback.text = "";
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
  }
  blankBox() {
    this.feedback.text = "";
    $(".custom-handle").text(1);
  }
  getProjectData(activeProjectId) {
    let data = {
      projectId: activeProjectId, //58
    };
    this.loading = true;
    //call login api here
    this.commonService.getProjectData(data).subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          let projectData = this.changeObjToArray(
            result["data"]["ResponseData"]["ProjectData"]
          );
          //Sort
          this.customeSlider();
          this.ProjectPeriod = this.changeObjToArray(
            result["data"]["ResponseData"]["ProjectPeriod"]
          );
          this.ExtraColumn = this.changeObjToArray(
            result["data"]["ResponseData"]["ExtraColumn"]
          );
          this.projectData = Object.keys(projectData).map(function (
            personNamedIndex
          ) {
            let project = projectData[personNamedIndex];
            return project;
          });
          this.projectDetail = result["data"].ResponseData.Projectsetting;

          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
  }
  changeObjToArrayForTasks(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });

    return project;
  }
  changeObjToArray(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });
    return project;
  }
  changeObjToArraySort(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });

    project.sort(function (a, b) {
      var keyA = a.priority,
        keyB = b.priority;
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
    return project;
  }

  checkMergeColumn(init: any, periodId: number) {
    let currentId = init[periodId].periodId;
    let nextperiodId = currentId + 1;
    let manageColumn = 0;
    if (init[periodId]) {
      if (init[nextperiodId] != undefined) {
        if (init[currentId].Task.id == init[nextperiodId].Task.id) {
          this.colsapnValue = 2;
          manageColumn = 1;
        }
      }
    }
    return manageColumn;
  }
  setColspanValue(init: any, periodId: number, i) {
    let currentId = init[periodId].periodId;
    let nextperiodId: number;
    var colsapnValue = 1;
    if (
      $("#" + periodId)
        .parent("th")
        .next()
        .children("p")
        .attr("id") != undefined
    ) {
      nextperiodId = $("#" + periodId);
      nextperiodId = $("#" + periodId)
        .parent("th")
        .next()
        .children("p")
        .attr("id");
    }
    this.colsapnValue = 1;
    this.mergeTdRemove = 0;
    if (init[periodId]) {
      while (init[nextperiodId] != undefined) {
        if (init[nextperiodId] != undefined) {
          if (init[currentId].Task.id == init[nextperiodId].Task.id) {
            colsapnValue++;
            if (
              $("#" + nextperiodId)
                .parent("th")
                .next()
                .children("p")
                .attr("id") != undefined
            ) {
              nextperiodId = $("#" + nextperiodId)
                .parent("th")
                .next()
                .children("p")
                .attr("id");
            } else {
              break;
            }
          } else {
            break;
          }
        } else {
          break;
        }
      }
    }
    return colsapnValue;
  }
  hideDivNextTime(inIt, pId) {
    let PrePerIodId: number;
    if (
      $("#" + pId)
        .parent("th")
        .prev()
        .children("p")
        .attr("id") != undefined
    ) {
      PrePerIodId = $("#" + pId)
        .parent("th")
        .prev()
        .children("p")
        .attr("id");
    }
    //return
    let aTaskId: any;
    let bTaskId: any;
    if (inIt[PrePerIodId] != undefined) {
      aTaskId = inIt[pId].Task.id;
      bTaskId = inIt[PrePerIodId].Task.id;
      if (aTaskId == bTaskId) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
    //console.log('======');
  }
  counter(i: number) {
    if (i && i > 1) {
      return new Array(i);
    }
  }
  getNextId(pId) {
    let PrePerIodId: number;
    if (
      $("#" + pId)
        .parent("th")
        .next()
        .children("p")
        .attr("id") != undefined
    ) {
      PrePerIodId = $("#" + pId)
        .parent("th")
        .next()
        .children("p")
        .attr("id");
    }
    return PrePerIodId;
  }
  getRatingAndComment(taskId, periodId) {
    let data = {
      taskId: taskId,
      periodId: periodId,
    };

    this.loading = true;
    //call login api here
    this.commonService.getRatingAndComment(data).subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
          this.ratingAndComment = result["data"].ResponseData[0];
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
    this.loading = false;
    this.sidebarToggle = true;
    this.userColHide = true;
  }

  changeProjectType(metricType) {
    if (metricType.target.value == 1) {
      this.htmlOptions = {
        individualMode: false,
        averageMode: true,
        medianMode: false,
        adjustedMode: false,
      };
    } else if (metricType.target.value == 2) {
      this.htmlOptions = {
        individualMode: false,
        averageMode: false,
        medianMode: true,
        adjustedMode: false,
      };
    } else if (metricType.target.value == 3) {
      this.htmlOptions = {
        individualMode: false,
        averageMode: false,
        medianMode: false,
        adjustedMode: true,
      };
    } else {
      this.htmlOptions = {
        individualMode: true,
        averageMode: false,
        medianMode: false,
        adjustedMode: false,
      };
    }
    this.htmlOptions.download = true;
    this.customeSlider();
  }
  averageRating(allRatings) {
    const allRatingsArr = this.changeObjToArray(allRatings);
    const arrAvg =
      allRatingsArr.reduce((a, b) => a + b, 0) / allRatingsArr.length;
    return Math.round(arrAvg);
  }
  getRound(rating) {
    return Math.round(rating);
  }
  medianRating(allRatings) {
    const values = this.changeObjToArray(allRatings);
    if (values && values.length < 5) {
      return "N/A";
    } else {
      const mid = Math.floor(values.length / 2),
        nums = [...values].sort((a, b) => a - b);
      return Math.round(
        values.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2
      );
    }
  }
  adjustedMode(allRatings) {
    const values = this.changeObjToArray(allRatings);
    if (values && values.length < 5) {
      return "N/A";
    } else {
      var maxValue = Math.max(...values);
      var minValue = Math.min(...values);
      const arrAvg = values.reduce((a, b) => a + b, 0);
      const adjusted = (arrAvg - maxValue - minValue) / values.length - 2;
      return Math.round(adjusted);
    }
  }
  closeCommentPopup() {
    this.sidebarToggle = false;
    this.userColHide = false;
  }

  userColHideShow() {
    this.userColHide = !this.userColHide;
  }
  captureScreen() {
    this.htmlOptions.download = false;
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 500);
    var data = $("#contentToConvert").get(0);
    // var data = document.getElementById("contentToConvert");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("l", "mm", "a3"); // A4 size page of PDF
      var position = 0;

      const elementToPrint = document.getElementsByTagName("body"); //The html element to become a pdf
      var self = this
      pdf.addHTML(elementToPrint, () => {
        self.htmlOptions.download = true;
        pdf.save(this.projectDetail.title + ".pdf");
      });

      //pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      /*  pdf.fromHTML($('body').get(0), 15, 15, {
         width: 170,
         elementHandlers: contentDataURL,
       });
      pdf.save(this.projectDetail.title + ".pdf"); */ // Generated PDF
    });
  }
  getUserPermissionOnProject(projectId) {
    var data = {
      projectId: projectId,
    };
    this.commonService.apiCall(data, "getUserPermissionOnProject").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.permission = result["data"].ResponseData;
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
}



