<app-growler>
</app-growler>
<app-header></app-header>

<div class="loaderOuter" *ngIf="showLoder">
    <div class="lds-ripple">
        <div></div>
        <div></div>
    </div>
</div>

<div class="wrapper">
    <app-nav-bar></app-nav-bar>
    <div class="inner-container">

        <div class="white_box-body">

            <h2>
                <a href="javaScript:void()" (click)="sectionToggle1 = !sectionToggle1"> <i class="fa fa-angle-down" aria-hidden="true"></i> Active Projects </a>
            </h2>
            <div *ngIf="activeProjectList.length == 0">
                <div class="col-md-12">
                    <div class="text-center no-recod-box">
                        <p>No record found </p>
                    </div>
                </div>
            </div>
            <div class="white_box-content py-0" [ngClass]="{'d-none' : !sectionToggle1}">
                <div class="row">
                    <div class="col-md-12">
                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" slidesToShow="4" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)">
                            <div class="slide" ngxSlickItem *ngFor="let project of activeProjectList">
                                <div class="white_box-content-box ">
                                    <div (click)="activeProjectOnClick(project.id,project.is_group,project.project_type, project)" class="white_box-content-box-TopContent activeProjectClick">
                                        <p class="text-center projectNameEql"><b>{{(project.title.length>40)? (project.title | slice:0:40)+'..':(project.title)}}</b></p>
                                        <div class="text-center centerIcon">
                                            <img *ngIf="project.is_group == 1" src="./assets/images/individual-sop-icon.png" alt="icon">
                                            <img *ngIf="project.is_group == 2" src="./assets/images/group-sop-icon.png" alt="icon">
                                            <p class="text-bold">
                                                <span *ngIf="project.is_group == 1">Individual</span>
                                                <span *ngIf="project.is_group == 2">Group</span>
                                                <span *ngIf="project.project_type == 1">  SOP</span>
                                                <span *ngIf="project.project_type == 2">  SFR</span>
                                            </p>

                                        </div>
                                        <p class="mb-0"><small><strong>Owner : </strong>{{project.owner_name }}</small></p>

                                    </div>
                                    <div class="white_box-content-box-footer" [ngClass]="(project.project_type == 2)?'orangeBg':'greenBg'">
                                        <h3 class="dropdown"><span *ngIf="project.project_type == 1">Strategic Operating <br>Plan</span>
                                            <span *ngIf="project.project_type == 2">Stakeholder Feedback  <br>Report</span>
                                            <a href="javaScript:void()" class="dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                            </a>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                <!-- <a class="dropdown-item" (click)="activeProjectOnClick(project.id,project.is_group,project.project_type, project)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> View Project</a> -->

                                                <a class="dropdown-item" (click)="goToProjectSetting(project.id,project.title,project.project_type,project)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit Project Setting</a>
                                                <a class="dropdown-item" href="javascript:void(0)" (click)="copyProject(project.id,project.project_type,project)"><i class="fa fa-clipboard"></i> Copy Project</a>
                                                <a *ngIf="!project.is_dummy_user" class="dropdown-item" href="javascript:void(0)" (click)="addUser(project.id,project.organisation_id,project.project_type, project.title,project)"><i class="fa fa-user-plus" aria-hidden="true"></i> Add user</a>
                                                <a class="dropdown-item" href="javascript:void(0)" (click)="deleteProject(project.id,project.project_type,project)"><i class="fa fa-archive" aria-hidden="true"></i> Archive project</a>
                                            </div>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>



                </div>
            </div>


        </div>

        <div class="white_box-body mt-4">
            <h2>
                <a href="javascript:void(0)" (click)="sectionToggle2 = !sectionToggle2"> <i class="fa fa-angle-down" aria-hidden="true"></i> Create from template </a>
            </h2>
            <div class="white_box-content" [ngClass]="{'d-none' : !sectionToggle2}">
                <div class="row createFromTemplateOuter">

                    <ng-container *ngFor="let template of templatesList">
                        <div class="col-sm-6 col-lg-4 col-xl-3 breakeAfterDiv text-center mb-3">
                            <a herf='javascript:void(0);' (click)="projectType(template.id,template.is_group,template.project_type)" class="white_box-content-box createFromTemplateBox">
                                <div class="white_box-content-box-TopContent">

                                    <div class="text-center centerIcon">
                                        <img *ngIf="template.is_group == 1" src="./assets/images/individual-sop-icon.png" alt="icon">
                                        <img *ngIf="template.is_group == 2" src="./assets/images/group-sop-icon.png" alt="icon">
                                        <h3 class="text-bold">
                                            <span *ngIf="template.is_group == 1">Individual </span>
                                            <span *ngIf="template.is_group == 2">Group </span>
                                            <span *ngIf="template.project_type == 1"> SOP</span>
                                            <span *ngIf="template.project_type == 2"> SFR</span>
                                        </h3>
                                    </div>

                                </div>
                                <div *ngIf="template.project_type == 1" class="white_box-content-box-footer greenBg">
                                    <h3>{{template.title}}</h3>
                                    <!-- <h3>Strategic Operating<br> Plan </h3> -->

                                </div>
                                <div *ngIf="template.project_type == 2" class="white_box-content-box-footer orangeBg">
                                    <h3>{{template.title}}</h3>
                                    <!-- <h3>Stakeholder Feedback <br>Report </h3> -->
                                </div>
                            </a>
                        </div>

                        <div class="col-md-12 colBreakShowOnly"> </div>

                    </ng-container>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <a (click)="createFromScratch()" class="createFromScratch"><i class="fa fa-plus" aria-hidden="true"></i> Create from scratch </a>
                    </div>
                </div>

            </div>
        </div>

    </div>



    <div class="input-group-text" data-toggle="modal" data-target="#addorganization" id="btnChangePassword"><i class="fa fa-plus"></i></div>

    <!-- Modal start here -->
    <div data-backdrop="false" class="modal fade" id="addorganization" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title m-0" id="exampleModalLabel">Change Password</h3>
                    <!-- {{userlist | json}} -->
                    <button [hidden]="true" type="button" id="buttonClosePop" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-4 buttonPOsSetBt">

                    <div class="min-height50vh">
                        <div class="row justify-content-center">
                            <div class="col-md-12 text-center">
                                <p></p>
                            </div>
                            <div class="col-10 col-lg-8 col-xl-5 mt-5">
                                <form class="form-horizontal form-material" name="form" #f="ngForm" (ngSubmit)="f.form.valid && onSubmitChangePass()" novalidate>

                                    <div class="form-group">
                                        <label for="exampleInputPassword1">Old Password</label>

                                        <input type="password" maxlength="80" class="form-control" name="old_password" [(ngModel)]="htmlOptions.old_password" #old_password="ngModel" trim="blur" [ngClass]="{ 'is-invalid': (f.submitted && !old_password.valid) }" required>

                                        <div *ngIf="f.submitted && !old_password.valid" class="invalid-feedback">
                                            <div>Old Password is required</div>
                                        </div>

                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputPassword1">Password</label>

                                        <input type="password" maxlength="80" class="form-control" name="password" [(ngModel)]="user.password" #password="ngModel" trim="blur" pattern="^[0-9,a-z,A-Z,@,#,$,^,!,%,&,*]{6,30}$" [ngClass]="{ 'is-invalid': (f.submitted && !password.valid) || (password.errors && password.errors.pattern) }"
                                            required>

                                        <div *ngIf="f.submitted && !password.valid && !password.errors.pattern" class="invalid-feedback">
                                            <div>Password is required</div>
                                        </div>
                                        <div *ngIf="password.errors && password.errors.pattern" class="invalid-feedback">
                                            <div>Password must contain at least 6 characters</div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="exampleInputPassword1">Confirm Password</label>

                                        <input type="password" maxlength="80" class="form-control" name="confirm_password" [(ngModel)]="htmlOptions.confirm_password" #confirm_password="ngModel" trim="blur" [ngClass]="{ 'is-invalid': (f.submitted && !confirm_password.valid) || (htmlOptions.confirm_password != user.password) }"
                                            required>

                                        <div *ngIf="f.submitted && !confirm_password.valid" class="invalid-feedback">
                                            <div>Confirm Password is required</div>
                                        </div>
                                        <div *ngIf="confirm_password.valid && htmlOptions.confirm_password != user.password" class="invalid-feedback">
                                            <div>Confirm password doesn't match with password</div>
                                        </div>
                                    </div>


                                    <button type="submit" class="btn btn-primary btn-block themeBtnBlue mt-5">Submit</button>
                                </form>
                                <div *ngIf="validTrue" class="alert alert-success mt-2">
                                    <strong>{{message}}</strong>
                                </div>
                                <div *ngIf="notValid" class="alert alert-danger mt-2">
                                    <strong>{{message}}.</strong>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!--Add User for project assign -->
<input type="hidden" data-toggle="modal" data-target="#addUserModal" id="btnAddUser" placeholder="+ Add New User" class="addNewUserTextBox">
<div data-backdrop="false" class="modal fade" id="addUserModal" tabindex="-1" role="dialog" aria-labelledby="addUserModal" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title m-0" id="addUserModal">Add New User</h3>
                <button type="button" id="addNewUserHide" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body py-4">
                <div class="row">
                    <div class="col-md-12">
                        <label>Select User<span class="req">*</span></label>
                        <div class="input-group mb-2 mr-sm-2">

                            <ng-select class="form-control" [items]="searchUsers" bindValue="id" bindLabel="fullDetails" placeholder="Select user" [(ngModel)]="selectedUser">
                            </ng-select>


                            <div class="input-group-prepend">
                                <div class="input-group-text" data-toggle="modal" (click)="selectedUserAdd()">Add</div>
                            </div>

                        </div>

                        <div *ngIf="notSelected" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User field is required
                        </div>


                        <div class="py-6 buttonPOsSetBt">
                            <input type="text" readonly data-toggle="modal" (click)="addMoreUser()" placeholder="+ Add New User" class="addNewUserTextBox">
                            <div class="" *ngIf="showAddUserDiv">

                                <form class="" name="form" #forguser="ngForm" (ngSubmit)="forguser.form.valid && onAddUser(forguser.form)" novalidate>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Job Title<span class="req">*</span></label>
                                                <input trim="blur" type="text" maxlength="50" name="usertitle" [(ngModel)]="Adduserorganization.usertitle" #usertitle="ngModel" required class="form-control" />
                                                <div *ngIf="forguser.submitted && !usertitle.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User title field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Name<span class="req">*</span></label>
                                                <input trim="blur" type="text" maxlength="50" name="username" [(ngModel)]="Adduserorganization.username" #username="ngModel" required class="form-control" />
                                                <div *ngIf="forguser.submitted && !username.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User name field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Email <span class="req">*</span></label>
                                                <input type="text" trim="blur" maxlength="100" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" name="useremail" [(ngModel)]="Adduserorganization.useremail" #useremail="ngModel" required class="form-control" />
                                                <div *ngIf="forguser.submitted && !useremail.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Email field is invalid
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>User Role<span class="req">*</span></label>
                                                <select class="form-control" name="userRole" id="userRole" [(ngModel)]="Adduserorganization.userRole" #userRole="ngModel" required name="userRole">
                                                    <option *ngFor="let role of roleList" [value]="role.id">
                                                        {{ role.role }}</option>
                                                </select>
                                                <div *ngIf="forguser.submitted && !userRole.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User Role field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-right">
                                            <button type="submit" id="closeModal" class="btn btn-primary themeBtnBlue">Add User</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>