import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "../../services/commonservices";
import { GrowlerService } from "../../services/growler.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent implements OnInit {
  message: any;
  token: any;
  public user: any = {};
  htmlOptions: any = {};
  notValid: boolean = false;
  validTrue: boolean = false;
  loading: boolean = false;
  constructor(
    private commonService: CommonService,
    public growlerService: GrowlerService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {

  }

   // convenience getter for easy access to form fields
  onSubmit() {
    if (this.user.password != this.htmlOptions.confirm_password) {
      return;
    }
    let data = {
      id: localStorage.getItem("userId"),
      password: this.htmlOptions.old_password,
      new_password: this.user.password
    };
    this.loading = true;
    //call login api here
    this.commonService.ChangePassword(data).subscribe(
      result => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          //this.message = result;
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;

          setTimeout(() => {
            this.router.navigateByUrl("/");
          }, 4000);
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      error => {}
    );
  }
}
