import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonService } from "../../services/commonservices";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../auth/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GrowlerService } from "../../services/growler.service";
import { Project } from "../../model/project";
import { Client } from "../../model/client";
import { Adduserorganization } from "../../model/adduserorganization";
import * as AWS from "aws-sdk";
import * as $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationDialougModalComponent } from "src/app/confirmation-dialoug-modal/confirmation-dialoug-modal.component";

@Component({
  selector: "app-my-projects",
  templateUrl: "./my-projects.component.html",
  styleUrls: ["./my-projects.component.scss"],
})
export class MyProjectsComponent implements OnInit {
  globalFilesArray: any = [];
  globalRoles: any = [];
  userList: any = {};
  loading: boolean = false;
  orgData: any = {};
  principalData: any = {};
  myProjects: any;
  orguser: any = {};
  addOrguserData: any = {};
  userOrgData: any = [];
  notValid: boolean = false;
  public sectionToggle1: boolean = true;
  validTrue: boolean = false;
  notSelected: boolean = false;
  message: any;
  showAddUserDiv: boolean = false;
  searchUsers: any = [];
  userlist: any = [];
  roleList: object;
  selectedUser: any;
  profileImage: any;
  tempImage: any;
  p: number = 1;
  htmlOptions: any = {};
  public Client: Client = {} as Client;
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public growlerService: GrowlerService,
    public router: Router,
    public modalService: NgbModal
  ) {}
  cities = [
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 5, name: "Klaipėda" },
  ];
  selectedCity: any;
  selectedCityIds: string[];
  selectedCityName = "Vilnius";
  selectedCityId: number;
  selectedUserIds: number[];
  ngOnInit(): void {
    let roles = localStorage.getItem("roles");
    this.getPermissionByRole(roles);
    let userId = localStorage.getItem("userId");
    if (userId) {
      this.getUserData(userId);
    }
  }
  pageChange(p) {
    console.log(p);
  }
  cancleEdit() {
    this.htmlOptions = {
      editMode: false,
    };
  }
  checkPermission(permissionKey, permissions) {
    if (
      (permissions != null &&
        (permissions[permissionKey] === undefined ||
          !permissions[permissionKey])) ||
      (permissions === null && this.globalRoles[permissionKey] === undefined)
    ) {
      return true;
    }
  }
  activeProjectOnClick(editProjId, is_group, project_type, project) {
    var projType = project_type == 1 ? "sop_view_project" : "sfr_view_project";
    //var permissions = (project)?project.permissions:null;
    var permissions = project ? project.permissions : null;
    if (this.checkPermission(projType, permissions)) {
      alert("You do not have permissions to view project.");
      return false;
    }
    localStorage.setItem("editProjectId", editProjId);
    localStorage.setItem("pType", project_type);
    localStorage.setItem("gType", is_group);
    if (project_type == 1) {
      this.router.navigateByUrl("/sop-view");
    } else {
      this.router.navigateByUrl("/feedback-sfr");
    }
  }
  changeObjToArray(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      project.checked = false;
      return project;
    });
    return project;
  }

  getUserData(userId) {
    let data = {
      userId: userId,
      allProject: 0,
    };
    this.loading = true;
    this.commonService
      .apiCall(data, "getUserData")
      .subscribe((activeProjectResult) => {
        this.loading = false;
        if (!activeProjectResult["success"]) {
          this.message = activeProjectResult["error"];
        }
        if (
          activeProjectResult["success"] &&
          activeProjectResult["data"].ResponseCode == 200
        ) {
          this.myProjects = activeProjectResult["data"].ResponseData.projects;
          this.myProjects = this.changeObjToArray(this.myProjects);
          console.log("my projects...", this.myProjects);
        } else {
          this.message = activeProjectResult["data"].ResponseMessage;
        }
      });
  }
  getPermissionByRole(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = result["data"].ResponseData;
          console.log(this.globalRoles);
          //localStorage.setItem("globalRoles", this.globalRoles);
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }

  checkAllCheckBox(ev: any) {
    this.myProjects.forEach((x) => (x.checked = ev.target.checked));
  }

  isAllCheckBoxChecked() {
    return this.myProjects.every((p) => p.checked);
  }

  isAllCheckBoxNotChecked() {
    return this.myProjects.every((p) => !p.checked);
  }

  deleteProjects() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    const modalRef = this.modalService.open(
      ConfirmationDialougModalComponent,
      ngbModalOptions
    );
    modalRef.componentInstance.title =
      "Are you sure you want to delete the selected projects(s).";
    modalRef.result
      .then((result: any) => {
        let projects: any;
        console.log(result);
        if (result) {
          projects = this.myProjects.filter((p) => {
            return p.checked == true;
          });
          console.log("projects...", projects);
          let projectId: any = [];
          for (let index = 0; index < projects.length; index++) {
            projectId.push(projects[index].id);
          }
          console.log("project id", projectId);
          this.loading = true;
          this.commonService
            .apiCall({ projectId: projectId }, "removeProject")
            .subscribe(
              (activeProjectResult) => {
                this.loading = false;
                if (!activeProjectResult["success"]) {
                  this.message = activeProjectResult["error"];
                }
                if (
                  activeProjectResult["success"] &&
                  activeProjectResult["data"].ResponseCode == 200
                ) {
                  this.growlerService.success(
                    activeProjectResult["data"].ResponseMessage
                  );
                  this.growlerService.clear();
                  let userId = localStorage.getItem("userId");
                  if (userId) {
                    this.getUserData(userId);
                  }
                } else {
                  this.message = activeProjectResult["data"].ResponseMessage;
                }
              },
              (err) => {
                console.log(err);
                this.loading = false;
                this.message = err.message;
              }
            );
        }
      })
      .catch((result) => {
        console.log("cancelling");
      });
  }
}
