import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonService } from "../../services/commonservices";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../auth/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GrowlerService } from "../../services/growler.service";
import { Project } from "../../model/project";
import { Profile } from "../../model/profile";
import { Adduserorganization } from "../../model/adduserorganization";
import * as AWS from "aws-sdk";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationModalComponent } from "../confirmation-modal/confirmation-modal.component";

@Component({
  selector: "app-my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["./my-profile.component.scss"],
})
export class MyProfileComponent implements OnInit {
  @ViewChild("forguser") forguser; // Added this
  htmlOptions: any = {};
  globalFilesArray: any = [];
  globalRoles: any = [];
  userList: any = {};
  loading: boolean = false;
  projectData: any = {};
  notValid: boolean = false;
  validTrue: boolean = false;
  notSelected: boolean = false;
  message: any;
  showAddUserDiv: boolean = false;
  searchUsers: any = [];
  userlist: any = [];
  roleList: object;
  selectedUser: any;
  profile_image: any;
  tempImage: any;
  p: number = 1;

  public Profile: Profile = {} as Profile;
  public Adduserorganization: Adduserorganization = {} as Adduserorganization;
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public growlerService: GrowlerService,
    public router: Router,
    public modalService: NgbModal
  ) {}

  ngOnInit(): void {
    let roles = localStorage.getItem("roles");
    this.getPermissionByRole(roles);
    this.getUserData();
    this.setHtmlOptions();
  }
  setHtmlOptions() {
    this.htmlOptions = {
      editMode: false,
    };
  }
  editUser() {
    this.getUserData();
    this.htmlOptions = {
      editMode: true,
    };
  }
  cancleEdit() {
    this.htmlOptions = {
      editMode: false,
    };
  }
  getUserData() {
    let data = {
      allProject: 1,
    };
    this.loading = true;
    this.commonService
      .apiCall(data, "getUserData")
      .subscribe((activeProjectResult) => {
        this.loading = false;
        if (!activeProjectResult["success"]) {
          this.message = activeProjectResult["error"];
        }
        if (
          activeProjectResult["success"] &&
          activeProjectResult["data"].ResponseCode == 200
        ) {
          this.Profile = activeProjectResult["data"].ResponseData;
          this.tempImage = this.Profile.profile_image;
        } else {
          this.message = activeProjectResult["data"].ResponseMessage;
        }
      });
  }
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    draggable: true,
  };
  slickInit(e) {
    console.log("slick initialized");
  }

  breakpoint(e) {
    console.log("breakpoint");
  }
  onSubmit() {
    var that = this;

    if (that.Profile.new_password != that.htmlOptions.cpassword) {
      return;
    }
    if (that.Profile.password && that.Profile.new_password == "") {
      return;
    }
    if (!that.Profile.password && that.Profile.new_password) {
      return;
    }
    that.loading = true;
    var img;
    this.uploadFile(
      this.globalFilesArray,
      function (error) {
        that.Profile.profile_image = that.Profile.profile_image;
        that.saveData();
      },
      function (success) {
        that.Profile.profile_image = success.Location;
        localStorage.setItem("profile_image", success.Location);
        that.saveData();
      }
    );
  }
  saveData() {
    var that = this;

    let data = {
      password: that.Profile.password,
      new_password: that.Profile.new_password,
      name: that.Profile.user_name,
      title: that.Profile.title,
      email: that.Profile.email,
      profileImage: that.Profile.profile_image,
      bio: that.Profile.bio,
      organization_id: that.Profile.organization_id,
      isStatusChanged: false,
    };

    that.commonService.apiCall(data, "updateUserProfile").subscribe(
      (result) => {
        that.loading = false;
        if (!result["success"]) {
          that.message = result["error"];
          that.growlerService.error(that.message);
          that.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          //that.ngOnInit();
          localStorage.setItem("username", that.Profile.user_name);
          location.reload();
        } else {
          that.growlerService.error(result["data"].ResponseMessage);
          that.growlerService.clear();
        }
      },
      (error) => {}
    );
  }
  readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {};
      reader.readAsDataURL(input.files[0]);
    }
  }
  fileEvent(fileInput: any, fileType) {
    var files = fileInput.target.files;
    var file = files[0];
    console.log(file.size);
    if (file.size > 1024909) {
      this.Profile.profile_image = this.tempImage;
      this.notValid = true;
      setTimeout(() => {
        this.notValid = false;
      }, 4000);
      this.message = "Image size should be less than 1 MB";
      alert(this.message);
      this.globalFilesArray = [];
      return false;
    } else if (
      file.type == "image/jpeg" ||
      file.type == "image/JPEG" ||
      file.type == "image/png" ||
      file.type == "image/PNG" ||
      file.type == "image/jpg" ||
      file.type == "image/JPG"
    ) {
      var self = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        self.Profile.profile_image = reader.result;
      };
      this.globalFilesArray = file;
    } else {
      this.Profile.profile_image = this.tempImage;
      this.notValid = true;
      setTimeout(() => {
        this.notValid = false;
      }, 4000);
      this.message = "Only jpg, png and jpeg file format allowed";
      alert(this.message);
      this.globalFilesArray = [];
      return false;
    }
  }
  uploadFile(file, callbackError, callbackSuccess) {
    var self = this;
    //return new Promise<any>((resolve) => {
    const contentType = file.type;
    if (file.length == "0") {
      this.message = "Please upload a valid image";
      callbackError(this.message);
    } else {
      const timenow = new Date();

      const bucket = new AWS.S3({
        accessKeyId: "AKIA2CCQ2X6SLXNDTERL",
        secretAccessKey: "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",
        region: "ap-southeast-2",
      });
      let filename = "";
      const params = {
        Bucket: "dev-adelante-images/_development",
        Key: timenow.getTime() + "_" + file.name,
        Body: file,
        ACL: "public-read",
        ContentType: contentType,
      };
      bucket.upload(params, function (err, data) {
        if (err) {
          alert(err.message);
          callbackError(err);
          return true;
        } else {
          callbackSuccess(data);
          return params.Key;
        }
        //});
      });
    }
  }
  copyProject(projectId, projectType, project) {
    var projType =
      projectType == 1 ? "sop_create_project" : "sfr_create_project";
    var permissions = project.permissions;
    if (this.checkPermission(projType, permissions)) {
      alert("You do not have permissions to copy project.");
      return false;
    }
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ngbModalOptions
    );
    modalRef.result
      .then((result: any) => {
        console.log(result);
        if (result) {
          this.loading = true;
          let data = {
            projectId: projectId,
            projectType: projectType,
            withRating: result,
          };
          this.commonService
            .apiCall(data, "copyProject")
            .subscribe((result) => {
              this.loading = false;
              if (!result["success"]) {
                this.message = result["error"];
              }
              if (result["success"] && result["data"].ResponseCode == 200) {
                var msg = "Project copied successfully.";
                this.growlerService.success(msg);
                this.growlerService.clear();
                this.ngOnInit();
              } else {
                this.message = result["data"].ResponseMessage;
              }
            });
        }
      })
      .catch((result) => {
        console.log("cancelling");
      });
  }
  deleteProject(projectId, project_type, project) {
    var projType = project_type == 1 ? "sop_delete" : "sfr_delete";
    var permissions = project.permissions;
    if (this.checkPermission(projType, permissions)) {
      alert("You do not have permissions to archive projects");
      return false;
    }
    this.loading = true;
    let data = {
      projectId: projectId,
    };
    this.commonService.apiCall(data, "deleteProject").subscribe((result) => {
      this.loading = false;
      if (!result["success"]) {
        this.message = result["error"];
      }
      if (result["success"] && result["data"].ResponseCode == 200) {
        var msg = "Project archived successfully.";
        this.growlerService.success(msg);
        this.growlerService.clear();
        this.ngOnInit();
      } else {
        this.message = result["data"].ResponseMessage;
      }
    });
  }
  addUser(ProjId, OrgId, type, title, project) {
    var projType = type == 1 ? "sop_add_participants" : "sfr_add_participants";
    var permissions = project.permissions;
    if (this.checkPermission(projType, permissions)) {
      alert("You do not have permissions to add participants.");
      return false;
    }
    this.getUserRoleList();
    this.projectData = {
      organizationId: OrgId,
      projectId: ProjId,
      selectedUserId: this.selectedUser,
      roleId: 2,
      projectName: title,
      projectType: type,
    };
    this.searchPrincipleForProject(OrgId, ProjId);
    document.getElementById("btnAddUser").click();
  }
  searchPrincipleForProject(OrgId, ProjId) {
    this.loading = true;
    let PostDetail = {
      orgId: OrgId,
      projectId: ProjId,
    };

    this.commonService
      .searchPrincipleForProject(PostDetail)
      .subscribe((projectUsersResultData) => {
        this.loading = false;

        if (!projectUsersResultData["success"]) {
          this.message = projectUsersResultData["error"];
        }

        if (
          projectUsersResultData["success"] &&
          projectUsersResultData["data"].ResponseCode == 200
        ) {
          let currentOrgUser = projectUsersResultData["data"].ResponseData[0];
          let otherPrincipals = projectUsersResultData["data"].ResponseData[1];
          this.searchUsers = currentOrgUser.concat(otherPrincipals);
          this.searchUsers.map((i) => {
            if (i.orgnization) {
              i.fullDetails = i.user_name + " (" + i.orgnization + ")";
            } else {
              i.fullDetails = i.user_name;
            }
            return i;
          });
        } else {
          this.message = projectUsersResultData["data"].ResponseMessage;
        }
      });
  }
  selectedUserAdd() {
    this.loading = true;

    if (!this.selectedUser) {
      //this.notSelected = true;
      setTimeout(() => {
        this.notSelected = true;
      }, 1000);
    } else {
      this.notSelected = false;
    }

    if (this.selectedUser) {
      let data = {
        organizationId: this.projectData.organizationId,
        projectId: this.projectData.projectId,
        selectedUserId: this.selectedUser,
        roleId: 2,
        projectName: this.projectData.projectId,
        projectType: this.projectData.projectType,
      };
      document.getElementById("addNewUserHide").click();
      this.commonService
        .addUserInProject(data)
        .subscribe((addUserInProjectResult) => {
          this.loading = false;
          if (!addUserInProjectResult["success"]) {
            this.message = addUserInProjectResult["error"];
          }
          if (
            addUserInProjectResult["success"] &&
            addUserInProjectResult["data"].ResponseCode == 200
          ) {
            this.selectedUser = "";
          } else {
            this.message = addUserInProjectResult["data"].ResponseMessage;
          }
        });
    } else {
      this.loading = false;
    }
  }
  addMoreUser() {
    this.showAddUserDiv = true;
  }
  onAddUser(formUser: any) {
    this.loading = true;

    this.userlist = formUser.value;
    if (this.userlist.useremail) {
      let dataEmail = {
        email: this.userlist.useremail,
      };
      this.commonService
        .checkEmailExist(dataEmail)
        .subscribe((emailCheckResult) => {
          this.loading = false;
          if (!emailCheckResult["success"]) {
            this.message = emailCheckResult["error"];
          }

          if (emailCheckResult["data"].ResponseCode == 200) {
            const listArray = Object.assign({}, this.userlist);

            this.forguser.resetForm();

            let selfuser = this;
            let data = {
              organizationId: this.projectData.organizationId,
              user: listArray,
              projectId: this.projectData.projectId,
              selectedUserId: this.selectedUser,
              roleId: 2,
              projectName: this.projectData.projectId,
              projectType: this.projectData.projectType,
            };

            document.getElementById("addNewUserHide").click();
            this.commonService
              .addUserInProject(data)
              .subscribe((addUserInProjectResult) => {
                this.loading = false;
                if (!addUserInProjectResult["success"]) {
                  this.message = addUserInProjectResult["error"];
                }
                if (
                  addUserInProjectResult["success"] &&
                  addUserInProjectResult["data"].ResponseCode == 200
                ) {
                  var msg = "User added successfully.";
                  this.growlerService.success(msg);
                  this.growlerService.clear();
                  this.loading = false;
                } else {
                  this.message = addUserInProjectResult["data"].ResponseMessage;
                  this.growlerService.error(this.message);
                  this.growlerService.clear();
                  this.loading = false;
                }
              });
          } else {
            this.growlerService.error(
              "This email is already registered with the system."
            );
            this.growlerService.clear();
            this.loading = false;
          }
        });
    } else {
      this.loading = false;
    }
  }
  getUserRoleList() {
    let data = {};
    this.commonService.GetUserRoleList(data).subscribe((roleResult) => {
      if (!roleResult["success"]) {
        this.message = roleResult["error"];
      }
      if (roleResult["success"] && roleResult["data"].ResponseCode == 200) {
        this.roleList = roleResult["data"].ResponseData;
      } else {
        this.message = roleResult["data"]
          ? roleResult["data"].ResponseMessage
          : "";
      }
    });
  }
  goToProjectView(editProjId, is_group, project_type, project) {
    var projType = project_type == 1 ? "sop_view_project" : "sfr_view_project";
    var permissions = project.permissions;
    if (this.checkPermission(projType, permissions)) {
      alert("You do not have permissions to view project.");
      return false;
    }
    localStorage.setItem("editProjectId", editProjId);
    localStorage.setItem("pType", project_type);
    localStorage.setItem("gType", is_group);
    if (project_type == 1) {
      this.router.navigateByUrl("/sop-view");
    } else {
      this.router.navigateByUrl("/feedback-sfr");
    }
  }
  activeProjectOnClick(editProjId, is_group, project_type, project) {
    var projType = project_type == 1 ? "sop_view_project" : "sfr_view_project";
    //var permissions = (project)?project.permissions:null;
    var permissions = project ? project.permissions : null;
    /* if (this.checkPermission(projType, permissions)) {
      alert("You do not have permissions to view project.");
      return false;
    } */
    localStorage.setItem("editProjectId", editProjId);
    localStorage.setItem("pType", project_type);
    localStorage.setItem("gType", is_group);
    if (project_type == 1) {
      this.router.navigateByUrl("/sop-view");
    } else {
      this.router.navigateByUrl("/feedback-sfr");
    }
  }
  /****Go to Project setting page from edit project setting option ****/
  goToProjectSetting(editProjId, projectName, project_type, project) {
    var projectKey =
      project_type == 1 ? "sop_change_settings" : "sfr_change_settings";
    var permissions = project.permissions;
    if (this.checkPermission(projectKey, permissions)) {
      alert("You do not have permissions to change project setting.");
      return false;
    }
    localStorage.setItem("editProjectId", editProjId);
    localStorage.setItem("projectName", projectName);
    localStorage.setItem("pType", project_type);
    this.router.navigateByUrl("/project-settings");
  }
  checkPermission(permissionKey, permissions) {
    if (
      (permissions != null &&
        (permissions[permissionKey] === undefined ||
          !permissions[permissionKey])) ||
      (permissions === null && this.globalRoles[permissionKey] === undefined)
    ) {
      return true;
    }
  }
  getPermissionByRole(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = result["data"].ResponseData;
          console.log(this.globalRoles);
          //localStorage.setItem("globalRoles", this.globalRoles);
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
}
