import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/commonservices';
import { GrowlerService } from '../../services/growler.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  message: any;
  public user: any = {};
  email: any;
  logoUrl: string;
  notValid: boolean = false;
  validTrue: boolean = false;
  loading: boolean = false;
  constructor(
    private commonService: CommonService,
    public growlerService: GrowlerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.logoUrl = localStorage.getItem("logo");
  }
  onSubmit() {
    let data = {
      email: this.user.email,
    };
    this.loading = true;
    this.commonService.forgotPass(data).subscribe(
      (result) => {
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.validTrue = true;
          this.loading = false;
          setTimeout(() => {
            this.validTrue = false;
          }, 3000);
          this.message = result["data"].ResponseMessage;
          setTimeout(() => {
            this.router.navigateByUrl("/");
          }, 3000);
        } else {
          this.loading = false;
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
          //this.message = result;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }
}
