import {
  Component,
  OnInit,
  ViewChild,
  ɵConsole,
  ComponentFactoryResolver,
  ElementRef,
} from "@angular/core";
import { CommonService } from "../../services/commonservices";
import { ActivatedRoute, Router } from "@angular/router";
import { GrowlerService } from "../../services/growler.service";
import * as $ from 'jquery';


@Component({
  selector: 'app-feedback-sfr-pdf',
  templateUrl: './feedback-sfr-pdf.component.html',
  styleUrls: ['./feedback-sfr-pdf.component.scss']
})
export class FeedbackSfrPdfComponent implements OnInit {
  @ViewChild("content") content: ElementRef;
  public showLoder: boolean = true;
  public showCompare: boolean = false;
  htmlOptions: any = {};
  public showCompareMode: boolean = false;
  public showFeedback: boolean = false;
  public showFeedbackMode: boolean = false;
  pageSize: any;
  globalRoles: any = [];
  permission: any = [];
  date12: Date;
  minDate: Date;
  maxDate: Date;
  currentMonth: any;
  compareMonth: any;
  notValid: boolean = false;
  notValid1: boolean = false;
  validTrue: boolean = false;
  disableFeedbackButton: boolean = true;
  isDummyUser: boolean = false;
  priorities: any = {};
  dummyUser: any = [];
  /* dummyUser: any = [
    {
      id: "",
      name: "",
    },
  ]; */
  projectId: any;
  userRating: any;
  projectData: any = [];
  userData: any = [];
  feedback: any = {};
  ProjectPeriodData: any = {};
  projectSettingData: any = {};
  ProjectPeriod: any = [];
  loading: boolean = false;
  notesdata: any;
  currentMonthCount: any;
  currentYearCount: any;
  message: any;
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    public growlerService: GrowlerService
  ) {}

  ngOnInit(): void {
    let d = new Date();
    let m = d.getMonth();
    let y = d.getFullYear();
    this.currentMonthCount = m + 1;
    this.currentYearCount = y;
    this.priorities.pageSize = "";
    this.htmlOptions.download = true;
    this.showCompareMode = false;
    //this.disableFeedbackButton = true;
    this.currentMonth = this.getMonthNameByDate(new Date());
    this.projectSettingData.projectName = "";
    this.projectId = localStorage.getItem("editProjectId");
    this.getProjectSetting(this.projectId);

    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    let roles = localStorage.getItem("roles");
    this.getPermissionByRole(roles);
    this.getUserPermissionOnProject(this.projectId);
    this.customeSlider();
    $("body").on("click", ".dropdown-menu", function (e) {
      $(this).parent().is(".show") && e.stopPropagation();
    });
  }
  editPage(is_group, project_type) {
    var projType = "global_edit_proj";
    if (this.checkPermission(projType, this.permission)) {
      alert("You do not have permission to edit project");
      return false;
    }
    localStorage.setItem("editProjectId", this.projectId);
    localStorage.setItem("pType", project_type);
    localStorage.setItem("gType", is_group);
    if (project_type == 1) {
      this.router.navigateByUrl("/sop-create");
    } else {
      this.router.navigateByUrl("/project-sfr");
    }
  }
  getPermissionByRole(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = result["data"].ResponseData;
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  disbleFeedbackProvider() {
    var projType = "sfr_capture_feedback";
    if (this.checkPermission(projType, this.permission)) {
      this.disableFeedbackButton = false;
    } else {
      this.disableFeedbackButton = true;
    }
  }
  addRating(queId: any, userId: any, commntes: any, isDummyUser: any) {
    var projType = "sfr_capture_feedback";
    if (this.checkPermission(projType, this.permission)) {
      alert("You do not have permissions to rate the tasks");
      return false;
    }
    let projectId = this.projectId;
    let rating = $("#range" + queId + userId).val();
    if (rating == 0) {
      rating = 1;
    }
    this.addRatingAndCommentTask(
      queId,
      rating,
      userId,
      commntes,
      projectId,
      isDummyUser
    );
  }
  addRatingAndCommentTask(
    queId,
    rating,
    userId,
    commntes,
    projectId,
    isDummyUser
  ) {
    let data = {
      id: queId,
      userId: userId,
      token: "",
      rating: rating,
      comment: commntes,
      projectId: projectId,
      isDummyUser: isDummyUser,
      requestedMonth: this.currentMonthCount,
      requestedYear: this.currentYearCount,
    };

    this.loading = true;
    //call login api here
    this.commonService.addRatingComment(data).subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.ngOnInit();
          $(".custom-handle").text(1);
          this.feedback.text = "";
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
  }
  // blankBox() {
  //   this.feedback.text = "";
  //   this.customeSlider();
  //   $(".custom-handle").text(1);
  // }

  blankBox() {
    this.feedback.text = "";
    $(".custom-handle").text(1);
    var handle = $(".custom-handle");
    handle.text();
    for (let n = 2; n < 11; ++n) {
      handle.removeClass("rating" + n + "bg");
    }
    this.customeSlider();
  }

  customeSlider() {
    $(function () {
      var handle = $(".custom-handle");
      $(".slider").slider({
        orientation: "horizantal",
        range: "min",
        value: 1,
        step: 0.5,
        min: 1.0,
        max: 10.0,
        animate: "fast",
        create: function () {
          handle.text($(this).slider("value"));
          handle.addClass(
            "rating" + Math.round($(this).slider("value")) + "bg"
          );
        },
        slide: function (event, ui) {
          //console.log("attrId");
          handle.text(ui.value);
          handle.addClass("rating" + Math.round(ui.value) + "bg");
          handle.removeClass("rating" + (Math.round(ui.value) + 1) + "bg");
          var attrId = $(this)
            .closest(".dropdown-menu.p-4")
            .find('input[name="feedbackRange"]')
            .attr("id");
          $("#" + attrId).val(ui.value);
          //console.log(attrId);
        },
      });
    });
  }
  getSfrProjectData() {
    let data = {
      projectId: this.projectId,
      requestedMonth: this.currentMonthCount,
      requestedYear: this.currentYearCount,
    };
    this.loading = true;
    //call login api here
    this.commonService.getSfrProjectData(data).subscribe(
      (result) => {
        this.loading = false;
        this.notesdata = "";
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.projectData = result["data"]["ResponseData"].questionData;
          this.isDummyUser = this.projectData[0]["isDummyUser"];
          this.userData = result["data"]["ResponseData"].projectUsers;
          if (this.isDummyUser) {
            this.dummyUser = this.userData;
          }
          this.customeSlider();
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          $(function () {
            $('[data-toggle="tooltip"]').tooltip();
          });
          this.message = result["data"].ResponseMessage;
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
  }
  calculateWidth(minVal: any, maxVal: any) {
    var max = maxVal * 10 - 5;
    var min = minVal * 10 - 5;
    let total = max - min;
    return total;
  }
  getProjectSetting(activeProjectId) {
    let projectDetail = {
      projectId: activeProjectId,
    };
    this.commonService
      .editProjectSetting(projectDetail)
      .subscribe((projectSettingResultData) => {
        this.loading = false;
        if (!projectSettingResultData["success"]) {
          this.message = projectSettingResultData["error"];
        }
        if (
          projectSettingResultData["success"] &&
          projectSettingResultData["data"].ResponseCode == 200
        ) {
          //let projectData= projectSettingResultData["data"].ResponseData.data;
          this.projectSettingData =
            projectSettingResultData["data"].ResponseData.data[0];
          let makeDate =
            projectSettingResultData["data"].ResponseData.data[0].currentPeriod;
          makeDate = new Date(makeDate);
          this.currentMonth = this.getMonthNameByDate(makeDate);
          let m = makeDate.getMonth();
          let y = makeDate.getFullYear();
          makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
          this.minDate = makeDate;

          this.maxDate = new Date();
          makeDate = new Date(
            this.maxDate.setMonth(this.maxDate.getMonth() - 1)
          );
          this.currentMonthCount = m+1;

          this.currentYearCount = y;
          this.getSfrProjectData();
        } else {
          this.message = projectSettingResultData["data"].ResponseMessage;
        }
      });
  }
  median(values) {
    if (!values) return "";
    var temp = [];
    values.forEach(function (item) {
      temp.push(item.rating);
    });

    temp.sort(function (a, b) {
      return a - b;
    });

    var half = Math.floor(temp.length / 2);
    if (temp.length % 2) return temp[half];

    return (temp[half - 1] + temp[half]) / 2.0;
  }
  getUserRating(user, userRating) {
    var temp = 0;
    if (!userRating) {
      temp = 0;
    } else if (userRating) {
      userRating.forEach(function (item) {
        if (item.userId == user.id) {
          temp = item.rating;
        }
      });
    } else {
      temp = 0;
    }
    return temp;
  }
  getUserComments(user, userRating) {
    var temp = "";
    if (!userRating) {
      temp = "";
    } else if (userRating) {
      userRating.forEach(function (item) {
        if (item.userId == user.id) {
          temp = item.comment;
        }
      });
    } else {
      temp = "";
    }
    return temp;
  }
  handleCompare(e: any) {
    this.showCompare = e.target.checked;
    this.showCompareMode = e.target.checked;
    this.showFeedback = false;
    this.showFeedbackMode = false;
    if (!e.target.checked) {
      this.ngOnInit();
    } else {
      this.ProjectPeriodData.currentPeriod = "";
    }
  }
  handleFeedback(e: any) {
    this.showFeedback = e.target.checked;
    this.showFeedbackMode = e.target.checked;
    if (e.target.checked) {
      this.customeSlider();
    }
  }
  changePeriod(e: any, isDummyUser: any) {
    if (e) {
      this.showCompareMode = true;
      //this.currentMonth = this.getMonthNameByDate(new Date());
      this.compareMonth = this.getMonthNameByDate(e);
      var date = e;
      var month = date.getMonth();
      month = month + 1;
      this.sfrCompareMonth(month, isDummyUser);
    }
  }
  getMonthNameByDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date(date);
    return monthNames[d.getMonth()] + " " + d.getFullYear();
  }

  sfrCompareMonth(month, isDummyUser) {
    let projectDetail = {
      projectId: this.projectId,
      primaryMonth: this.currentMonthCount,
      primaryYear: this.currentYearCount,
      secondaryMonth: month,
      secondaryYear: this.currentYearCount,
      isDummyUser: isDummyUser,
    };
    this.commonService
      .sfrCompareMonth(projectDetail)
      .subscribe((projectSettingResultData) => {
        this.loading = false;
        if (!projectSettingResultData["success"]) {
          this.message = projectSettingResultData["error"];
        }

        if (
          projectSettingResultData["success"] &&
          projectSettingResultData["data"].ResponseCode == 200
        ) {
          this.projectData = projectSettingResultData["data"].ResponseData;
          $(function () {
            $('[data-toggle="tooltip"]').tooltip();
          });
        } else {
          this.message = projectSettingResultData["data"].ResponseMessage;
        }
      });
  }
  delta(min1, min2) {
    if (min1 && min2) {
      return min1 - min2;
    } else {
      return "";
    }
  }

  addClass(e, event): void {
    $(".user" + e).addClass("highlight");
    $("." + event).addClass("highlight");
    $("#"+e+event).addClass("highlight-dark");

  }

  removeClass(e, event): void {
    $(".user" + e).removeClass("highlight");
    $("." + event).removeClass("highlight");
    $("#"+e+event).removeClass("highlight-dark");
  }

  trend(min1, min2) {
    if (min1 && min2) {
      let delta = (min1 - min2) / min2;
      if (delta > 1.5) {
        return 1;
      } else if (delta >= 0.51 && delta < 1.5) {
        return 2;
      } else if (delta >= -0.5 && delta < +0.5) {
        return 3;
      } else if (delta >= -0.51 && delta < -1.51) {
        return 4;
      } else {
        return 5;
      }
    } else {
      return 0;
    }
  }
  roundRating(number) {
    return Math.round(number);
  }
  // captureScreen1() {
  //   this.loading = true;
  //   setTimeout(() => {
  //     this.loading = false;
  //   }, 1000);
  //   var data = document.getElementById("contentToConvert");
  //   html2canvas(data).then((canvas) => {
  //     // Few necessary setting options
  //     var imgWidth = 400;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;

  //     const contentDataURL = canvas.toDataURL("image/png");
  //     let pdf = new jspdf("l", "mm", "a3"); // A4 size page of PDF
  //     var position = 0;
  //     pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
  //     pdf.save(this.projectSettingData.projectName + ".pdf"); // Generated PDF
  //   });
  // }
  // captureScreen() {
  //   this.htmlOptions.download = false;
  //   this.loading = true;
  //   setTimeout(() => {
  //     this.loading = false;
  //   }, 500);
  //   var data = $("#contentToConvert").get(0);
  //   // var data = document.getElementById("contentToConvert");
  //   html2canvas(data).then((canvas) => {
  //     // Few necessary setting options
  //     var imgWidth = 208;
  //     var pageHeight = 295;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     var heightLeft = imgHeight;

  //     const contentDataURL = canvas.toDataURL("image/png");
  //     let pdf = new jspdf("p", "mm", "a3"); // A4 size page of PDF
  //     var position = 0;

  //     //const elementToPrint = document.getElementsByTagName("body"); //The html element to become a pdf
  //     const elementToPrint = document.getElementById("contentToConvert"); //The html element to become a pdf
  //     var self = this;
  //     pdf.addHTML(elementToPrint, () => {
  //       pdf.save(this.projectSettingData.projectName + ".pdf"); // Generated PDF
  //       self.htmlOptions.download = true;
  //       //pdf.save("web.pdf");
  //     });
  //   });
  // }
  captureScreenold() {
    const elementToPrint =$("#contentToConvert").html();
    //let html = htmlToPdfmake(elementToPrint.outerHTML)

    let data = {
      html: elementToPrint,
      pageSize: this.pageSize,
      orientation:'potrate'
    };
    this.commonService.apiCall(data, "downloadPdf").subscribe(
      (result) => {
        if (result["success"] && result["data"].ResponseCode == 200) {
          var fileToDownload = result["data"].ResponseData;

         var href = "https://d37b3e1yz0z6u8.cloudfront.net/" + fileToDownload;

         setTimeout(() => {

          var req = new XMLHttpRequest();
            req.open("GET", href, true);
            req.responseType = "blob";

            req.onload = function (event) {
              var blob = req.response;
              console.log(blob.size);
              var link=document.createElement('a');
              link.href=window.URL.createObjectURL(blob);
              link.download="SFR_" + new Date() + ".pdf";
              link.click();
            };

            req.send();
         }, 5000);

          //a.remove();

        }
      },
      (error) => {}
    );


  }
  captureScreen() {
    $("#printHide").click();
    // elementToPrint = document.getElementById("contentToConvert");
    const elementToPrint = $("#contentToConvert").html();
    const header = "";
    let data = {
      method:"printpdf",
      action:"print",
      html: elementToPrint,
      pageSize: this.pageSize,
      orientation:'portrait',
      header: header,
      projectType: 2
    };
  this.commonService.apiCallPdf(data).subscribe(
    (result) => {
      if (result["success"] && result["data"].ResponseCode == 200) {
        // var fileToDownload = result["data"].ResponseData;
        // var href = "https://d37b3e1yz0z6u8.cloudfront.net/" + fileToDownload;
        // setTimeout(() => {
        //   var req = new XMLHttpRequest();
        //   req.open("GET", href, true);
        //   req.responseType = "blob";
        //   req.onload = function (event) {
        //     var blob = req.response;
        //     console.log(blob.size);
        //     var link=document.createElement('a');
        //     link.href=window.URL.createObjectURL(blob);
        //     link.download="SOP_" + new Date() + ".pdf";
        //     link.click();
        //   };
        //   req.send();
        // }, 5000);
      }
    },
    (error) => {}
  )
    // this.htmlOptions.download = false;
    // var self = this;
    // var options = {
    //   pagesplit: true,
    //   margin: { top: 10, right: 10, bottom: 10, left: 10, useFor: "page" },
    // };
    // let pdf = new jspdf("l", "mm", this.pageSize);
    // pdf.internal.scaleFactor = 3; // A4 size page of PDF
    // var position = 0;

    //const elementToPrint = document.getElementById("contentToConvert"); //The html element to become a pdf
    // var self = this;

    // pdf.addHTML(elementToPrint, 10, 10, options, () => {
    //   self.htmlOptions.download = true;
    //   pdf.save(this.projectSettingData.projectName + ".pdf");
    //   this.ngOnInit();
    // });


  }

  checkPermission(permissionKey, permissions) {
    if (
      (permissions != null &&
        (permissions[permissionKey] === undefined ||
          !permissions[permissionKey])) ||
      (permissions === null && this.globalRoles[permissionKey] === undefined)
    ) {
      return true;
    }
  }
  getUserPermissionOnProject(projectId) {
    var data = {
      projectId: projectId,
    };
    this.commonService.apiCall(data, "getUserPermissionOnProject").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.permission = result["data"].ResponseData;
          this.disbleFeedbackProvider();
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  addDummyUsers() {
    this.dummyUser.push({
      id: 0,
      name: "",
    });
  }
  addDummyUser(name) {
    if (!name) {
      this.growlerService.error("Name field is required.");
      this.growlerService.clear();
      return false;
    }
    this.loading = true;
    let data = {
      id: 0,
      name: name,
      projectId: this.projectId,
    };
    this.commonService.apiCall(data, "addDummyUser").subscribe((userdata) => {
      this.loading = false;
      if (!userdata["success"]) {
        this.message = userdata["error"];
      }
      if (userdata["success"] && userdata["data"].ResponseCode == 200) {
        this.dummyUser = userdata["data"].ResponseData;
        this.growlerService.success("User added successfully.");
        this.growlerService.clear();
        this.ngOnInit();
      } else {
        this.message = userdata["data"].ResponseMessage;
        this.growlerService.error(this.message);
        this.growlerService.clear();
      }
    });
  }
  changeSize(size) {
    this.pageSize = size;
  }
}
