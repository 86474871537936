import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonService } from "../../services/commonservices";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../auth/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GrowlerService } from "../../services/growler.service";
import { Project } from "../../model/project";
import { Client } from "../../model/client";
import { Adduserorganization } from "../../model/adduserorganization";
import * as AWS from "aws-sdk";
import * as $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationDialougModalComponent } from "src/app/confirmation-dialoug-modal/confirmation-dialoug-modal.component";

@Component({
  selector: "app-clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.scss"],
})
export class ClientsComponent implements OnInit {
  globalFilesArray: any = [];
  userList: any = {};
  loading: boolean = false;
  orgTempData: any = {};
  orgData: any = [];
  principalData: any = {};
  orguser: any = {};
  addOrguserData: any = {};
  globalRoles: any = [];
  userOrgData: any = [];
  notValid: boolean = false;
  public sectionToggle1: boolean = true;
  validTrue: boolean = false;
  notSelected: boolean = false;
  message: any;
  showAddUserDiv: boolean = false;
  isSearched: boolean = false;
  searchUsers: any = [];
  userlist: any = [];
  roleList: object;
  selectedUser: any;
  profileImage: any;
  RecordCnt: any;
  tempImage: any;
  p: number = 1;
  htmlOptions: any = {};
  public Client: Client = {} as Client;
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public growlerService: GrowlerService,
    public router: Router,
    public modalService: NgbModal
  ) {}
  cities = [
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 5, name: "Klaipėda" },
  ];
  selectedCity: any;
  selectedCityIds: string[];
  selectedCityName = "Vilnius";
  selectedCityId: number;
  selectedUserIds: number[];
  ngOnInit(): void {
    let roles = localStorage.getItem("roles");
    this.getPermissionByRole(roles);
    this.getOrgData();
    this.setHtmlOptions();
  }
  setHtmlOptions() {
    this.htmlOptions = {
      editMode: false,
    };
  }
  editUser(ordgId) {
    this.getOrgDataById(ordgId);
    this.htmlOptions = {
      editMode: true,
    };
  }
  cancleEdit() {
    this.htmlOptions = {
      editMode: false,
    };
  }
  getOrgDataById(id) {
    let data = {
      id: id,
    };
    this.loading = true;
    this.commonService
      .apiCall(data, "getOrgDataById")
      .subscribe((activeOrgResult) => {
        this.loading = false;
        if (!activeOrgResult["success"]) {
          this.message = activeOrgResult["error"];
        }
        if (
          activeOrgResult["success"] &&
          activeOrgResult["data"].ResponseCode == 200
        ) {
          this.Client = activeOrgResult["data"].ResponseData.orgdata;
          this.orguser = activeOrgResult["data"].ResponseData.orguser;
          this.principalData = activeOrgResult["data"].ResponseData.principal;
          this.tempImage = this.Client.profileImage;
          this.Client.titleOld = this.Client.title;
        } else {
          this.message = activeOrgResult["data"].ResponseMessage;
        }
      });
  }
  checkEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  addOrganisationUser(userId, title) {
    if (!$("#userName").val()) {
      this.growlerService.error("Please add user name");
      this.growlerService.clear();
    } else if (!$("#userTitle").val()) {
      this.growlerService.error("Please add user title");
      this.growlerService.clear();
    } else if (!$("#userEmail").val()) {
      this.growlerService.error("Please add user email");
      this.growlerService.clear();
    } else if (!this.checkEmail($("#userEmail").val())) {
      this.growlerService.error("Please enter valid email");
      this.growlerService.clear();
    } else {
      this.addOrgUser(
        userId,
        $("#userName").val(),
        $("#userTitle").val(),
        $("#userEmail").val()
      );
    }
  }
  addOrgUser(userId: number, name: any, title: any, email: any) {
    status = status ? "0" : "1";
    this.loading = true;
    //call login api here
    let data = {
      organizationId: userId,
      name: name,
      title: title,
      email: email,
    };
    this.commonService.apiCall(data, "addOrgUser").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.growlerService.success("User added Successfully");
          this.growlerService.clear();
          this.editUser(userId);
          $("#userName").val(""),
            $("#userTitle").val(""),
            $("#userEmail").val("");
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }
  updateUserStatus(userId: number, status: any) {
    console.log(this.globalRoles.global_active_user);
    if (this.globalRoles && !this.globalRoles.global_active_user) {
      alert("You do not have permissions to active/inactive users.");
      return false;
    }
    status = status ? "0" : "1";
    this.loading = true;
    //call login api here
    let data = {
      userId: userId,
      status: status,
    };
    this.commonService.apiCall(data, "updateUserStatus").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.growlerService.success("Status updated Successfully");
          this.growlerService.clear();
          this.ngOnInit();
          this.htmlOptions = {
            editMode: true,
          };
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }
  getOrgData() {
    let data = {
      searchKey: "",
    };
    this.loading = true;
    this.commonService
      .apiCall(data, "getOrgData")
      .subscribe((activeOrgResult) => {
        this.loading = false;
        if (!activeOrgResult["success"]) {
          this.message = activeOrgResult["error"];
        }
        if (
          activeOrgResult["success"] &&
          activeOrgResult["data"].ResponseCode == 200
        ) {
          this.orgData = this.changeObjToArray(
            activeOrgResult["data"].ResponseData
          );
          this.orgTempData = this.changeObjToArray(
            activeOrgResult["data"].ResponseData
          );
          if (this.orgData && this.orgData.length) {
             for (let index = 0; index < this.orgData.length; index++) {
              this.orgData[index].projects = this.changeObjToArrayProjects(this.orgData[index].projects);
             }
             console.log('orgnizations...', this.orgData);
          }
        } else {
          this.message = activeOrgResult["data"].ResponseMessage;
        }
      });
  }
  changeObjToArray(orgizationData) {
    var orgnization = Object.keys(orgizationData).map(function (personNamedIndex) {
      let org = orgizationData[personNamedIndex];
      if (org.id) {
        org.checked = false;
      }
      // if(org.projects){
      //   this.changeObjToArrayProjects(org.projects);
      // }
      // if (org.principal) {
      //   this.changeObjToArrayPrincipal(org.principal);
      // }
      return org;
    });
    return orgnization;
  }
  changeObjToArrayProjects(projectData) {
    var projects = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      if (project.projectId) {
        project.checked = false;
      }
      return project;
    });
    return projects;
  }
  changeObjToArrayPrincipal(principalData) {
    var principals = Object.keys(principalData).map(function (personNamedIndex) {
      let principal = principalData[personNamedIndex];
      return principal;
    });
    return principals;
  }
  fileEvent(fileInput: any, fileType) {
    var files = fileInput.target.files;
    var file = files[0];
    if (file.size > 1024909) {
      this.Client.profileImage = this.tempImage;
      this.message = "Image size should be less than 1 MB";
      alert(this.message);
      this.globalFilesArray = [];
      return false;
    } else if (
      file.type == "image/jpeg" ||
      file.type == "image/JPEG" ||
      file.type == "image/png" ||
      file.type == "image/PNG" ||
      file.type == "image/jpg" ||
      file.type == "image/JPG"
    ) {
      var self = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        self.Client.profileImage = reader.result;
      };
      this.globalFilesArray = file;
      this.uploadFile(file);
    } else {
      this.Client.profileImage = this.tempImage;

      this.notValid = true;
      setTimeout(() => {
        this.notValid = false;
      }, 4000);
      this.message = "Only jpg, png and jpeg file format allowed";
      alert(this.message);
      this.globalFilesArray = [];
      return false;
    }
  }
  uploadFile(file) {
    var self = this;
    this.loading = true;
    //return new Promise<any>((resolve) => {
    const contentType = file.type;
    if (file.length == "0") {
      self.loading = false;
      self.message = "Please upload a valid image";
      alert(self.message);
    } else {
      const timenow = new Date();

      const bucket = new AWS.S3({
        accessKeyId: "AKIA2CCQ2X6SLXNDTERL",
        secretAccessKey: "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",
        region: "ap-southeast-2",
      });
      let filename = "";
      const params = {
        Bucket: "dev-adelante-images/_development",
        Key: timenow.getTime() + "_" + "img",
        Body: file,
        ACL: "public-read",
        ContentType: contentType,
      };
      bucket.upload(params, function (err, data) {
        if (err) {
          self.loading = false;
          alert(err.message);
        } else {
          self.loading = false;
          self.Client.profileImage = data.Location;
          self.updateOrgData();
          //alert("Successfully uploaded file.");
          //return params.Key;
        }
        //});
      });
    }
  }
  updateOrgStatus(eve: any) {
    if (this.globalRoles && !this.globalRoles.global_active_org) {
      alert("You do not have permissions to active/inactive organisation.");
      return false;
    }
    if (!eve.target.checked) {
      if (
        confirm(
          "By De-activating this client all the user accounts will be set to inactive and they will not be able to log into their accounts"
        )
      ) {
        this.Client.status = eve.target.checked;
        this.Client.isStatusChanged = true;
        this.updateOrgData();
      } else {
        return false;
      }
    } else {
      this.Client.status = eve.target.checked;
      this.Client.isStatusChanged = true;
      this.updateOrgData();
    }
  }
  changeManager(eve: any) {
    this.Client.relationshipManager = eve.target.value;
    this.updateOrgData();
  }
  updateOrgData() {
    var url = this.Client.webUrl;
    if (url) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      if (!pattern.test(url)) {
        this.growlerService.error("Url is not valid");
        this.growlerService.clear();
        return false;
      }
    }

    let data: any = {
      id: this.Client.id,
      profileImage: this.Client.profileImage,
      title: this.Client.title,
      titleOld: this.Client.titleOld,
      description: this.Client.description,
      webUrl: this.Client.webUrl,
      relationshipManager: this.Client.relationshipManager,
      status: this.Client.status,
    };
    if (this.Client.isStatusChanged) {
      data.isStatusChanged = true;
    }

    this.loading = true;
    //call login api here
    this.commonService.apiCall(data, "updateOrgData").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          //this.ngOnInit();
          //this.logoImg = result["data"]["ResponseData"].logo;
          this.growlerService.success("Record Updated Successfully");
          this.growlerService.clear();
          this.Client.isStatusChanged = false;
          this.Client.titleOld = this.Client.title;
          this.ngOnInit();
          this.htmlOptions = {
            editMode: true,
          };
          $("#org1title").show();
          $("#org2title").hide();
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }
  OpenCloseDiv(field, desc) {
    $("#org2" + field).show();
    $("#org1" + field).hide();

    $("#que2" + field).val(desc);
  }
  checkPermission(permissionKey, permissions) {
    if (
      (permissions != null &&
        (permissions[permissionKey] === undefined ||
          !permissions[permissionKey])) ||
      (permissions === null && this.globalRoles[permissionKey] === undefined)
    ) {
      return true;
    }
  }
  goToProjectView(editProjId, is_group, project_type, project) {
    var projType = project_type == 1 ? "sop_view_project" : "sfr_view_project";
    var permissions = project.permissions;
    if (this.checkPermission(projType, permissions)) {
      alert("You do not have permissions to view project.");
      return false;
    }
    localStorage.setItem("editProjectId", editProjId);
    localStorage.setItem("pType", project_type);
    localStorage.setItem("gType", is_group);
    if (project_type == 1) {
      this.router.navigateByUrl("/sop-view");
    } else {
      this.router.navigateByUrl("/feedback-sfr");
    }
  }
  resetAllUserOnSearchButton() {
    $("#searchKey").val(" ");

    this.isSearched = false;
    this.getOrgData();
  }
  getAllUserOnEnter(event) {
    console.log(event);
    var searchKey = $("#searchKey").val();
    if (event.keyCode === 13) {
      this.getAllUserOnSearch(searchKey);
    }
  }
  getAllUserOnSearchButton() {
    var searchKey = $("#searchKey").val();

    if (!searchKey) {
      this.isSearched = false;
      alert("Please enter text to search");
    }
    this.getAllUserOnSearch(searchKey);
  }
  getAllUserOnSearch(searchKey: any) {
    //console.log(searchKey);
    var searchKey = $("#searchKey").val();
    this.isSearched = true;
    if (searchKey.length == 0) {
      this.getOrgData();
    }

    if (searchKey && searchKey.length > 3) {
      this.loading = true;
      //call login api here
      var data = {
        searchKey: searchKey,
      };
      this.commonService.apiCall(data, "getOrgData").subscribe(
        (result) => {
          this.loading = false;
          if (!result["success"]) {
            this.message = result["error"];
            this.growlerService.error(this.message);
            this.growlerService.clear();
          }
          if (result["success"] && result["data"].ResponseCode == 200) {
            //this.userOrgData= []
            if (result["data"]["ResponseData"]) {
              this.orgData = this.changeObjToArray(
                result["data"]["ResponseData"]
              );
            }
            // this.orgData = this.orgTempData;
            // document.getElementById("orgSearchBoxClick").click();
          } else {
            this.message = result["data"].ResponseMessage;
            this.growlerService.error(result["data"].ResponseMessage);
            this.growlerService.clear();
          }
        },
        (error) => {}
      );
    }
  }
  getAllUserBykeyWord(userId) {
    var temp = [];
    var self = this;
    if (userId === undefined) {
      temp = [];
    } else if (userId) {
      this.orgData.forEach(function (item) {
        if (item != undefined && item.id == userId) {
          temp[0] = item;
        }
      });
    } else {
      temp = [];
    }
    console.log(temp);
    this.orgData = temp;
  }
  changeObjToArrayForOrg(arr, key) {
    let arrVal = this.changeObjToArray(arr);
    return arrVal[0]["orgnization"];
  }
  getPermissionByRole(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        if (!result["success"]) {
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = result["data"].ResponseData;
          console.log(this.globalRoles);
          //localStorage.setItem("globalRoles", this.globalRoles);
        } else {
          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }

  checkAllCheckBox(ev: any) {
    this.orgData.forEach((x) => (x.checked = ev.target.checked));
  }

  isAllCheckBoxChecked() {
    return this.orgData.every((p) => p.checked);
  }

  isAllCheckBoxNotChecked() {
    return this.orgData.every((p) => !p.checked);
  }

  isAllCheckBoxNotCheckedProject() {
    let projects = [];
    this.orgData.map(org=>{
      org.projects.map(project=>{
        projects.push(project);
      })
    })
    console.log('projects...', projects)
    return projects.every((p) => !p.checked);
  }
  deleteProjects() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    const modalRef = this.modalService.open(
      ConfirmationDialougModalComponent,
      ngbModalOptions
    );
    modalRef.componentInstance.message =
      "Are you sure you want to delete the selected projects(s). WARNING: DELETED DATA CANNOT BE RECOVERED";
    modalRef.result
      .then((result: any) => {
        let projectss: any;
        console.log(result);
        if (result) {
          let projects = [];
          this.orgData.map(org=>{
            org.projects.map(project=>{
              projects.push(project);
            })
          })
          projectss = projects.filter((p) => {
            return p.checked == true;
          });
          console.log("projects...", projectss);
          let projectId: any = [];
          for (let index = 0; index < projectss.length; index++) {
            projectId.push(projectss[index].projectId);
          }
          console.log("project id", projectId);
          this.loading = true;
          this.commonService.apiCall({projectId: projectId, userId: localStorage.getItem("userId")}, "removeProject").subscribe(
            (activeProjectResult) => {
              this.loading = false;
              if (!activeProjectResult["success"]) {
                this.message = activeProjectResult["error"];
              }
              if (
                activeProjectResult["success"] &&
                activeProjectResult["data"].ResponseCode == 200
              ) {
                this.growlerService.success(activeProjectResult["data"].ResponseMessage);
                this.growlerService.clear();
                  this.getOrgData();
              } else {
                this.message = activeProjectResult["data"].ResponseMessage;
              }
            },
            (err) => {
              console.log(err);
              this.loading = false;
              this.message = err.message;
            }
          );
        }
      })
      .catch((result) => {
        console.log("cancelling");
      });
  }
  deleteOrgnations() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    const modalRef = this.modalService.open(
      ConfirmationDialougModalComponent,
      ngbModalOptions
    );
    modalRef.componentInstance.message =
      "Are you sure you want to delete the selected orgnations(s). WARNING: DELETED DATA CANNOT BE RECOVERED";
    modalRef.result
      .then((result: any) => {
        let orgnations: any;
        console.log(result);
        if (result) {
          orgnations = this.orgData.filter((o) => {
            return o.checked == true;
          });
          console.log("orgnations...", orgnations);
          let organizationId: any = [];
          for (let index = 0; index < orgnations.length; index++) {
            organizationId.push(orgnations[index].id);
          }
          console.log("orgnationId", organizationId);
          this.loading = true;
          this.commonService
            .apiCall({ organizationId: organizationId, userId: localStorage.getItem("userId") }, "removeOrganization")
            .subscribe(
              (activeProjectResult) => {
                this.loading = false;
                if (!activeProjectResult["success"]) {
                  this.message = activeProjectResult["error"];
                }
                if (
                  activeProjectResult["success"] &&
                  activeProjectResult["data"].ResponseCode == 200
                ) {
                  this.growlerService.success(activeProjectResult["data"].ResponseMessage);
                  this.growlerService.clear();
                  this.getOrgData();
                } else {
                  this.message = activeProjectResult["data"].ResponseMessage;
                }
              },
              (err) => {
                console.log(err);
                this.loading = false;
                this.message = err.message;
              }
            );
        }
      })
      .catch((result) => {
        console.log("cancelling");
      });
  }
}
