<app-growler>
</app-growler>
<app-header></app-header>
<div class="wrapper">
    <div class="loaderOuter" *ngIf="loading">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
    <app-nav-bar></app-nav-bar>
    <div class="inner-container headerTopPaddipad">
        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-10 col-xl-8">
                    <span class="ptypespan">
                        <img *ngIf="projectSettingData.group==1" src="./assets/images/individual-sop-icon.png" alt="icon" height="15">
                        <img *ngIf="projectSettingData.group==2" src="./assets/images/group-sop-icon.png" alt="icon" height="15">
                    </span>
                    <h3 class="sfp_project-title"> {{projectSettingData.projectName}}</h3>
                    
                    <!--  <a routerLink="/project-sfr"><i class="fa fa-pencil mx-2" aria-hidden="true"></i></a> -->
                    <!-- <a routerLink="/project-settings"><i class="fa fa-cog mx-2" aria-hidden="true" title="Click here to go project setting page"></i></a> -->

                    <a href="javascript:void(0)"  title="Click here to go to project setting page"><i class="fa fa-cog mx-2" aria-hidden="true" (click)="goToProjectSetting()"></i></a>

                    
                    <a href="javascript:void(0)" title="Click here to view project" (click)="viewPage(projectSettingData.group, projectSettingData.projectType)"><i
                            class="fa fa-eye mx-2" aria-hidden="true"></i></a>
                </div>
            </div>
        </div>
        <div class="strategic_priority-outer mt-2">
            <div class="strategic_priority-content">

                <div class="table-responsive">
                    <table class="strategic_priority-Table widthFull">
                        <thead>
                            <tr>
                                <th style="max-width: 30px; width: 30px;"></th>
                                <th class="titleThIcon pl-0">Perceptions of Key Leadership Capabilities & Behaviours</th>
                                <th class="pl-0">Question Notes</th>
                            </tr>
                        </thead>

                        <tbody class="strategic_priority-tbody sortable">
                            <tr *ngFor="let project of projectData">
                                
                                <td style="max-width: 30px; width: 30px;">
                                    <a href="javascript:void(0)" (click)="deleteQuestion(project.id)"><i class="fa fa-trash"></i></a>
                                    <input type="hidden" class="questionId" value="{{project.id}}">
                                </td>
                                <td class="width45per brleftBlue">
                                    <p id="que1{{project.id}}" class="mb-0" (click)="OpenCloseDiv(project.id, project.question)">{{project.question}}</p>
                                    <input class="hideBlock" maxlength="120" id="que2{{project.id}}" type="text" id="questionBox" (blur)="updateQuestion(project.id, project.description, $event.target.value)" placeholder="+Question">
                                </td>
                                <td>
                                    <p *ngIf="project.description" id="ans1{{project.id}}" class="mb-0" (click)="OpenCloseDivAns(project.id, project.description)">{{project.description}}</p>
                                    <input class="hideBlock" maxlength="120" id="ans2{{project.id}}" type="text" (blur)="UpdateQuestionNotes(project.id, project.question, $event.target.value)" placeholder="+Question">

                                    <input *ngIf="!project.description" type="text" (blur)="addQuestionNotes(project.id, project.question, $event.target.value)" maxlength="120" placeholder="Enter Question notes here">
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td class="width45per brleftBlue">
                                    <input type="text" id="questionBox" (blur)="addQuestion($event.target.value)" maxlength="120" placeholder="+Question">
                                    <div *ngIf="notValid" class="alert alert-danger mt-2">
                                        <strong>{{message}}.</strong>
                                    </div>
                                </td>
                                <td>
                                    <input type="text" id="answerBox" (blur)="addQuestionNotesSecond($event.target.value)" placeholder="Enter Question notes here">
                                    <div *ngIf="notValid1" class="alert alert-danger mt-2">
                                        <strong>{{message1}}.</strong>
                                    </div>
                                </td>
                            </tr>


                        </tbody>

                    </table>
                </div>

            </div>
        </div>

    </div>