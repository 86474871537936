import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./confirmation-modal.component.scss"],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() title: any = "Please Confirm";
  @Input() message: any = "Do you really want to delete?";
  @Input() btnOkText: string = "Ok";
  @Input() btnCancelText: string = "Cancel";
  option: any = '1';
  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {}

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    console.log(this.option)
    this.activeModal.close(this.option);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
