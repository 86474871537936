import {
  Component,
  OnInit,
  ViewChild,
  ɵConsole,
  ComponentFactoryResolver,
} from "@angular/core";
import { CommonService } from "../../services/commonservices";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, Validators } from "@angular/forms";
import { GrowlerService } from "../../services/growler.service";
import * as $ from "jquery";
import "jquery-ui-dist/jquery-ui";

@Component({
  selector: "app-project-sfr",
  templateUrl: "./project-sfr.component.html",
  styleUrls: ["./project-sfr.component.scss"],
})
export class ProjectSfrComponent implements OnInit {
  public showLoder: boolean = true;
  permission: any = [];
  globalRoles: any = [];
  projectId: any;
  notValid: boolean = false;
  notValid1: boolean = false;
  validTrue: boolean = false;
  projectData: any = [];
  projectSettingData: any = {};
  ProjectPeriod: any = [];
  loading: boolean = false;
  notesdata: any;
  message: any;
  message1: any;
  activeProjectId: any;
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    public growlerService: GrowlerService
  ) {}

  ngOnInit(): void {
    this.activeProjectId = localStorage.getItem("editProjectId");
    this.projectSettingData.projectName = "";
    this.projectId = localStorage.getItem("editProjectId");
    this.notesdata = "";
    this.getSfrProjectData();
    this.getProjectSetting(this.projectId);
    let roles = localStorage.getItem("roles");
    this.getPermissionByRole(roles);
    this.getUserPermissionOnProject(this.activeProjectId);
    this.changeOrder();
  }
  checkPermission(permissionKey, permissions) {
    if (
      (permissions != null &&
        (permissions[permissionKey] === undefined ||
          !permissions[permissionKey])) ||
      (permissions === null && this.globalRoles[permissionKey] === undefined)
    ) {
      return true;
    }
  }
  viewPage(is_group, project_type) {
    localStorage.setItem("editProjectId", this.projectId);
    localStorage.setItem("pType", project_type);
    localStorage.setItem("gType", is_group);
    var projType = "sfr_view_project";
    if (this.checkPermission(projType, this.permission)) {
      alert("You do not have permission to edit project");
      return false;
    }
    this.router.navigateByUrl("/feedback-sfr");
  }
  getUserPermissionOnProject(projectId) {
    var data = {
      projectId: projectId,
    };
    this.commonService.apiCall(data, "getUserPermissionOnProject").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.permission = result["data"].ResponseData;
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  goToProjectSetting(){
    var projectKey = "sfr_change_settings";
     if (this.checkPermission(projectKey, this.permission)) {
      alert("You do not have permissions to change project setting.");
      return false;
    }

    this.router.navigateByUrl("/project-settings");
  }
  getPermissionByRole(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = result["data"].ResponseData;
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  changeOrder() {
    var self = this;
    $(function () {
      $(".sortable").sortable({
        //placeholder: "ui-state-highlight",
        stop: function (event, ui) {
          self.changeQuestioOrder(
            ui.item.find(".questionId").val(),
            ui.item.index()
          );
          /* alert(
            "New position: " + ui.item.index() +"  and "+ ui.item.find(".questionId").val()
          ); */
        },
      });
      $(".sortable").disableSelection();
    });
  }
  changeQuestioOrder(questionId, order) {
    let data = {
      id: questionId,
      order: order + 1,
      object: "question",
    };
    this.loading = true;
    //call login api here
    this.commonService.changeOrder(data).subscribe(
      (result) => {
        this.loading = false;
        this.notesdata = "";
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.growlerService.success("Order changed successfully");
          this.growlerService.clear();
          this.ngOnInit();
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {
        this.loading = false;
      }
    );
    this.loading = false;
  }
  addSfrQuestion(question, description, id) {
    let data = {
      id: id,
      projectId: this.projectId,
      question: question,
      description: description,
    };

    this.loading = true;
    //call login api here
    this.commonService.addSfrQuestion(data).subscribe(
      (result) => {
        this.loading = false;
        this.notesdata = "";
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.notesdata = "";
          $("#questionBox").val("");
          $("#answerBox").val("");
          this.growlerService.success("Question/Notes added successfully");
          this.growlerService.clear();
          this.ngOnInit();
          /* this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage; */
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }
  deleteQuestion(id) {
    if(confirm("Are you sure to delete this question ?")){


    let data = {
      id: id,
      isdelete: 1,
    };

    this.loading = true;
    //call login api here
    this.commonService.addSfrQuestion(data).subscribe(
      (result) => {
        this.loading = false;
        this.notesdata = "";
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.notesdata = "";
          $("#questionBox").val("");
          $("#answerBox").val("");
          this.growlerService.success("Question deleted successfully");
          this.growlerService.clear();
          this.ngOnInit();
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
    }
  }
  getSfrProjectData() {
    let data = {
      projectId: this.projectId,
    };
    this.loading = true;
    //call login api here
    this.commonService.getSfrProjectData(data).subscribe(
      (result) => {
        this.loading = false;
        this.notesdata = "";
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.projectData = result["data"]["ResponseData"].questionData;

          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
    this.loading = false;
  }
  addQuestion(e: any) {
    if (e != "") {
      let id = "";
      var description = "";
      if (this.notesdata) {
        description = this.notesdata;
      }
      this.addSfrQuestion(e, description, id);
    } else {
      this.notValid = true;
      setTimeout(() => {
        this.notValid = false;
      }, 4000);
      this.message = "Please enter a valid Question";
    }
  }
  addQuestionNotes(id: any, que: any, notes: any) {
    if (notes != "") {
      let description = "";
      this.addSfrQuestion(que, notes, id);
      this.notesdata = "";
    } else {
    }
  }
  updateQuestion(id: any, notes: any, que: any) {
    if (que != "") {
      let description = "";
      this.addSfrQuestion(que, notes, id);
      this.notesdata = "";
    } else {
    }
  }
  UpdateQuestionNotes(id: any, que: any, notes: any) {
    if (notes != "") {
      let description = "";
      this.addSfrQuestion(que, notes, id);
      this.notesdata = "";
    } else {
    }
  }
  addQuestionNotesSecond(notes: any) {
    if (notes != "") {
      let description = "";
      this.notesdata = notes;
    } else {
      this.notesdata = "";
      /* this.notValid1 = true;
      setTimeout(() => {
        this.notValid1 = false;
      }, 4000);
      this.message1 = "Please enter a valid Question notes"; */
    }
  }
  changeObjToArray(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });
    return project;
  }
  OpenCloseDiv(queId, queText) {
    $("#que2" + queId).show();
    $("#que1" + queId).hide();

    $("#que2" + queId).val(queText);
  }
  OpenCloseDivAns(queId, notesText) {
    $("#ans2" + queId).show();
    $("#ans1" + queId).hide();

    $("#ans2" + queId).val(notesText);
  }
  getProjectSetting(activeProjectId) {
    let projectDetail = {
      projectId: activeProjectId,
    };
    this.commonService
      .editProjectSetting(projectDetail)
      .subscribe((projectSettingResultData) => {
        if (!projectSettingResultData["success"]) {
          this.message = projectSettingResultData["error"];
        }

        if (
          projectSettingResultData["success"] &&
          projectSettingResultData["data"].ResponseCode == 200
        ) {
          //let projectData= projectSettingResultData["data"].ResponseData.data;
          this.projectSettingData =
            projectSettingResultData["data"].ResponseData.data[0];
        } else {
          this.message = projectSettingResultData["data"].ResponseMessage;
        }
      });
  }
}


