import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { CommonService } from "../services/commonservices";


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  helper: any = new JwtHelperService();
  constructor(
    public userService: CommonService,
    private router: Router,
    private authService: AuthService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authToken: string = this.userService.getCurrentUser()
      ? this.userService.getCurrentUser().token
      : "";
    const isExpired = authToken ? this.helper.isTokenExpired(authToken) : false;

    if (isExpired && localStorage.getItem("isLoggedIn") === "true") {
      alert("Your session has been expired, Please log in again.");
      this.authService.logout();

    }
    if (request.method == "POST") {

      if (authToken) {
        request = request.clone({
         headers: request.headers.set("Authorization", authToken)
        });
      }

      if (!request.headers.has("Content-Type")) {
        request = request.clone({
          headers: request.headers.set("Content-Type", "application/json")
        });
      }
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let data = {};
        data = {
          reason: error && error.error.reason ? error.error.reason : "",
          status: error.status
        };
        return throwError(error);
      })
    );
  }
}