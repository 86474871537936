import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
   // initColSpan: number;
   constructor() {
    //let initColSpan: number = 6;
   }
  
  ngOnInit(): void {
    $( function() {

        $(".divSlider").each(function(index,value  ) {
            var handle = $("#custom-handle",$(this));
           var myslider =$("#bonusScore", $(this)).slider({// $this is a reference to .slider in current iteration of each
                               orientation:"horizantal",
                               range: "min",
                       value:1,
                       step: 1,
                       min:1,
                       max:10,
                       animate: "fast",// handle smoothly when the user clicks on the slider track
                         create: function() {
                           handle.html( $( this ).slider('value'));
                           $(this).find('.ui-slider-handle').html($( this ).slider('value'));
                       } , 
                       slide: function( event, ui ) {
                           $(this).find(".ui-slider-handle").html(ui.value);
                           $("#bonusScore").slider().find(".ui-slider-handle");
                                            
                           $(this).parents("tr").find('input[name="assesmentScore"]').val(ui.value);
                            
                       }					 
                           });
                        });

      });

    $( function() {
        $( "#sortable" ).sortable({
            placeholder: "ui-state-highlight"
          });
        $( "#sortable" ).disableSelection();
      } );
    function BindResizing(selectedElements) {
    //    selectedElements = ""; // This is to disable resizable functionality as client does not require it now, In future if required again then remove/comment this line  - 04-07-2022
        $(selectedElements).resizable({
            handles: 'e',
            helper: "ui-resizable-helper",
            stop: function(event, ui) {

                if (ui.originalSize.width < ui.size.width) {

                    if ($(ui.element).next().hasClass("disableResizeDrag")) {
                        return false;
                    }
                    //debugger;

                    var maxAllowedWidth = ui.size.width;
                    var initWidth = ui.originalSize.width;
                    //start with 0
                    var initIndex = $(ui.element).index() + 1;
                    var initColSpan = parseInt($(ui.element).prop("colspan")) || 1;

                    var colLength = $(ui.element).closest("tr").find("td").length - $(ui.element).closest("tr").find("td").filter(function() {
                        return $(this).index() > initIndex && $(this).hasClass("disableResizeDrag")
                    }).length;;

                    while (initWidth <= maxAllowedWidth && initIndex < colLength) {

                        //var selectEleWidth = $($(ui.element).closest("tr").find("td")[initIndex]).width();

                        //var selectEleWidth = $(ui.element).width() + parseFloat($(ui.element).css("padding-left")) + parseFloat($(ui.element).css("padding-right")) + parseFloat($(ui.element).css("border-left")) + parseFloat($(ui.element).css("border-right"))

                        //Remove last 2 params due to firefox compatitbility
                        var selectEleWidth = $($(ui.element).closest("tr").find("td")[initIndex]).width() + parseFloat($($(ui.element).closest("tr").find("td")[initIndex]).css("padding-left")) + parseFloat($($(ui.element).closest("tr").find("td")[initIndex]).css("padding-right")); // + parseFloat($(ui.element).css("border-left")) + parseFloat($(ui.element).css("border-right"))

                        //Add some constant as border
                        selectEleWidth = (selectEleWidth || 0) + 2;

                        if (selectEleWidth) {
                            initColSpan += $($(ui.element).closest("tr").find("td")[initIndex]).prop("colspan") || 1;
                            initWidth += (selectEleWidth || 0);
                        }

                        initIndex += 1;
                    }
                    $(ui.element).prop("colspan", initColSpan);
                    //$(ui.element).css("width", initWidth);

                    $(ui.element).closest("tr").find("td").filter(function() {
                        return $(this).index() < initIndex && $(this).index() > $(ui.element).index()
                    }).remove();

                    ui.size.width = ui.originalSize.width;


                    $(ui.element).closest("table.time_management_personal-table").find("td").removeAttr("style");

                    var columnSize = $(ui.element).closest("tr").find("td").length;

                    if ($(ui.element).closest("table.time_management_personal-table").find("tr").filter(function() {
                            return $(this).find("td").length != columnSize
                        }).length == 0) {
                        $(ui.element).closest("table.time_management_personal-table").find("td").removeAttr("colspan");
                    }

                } else if (ui.originalSize.width > ui.size.width) {

                    var initColSpan = parseInt($(ui.element).prop("colspan") || 1);

                    if (initColSpan > 1) {

                        $(ui.element).prop("colspan", initColSpan - 1);

                        var newColumn = $(ui.element).clone().removeAttr("colspan").removeAttr("style");

                        //$(newColumn).find(".dragDivOuter").html($("<input>", {placeholder:'Enter task here', class:"text-centre", style:"border:none", type:'text'}));
                        
                        newColumn.find("div:not(.dragDivOuter)").remove();

                        $(newColumn).find(".dragDivOuter").html($("<input>", {placeholder:'Enter task here', class:"text-center", style:"border:none", type:'text'}));
                        
                        $(ui.element).after(newColumn);
                        
                        $gallery = $("table.time_management_personal-table tr td:not(.disableResizeDrag)");
                        //AddDragDropEvent($(newColumn).find(".dragDivOuter"));

                        BindDragDrop(".dragDivOuter");

                        BindResizing(newColumn);
                    }

                    $("table.time_management_personal-table tr").find("td:not(.disableResizeDrag):last").filter(function() {
                        return (parseInt($(this).attr("colspan")) || 0) <= 1;
                    }).addClass("ui-resizable-disabled");

                }

            }
        });

    }

    //Resizing
    $(function() {

        BindResizing($("table.time_management_personal-table tr").find("td:not(.disableResizeDrag):not(:last)"));

        BindDragDrop(".dragDivOuter");
    });



    /**
     * selector will only a string
     */

    var $gallery = $("table.time_management_personal-table tr td:not(.disableResizeDrag)");


    function BindDragDrop(selector) {

        if (typeof $gallery == "undefined" || $gallery == undefined) {
            $gallery = $("table.time_management_personal-table tr td:not(.disableResizeDrag)");
        }

        // Let the gallery items be draggable
        $(selector, $gallery).draggable({
            cancel: "a.ui-icon", // clicking an icon won't initiate dragging
            revert: "invalid", // when not dropped, the item will revert back to its initial position
            containment: "document",
            helper: "clone",
            cursor: "move"
        });


        // Let the gallery be droppable as well, accepting items from the trash
        $gallery.droppable({
            accept: selector, //"table.time_management_personal-table tr td:not(.disableResizeDrag) .dragDivOuter",
            classes: {
                "ui-droppable-active": "custom-state-active"
            },
            drop: function(event, ui) {
                //debugger;

                DragEvent(event, ui)

            }
        });


    }

    function DragEvent(event, ui) {

        if ($(ui.draggable).closest("tr").find(event.target).length == 0 || $(event.target).hasClass("disableResizeDrag")) {
            return false;
        }

        var clone = $(ui.draggable).clone();

        AddDragDropEvent(clone);

        $(event.target).html(clone[0]);
        $(ui.draggable).html($("<input>", {
            placeholder: 'Enter task here',
            class: "text-center",
            style: "border:none; width:100%",
            type: 'text'
        }));

        if ($(event.target).closest("tr").find("td:not(.disableResizeDrag):last").not(event.target).length == 1 || $(event.target).closest("tr").find("td:not(.disableResizeDrag):last").filter(function() {
                return (parseInt($(this).attr("colspan")) || 0) > 1;
            }).length == 1) {
            BindResizing($(event.target));
        }

        //$(ui.draggable).detach().appendTo(event.target);
    }

    function AddDragDropEvent(selector) {
        $(selector, $gallery).draggable({
            cancel: "a.ui-icon", // clicking an icon won't initiate dragging
            revert: "invalid", // when not dropped, the item will revert back to its initial position
            containment: "document",
            helper: "clone",
            cursor: "move"
        });

    }


$(document).on("click", '.dragDivOuter input', function() {
    $(this).focus();
});


  }

}


