<header class="header_body" *ngIf="isLoggedIn == 'true'">
    <div class="row">
        <div class="col-lg-7"></div>
        <div class="col-lg-5">
            <div class="header__topbar-wrapper text-right headerTopPaddipad">
                <div class="d-inline-block text-right header_userDetail">
                    <p class="largeFontSize"><a href="javascript:void(0);" (click)="onLogout()"><i class="fa fa-power-off pr-2" aria-hidden="true" title="Click here to logout"></i></a></p>
                </div>
                <div class="d-inline-block text-right header_userDetail">
                    <p class="">
                        <span>
                        <a href="javaScript:void(0)" (click)="profilePage()"><i class="fa fa-pencil" aria-hidden="true"></i> </a>{{username}}
                    </span>
                    </p>
                    <p class="">{{useremail}}</p>
                </div>

                <div class="d-inline-block headeruserImg">
                    <!--  <a href="javaScript:void()" routerLink="/my-profile"> -->
                    <img *ngIf="profile_image" alt="Pic" [src]='profile_image'>
                    <img *ngIf="!profile_image" alt="Pic" src="assets/images/dummy-img.png">
                    <!-- </a> -->
                </div>
            </div>
        </div>
    </div>
</header>