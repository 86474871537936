<app-growler>
</app-growler>
<app-header></app-header>
<div class="wrapper">
    <div class="inner-container headerTopPaddipad">
        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-10 col-xl-8">
                    <input type="text" class="projectTitleInputBox" value="Chifley Corporation (Matt Tench)">
                    <img src="./assets/images/individual-sop-icon.png" alt="icon" height="15"> <span class="projectType">Individual</span>
                    <a href="javascript:void(0);"><i class="fa fa-pencil mx-2" aria-hidden="true"></i></a>
                    <a href="javascript:void(0);"><i class="fa fa-cog mx-2" aria-hidden="true"></i></a>

                </div>
            </div>
        </div>

        <div class="white_box-body">
            <div class="sfp_project_header-outer">
                <div class="row">
                    <div class="col-md-6">
                        <h3 class="sfp_project-title">
                            <span>Displaying period: March 2019 <a href="javascript:void(0);"><i class="fa fa-download mx-2"
                  aria-hidden="true"></i></a></span>
                        </h3>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6 text-right">

                                <div class="switcherOuter-body mt-2">
                                    <span> Compare mode ON</span>
                                    <div class="switchToggle d-inline-block ml-2">
                                        <input type="checkbox" id="switch">
                                        <label for="switch"></label>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="inputState" class="col-sm-5 col-form-label text-right">Compare period</label>
                                    <div class="col-sm-7">
                                        <select id="inputState" class="form-control">
                    <option selected>Select Period</option>
                    <option>...</option>
                  </select>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="table-responsive">
                <div class="sfpProject_table-outer">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="3" rowspan="2" class="text-left"><span>Perceptions of Key Leadership Capabilities &
                  Behaviours</span></th>
                                <th colspan="4"> Summary </th>
                                <th colspan="10">Score Results</th>
                            </tr>
                            <tr>
                                <th><small>Mean</small></th>
                                <th><small>Median</small> </th>
                                <th><small>Min</small></th>
                                <th><small>Max</small></th>

                                <th><small>1</small></th>
                                <th><small>2</small></th>
                                <th><small>3</small></th>
                                <th><small>4</small></th>
                                <th><small>5</small></th>
                                <th><small>6</small></th>
                                <th><small>7</small></th>
                                <th><small>8</small></th>
                                <th><small>9</small></th>
                                <th><small>10</small></th>

                            </tr>
                        </thead>

                        <tbody>

                            <tr>
                                <td><span class="text-center">1</span></td>
                                <td><span>Attract and develop talent</span></td>
                                <td> <a href="javascript:void(0);"><i class="fa fa-question-circle" aria-hidden="true"></i></a> </td>
                                <td>6.1</td>
                                <td>5.5</td>
                                <td>4.2</td>
                                <td>8.2</td>

                                <td colspan="10">
                                    <div class="scoreResult_bar-body">
                                        <div class="scoreBarpipe current_Result_Bar-Bg" style="left: 42%; width: 40%;">
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">4.2</span>
                                            <span class="scoreBarpipe_Circle"></span>
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">4.4</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><span class="text-center">2</span></td>
                                <td><span>People learning</span></td>
                                <td> <a href="javascript:void(0);"><i class="fa fa-question-circle" aria-hidden="true"></i></a> </td>

                                <td>6.1</td>
                                <td>5.5</td>
                                <td>4.2</td>
                                <td>8.2</td>

                                <td colspan="10">
                                    <div class="scoreResult_bar-body">
                                        <div class="scoreBarpipe current_Result_Bar-Bg" style="left: 21%; width: 30%;">
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                            <span class="scoreBarpipe_Circle"></span>
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td><span class="text-center">2</span></td>
                                <td><span>Delegating</span></td>
                                <td> <a href="javascript:void(0);"><i class="fa fa-question-circle" aria-hidden="true"></i></a> </td>
                                <td>6.1</td>
                                <td>5.5</td>
                                <td>4.2</td>
                                <td>8.2</td>

                                <td colspan="10">
                                    <div class="scoreResult_bar-body">
                                        <div class="scoreBarpipe current_Result_Bar-Bg" style="left: 55%; width: 30%;">
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                            <span class="scoreBarpipe_Circle"></span>
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td><span class="text-center">2</span></td>
                                <td><span>Managing own time</span></td>
                                <td> <a href="javascript:void(0);"><i class="fa fa-question-circle" aria-hidden="true"></i></a> </td>

                                <td>6.1</td>
                                <td>5.5</td>
                                <td>4.2</td>
                                <td>8.2</td>

                                <td colspan="10">
                                    <div class="scoreResult_bar-body">
                                        <div class="scoreBarpipe current_Result_Bar-Bg" style="left: 26%; width: 30%;">
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                            <span class="scoreBarpipe_Circle"></span>
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td><span class="text-center">2</span></td>
                                <td><span>Communication</span></td>
                                <td> <a href="javascript:void(0);"><i class="fa fa-question-circle" aria-hidden="true"></i></a> </td>

                                <td>6.1</td>
                                <td>5.5</td>
                                <td>4.2</td>
                                <td>8.2</td>

                                <td colspan="10">
                                    <div class="scoreResult_bar-body">
                                        <div class="scoreBarpipe current_Result_Bar-Bg" style="left: 56%; width: 30%;">
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                            <span class="scoreBarpipe_Circle"></span>
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td><span class="text-center">2</span></td>
                                <td><span>Managing diversity in teams (all diversity types)</span></td>
                                <td> <a href="javascript:void(0);"><i class="fa fa-question-circle" aria-hidden="true"></i></a> </td>

                                <td>6.1</td>
                                <td>5.5</td>
                                <td>4.2</td>
                                <td>8.2</td>

                                <td colspan="10">
                                    <div class="scoreResult_bar-body">
                                        <div class="scoreBarpipe current_Result_Bar-Bg" style="left: 35%; width: 30%;">
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                            <span class="scoreBarpipe_Circle"></span>
                                            <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
            </div>



            <div class="sfp_project_header-outer mt-5">
                <div class="row">
                    <div class="col-md-7">
                        <h3 class="sfp_project-title">
                            <span>Displaying period:

              <div class="currentMonth_Box d-inline-block text-center">
                <p>March 2019 (Current)</p>
                <span class="progressPipe_currentbg current_Result_Bar-Bg"></span>
                    </div>
                    <div class="currentMonth_Box d-inline-block text-center">
                        <p>September 2018</p>
                        <span class="progressPipe_currentbg compareMonth_Result_Bar-Bg"></span>
                    </div>

                    <a href="javascript:void(0);"><i class="fa fa-download mx-2" aria-hidden="true"></i></a>
                    </span>
                    </h3>
                </div>
                <div class="col-md-5">
                    <div class="row">
                        <div class="col-md-5 text-right">

                            <div class="switcherOuter-body mt-2">
                                <span> Compare mode ON</span>
                                <div class="switchToggle d-inline-block ml-2">
                                    <input type="checkbox" id="switch2" checked>
                                    <label for="switch2"></label>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="form-group row">
                                <label for="inputState" class="col-sm-5 col-form-label text-right">Compare period</label>
                                <div class="col-sm-7">
                                    <select id="inputState" class="form-control">
                    <option selected>Select Period</option>
                    <option>...</option>
                  </select>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <div class="table-responsive">
            <div class="sfpProject_table-outer sfpProject_table-comapreMode ">
                <table>
                    <thead>
                        <tr>
                            <th colspan="3" rowspan="2" class="text-left"><span>Perceptions of Key Leadership Capabilities &
                  Behaviours</span></th>
                            <th colspan="4"> Summary </th>
                            <th colspan="10">Score Results</th>
                        </tr>
                        <tr>
                            <th><small>Current Period Mean</small></th>
                            <th><small>Compared Period Mean</small> </th>
                            <th><small>Delta</small></th>
                            <th><small>Trend</small></th>

                            <th><small>1</small></th>
                            <th><small>2</small></th>
                            <th><small>3</small></th>
                            <th><small>4</small></th>
                            <th><small>5</small></th>
                            <th><small>6</small></th>
                            <th><small>7</small></th>
                            <th><small>8</small></th>
                            <th><small>9</small></th>
                            <th><small>10</small></th>

                        </tr>
                    </thead>

                    <tbody>

                        <tr>
                            <td><span class="text-center">1</span></td>
                            <td><span>Attract and develop talent</span></td>
                            <td> <a href="javascript:void(0);"><i class="fa fa-question-circle" aria-hidden="true"></i></a> </td>

                            <td>6.1</td>
                            <td>5.5</td>
                            <td>4.2</td>
                            <td><i class="fa fa-arrow-up" aria-hidden="true"></i></td>

                            <td colspan="10">
                                <div class="scoreResult_bar-body">
                                    <div class="scoreBarpipe current_Result_Bar-Bg" style="left: 42%; width: 40%;">
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">4.2</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">4.4</span>
                                    </div>
                                    <div class="scoreBarpipe compareMonth_Result_Bar-Bg" style="left: 40%; width: 25%;">
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><span class="text-center">2</span></td>
                            <td><span>People learning</span></td>
                            <td> <a href="javascript:void(0);"><i class="fa fa-question-circle" aria-hidden="true"></i></a> </td>

                            <td>6.1</td>
                            <td>5.5</td>
                            <td>4.2</td>
                            <td><i class="fa fa-arrow-down" aria-hidden="true"></i></td>

                            <td colspan="10">
                                <div class="scoreResult_bar-body">
                                    <div class="scoreBarpipe current_Result_Bar-Bg" style="left: 21%; width: 30%;">
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                    </div>
                                    <div class="scoreBarpipe compareMonth_Result_Bar-Bg" style="left: 40%; width: 25%;">
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td><span class="text-center">2</span></td>
                            <td><span>Delegating</span></td>
                            <td> <a href="javascript:void(0);"><i class="fa fa-question-circle" aria-hidden="true"></i></a> </td>

                            <td>6.1</td>
                            <td>5.5</td>
                            <td>4.2</td>
                            <td><i class="fa fa-arrow-down downRight_angle" aria-hidden="true"></i></td>

                            <td colspan="10">
                                <div class="scoreResult_bar-body">
                                    <div class="scoreBarpipe current_Result_Bar-Bg" style="left: 55%; width: 30%;">
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                    </div>
                                    <div class="scoreBarpipe compareMonth_Result_Bar-Bg" style="left: 40%; width: 25%;">
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td><span class="text-center">2</span></td>
                            <td><span>Managing own time</span></td>
                            <td> <a href="javascript:void(0);"><i class="fa fa-question-circle" aria-hidden="true"></i></a> </td>

                            <td>6.1</td>
                            <td>5.5</td>
                            <td>4.2</td>
                            <td><i class="fa fa-arrow-up upRight_angle" aria-hidden="true"></i></td>

                            <td colspan="10">
                                <div class="scoreResult_bar-body">
                                    <div class="scoreBarpipe current_Result_Bar-Bg" style="left: 26%; width: 30%;">
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                    </div>
                                    <div class="scoreBarpipe compareMonth_Result_Bar-Bg" style="left: 40%; width: 25%;">
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td><span class="text-center">2</span></td>
                            <td><span>Communication</span></td>
                            <td> <a href="javascript:void(0);"><i class="fa fa-question-circle" aria-hidden="true"></i></a> </td>

                            <td>6.1</td>
                            <td>5.5</td>
                            <td>4.2</td>
                            <td><i class="fa fa-arrow-down" aria-hidden="true"></i></td>

                            <td colspan="10">
                                <div class="scoreResult_bar-body">
                                    <div class="scoreBarpipe current_Result_Bar-Bg" style="left: 56%; width: 30%;">
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                    </div>
                                    <div class="scoreBarpipe compareMonth_Result_Bar-Bg" style="left: 20%; width: 25%;">
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td><span class="text-center">2</span></td>
                            <td><span>Managing diversity in teams (all diversity types)</span></td>
                            <td> <a href="javascript:void(0);"><i class="fa fa-question-circle" aria-hidden="true"></i></a> </td>

                            <td>6.1</td>
                            <td>5.5</td>
                            <td>4.2</td>
                            <td><i class="fa fa-arrow-down downRight_angle" aria-hidden="true"></i></td>

                            <td colspan="10">
                                <div class="scoreResult_bar-body">
                                    <div class="scoreBarpipe current_Result_Bar-Bg" style="left: 35%; width: 30%;">
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                    </div>
                                    <div class="scoreBarpipe compareMonth_Result_Bar-Bg" style="left: 40%; width: 25%;">
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">2.1</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">5.4</span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </tbody>

                </table>
            </div>
        </div>


    </div>

</div>
</div>