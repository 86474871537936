<app-growler *ngIf="htmlOptions.download">
</app-growler>
<app-header *ngIf="htmlOptions.download"></app-header>
<div class="wrapper">
    <div class="loaderOuter" *ngIf="loading">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
    <app-nav-bar *ngIf="htmlOptions.download"></app-nav-bar>
    <div class=" headerTopPaddipad" id="contentToConvert" [ngClass]="{'inner-container' : htmlOptions.download}">
        <div class="header_left-part" *ngIf="htmlOptions.download">
            <div class="row">
                <div class="col-lg-7 col-xl-8">
                    <span class="ptypespan">
                        <img *ngIf="gType == 1" src="./assets/images/individual-sop-icon2.png" alt="icon" height="19">
                        <img *ngIf="gType == 2" src="./assets/images/group-sop-icon2.png" alt="icon" height="19">
                    </span>
                    <h3 class="sfp_project-title"> {{projectDetail.title}}</h3>

                    <a routerLink="/project-settings"><i class="fa fa-cog mx-2" aria-hidden="true" title="Click here to go to project settings"></i></a>
                    <a href="javascript:void(0)" title="Click here to edit project" (click)="editPage(gType, projectDetail.projectType)"><i class="fa fa-pencil mx-2"
                            aria-hidden="true"></i></a>
                    <a href="javascript:void(0)" (click)="captureScreen()"><i class="fa fa-download mx-2" aria-hidden="true" title="Click here to download report"></i></a>
                    <a href="javaScript:void(0)" id="fullScreenBtn" onclick="fullScreen()"><i class="fullScreenIcon mx-2"> <img width="23" src="./assets/images/fulllScreen.png"> </i></a>
                </div>
                <div class="col-lg-5 col-xl-4">
                    <div class="form-group row mb-0">
                        <label class="col-md-3 col-xl-6 col-5 mt-2 pl-0 text-right">METRIC TYPE<span class="req">*</span></label>
                        <select class="form-control col-6 col-md-6 col-xl-6" name="projectType" (change)="changeProjectType($event)">
                            <option [value]="null">--Select--</option>
                            <option [value]="1">Average</option>
                            <option [value]="2">Median</option>
                            <option [value]="3">Adjusted Average</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="strategic_priority-outer strategic_priority-viewMode">
            <div class="strategic_priority-content" *ngIf="projectData">
                <!-- table-responsive -->
                <div *ngFor="let project of projectData; let k= index">

                    <table class="strategic_priority-Table">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="titleThIcon pl-0">{{project.title}}</th>
                                <ng-container *ngIf="ExtraColumn">
                                    <th class="text-center" *ngFor="let column of ExtraColumn">
                                        <p id="{{column.id}}" *ngIf="k==0">{{column.title}}</p>
                                    </th>
                                </ng-container>
                                <th class="titleThIcon pl-0 text-center" *ngIf="projectDetail.ownerColumn">
                                    <p *ngIf="k==0"> Owner</p>
                                </th>
                                <th class="text-center" *ngFor="let Period of ProjectPeriod">
                                    <p *ngIf="k==0">{{Period.title}}</p>
                                </th>
                                <th style="width: 35px;"></th>
                                <th style="min-width: 220px;">
                                    <p *ngIf="k==0">What success looks like...</p>
                                </th>
                            </tr>
                        </thead>

                        <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.individualMode">

                            <ng-container *ngIf="project.InitativeData">
                                <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                    <td></td>
                                    <td class="text-left widthSefix strategicIntTitle">
                                        <p>{{Initative.title}}</p>
                                    </td>
                                    <ng-container *ngIf="ExtraColumn">
                                        <td class="periodsBoxOuter" *ngFor="let Column of ExtraColumn">
                                            <span class="text-center" *ngFor="let extra of changeObjToArrayForTasks(Initative.extraFields) ">
                                                <p *ngIf="extra.id==Column.id">{{extra.title}} </p>
                                            </span>
                                        </td>
                                    </ng-container>
                                    <td style="max-width: 100px !important;width: 100px !important;min-width: 100px !important;" class="periodsBoxOuter" *ngIf="projectDetail.ownerColumn">
                                        <p *ngIf="Initative.userId">{{Initative.userId}}</p>
                                    </td>
                                    <ng-container *ngFor="let Periods of ProjectPeriod; let idx= index">
                                        <ng-container *ngIf="Initative.period[Periods.id]">
                                            <ng-container *ngIf="hideDivNextTime(Initative.period,Periods.id)">
                                                <td class="periodsBoxOuter" class="periodsBoxOuter" id="{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}-{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.title:''}}" [attr.colspan]="setColspanValue(Initative.period,Periods.id,'')">
                                                    <span class="text-center" *ngFor="let Period of changeObjToArrayForTasks(Initative.period) ; let i= index">
                                    <p *ngIf="Period.periodId==Periods.id">{{Period.Task.title}} </p>
                                    <ng-container *ngIf="setColspanValue(Initative.period,Periods.id,i)>1">
                                    <ng-container >
                                    <ng-container *ngFor="let in of counter(setColspanValue(Initative.period,Periods.id,i)) ; let pdx= index">
                                        <p class="dropdownLeftSide">
                                        <input [readonly]="true" *ngIf="Period.periodId==Periods.id && Period.rating && pdx==0" class="ratingBoxBlank" type="text" value="{{getRound(Period.rating)}}" (click)="getRatingAndComment(Period.Task.id, Periods.id)" class="rating{{getRound(Period.rating)}}bg"></p>
                                        <p class="dropdownRightSide">
                                        <input [readonly]="true" *ngIf="Period.periodId==getNextId(Periods.id) && Period.rating && pdx!=0" class="ratingBoxBlank" type="text" value="{{getRound(Period.rating)}}" (click)="getRatingAndComment(Period.Task.id, getNextId(Periods.id))" class="rating{{getRound(Period.rating)}}bg">
                                    </p>
                                    <div class="dropdown dropdownLeftSide" *ngIf="Period.periodId==Periods.id && !Period.rating && pdx==0">
                                        <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" role="button" (click)="blankBox()"
                                            id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        </a>


                                        <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                            <div class="divSlider slider">
                                                <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                    <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                </div>
                                            </div>
                                            <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                id='range{{Period.Task.id}}{{Period.periodId}}' class="" value="">
                                            <textarea rows="2" class="form-control"
                                                [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                    (click)="addRating(Period.Task.id,Period.periodId, feedback.text)">Submit</a>
                                        </div>
                                    </div>
                                    <div class="dropdown dropdownRightSide pp" *ngIf="Period.periodId==getNextId(Periods.id) && !Period.rating && pdx==0">
                                        <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" role="button" (click)="blankBox()"
                                            id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        </a>

                                        <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                            <div class="divSlider slider">
                                                <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                    <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                </div>
                                            </div>
                                            <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                id='range{{Period.Task.id}}{{Period.periodId}}' class="" value="">
                                            <textarea rows="2" class="form-control"                                                 [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                    (click)="addRating(Period.Task.id,Period.periodId, feedback.text)">Submit</a>
                                        </div>
                                    </div>
                                    </ng-container>
                                    </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="setColspanValue(Initative.period,Periods.id,i)==1">
                                                <p class="dropdownFullSize" >
                                                    <input [readonly]="true" *ngIf="Period.periodId==Periods.id && Period.rating" class="ratingBoxBlank" (click)="getRatingAndComment(Period.Task.id, Periods.id)" type="text"
                                                        value="{{getRound(Period.rating)}}" class="rating{{getRound(Period.rating)}}bg">
                                                </p>
                                                <div class="dropdown dropdownFullSize" *ngIf="Period.periodId==Periods.id && !Period.rating">
                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" href="javascript:void(0)" (click)="blankBox()"
                                                        role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                    </a>
                                                    <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                                        <div class="divSlider slider">
                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                            </div>
                                                        </div>
                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                            id='range{{Period.Task.id}}{{Period.periodId}}' class="" value="">
                                                        <textarea rows="2" class="form-control"

                                                            [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                            <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                (click)="addRating(Period.Task.id,Period.periodId, feedback.text)">Submit</a>
                                                    </div>
                                                </div>
                                    </ng-container>
                                    </span>
                                                </td>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="!Initative.period[Periods.id]">
                                            <td class="periodsBoxOuter"></td>
                                        </ng-container>
                                    </ng-container>
                                    <td></td>
                                    <td class="strategicSuccessNote">
                                        <p>{{Initative.title}}</p>
                                    </td>
                                </tr>
                            </ng-container>

                        </tbody>
                        <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.averageMode">
                            <ng-container *ngIf="project.InitativeData">
                                <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                    <td></td>
                                    <td class="text-left widthSefix strategicIntTitle">
                                        <p>{{Initative.title}}</p>
                                    </td>
                                    <ng-container *ngIf="ExtraColumn">
                                        <td class="" *ngFor="let Column of ExtraColumn">
                                            <span class="text-center" *ngFor="let extra of changeObjToArrayForTasks(Initative.extraFields) ">
                                                <p *ngIf="extra.id==Column.id">{{extra.title}} </p>
                                            </span>
                                        </td>
                                    </ng-container>
                                    <td class="periodsBoxOuter" *ngIf="projectDetail.ownerColumn">
                                        <p>{{Initative.userId}}</p>
                                    </td>
                                    <ng-container *ngFor="let Periods of ProjectPeriod; let idx= index">
                                        <ng-container *ngIf="Initative.period[Periods.id]">
                                            <ng-container *ngIf="hideDivNextTime(Initative.period,Periods.id)">
                                                <td class="periodsBoxOuter" class="periodsBoxOuter" id="{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}-{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.title:''}}" [attr.colspan]="setColspanValue(Initative.period,Periods.id,'i')">
                                                    <span class="text-center" *ngFor="let Period of changeObjToArrayForTasks(Initative.period) ; let i= index">
                                                    <p *ngIf="Period.periodId==Periods.id">{{Period.Task.title}} </p>
                                                    <ng-container *ngIf="setColspanValue(Initative.period,Periods.id,i)>1">
                                                        <ng-container>
                                                            <ng-container
                                                                *ngFor="let in of counter(setColspanValue(Initative.period,Periods.id,i)) ; let pdx= index">
                                                                <p class="dropdownLeftSide">
                                                                    <input [readonly]="true"
                                                                        *ngIf="Period.periodId==Periods.id && Period.rating && pdx==0"
                                                                        class="ratingBoxBlank" type="text" value="{{averageRating(Period.allRatings)}}"
                                                                        (click)="getRatingAndComment(Period.Task.id, Periods.id)"
                                                                        class="rating{{averageRating(Period.allRatings)}}bg"></p>
                                                                <p class="dropdownRightSide">
                                                                    <input [readonly]="true"
                                                                        *ngIf="Period.periodId==getNextId(Periods.id) && Period.rating && pdx!=0"
                                                                        class="ratingBoxBlank" type="text" value="{{averageRating(Period.allRatings)}}"
                                                                        (click)="getRatingAndComment(Period.Task.id, getNextId(Periods.id))"
                                                                        class="rating{{averageRating(Period.allRatings)}}bg">
                                                                </p>
                                                                <div class="dropdown dropdownLeftSide ss"
                                                                    *ngIf="Period.periodId==Periods.id && !Period.rating && pdx==0">
                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" (click)="blankBox()"
                                                                        href="javascript:void(0)" role="button" id="dropdownMenuLink"
                                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    </a>


                                                                    <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                                                        <div class="divSlider slider">
                                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                <div id="custom-handle" class="ui-slider-handle custom-handle">
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                            id='range{{Period.Task.id}}{{Period.periodId}}' class="" value="">
                                                                        <textarea rows="2" class="form-control"

                                                                            [(ngModel)]="feedback.text"
                                                                            placeholder="Enter your comments"></textarea>
                                                                            <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                (click)="addRating(Period.Task.id,Period.periodId, feedback.text)">Submit</a>
                                                                    </div>
                                                                </div>
                                                                <div class="dropdown dropdownRightSide pp"
                                                                    *ngIf="Period.periodId==getNextId(Periods.id) && !Period.rating && pdx==0">
                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" (click)="blankBox()"
                                                                        href="javascript:void(0)" role="button" id="dropdownMenuLink"
                                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    </a>

                                                                    <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                                                        <div class="divSlider slider">
                                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                <div id="custom-handle" class="ui-slider-handle custom-handle">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                            id='range{{Period.Task.id}}{{Period.periodId}}' class="" value="">
                                                                        <textarea rows="2" class="form-control"

                                                                            [(ngModel)]="feedback.text"
                                                                            placeholder="Enter your comments"></textarea>
                                                                            <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                (click)="addRating(Period.Task.id,Period.periodId, feedback.text)">Submit</a>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="setColspanValue(Initative.period,Periods.id,i)==1">
                                                        <p class="dropdownFullSize">
                                                            <input [readonly]="true" *ngIf="Period.periodId==Periods.id && Period.rating"
                                                                class="ratingBoxBlank" type="text"  (click)="getRatingAndComment(Period.Task.id, Periods.id)" value="{{averageRating(Period.allRatings)}}"
                                                                class="rating{{averageRating(Period.allRatings)}}bg">
                                                        </p>
                                                        <div class="dropdown dropdownFullSize"
                                                            *ngIf="Period.periodId==Periods.id && !Period.rating">
                                                            <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1"
                                                                href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBox()"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            </a>
                                                            <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                                                <div class="divSlider slider">
                                                                    <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                        <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                                    </div>
                                                                </div>
                                                                <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                    id='range{{Period.Task.id}}{{Period.periodId}}' class="" value="">
                                                                <textarea rows="2" class="form-control"

                                                                    [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                                    <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                        (click)="addRating(Period.Task.id,Period.periodId, feedback.text)">Submit</a>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </span>
                                                </td>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="!Initative.period[Periods.id]">
                                            <td class="periodsBoxOuter"></td>
                                        </ng-container>
                                    </ng-container>
                                    <td></td>
                                    <td class="strategicSuccessNote">
                                        <p>{{Initative.title}}</p>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                        <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.medianMode">
                            <ng-container *ngIf="project.InitativeData">
                                <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                    <td></td>
                                    <td class="text-left widthSefix strategicIntTitle">
                                        <p>{{Initative.title}}</p>
                                    </td>
                                    <ng-container *ngIf="ExtraColumn">
                                        <td class="" *ngFor="let Column of ExtraColumn">
                                            <span class="text-center" *ngFor="let extra of changeObjToArrayForTasks(Initative.extraFields) ">
                                                <p *ngIf="extra.id==Column.id">{{extra.title}} </p>
                                            </span>
                                        </td>
                                    </ng-container>
                                    <td class="periodsBoxOuter" *ngIf="projectDetail.ownerColumn">
                                        <p>{{Initative.userId}}</p>
                                    </td>
                                    <ng-container *ngFor="let Periods of ProjectPeriod; let idx= index">
                                        <ng-container *ngIf="Initative.period[Periods.id]">
                                            <ng-container *ngIf="hideDivNextTime(Initative.period,Periods.id)">
                                                <td class="periodsBoxOuter" class="periodsBoxOuter" id="{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}-{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.title:''}}" [attr.colspan]="setColspanValue(Initative.period,Periods.id,'i')">
                                                    <span class="text-center" *ngFor="let Period of changeObjToArrayForTasks(Initative.period) ; let i= index">
                                                    <p *ngIf="Period.periodId==Periods.id">{{Period.Task.title}} </p>
                                                    <ng-container *ngIf="setColspanValue(Initative.period,Periods.id,i)>1">
                                                        <ng-container>
                                                            <ng-container
                                                                *ngFor="let in of counter(setColspanValue(Initative.period,Periods.id,i)) ; let pdx= index">
                                                                <p class="dropdownLeftSide">
                                                                    <input [readonly]="true"
                                                                        *ngIf="Period.periodId==Periods.id && Period.rating && pdx==0"
                                                                        class="ratingBoxBlank" type="text" value="{{medianRating(Period.allRatings)}}"
                                                                        (click)="getRatingAndComment(Period.Task.id, Periods.id)"
                                                                        class="rating{{medianRating(Period.allRatings)}}bg"></p>
                                                                <p class="dropdownRightSide">
                                                                    <input [readonly]="true"
                                                                        *ngIf="Period.periodId==getNextId(Periods.id) && Period.rating && pdx!=0"
                                                                        class="ratingBoxBlank" type="text" value="{{medianRating(Period.allRatings)}}"
                                                                        (click)="getRatingAndComment(Period.Task.id, getNextId(Periods.id))"
                                                                        class="rating{{medianRating(Period.allRatings)}}bg">
                                                                </p>
                                                                <div class="dropdown dropdownLeftSide ss"
                                                                    *ngIf="Period.periodId==Periods.id && !Period.rating && pdx==0">
                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1"
                                                                        href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBox()"
                                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    </a>


                                                                    <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                                                        <div class="divSlider slider">
                                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                <div id="custom-handle" class="ui-slider-handle custom-handle">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                            id='range{{Period.Task.id}}{{Period.periodId}}' class="" value="">
                                                                        <textarea class="form-control"

                                                                            [(ngModel)]="feedback.text"
                                                                            placeholder="Enter your comments"></textarea>
                                                                            <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                (click)="addRating(Period.Task.id,Period.periodId, feedback.text)">Submit</a>
                                                                    </div>
                                                                </div>
                                                                <div class="dropdown dropdownRightSide pp"
                                                                    *ngIf="Period.periodId==getNextId(Periods.id) && !Period.rating && pdx==0">
                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1"
                                                                        href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBox()"
                                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    </a>

                                                                    <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                                                        <div class="divSlider slider">
                                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                <div id="custom-handle" class="ui-slider-handle custom-handle">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                            id='range{{Period.Task.id}}{{Period.periodId}}' class="" value="">
                                                                        <textarea class="form-control"

                                                                            [(ngModel)]="feedback.text"
                                                                            placeholder="Enter your comments"></textarea>
                                                                            <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                (click)="addRating(Period.Task.id,Period.periodId, feedback.text)">Submit</a>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="setColspanValue(Initative.period,Periods.id,i)==1">
                                                        <p class="dropdownFullSize">
                                                            <input [readonly]="true" *ngIf="Period.periodId==Periods.id && Period.rating"
                                                                class="ratingBoxBlank" type="text" value="{{medianRating(Period.allRatings)}}"  (click)="getRatingAndComment(Period.Task.id, Periods.id)"
                                                                class="rating{{medianRating(Period.allRatings)}}bg">
                                                        </p>
                                                        <div class="dropdown dropdownFullSize"
                                                            *ngIf="Period.periodId==Periods.id && !Period.rating">
                                                            <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1"
                                                                href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBox()"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            </a>
                                                            <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                                                <div class="divSlider slider">
                                                                    <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                        <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                                    </div>
                                                                </div>
                                                                <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                    id='range{{Period.Task.id}}{{Period.periodId}}' class="" value="">
                                                                <textarea class="form-control"

                                                                    [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                                    <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                        (click)="addRating(Period.Task.id,Period.periodId, feedback.text)">Submit</a>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </span>
                                                </td>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="!Initative.period[Periods.id]">
                                            <td class="periodsBoxOuter"></td>
                                        </ng-container>
                                    </ng-container>
                                    <td></td>
                                    <td class="strategicSuccessNote">
                                        <p>{{Initative.title}}</p>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                        <tbody class="strategic_priority-tbody" *ngIf="htmlOptions.adjustedMode">
                            <ng-container *ngIf="project.InitativeData">
                                <tr *ngFor="let Initative of changeObjToArray(project.InitativeData)">
                                    <td></td>
                                    <td class="text-left widthSefix strategicIntTitle">
                                        <p>{{Initative.title}}</p>
                                    </td>
                                    <ng-container *ngIf="ExtraColumn">
                                        <td class="" *ngFor="let Column of ExtraColumn">
                                            <span class="text-center" *ngFor="let extra of changeObjToArrayForTasks(Initative.extraFields) ">
                                                <p *ngIf="extra.id==Column.id">{{extra.title}} </p>
                                            </span>
                                        </td>
                                    </ng-container>
                                    <td class="periodsBoxOuter" *ngIf="projectDetail.ownerColumn">
                                        <p>{{Initative.userId}}</p>
                                    </td>
                                    <ng-container *ngFor="let Periods of ProjectPeriod; let idx= index">
                                        <ng-container *ngIf="Initative.period[Periods.id]">
                                            <ng-container *ngIf="hideDivNextTime(Initative.period,Periods.id)">
                                                <td class="periodsBoxOuter" class="periodsBoxOuter" id="{{Initative.period[Periods.id].Task.id}}-{{Periods.id}}-{{Initative.period[Periods.id]?Initative.period[Periods.id].Task.title:''}}" [attr.colspan]="setColspanValue(Initative.period,Periods.id,'i')">
                                                    <span class="text-center" *ngFor="let Period of changeObjToArrayForTasks(Initative.period) ; let i= index">
                                                    <p *ngIf="Period.periodId==Periods.id">{{Period.Task.title}} </p>
                                                    <ng-container *ngIf="setColspanValue(Initative.period,Periods.id,i)>1">
                                                        <ng-container>
                                                            <ng-container
                                                                *ngFor="let in of counter(setColspanValue(Initative.period,Periods.id,i)) ; let pdx= index">
                                                                <p class="dropdownLeftSide">
                                                                    <input [readonly]="true"
                                                                        *ngIf="Period.periodId==Periods.id && Period.rating && pdx==0"
                                                                        class="ratingBoxBlank" type="text" value="{{adjustedMode(Period.allRatings)}}"
                                                                        (click)="getRatingAndComment(Period.Task.id, Periods.id)"
                                                                        class="rating{{adjustedMode(Period.allRatings)}}bg"></p>
                                                                <p class="dropdownRightSide">
                                                                    <input [readonly]="true"
                                                                        *ngIf="Period.periodId==getNextId(Periods.id) && Period.rating && pdx!=0"
                                                                        class="ratingBoxBlank" type="text" value="{{adjustedMode(Period.allRatings)}}"
                                                                        (click)="getRatingAndComment(Period.Task.id, getNextId(Periods.id))"
                                                                        class="rating{{adjustedMode(Period.allRatings)}}bg">
                                                                </p>
                                                                <div class="dropdown dropdownLeftSide ss"
                                                                    *ngIf="Period.periodId==Periods.id && !Period.rating && pdx==0">
                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1" (click)="blankBox()"
                                                                        href="javascript:void(0)" role="button" id="dropdownMenuLink"
                                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    </a>


                                                                    <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                                                        <div class="divSlider slider">
                                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                <div id="custom-handle" class="ui-slider-handle custom-handle">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                            id='range{{Period.Task.id}}{{Period.periodId}}' class="" value="">
                                                                        <textarea class="form-control" [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                                            <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                (click)="addRating(Period.Task.id,Period.periodId, feedback.text)">Submit</a>
                                                                    </div>
                                                                </div>
                                                                <div class="dropdown dropdownRightSide pp"
                                                                    *ngIf="Period.periodId==getNextId(Periods.id) && !Period.rating && pdx==0">
                                                                    <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1"
                                                                        href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBox()"
                                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    </a>

                                                                    <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                                                        <div class="divSlider slider">
                                                                            <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                                <div id="custom-handle" class="ui-slider-handle custom-handle">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                            id='range{{Period.Task.id}}{{Period.periodId}}' class="" value="">
                                                                        <textarea class="form-control"

                                                                            [(ngModel)]="feedback.text"
                                                                            placeholder="Enter your comments"></textarea>
                                                                            <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                                (click)="addRating(Period.Task.id,Period.periodId, feedback.text)">Submit</a>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="setColspanValue(Initative.period,Periods.id,i)==1">
                                                        <p class="dropdownFullSize">
                                                            <input [readonly]="true" *ngIf="Period.periodId==Periods.id && Period.rating"
                                                                class="ratingBoxBlank" type="text" value="{{adjustedMode(Period.allRatings)}}"  (click)="getRatingAndComment(Period.Task.id, Periods.id)"
                                                                class="rating{{adjustedMode(Period.allRatings)}}bg">
                                                        </p>
                                                        <div class="dropdown dropdownFullSize"
                                                            *ngIf="Period.periodId==Periods.id && !Period.rating">
                                                            <a class="btn btn-outline-dark dropdown-toggle btn-block pt-3 mt-1"
                                                                href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="blankBox()"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            </a>
                                                            <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                                                <div class="divSlider slider">
                                                                    <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                                        <div id="custom-handle" class="ui-slider-handle custom-handle"></div>
                                                                    </div>
                                                                </div>


                                                                <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange"
                                                                    id='range{{Period.Task.id}}{{Period.periodId}}' class="" value="">
                                                                <textarea class="form-control"

                                                                    [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                                                    <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1"
                                                                        (click)="addRating(Period.Task.id,Period.periodId, feedback.text)">Submit</a>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </span>
                                                </td>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="!Initative.period[Periods.id]">
                                            <td class="periodsBoxOuter"></td>
                                        </ng-container>
                                    </ng-container>
                                    <td></td>
                                    <td class="strategicSuccessNote">
                                        <p>{{Initative.title}}</p>
                                    </td>
                                </tr>
                            </ng-container>

                        </tbody>

                    </table>

                </div>
            </div>

            <div class="rightSidebar_Table-body" [ngClass]="{'active' : sidebarToggle}" *ngIf="htmlOptions.download">
                <div class="rightSidebar_Table-header">
                    <h4>Rating & Comments
                        <a class="pull-right userViewLink" (click)="userColHideShow()" href="javascript:void(0);"> <i style="font-size: 20px;" class="fa fa-user"></i> </a>
                        <a class="rightSidebarCloseBtn" href="javascript:void(0);" (click)="closeCommentPopup()"> <i class="fa fa-times"></i> </a>
                    </h4>
                </div>
                <div class="table-responsive normal_table-outer" *ngIf="ratingAndComment">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="d-none" [ngClass]="{'d-none' : userColHide}">User </th>
                                <th class="text-center">Rating</th>
                                <th class="text-center">Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let rating of ratingAndComment">
                                <td class="d-none" [ngClass]="{'d-none' : userColHide}">{{rating.user_name}} </td>
                                <td class="text-center"> <button class="btn btn-sm rating{{getRound(rating.rating)}}bg"> {{getRound(rating.rating)}} </button> </td>
                                <td class="text-left">{{rating.comment}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="table-responsive normal_table-outer" *ngIf="ratingAndComment">
                    <div *ngIf="notValid" class="alert alert-danger mt-2">
                        <strong>{{message}}.</strong>
                    </div>
                </div>
            </div>

        </div>
    </div>