import { Component, OnInit, ChangeDetectorRef  } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "../../services/commonservices";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GrowlerService } from "../../services/growler.service";
import { BehaviorSubject } from "rxjs";
import { NgxOtpInputConfig } from "ngx-otp-input";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  logoUrl: string;
  roles: string;
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  loading: boolean = false;
  notValid: boolean = false;
  sendOTP: boolean = false;
  public user: any = {};
  public globalRoles: any = {};
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
  message: any;
  no;
  tValid: boolean = false;
  //loading: boolean = true;
  valid: boolean = false;
  isTrustedAfterLogin: any;
  deviceToken : any;
  otpValues: any = [];
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      inputBox: "my-super-box-class",
      input: "my-super-class",
      inputFilled: "my-super-filled-class",
      inputDisabled: "my-super-disable-class",
      inputSuccess: "my-super-success-class",
      inputError: "my-super-error-class",
    },
  };
  constructor(
    private router: Router,
    private commonService: CommonService,
    public growlerService: GrowlerService,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.logoUrl = localStorage.getItem("logo");
    if (localStorage.getItem("isLoggedIn") == "true") {
      this.router.navigateByUrl("/dashboard");
    }
    this.getSetting();    
  }

  onCheckboxChange(event: any) {
    console.log("Checkbox changed:", event.target.checked);
    this.user.is_trusted = event.target.checked;
    this.cdr.detectChanges(); // Trigger change detection if needed
  }
  
  getSetting() {
    this.loading = true;
    //call login api here
    this.commonService.getSetting().subscribe(
      (result) => {
        this.loading = false;
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.logoUrl = result["data"]["ResponseData"].logo;
        } else {
        }
      },
      (error) => {}
    );
  }
  /******On login submit function ******/

 
  onSubmit() {
    this.roles = "";
    this.loading = true;
    
     console.log("this.user++++",this.user);
 
    this.commonService.userlogin(this.user).subscribe((loginResult) => {
      this.loading = false;
      console.log("loginResult+++",loginResult);
      if (!loginResult["success"]) {
        this.message = loginResult["error"];
      }
      if (loginResult["success"] && loginResult["data"].ResponseCode == 200) {
        if (
          loginResult["data"].ResponseData &&
          (!loginResult["data"].ResponseData.is_trusted || loginResult["data"].ResponseData.is_trusted == 0) &&
          !loginResult["data"].ResponseData.token
        ) {
         
          this.sendOTP = true;
          setTimeout(() => {
            this.sendOTP = false;
          }, 4000);
        
          this.message = loginResult["data"].ResponseMessage;
          this.growlerService.success(loginResult["data"].ResponseMessage);
          this.growlerService.clear();
          this.isTrustedAfterLogin =
            loginResult["data"].ResponseData.is_trusted;
        } else if (
          (loginResult["data"].ResponseData &&
            loginResult["data"].ResponseData.is_trusted == 1) ||
          (loginResult["data"].ResponseData &&
            (!loginResult["data"].ResponseData.is_trusted || loginResult["data"].ResponseData.is_trusted == 0) &&
            loginResult["data"].ResponseData.token)
        ) {
          if (loginResult["data"].headers) {
            const setCookieHeader = loginResult["data"].headers['Set-Cookie'];
            const deviceIdMatch = setCookieHeader.match(/device_id=([^;]*)/);

            if (deviceIdMatch && deviceIdMatch.length > 1) {
              const deviceId = deviceIdMatch[1]; // Extract the device_id value

              this.cookieService.set('device_id', deviceId,365);
            }
          }

          this.loggedIn.next(true);
          this.message = loginResult["data"].ResponseMessage;
          this.commonService.setCurrentUser(loginResult["data"].ResponseData);
          this.setLoggedInTime();
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem(
            "user_session_data",
            loginResult["data"].ResponseData
          );
          localStorage.setItem(
            "profile_image",
            loginResult["data"].ResponseData.profile_image
          );
          localStorage.setItem("token", loginResult["data"].ResponseData.token);
          localStorage.setItem("userId", loginResult["data"].ResponseData.id);
          localStorage.setItem(
            "orgId",
            loginResult["data"].ResponseData.organization_id
          );
          localStorage.setItem(
            "is_initial_pass_change",
            loginResult["data"].ResponseData.is_initial_pass_change
          );
          localStorage.setItem("email", this.user.email);
          localStorage.setItem("role", "");
          localStorage.setItem(
            "username",
            loginResult["data"].ResponseData.user_name
          );
          localStorage.setItem(
            "orgnization_name",
            loginResult["data"].ResponseData.orgnization_name
          );
          let role = loginResult["data"].ResponseData.role;
          var self = this;
          role.forEach(function (item, index) {
            if (item != undefined) {
              if (self.roles == "") {
                self.roles = item.id;
              } else {
                self.roles = self.roles + "," + item.id;
              }
            }
          });
          localStorage.setItem("roles", self.roles);
          this.router.navigateByUrl("/dashboard");
        }
      } else {
        //this.loading = false;
        this.notValid = true;
        setTimeout(() => {
          this.notValid = false;
        }, 4000);
        this.message = loginResult["data"].ResponseMessage;
        localStorage.setItem("statusCode", loginResult["data"].ResponseCode);
        this.growlerService.error("Login Failed");
        this.growlerService.clear();
      }
    });
  }
  setLoggedInTime() {
    var loginTime = new Date().getTime();
    localStorage.setItem("LoggedInTime", loginTime.toString());
  }
  getPermissionByRole(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = JSON.stringify(result["data"].ResponseData);
          localStorage.setItem("globalRoles", this.globalRoles);
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }

  handeOtpChange(value: string[]): void {
    console.log("change...", value);
    this.otpValues = value;
    if (this.otpValues.length) {
      this.valid = this.otpValues.some((el: any) => el === null || el === "");
    }
  }

  handleFillEvent(value: string): void {
    console.log("fill...", value);
    this.user.otp = value;
  }

  checkIsEmailTrusted(email: any, device_id : any) {
    this.loading = true;
    //call login api here
    this.commonService.checkEmailTrusted(email, device_id).subscribe(
      (result) => {
        this.loading = false;
        if (result["success"] && result["data"].ResponseCode == 200) {
          let data = result["data"]["ResponseData"];
          if (data) {
            // data.is_trusted = 1;
            this.user.is_trusted = data.is_trusted;
            this.cdr.detectChanges(); 
            console.log("data.is_trusted...", data);
            console.log("user.is_trusted...", this.user.is_trusted);
          }
        } else {
        }
      },
      (error) => {}
    );
  }

  onChangeEvent(ev: any) {
    console.log("ev...", ev.target.value);
    this.deviceToken = this.cookieService.get('device_id');
    console.log("this.deviceToken+++dddd",this.deviceToken)
    if (ev.target.value && ev.target.value != "") {
      this.checkIsEmailTrusted(ev.target.value, this.deviceToken);
    }
  }
}
