<app-growler>
</app-growler>
<header class="header_body">
    <div class="row">
        <div class="col-lg-7"></div>
        <div class="col-lg-5">
            <div class="header__topbar-wrapper text-right headerTopPaddipad">

            </div>
        </div>
    </div>
</header>
<div class="wrapper">
    <div class="loaderOuter" *ngIf="loading">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
    <aside class="leftNav themeBg">
        <nav>
            <div class="navcollapseIcon">
                <a href="javaScript:void(0)" onclick="myFunction()"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
            </div>
            <div class="navLogo">
                <a routerLink="/"> <img [src]="logoUrl" alt="logo" class="img-fluid mt-3"> </a>
            </div>

        </nav>
    </aside>
    <div class="inner-container headerTopPaddipad">
        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-10 col-xl-8">

                </div>
            </div>
        </div>

        <div class="mb-3">
            <h3 class="sfp_project-title">Stakeholder Feedback Report</h3>
        </div>
        <div class="white_box-body">
            <div class="sfp_project_header-outer">
                <div class="row">
                    <div class="col-md-7">
                        <h3 class="sfp_project-title">
                            <span>Displaying period:
                                <div class="currentMonth_Box d-inline-block text-center">
                                    <p>{{currentMonth}} <span *ngIf="showCompareMode">(Current)</span></p>
                            <span class="progressPipe_currentbg current_Result_Bar-Bg" *ngIf="showCompareMode"></span>
                    </div>
                    </span>
                    </h3>
                </div>

            </div>
        </div>

        <div class="table-responsive pb-4 pr-4" *ngIf='showCompareMode'>
            <div class="sfpProject_table-outer">
                <table>
                    <thead>
                        <tr>
                            <th colspan="3" rowspan="2" class="text-left v-top"><span>Perceptions of Key Leadership
                                        Capabilities &
                                        Behaviours</span></th>
                            <!-- <th colspan="4"> Summary </th>
                            <th colspan="10">Score Results</th> -->
                        </tr>
                        <!-- <tr>
                            <th><small>Current Period Mean</small></th>
                            <th><small>Compared Period Mean</small> </th>
                            <th><small>Delta</small></th>
                            <th><small>Trend</small></th>

                            <th><small>1</small></th>
                            <th><small>2</small></th>
                            <th><small>3</small></th>
                            <th><small>4</small></th>
                            <th><small>5</small></th>
                            <th><small>6</small></th>
                            <th><small>7</small></th>
                            <th><small>8</small></th>
                            <th><small>9</small></th>
                            <th><small>10</small></th>

                        </tr> -->

                    </thead>

                    <tbody>

                        <tr *ngFor="let project of projectData; let idx= index">
                            <td><span class="text-center">{{idx+1}}</span></td>
                            <td><span>{{project.question}}</span></td>
                            <td>
                                <a href="javascript:void(0)" data-toggle="tooltip" [title]="project.description"><i
                                            class="fa fa-question-circle" aria-hidden="true"></i></a>


                            </td>
                            <td>{{project.mean | number : '1.1-1'}}</td>
                            <td>{{project.secondaryMean| number : '1.1-1'}}</td>
                            <td>{{delta(project.mean, project.secondaryMean)| number : '1.1-1'}}</td>
                            <td>
                                <i *ngIf="trend(project.mean, project.secondaryMean) == 1" class="fa fa-arrow-up" aria-hidden="true"></i>
                                <i *ngIf="trend(project.mean, project.secondaryMean) == 2" class="fa fa-arrow-up upRight_angle" aria-hidden="true"></i>
                                <i *ngIf="trend(project.mean, project.secondaryMean) == 3" class="fa fa-arrow-down downRight_angle" aria-hidden="true"></i>
                                <i *ngIf="trend(project.mean, project.secondaryMean) == 4" class="fa fa-arrow-down" aria-hidden="true"></i>
                                <i *ngIf="trend(project.mean, project.secondaryMean) == 5" class="fa fa-arrow-up upRight_angle" aria-hidden="true"></i>
                            </td>
                            <td colspan="10">
                                <div class="scoreResult_bar-body">
                                    <div class="scoreBarpipe current_Result_Bar-Bg mb-2" [ngStyle]="{'left.%': (project.minval*10)-5, 'width.%': calculateWidth(project.minval, project.maxval)}" *ngIf='project.minval'>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">{{project.minval| number : '1.1-1'}}</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">{{project.maxval| number : '1.1-1'}}</span>
                                    </div>
                                    <div class="scoreBarpipe compareMonth_Result_Bar-Bg" [ngStyle]="{'left.%': (project.secondaryMinval*10)-5, 'width.%': calculateWidth(project.secondaryMinval, project.secondaryMaxval)}" *ngIf='project.secondaryMinval'>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_startpoint">{{project.secondaryMinval| number : '1.1-1'}}</span>
                                        <span class="scoreBarpipe_Circle"></span>
                                        <span class="scoreBarpipe_leftRight_Point scoreBarpipe_endpoint">{{project.secondaryMaxval| number : '1.1-1'}}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
        <!--  table-responsive -->
        <div class="pb-4 pr-4" *ngIf='!showCompareMode'>
            <div class="sfpProject_table-outer">
                <table>
                    <thead>
                        <tr>
                            <th colspan="3" rowspan="2" class="text-left v-top"><span>Perceptions of Key Leadership
                                        Capabilities &
                                        Behaviours</span></th>
                            <!-- <th colspan="4"> Summary </th>
                            <th colspan="10">Score Results</th> -->
                            <th>Feedback</th>
                        </tr>
                        <!-- <tr>
                            <th><small>Mean</small></th>
                            <th><small>Median</small> </th>
                            <th><small>Min</small></th>
                            <th><small>Max</small></th>

                            <th><small>1</small></th>
                            <th><small>2</small></th>
                            <th><small>3</small></th>
                            <th><small>4</small></th>
                            <th><small>5</small></th>
                            <th><small>6</small></th>
                            <th><small>7</small></th>
                            <th><small>8</small></th>
                            <th><small>9</small></th>
                            <th><small>10</small></th>

                            <th><small>&nbsp;</small></th>

                        </tr> -->

                    </thead>

                    <tbody>
                        <ng-container *ngFor="let project of projectData; let idx= index">
                            <tr id="{{project.id}}">

                                <td><span class="text-center">{{idx+1}}</span></td>
                                <td><span>{{project.question}}</span></td>
                                <td>
                                    <a href="javascript:void(0)" data-toggle="tooltip" [title]="project.description"><i
                                                class="fa fa-question-circle" aria-hidden="true"></i></a>

                                </td>



                                <td id="r{{project.id}}" class="feedbackRatingbgWhite" (mouseover)="addClass(project.id)" (mouseleave)="removeClass(project.id)">

                                    <div class="dropdown" *ngIf='!getRatingdata(project.userRating)'>
                                        <a class="p-3 ratingBtBorder" href="javascript:void(0)" role="button" id="dropdownMenuLink" data-toggle="dropdown" (click)="blankBox()" aria-haspopup="true" aria-expanded="false">
                                        </a>
                                        <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                            <div class="divSlider slider">
                                                <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                    <div id="custom-handle" class="ui-slider-handle custom-handle">
                                                    </div>
                                                </div>
                                            </div>
                                            <input type="hidden" [(ngModel)]="feedback.range" name="feedbackRange" id='range{{project.id}}{{token}}' class="" value="">
                                            <textarea class="form-control" [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                            <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1" (click)="addRating(project.id,token, feedback.text)">Submit</a>
                                        </div>
                                    </div>
                                    <div class="dropdown" *ngIf='getRatingdata(project.userRating)'>

                                        <a class="p-3 ratingBtBorder" href="javascript:void(0)" role="button" id="dropdownMenuLink" data-toggle="dropdown" (click)="blankBoxRating(project.userRating)" aria-haspopup="true" aria-expanded="false">
                                            <span class="m-0 text-center">{{getRatingdata(project.userRating)| number : '1.1-1'}}</span>
                                        </a>
                                        <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                            <div class="divSlider slider">
                                                <div style="margin:0px" id="bonusScore" Style="width:100px;">
                                                    <div id="custom-handle" class="ui-slider-handle custom-handle">
                                                    </div>
                                                </div>
                                            </div>

                                            <input type="hidden" name="feedbackRange" id='range{{project.id}}{{token}}' class="" value="{{getRatingdata(project.userRating)}}">
                                            <textarea class="form-control" [(ngModel)]="feedback.text" placeholder="Enter your comments"></textarea>
                                            <a href="javascript:void(0)" class="btn btn-primary pull-right btn-sm mt-1" (click)="addRating(project.id,token, feedback.text)">Submit</a>
                                        </div>
                                    </div>
                                    <!-- <label class="m-0 text-center"><p *ngIf="getRatingdata(project.userRating)">{{getRatingdata(project.userRating)| number : '1.1-1'}}</p></label> -->
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>

                </table>
                <div class="notinpdf text-right mt-2" *ngIf="projectData.length">
                    <button (click)="exitwindow()" class="btn btn-danger btn-sm px-4">Exit</button>
                </div>
            </div>
        </div>
    </div>


</div>
</div>