// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//const API_PATH = "http://192.168.0.80:3006/api";
const API_PATH = "http://192.168.10.92:3000/api";
const API_PATH_LOCAL = "http://192.168.10.92:3006/uat-v2";

//const API_PATH ="http://localhost:3006";
   //const BaseUrl = "https://dev-adelante-images.s3.ap-southeast-2.amazonaws.com/_development";
  //const BaseUrl = "https://uat-adelante-images.s3.ap-southeast-2.amazonaws.com/_development";
  const BaseUrl = "https://www.app.theadelantegroup.com/";
 const API_PATH_PROD_OLD =
   "https://470zufeeq7.execute-api.ap-southeast-2.amazonaws.com/prod";
   const API_PATH_UAT_OLD = "https://bt1tia2mzc.execute-api.ap-southeast-2.amazonaws.com/uat";
   const API_PATH_PROD =
   "https://zytj3s8up4.execute-api.ap-southeast-2.amazonaws.com/prod";
   const API_PATH_UAT = "https://l13b7zfgvb.execute-api.ap-southeast-2.amazonaws.com/uat-v2";
export const environment = {
  production: true,
  env: "production",
  // aws_bucketName: "websiteimageupload",
  // aws_bucketURL: "https://websiteimageupload.s3.amazonaws.com/",
  // //aws_bucketName: 'adminstreetpin',
  // aws_folderName: "_cmsImages",
  // aws_region: "us-east-1",
  // region: "eu-west-2",
  API_BASE_URL: API_PATH_PROD, // API_PATH_PROD, API_PATH_UAT, // 
  BASE_URL: BaseUrl
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
