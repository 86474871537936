<app-growler>
</app-growler>
<header class="header_body">
    <div class="row">
        <div class="col-lg-7"></div>
        <div class="col-lg-5">
            <div class="header__topbar-wrapper text-right headerTopPaddipad">

            </div>
        </div>
    </div>
</header>
<div class="wrapper">
    <div class="loaderOuter">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
    <aside class="leftNav themeBg">
        <nav>
            
            <div class="navLogo">
                <a> <img [src]="logoUrl" alt="logo" class="img-fluid mt-3"> </a>
            </div>

        </nav>
    </aside>
    <div class="inner-container headerTopPaddipad">
        <div class="white_box-body">
            <div class="sfp_project_header-outer">
                <div class="row">
                    <div class="paymentFaild" style="margin-left: 32%;">
                        <p>
                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                        </p>
                        <h1 >
                            Thanks 
                        </h1>
                        <p>
                            Your work is saved.<br>
                    You can re-enter the application if required.
                        </p>
                       
                    </div>

                </div>
        </div>

        
        
    </div>


</div>
</div>


