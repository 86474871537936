import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private router: Router
  ) {}
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  logout(): void {
    //alert('logout');
    this.loggedIn.next(false);
    localStorage.removeItem("screenSize");
    localStorage.removeItem('token');
    localStorage.setItem('isLoggedIn', "false");
    localStorage.removeItem('user_session_data');
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    localStorage.removeItem('username');
    localStorage.removeItem('orgId');
    localStorage.removeItem("globalRoles");
    localStorage.removeItem("editProjectId");
    localStorage.removeItem("SPcurrentUser");
    this.router.navigateByUrl("/");
  }
}
